import React, { useState } from "react";
import { AddLanguages } from "../../../../../../../../core/Services";
import { Button, Input } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { Dropdown } from "primereact/dropdown";
import { Message } from "primereact/message";

export default function LanguagesPage({
  uniqueCustomerId,
  selectedMonth,
  selectedCustomer,
  updateTableData,
  browser
}) {
  
  const [languages, setLanguages] = useState("");
  const [platform, setPlatform] = useState("");
  const [visitor, setVisitor] = useState("");
  const [validationError, setValidationError] = useState(false);

  const toast = useToast();

  const validateInputs = () => {
    if (
      !selectedCustomer ||
      !platform ||
       !visitor || 
      !languages ||
      !selectedMonth
    ) {
      setValidationError(true);
      return false;
    }
    setValidationError(false);
    return true;
  };


  const AddCountry = async () => {
    if (!validateInputs()) return;

    try {
      const response = await AddLanguages(
        uniqueCustomerId,
        platform,
        languages,
        visitor,
        selectedMonth
      );

      if (response.message === "Hata") {
        toast({
          title: "Hata",
          description: "Kullanıcya Ait Bu Ay Verisi Zaten Mevcut",
          status: "error",
          duration: 3000,
          position: "top-right",
          isClosable: true,
        });
      } else {
        setPlatform("");
        setLanguages("");
        setVisitor("");

        toast({
          title: "Başarılı",
          description: "Ülke başarıyla eklendi.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        updateTableData();
      }
    } catch (error) {
      console.error("Hata oluştu:", error);
      // Hata durumunda kullanıcıya bilgi vermek için bir toast veya başka bir bildirim yapılabilir.
    }
  };


  return (
    <div>
      <div>
        <div className="font-bold text-xl mt-4">Platform Seçiniz : </div>
        <div className="mt-2">
          <Dropdown
            value={platform}
            onChange={(e) => setPlatform(e.value)}
            options={browser}
            placeholder="Platform Seçiniz"
            style={{
              border:
                validationError && !platform
                  ? "1px solid red"
                  : "1px solid gray",
              width: "30rem",
            }}
          />
        </div>
      </div>
      <div className="font-bold text-xl mt-4">Dil Giriniz :</div>
      <div className="mt-2">
        <Input
          filter
          onChange={(e) => {
            setLanguages(e.target.value);
          }}
          value={languages}
          placeholder="Dil Giriniz"
          style={{
            border:
              validationError && !languages
                ? "1px solid red"
                : "1px solid gray",
            width: "30rem",
          }}
        />
      </div>
      <div>
        <div className="font-bold text-xl mt-4">
          Kullanıcı Sayısını Giriniz :
        </div>
        <div className="mt-2">
          <Input
            filter
            onChange={(e) => {
              setVisitor(e.target.value);
            }}
            value={visitor}
            placeholder="Kullanıcı Sayısı"
            style={{
              border:
                validationError && !visitor
                  ? "1px solid red"
                  : "1px solid gray",
              width: "30rem",
            }}
          />
        </div>
      </div>

      {validationError && (
        <div className="text-red-500 mt-2 text-sm">
          Lütfen boş alanları doldurunuz.
        </div>
      )}
      <div className="mt-3 flex justify-content-end">
        <Button width="8rem" onClick={AddCountry}>
          Kaydet
        </Button>
      </div>
      <Message
        className="mt-4 w-full"
        severity="info"
        text="Google Analytics > Raporlar > Kullanıcı > Demografik Grup Ayrıntıları"
      />
    </div>
  );
}
