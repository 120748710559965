import React, { useEffect, useState } from "react";
import { GetPagesGraph } from "../../../../../../../core/Services";
import { useAuth } from "../../../../../../../auth/core/AuthContext";
export default function PagesTable() {
  const [pages, setPages] = useState([]);
  const { userData } = useAuth();


   const browser = [
     { value: "0", label: "Google" },
     { value: "1", label: "Yandex" },
     { value: "2", label: "Diğer" },
   ];

   const months = [
     { name: "Ocak", value: "01" },
     { name: "Şubat", value: "02" },
     { name: "Mart", value: "03" },
     { name: "Nisan", value: "04" },
     { name: "Mayıs", value: "05" },
     { name: "Haziran", value: "06" },
     { name: "Temmuz", value: "07" },
     { name: "Ağustos", value: "08" },
     { name: "Eylül", value: "09" },
     { name: "Ekim", value: "10" },
     { name: "Kasım", value: "11" },
     { name: "Aralık", value: "12" },
   ];
  useEffect(() => {
    GetPagesGraph(userData?.unique_id)
      .then((data) => {
        setPages(data.data);
      })
      .catch((error) => {
        console.log("Ülke Veri Çekme Hatası", error);
      });
  }, [userData?.unique_id]);


  return (
    <div>
      <table
        style={{
          fontFamily: "arial, sans-serif",
          borderCollapse: "collapse",
          width: "80%",
        }}
      >
        <thead>
          <tr>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            ></th>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              Dönem
            </th>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              Platform
            </th>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              Sayfalar
            </th>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              Kullanıcılar
            </th>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              Görüntüleme Sayısı
            </th>
          </tr>
        </thead>
        <tbody>
          {pages &&
            pages?.map((page, index) => (
              <tr
                key={page.id}
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                <td
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  {index + 1}
                </td>
                <td
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  {months?.find((months) => months.value === page.month)
                    ?.name || ""}
                </td>
                <td
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  {browser?.find((bro) => bro.value === page.platform)?.label ||
                    ""}
                </td>
                <td
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  {page?.pages}
                </td>
                <td
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  {page?.visitor}
                </td>
                <td
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  {page?.screening}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}
