import React from "react";
import AdsMain from "./components/AdsMain";

export default function AdsReport({ selectedMonth }) {
  return (
    <div>
      <AdsMain selectedMonth={selectedMonth} />
    </div>
  );
}
