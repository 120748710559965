import React from 'react'

import Sidebar from '../../../../_ekdizayn/layout/Sidebar/Sidebar'
import NavBar from '../../../../_ekdizayn/layout/Navbar/components/Header'
import { Grid, GridItem } from '@chakra-ui/react'
import CustomerList from './components/CustomerList'


export default function CustomerPage() {
  return (
    <Grid
      templateAreas={`"header header"
    "nav main"
   `}
      gridTemplateRows={'auto 1fr '}
      gridTemplateColumns={'auto 1fr'}

      h='auto'
      color='blackAlpha.700'
      fontWeight='bold'
    >
      <GridItem area={'header'}>
        <NavBar />
      </GridItem>
      <GridItem area={'nav'}>
        <Sidebar />
      </GridItem>
      <GridItem area={'main'}>
        <CustomerList />
      </GridItem>

    </Grid>
  )
}
