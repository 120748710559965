import React, { useEffect, useState } from "react";
import { Card } from "primereact/card";
import Support from "../../../../../../../../_ekdizayn/img/support.png";
import Offer from "../../../../../../../../_ekdizayn/img/offer.png";
import Service from "../../../../../../../../_ekdizayn/img/service.png";
import Bill from "../../../../../../../../_ekdizayn/img/bill.png";
import { SupportsByUniqueId } from "../../../../../../../core/Services";
import { useAuth } from "../../../../../../../auth/core/AuthContext";
import { Link } from "react-router-dom";

export default function DashboardCard() {
  const { userData } = useAuth();
  const [supportTotal, setSupportTotal] = useState([]);

  useEffect(() => {
    SupportsByUniqueId(userData?.unique_id)
      .then((data) => {
        setSupportTotal(data.supports);
      })
      .catch((error) => {
        console.log("Destek Çekme Hatası", error);
      });
  }, [userData?.unique_id]);

  const NumberOfSupportRequests = supportTotal?.filter(
    (item) => Number(item.tasks_id) === 3
  ).length;

  const cardsData = [
    {
      id: 1,
      number: "0",
      service: "Hizmetler",
      image: Service,
      visibility: "0.5",
      to: "",
    },
    {
      id: 2,
      number: "0",
      service: "Teklifler",
      image: Offer,
      visibility: "0.5",
      to: "",
    },
    {
      id: 3,
      number: NumberOfSupportRequests?.toString(),
      service: "Destek Talepleri",
      image: Support,
      visibility: "",
      to: "/customer/support",
    },
    {
      id: 4,
      number: "0",
      service: "Faturalar",
      image: Bill,
      visibility: "0.5",
      to: "",
    },
  ];

  return (
    <div className="flex  justify-content-start align-items-center h-100 w-100 p-3">
      {cardsData.map((card) => (
        <Link to={card.to} key={card.id} className="mr-3 mb-3">
          <Card
            title={card.number}
            className="custom-card"
            style={{
              width: "14rem",
              opacity: card.visibility,
              backgroundImage: `url(${card.image})`,
              backgroundRepeat: "no-repeat",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <p className="m-0">{card.service}</p>
          </Card>
        </Link>
      ))}
    </div>
  );
}
