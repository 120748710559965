import React, { useState } from "react";
import ViewVisitorChartPage from "./components/ViewVisitorChartPage";
import { Splitter, SplitterPanel } from "primereact/splitter";
import ViewVisitorChartTable from "./components/ViewVisitorChartTable";
import ViewVisitorAppearance from "./components/ViewVisitorAppearance";

export default function ViewVisitorChart({
  uniqueCustomerId,
  selectedMonth,
  selectedCustomer,
  months,
}) {
  const [tableKey, setTableKey] = useState(0);
  const [appearanceKey, setAppearanceKey] = useState(0);
  const DataTypes = [
    { name: "Görüntüleme", value: "1" },
    { name: "Kullanıcılar", value: "2" },
    { name: "Etkinlik", value: "3" },
  ];

  const updateTableData = () => {
    setTableKey((prevKey) => prevKey + 1);
    setAppearanceKey((prevKey) => prevKey + 1);
  };

  return (
    <Splitter>
      <SplitterPanel>
        <ViewVisitorChartPage
          selectedMonth={selectedMonth}
          selectedCustomer={selectedCustomer}
          uniqueCustomerId={uniqueCustomerId}
          DataTypes={DataTypes}
          updateTableData={updateTableData}
        />
      </SplitterPanel>
      <SplitterPanel size={75} style={{ display: "block", padding: "1rem" }}>
        <ViewVisitorChartTable
          key={tableKey}
          DataTypes={DataTypes}
          months={months}
          selectedCustomer={selectedCustomer}
          updateTableData={updateTableData}
          selectedMonth={selectedMonth}
          uniqueCustomerId={uniqueCustomerId}
        />
        <br></br>
        <ViewVisitorAppearance
          appearanceKey={appearanceKey}
          DataTypes={DataTypes}
          months={months}
          selectedCustomer={selectedCustomer}
          selectedMonth={selectedMonth}
          uniqueCustomerId={uniqueCustomerId}
        />
      </SplitterPanel>
    </Splitter>
  );
}
