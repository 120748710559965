import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Flex,
  Button,
  Stack,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import Logo from "./Logo";
import { useToast } from "@chakra-ui/react";
import { useAuth } from "../../../../../../../auth/core/AuthContext";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { CustomerService } from "../../../../../../../core/Services";
import { Link } from "react-router-dom";

const Header = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <NavBarContainer className="not-to-print" {...props}>
      <Logo w="180px" />
      <MenuToggle toggle={toggle} isOpen={isOpen} />
      <MenuLinks isOpen={isOpen} />
    </NavBarContainer>
  );
};

const CloseIcon = () => (
  <svg width="24" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <title>Kapat</title>
    <path
      fill="black"
      d="M9.00023 7.58599L13.9502 2.63599L15.3642 4.04999L10.4142 8.99999L15.3642 13.95L13.9502 15.364L9.00023 10.414L4.05023 15.364L2.63623 13.95L7.58623 8.99999L2.63623 4.04999L4.05023 2.63599L9.00023 7.58599Z"
    />
  </svg>
);

const MenuIcon = () => (
  <svg
    width="24px"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="teal"
  >
    <title>Menu</title>
    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
  </svg>
);

const MenuToggle = ({ toggle, isOpen }) => {
  return (
    <Box display={{ base: "block", md: "none" }} onClick={toggle}>
      {isOpen ? <CloseIcon /> : <MenuIcon />}
    </Box>
  );
};

const MenuLinks = ({ isOpen }) => {
  const [navSize] = useState("");
  const toast = useToast();
  const { userData } = useAuth();
  const menu = useRef(null);
  const { logout } = useAuth();
  const handleButtonClick = async (e) => {
    const success = await logout();

    if (success) {
    } else {
      toast({
        title: "Giriş Başarısız",
        description: "Kullanıcı Adı ya da Parola Hatalı",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };
  const items = [
    {
      label: (
        <>
          <Button
            label="Ekle"
            className="w-full"
            style={{
              backgroundColor: "",
              color: "#1f1f1f",
            }}
          >
            Profilim
          </Button>
          <Link to="/settings">
            <Button
              label="Ekle"
              className="w-full"
              style={{
                backgroundColor: "",
                color: "#1f1f1f",
              }}
            >
              Ayarlar
            </Button>
          </Link>

          <Button
            label="Ekle"
            className="w-full"
            style={{
              backgroundColor: "",
              color: "#1f1f1f",
            }}
            onClick={handleButtonClick}
          >
            Çıkış Yap
          </Button>
        </>
      ),
    },
  ];

  const today = new Date();
  const options = {
    month: "long",
    day: "numeric",
    weekday: "long",
  };
  const formattedDate = today.toLocaleDateString("tr-TR", options);
  const [customer, setCustomer] = useState("");
  useEffect(() => {
    CustomerService()
      .then((data) => {
        setCustomer(data);
      })
      .catch((error) => {
        console.log("Hata", error);
      });
  }, []);

  const matchedCustomer = Array.isArray(customer)
    ? customer.find((c) => c.unique_id === userData?.unique_id)
    : null;
  return (
    <>
      {" "}
      <Box
        display={{ base: isOpen ? "block" : "none", md: "block" }}
        flexBasis={{ base: "100%", md: "auto" }}
      >
        <Stack
          spacing={8}
          align="center"
          justify={["center", "space-between", "flex-end", "flex-end"]}
          direction={["column", "row", "row", "row"]}
          pt={[4, 4, 0, 0]}
        >
          {/* İconlar */}

          <Stack align="center" justify="flex-start">
            <Flex align="center">
              <Flex
                flexDir="column"
                display={navSize === "small" ? "none" : "flex"}
                style={{ color: "black !important" }}
              >
                <div className="card flex justify-content-center">
                  <Menu model={items} popup ref={menu} id="popup_menu_left" />
                  <Menu>
                    <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                      <div>
                        <span>
                          Merhaba,{" "}
                          {matchedCustomer
                            ? matchedCustomer.name.split(" ")[0]
                            : ""}
                        </span>
                        <br />
                        <Text fontSize="sm" color="gray">
                          {formattedDate}
                        </Text>
                      </div>
                    </MenuButton>
                    <MenuList>
                      <MenuItem onClick={handleButtonClick}>Çıkış Yap</MenuItem>
                    </MenuList>
                  </Menu>
                </div>
              </Flex>
            </Flex>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

const NavBarContainer = ({ children, ...props }) => {
  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
      mb={-5}
      p={8}
      bg="#EDEDED"
      color="black"
      {...props}
    >
      {children}
    </Flex>
  );
};

export default Header;
