import React, { useEffect, useState } from "react";
import { Chart } from "primereact/chart";
import { GetTrafficSource } from "../../../../../../../../core/Services";

export default function TrafficSourceGraphAppearance({
  uniqueCustomerId,
  DataTypes,
  appearanceKey,
}) {
  const [trafficSourceData, setTrafficSourceData] = useState({});
  const [trafficSourceOptions, setTrafficSourceOptions] = useState({});
  const [trafficSource, setTrafficSource] = useState([]);

  useEffect(() => {
    GetTrafficSource(uniqueCustomerId)
      .then((data) => {
        setTrafficSource(data.data);
      })
      .catch((error) => {
        console.log("Ülke Veri Çekme Hatası", error);
      });
  }, [uniqueCustomerId, appearanceKey]);

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const data = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [
            documentStyle.getPropertyValue("--blue-500"),
            documentStyle.getPropertyValue("--yellow-500"),
            documentStyle.getPropertyValue("--green-500"),
          ],
          hoverBackgroundColor: [
            documentStyle.getPropertyValue("--blue-400"),
            documentStyle.getPropertyValue("--yellow-400"),
            documentStyle.getPropertyValue("--green-400"),
          ],
        },
      ],
    };

    // Gelen veriyi dönüştürme
    trafficSource.forEach((item) => {
      const dataTypeName = DataTypes.find(
        (dt) => dt.value === item.traffic_source
      )?.name;
      data.labels.push(dataTypeName ? dataTypeName : item.trafficSource);
      data.datasets[0].data.push(parseInt(item.value));
    });

    const options = {
      plugins: {
        legend: {
          labels: {
            usePointStyle: true,
          },
        },
      },
    };

    setTrafficSourceData(data);
    setTrafficSourceOptions(options);
  }, [DataTypes, trafficSource]);

  return (
    <div>
      <div className="mb-3 font-bold text-xl ml-5">Görünüm:</div>
      <Chart
        style={{ width: "30rem", height: "20rem" }}
        type="pie"
        data={trafficSourceData}
        options={trafficSourceOptions}
      />
    </div>
  );
}
