import { Textarea } from '@chakra-ui/react';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useState } from 'react'
import { AddGuarantee } from '../../../../../core/Services';

export default function AddGuaranteeDialog({ onChangeInput, toast,  form, setForm, customer, addDialog, setAddDialog }) {
    const [errors, setErrors] = useState({});

    const newCreate =  () => {
         AddGuarantee(form)
           .then((response) => {
             if (response.message === "Hata") {
               toast({
                 title: "Hata",
                 description: "Kullanıcya Ait Veri  Mevcut",
                 status: "error",
                 duration: 3000,
                 position: "top-right",
                 isClosable: true,
               });
             } else {
               toast({
                 title: "Başarılı",
                 description: "Veri başarılı bir şekilde kaydedildi.",
                 status: "success",
                 duration: 3000,
                 position: "top-right",
                 isClosable: true,
               });
             }
           })
           .catch((error) => {
             console.error("Backend'e gönderme hatası:", error);
             toast({
               title: "Hata",
               description: "Veri gönderilirken bir hata oluştu.",
               status: "error",
               duration: 3000,
               position: "top-right",
               isClosable: true,
             });
           });

    }
    const onSubmitForm = () => {
      const inputErrors = {};
      if (!form?.companyName) {
        inputErrors.companyName = "Lütfen müşteri seçiniz.";
      }
      if (!form?.region) {
        inputErrors.region = "Lütfen bölge giriniz";
      }
      if (!form?.productDescription) {
        inputErrors.productDescription = "Lütfen ürün açıklaması giriniz";
      }
      if (!form?.netPassageSize) {
        inputErrors.netPassageSize = "Lütfen net geçiş ölçüsü giriniz";
      }
      if (!form?.option) {
        inputErrors.option = "Lütfen opsiyon  giriniz";
      }
      if (!form?.firstRegistrationDate) {
        inputErrors.firstRegistrationDate = "Lütfen ilk kayıt tarihi  seçiniz";
      }
      if (!form?.endDate) {
        inputErrors.endDate = "Lütfen bitiş tarihi  seçiniz";
      }

      if (Object?.keys(inputErrors).length > 0) {
        setErrors(inputErrors);
      } else {
        setErrors({});
        newCreate();
        setForm(null);
        setAddDialog(false);
      }
    };
  const AddButon = () => {
    return (
      <Button
        label="Ekle"
        style={{ width: "9rem" }}
        onClick={onSubmitForm}
        className="btn-excel-two"
      />
    );
  };
  return (
    <Dialog
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      style={{ width: "50%" }}
      footer={AddButon}
      visible={addDialog}
      onHide={() => {
        setAddDialog(false);
        setForm(null);
      } }
      header="Garanti  Ekle"
      modal
    >
      <div className="confirmation-content">
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <div style={{ marginBottom: "1rem" }}>
              <Dropdown
                filter
                options={customer.map((cus) => ({
                  label: cus.title,
                  value: cus.id,
                }))}
                value={form?.companyName}
                className="w-full"
                name="companyName"
                onChange={onChangeInput}
                placeholder="Firma Adı"
                style={{
                  border: "1px solid gray",
                }}
              />
              {errors.companyName && (
                <small style={{ color: "red" }}>{errors.companyName} </small>
              )}
            </div>
            <div
              style={{
                marginBottom: "1rem",
              }}
            >
              <InputText
                filter
                value={form?.region}
                className="p-inputtext-lg w-full"
                name="region"
                onChange={onChangeInput}
                placeholder="Bölge"
                style={{
                  border: "1px solid gray",
                }}
              />
              {errors.region && (
                <small style={{ color: "red" }}>{errors.region} </small>
              )}
            </div>
            <div
              style={{
                marginBottom: "1rem",
              }}
            >
              <Textarea
                filter
                value={form?.productDescription}
                className="w-full"
                name="productDescription"
                onChange={onChangeInput}
                placeholder="Ürün Açıklama"
                style={{
                  border: "1px solid gray",
                }}
              />
              {errors.productDescription && (
                <small style={{ color: "red" }}>
                  {errors.productDescription}{" "}
                </small>
              )}
            </div>
            <div
              style={{
                marginBottom: "1rem",
              }}
            >
              <InputText
                filter
                value={form?.netPassageSize}
                className="p-inputtext-lg w-full"
                name="netPassageSize"
                onChange={onChangeInput}
                placeholder="Net Geçiş Ölçüsü"
                style={{
                  border: "1px solid gray",
                }}
              />
              {errors.netPassageSize && (
                <small style={{ color: "red" }}>{errors.netPassageSize} </small>
              )}
            </div>
            <div
              style={{
                marginBottom: "1rem",
              }}
            >
              <InputText
                filter
                value={form?.option}
                className="p-inputtext-lg w-full"
                name="option"
                onChange={onChangeInput}
                placeholder="Opsiyon"
                style={{
                  border: "1px solid gray",
                }}
              />
              {errors.option && (
                <small style={{ color: "red" }}>{errors.option} </small>
              )}
            </div>
            <div
              style={{
                marginBottom: "1rem",
              }}
            >
              <Calendar
                value={form?.firstRegistrationDate}
                placeholder="İlk Kayıt Tarihi"
                className="w-full"
                name="firstRegistrationDate"
                onChange={onChangeInput}
                locale="tr"
                style={{
                  border: "1px solid gray",
                  padding: "0.5rem",
                  borderRadius: "6px 6px 6px 6px",
                }}
                dateFormat="dd/mm/yy"
                showTime={false}
                firstDayOfWeek={1}
              />
              {errors.firstRegistrationDate && (
                <small style={{ color: "red" }}>
                  {errors.firstRegistrationDate}{" "}
                </small>
              )}
            </div>
            <div
              style={{
                marginBottom: "1rem",
              }}
            >
              <Calendar
                placeholder="Bitiş  Tarihi"
                value={form?.endDate}
                name="endDate"
                onChange={onChangeInput}
                className="w-full"
                locale="tr"
                style={{
                  border: "1px solid gray",
                  padding: "0.5rem",
                  borderRadius: "6px 6px 6px 6px",
                }}
                dateFormat="dd/mm/yy"
                showTime={false}
                firstDayOfWeek={1}
              />
              {errors.endDate && (
                <small style={{ color: "red" }}>{errors.endDate} </small>
              )}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
