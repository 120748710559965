import React, { useEffect } from "react";
import {

  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import DahsboardTableUserTask from "./DahsboardTableUserTask";
import DahsboardTableUserJobs from "./DashboardTableUserJobs";
import { useDispatch } from "react-redux";
import { addDashboardData } from "../../../../redux/DashboardSlice";
import { DashboardService } from "../../../../core/Services";



function DashboardTableUserPage() {
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await DashboardService();
        dispatch(addDashboardData(data));
      } catch (error) {
        console.error("API Hatası:", error);
      }
    };

    fetchData();
  }, [dispatch]);


  return (
          <Tabs align="end" variant="enclosed">
            <TabList>
              <Tab>Görevlerim</Tab>
              <Tab>İşlerim</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <DahsboardTableUserTask />
              </TabPanel>
              <TabPanel>
                <DahsboardTableUserJobs />
              </TabPanel>
            </TabPanels>
          </Tabs>
  );
}

export default DashboardTableUserPage;
