import React, { useEffect, useState } from "react";
import QuotationConfirmation from "./components/QuotationConfirmation";
import { AllCustomerForm, DeleteCustomerForm, TransferCustomerForm } from "../../../core/Services";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import packagesData from "../../../../_ekdizayn/api/packages.json";
import urgencyData from "../../../../_ekdizayn/api/urgency.json";
import { useToast } from "@chakra-ui/react";

export default function QuotationPage() {
  const [quotation, setQuotation] = useState([]);
  const toast = useToast();
  const [dialogs, setDialogs] = useState({});
  const [deleteDialogs, setDeleteDialogs] = useState({});
  const [selectedQuotation, setSelectedQuotation] = useState(null);
  const [selectedDeleteQuotation, setSelectedDeleteQuotation] = useState(null);
  const [deleteSuccess, setDeleteSuccess] = useState(false); // Başarıyla silme durumu
  const [transferSuccess, setTransferSuccess] = useState(false);
  const columns = [
    { field: "operations", header: "İşlemler" },
    { field: "name", header: "Ad" },
    { field: "surname", header: "Soyad" },
    { field: "phone", header: "Telefon" },
    { field: "email", header: "E-posta" },
    { field: "description", header: "Açıklama" },
    { field: "urgency", header: "Aciliyet" },
    { field: "package_id", header: "Seçilen Paketler" },
    { field: "created_at", header: "Talep Zamanı" },
  ];
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("tr-TR");
    const formattedTime = date.toLocaleTimeString("tr-TR");
    return `${formattedDate} ${formattedTime}`;
  };
  const handleComplateCustomer = async () => {
    try {
      if (!selectedQuotation) {
        console.error("Tamamlanacak Müşteri bulunamadı.");
        return;
      }

      const response = await TransferCustomerForm(selectedQuotation?.id);

      if (response.message === "Kullanici formu bulunamadi") {
        toast({
          title: "Hata",
          description: "Kullanıcı formu bulunamadı",
          status: "error",
          duration: 3000,
          position: "top-right",
          isClosable: true,
        });
      } else {
        setTransferSuccess(true)
        setDialogs(false);
        toast({
          title: "Başarılı",
          description: "Başarıyla müşteri tablosuna eklendi.",
          status: "success",
          duration: 3000,
          position: "top-right",
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Backend'e gönderme hatası:", error);
      toast({
        title: "Hata",
        description: "Veri gönderilirken bir hata oluştu.",
        status: "error",
        duration: 3000,
        position: "top-right",
        isClosable: true,
      });
    }
  };
  const deleteCustomerForm = async () => {
    try {
      if (!selectedDeleteQuotation) {
        console.error("Tamamlanacak Müşteri bulunamadı.");
        return;
      }

      
      const response = await DeleteCustomerForm(selectedDeleteQuotation?.id);

      
      if (response.message === "Silme sirasinda bir hata olustu") {
        toast({
          title: "Hata",
          description: "Silme sırasında bir hata oluştu",
          status: "error",
          duration: 3000,
          position: "top-right",
          isClosable: true,
        });
      } else {
        setDeleteSuccess(true);
        setDeleteDialogs(false);

        toast({
          title: "Başarılı",
          description: "Başarıyla silindi",
          status: "success",
          duration: 3000,
          position: "top-right",
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Backend'e gönderme hatası:", error);
      toast({
        title: "Hata",
        description: "Veri gönderilirken bir hata oluştu.",
        status: "error",
        duration: 3000,
        position: "top-right",
        isClosable: true,
      });
    }
  };
  const regulation = (quotation) => {
    const isVisible = dialogs[quotation.id] || false;
    const isVisibleDelete = deleteDialogs[quotation.id] || false;
    return (
      <React.Fragment>
        <Button
          icon="pi pi-check"
          rounded
          className="radio-b-new"
          onClick={() => {
            setSelectedQuotation(quotation);
            setDialogs((prevDialogs) => ({
              ...prevDialogs,
              [quotation.id]: true,
            }));
          }}
        />
        <Button
          icon="pi pi-trash"
          rounded
          color="#ae445a"
          severity="danger"
          className="radio-b-new"
          onClick={() => {
            setSelectedDeleteQuotation(quotation);
            setDeleteDialogs((prevDialogs) => ({
              ...prevDialogs,
              [quotation.id]: true,
            }));
          }}
        />
        <Dialog
          header="Müşteri Talebi İçeriği"
          visible={isVisible}
          style={{ width: "50vw" }}
          onHide={() => {
            setDialogs((prevDialogs) => ({
              ...prevDialogs,
              [quotation.id]: false,
            }));
            setSelectedQuotation(null);
          }}
          footer={
            <div>
              <Button
                label="Onayla"
                icon="pi pi-check"
                onClick={handleComplateCustomer}
                className="btn-excel-two"
                style={{ marginLeft: "15px" }}
              />
            </div>
          }
        >
          <div className="field">
            <label htmlFor="name" className="font-bold ">
              Ad-Soyad :{quotation?.name} {quotation?.surname}
            </label>
          </div>
          <div className="field">
            <label htmlFor="packages" className="mb-3 font-bold">
              Açıklama: {quotation?.description}
            </label>
          </div>
          <div className="field">
            <label htmlFor="description" className="mb-3 font-bold">
              Seçilen Paketler:{" "}
              {quotation?.package_id
                ?.split(",")
                .map((id, index) => {
                  const packageData = packagesData.find((pkg) => pkg.id === id);
                  return packageData ? packageData.title : "";
                })
                .filter(Boolean)
                .join(", ") || "Paket seçilmedi"}
            </label>
          </div>
          <div className="field">
            <label htmlFor="urgency" className="font-bold">
              Aciliyet Durumu :
              {urgencyData &&
                urgencyData.map((urg, i) =>
                  urg.value === quotation?.urgency ? urg.label : null
                )}
            </label>
          </div>
          <div className="field">
            <label htmlFor="created" className="font-bold">
              Talep Zamanı : {formatDate(quotation?.created_at)}
            </label>
          </div>
        </Dialog>
        <Dialog
          header="Müşteri Talebi Silme"
          visible={isVisibleDelete}
          style={{ width: "50vw" }}
          onHide={() => {
            setDeleteDialogs((prevDialogs) => ({
              ...prevDialogs,
              [quotation.id]: false,
            }));
            setSelectedDeleteQuotation(null);
          }}
          footer={
            <div>
              <Button
                label="Sil"
                icon="pi pi-check"
                onClick={deleteCustomerForm}
                className="btn-excel-two"
                style={{ marginLeft: "15px" }}
              />
            </div>
          }
        >
          <div className="field">
            <label htmlFor="name" className="font-bold ">
              Ad-Soyad :{quotation?.name} {quotation?.surname}
            </label>
          </div>
          <div className="field">
            <label htmlFor="packages" className="mb-3 font-bold">
              Açıklama: {quotation?.description}
            </label>
          </div>
          <div className="field">
            <label htmlFor="description" className="mb-3 font-bold">
              Seçilen Paketler:{" "}
              {quotation?.package_id
                ?.split(",")
                .map((id, index) => {
                  const packageData = packagesData.find((pkg) => pkg.id === id);
                  return packageData ? packageData.title : "";
                })
                .filter(Boolean)
                .join(", ") || "Paket seçilmedi"}
            </label>
          </div>
          <div className="field">
            <label htmlFor="urgency" className="font-bold">
              Aciliyet Durumu :
              {urgencyData &&
                urgencyData.map((urg, i) =>
                  urg.value === quotation?.urgency ? urg.label : null
                )}
            </label>
          </div>
          <div className="field">
            <label htmlFor="created" className="font-bold">
              Talep Zamanı : {formatDate(quotation?.created_at)}
            </label>
          </div>
        </Dialog>
      </React.Fragment>
    );
  };
  useEffect(() => {
    AllCustomerForm()
      .then((data) => {
        setQuotation(data);
      })
      .catch((error) => {
        console.log("Müşteri Formu Çekme Hatası", error);
      });
  }, [deleteSuccess ,transferSuccess]);
  return (
    <QuotationConfirmation
      regulation={regulation}
      columns={columns}
      formatDate={formatDate}
      packagesData={packagesData}
      urgencyData={urgencyData}
      quotation={quotation}
    />
  );
}
