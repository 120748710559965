import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  UserService,
  AddUserService,
  DeleteUserService,
  EditUserService,
} from "../../../../core/Services";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Card, CardHeader, Flex, Heading } from "@chakra-ui/react";
import { Dialog } from "primereact/dialog";
import userType from "../../../../../_ekdizayn/api/employees-type.json"
import userAuthY from "../../../../../_ekdizayn/api/employees-auth.json";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { BreadCrumb } from "primereact/breadcrumb";
import { useAuth } from "../../../../auth/core/AuthContext";

export default function EmployeesList() {
  const [users, setUsers] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState("");
  const [newName, setNewName] = useState("");
  const [is_auth, setIsAuth] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newPhone, setNewPhone] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newType, setNewType] = useState("");
  const [visible, setVisible] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [dep, setDep] = useState("");
  const [newDep, setNewDep] = useState("");
  const { userData } = useAuth();
  const toast = useRef(null);
  const [inputErrors, setInputErrors] = useState({
    name: false,
    email: false,
    dep: false,
    phone: false,
    type:false,
    password: false,
  });

  const hideDialog = () => {
    setAddModal(false);
    setEditModal(false);
    setType("");
  };
  const accessors = (rowData) => {
     const edit = editModal[rowData.id] || false;
     const deletes = visible[rowData.id] || false;
    return (
      <div style={{ display: "flex" }}>
        <div style={{ color: "#f39f5a" }}>
          <Button
            severity="warning"
            onClick={() => {
              setSelectedData(rowData);
              setEditModal((prevDialogs) => ({
                ...prevDialogs,
                [rowData.id]: true,
              }));
              setNewType(rowData.type);
              setNewDep(rowData.department);
              setIsAuth(rowData.is_auth);
              setNewName(rowData.name);
              setNewEmail(rowData.email);
              setNewPhone(rowData.phone);
            }}
            icon="pi pi-pencil"
          />
        </div>
        <div style={{ color: "#ae445a" }}>
          <Button
            icon="pi pi-trash"
            onClick={() => {
              setVisible((prevDialogs) => ({
                ...prevDialogs,
                [rowData.id]: true,
              }));
              setSelectedData(rowData);
              setName(rowData?.name);
              setEmail(rowData?.email);
              setPhone(rowData?.phone);
            


              if (rowData.type) {
                const type = userType.find(
                  (type) => type.value.toString() === rowData.type
                );
                setType(type?.label);
              } else {
                setType("Bilinmeyen");
              }
            }}
            severity="danger"
          />
        </div>
        <Dialog
          header="Kullanıcıyı silmek istediğinize emin misiniz?"
          visible={deletes}
          style={{ width: "50vw" }}
          onHide={() => {
            setVisible(false);
            setName("")
            setEmail("")
            setType("")
            setPhone("")
          } }
          footer={
            <div>
              <Button
                label="İptal"
                icon="pi pi-times"
                onClick={() => {
                  setVisible(false);
                  setName("");
                  setEmail("");
                  setType("");
                  setPhone("");
                }}
                className="btn-excel-two"
              />
              <Button
                label="Kullanıcıyı Sil"
                icon="pi pi-check"
                onClick={() => {
                  DeleteUserService(selectedData.id);
                  setVisible(false);
                   setName("");
                   setEmail("");
                   setType("");
                   setPhone("");
                }}
                className="btn-excel-two"
                style={{ marginLeft: "15px" }}
              />
            </div>
          }
        >
          <div className="confirmation-content">
          
                <div className="field">
                  <label htmlFor="name" className="font-bold">
                    Ad Soyad: {name}
                  </label>
                </div>

                <div className="field">
                  <label htmlFor="name" className="font-bold">
                    Email: {email}
                  </label>
                </div>
                <div className="field">
                  <label htmlFor="name" className="font-bold">
                    Telefon: {phone}
                  </label>
                </div>
                <div className="field">
                  <label htmlFor="name" className="font-bold">
                    Kullanıcı Tipi: {type}
                  </label>
                </div>
              </div>
        </Dialog>
        <Dialog
          visible={edit}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          style={{ width: "60vw" }}
          header="Kullanıcı Düzenle"
          onHide={hideDialog}
          modal
        >
          <div className="confirmation-content">
            <div className=" jc-space-around">
              <div className="col-6 " style={{ display: "inline-block" }}>
                <InputText
                  type="text"
                  defaultValue={newName}
                  className="p-inputtext-lg w-full"
                  placeholder="İsim Giriniz"
                  onChange={(e) => setNewName(e.target.value)}
                />
                <InputText
                  type="text"
                  defaultValue={newEmail}
                  className="p-inputtext-lg w-full"
                  onChange={(e) => setNewEmail(e.target.value)}
                  style={{ marginTop: "1rem" }}
                  placeholder={selectedData.email}
                />

                <Dropdown
                  filter
                  options={
                    Array.isArray(departments) && departments.length > 0
                      ? departments.map((dep) => ({
                          label: dep.department,
                          value: dep.id,
                        }))
                      : []
                  }
                  value={newDep}
                  placeholder="Departman"
                  onChange={(e) => setNewDep(e.target.value)}
                  style={{
                    border: "1px solid gray",
                    marginTop: "1rem",
                    width: "100%",
                  }}
                />
                <Dropdown
                  filter
                  options={userAuthY}
                  placeholder="Yetki"
                  value={is_auth}
                  className="w-full"
                  onChange={(e) => setIsAuth(e.value)}
                  style={{
                    border: "1px solid gray",
                    marginTop: "1rem",
                  }}
                />
              </div>
              <div className="col-6" style={{ display: "inline-block" }}>
                <InputText
                  type="text"
                  className="p-inputtext-lg w-full"
                  placeholder="Parola"
                  onChange={(e) => setNewPassword(e.target.value)}
                  defaultValue={selectedData.password}
                />
                <InputText
                  type="text"
                  defaultValue={newPhone}
                  className="p-inputtext-lg w-full"
                  placeholder="Telefon Numarası Giriniz"
                  onChange={(e) => setNewPhone(e.target.value)}
                  style={{ marginTop: "1rem" }}
                />
                <Dropdown
                  filter
                  options={userType}
                  placeholder="Kullanıcı Tipi"
                  value={newType}
                  className="w-full"
                  onChange={(e) => setNewType(e.value)}
                  style={{
                    border: "1px solid gray",
                    marginTop: "1rem",
                  }}
                />

                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    label="Kaydet"
                    onClick={() => saveChanges(selectedData.id)}
                    className="btn-excel-two"
                    style={{
                      marginTop: "1rem",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    );
  };

  const newCreate = async () => {
    
      if (!name || !email || !dep || !phone || !type || !password) {
        setInputErrors({
          name: !name,
          email: !email,
          dep: !dep,
          phone: !phone,
          type: !type,
          password: !password,
        });
        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: "Lütfen boş kısımları doldurun.",
        });
        return;
      }

    let requestBody = {
      name,
      email,
      phone,
      password,
      department: dep,
      type,
    };
    await AddUserService(requestBody);
    setAddModal(false);
    setNewName("");
    setName("");
    setEmail("");
    setPhone("");
    setPassword("");
    setDep("");
    setType("");
  };
  const saveChanges = async (id) => {
    let requestBody = {
      id,
      newName,
      newEmail,
      newPhone,
      newPassword,
      newDep,
      newType,
      is_auth,
    };
    await EditUserService(requestBody);
    setEditModal(false);

    setNewName("");
    setEmail("");
    setPhone("");
    setPassword("");
    setDep("");
    setType("");
  };
  

  useEffect(() => {
    UserService().then((data) => {
      setDepartments(data?.departments);
      setUsers(data.users);
    });
  }, [addModal, visible, editModal]);
  const getTurkishLabel = (url) => {
    switch (url) {
      case "/employees-list":
        return "Kullanıcılar";
      default:
        return "Sayfa"; 
    }
  };
  const url = "/employees-list";
  const turkishLabel = getTurkishLabel(url); 

  const itemsBread = [{ label: turkishLabel, url: url }];
  const home = { icon: "pi pi-home", url: "/dashboard" };
  return (
    <Flex mt="5" justifyContent="center" alignItems="center">
      <div
        className="card"
        style={{
          overflow: "auto",
          boxSizing: "border-box",
          width: "100%",
          height: "100%",
          position: "relative",
          padding: "1rem",
        }}
      >
        <Card>
          <BreadCrumb
            model={itemsBread}
            home={home}
            style={{ padding: "1rem" }}
          />
          <CardHeader>
            <Flex justifyContent="space-between" alignItems="center">
              <Heading size="md">Kullanıcılar</Heading>
              <div>
                {userData?.type === "1" && (
                  <>
                    <Button
                      label="Yeni Personel"
                      onClick={() => setAddModal(true)}
                      className="btn-excel-two"
                    />
                  </>
                )}
              </div>
            </Flex>
          </CardHeader>
          <Toast ref={toast} />

          <DataTable
            stripedRows
            value={
              users &&
              users.filter((users) => Number(users?.authorization) === 0)
            }
            size="small"
            dataKey="id"
            tableStyle={{ minWidth: "50rem" }}
            rows={15}
            rowsPerPageOptions={[15, 30, 60, 100]}
            scrollable
            paginator
            selectionMode="single"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate=" {totalRecords} kullanıcıdan {first} - {last} arası gösteriliyor"
          >
            {userData?.type === "1" && (
              <Column
                style={{ width: "8rem" }}
                field="accessor"
                body={(rowData) => accessors(rowData)}
                header="İşlemler"
              ></Column>
            )}
            <Column field="name" header="Ad Soyad"></Column>
            <Column field="email" header="Email"></Column>
            <Column field="phone" header="Telefon"></Column>
            <Column
              field="department"
              header="Departman"
              body={(rowData) => {
                const department = departments.find(
                  (dept) => dept.id.toString() === rowData.department
                );
                return department ? department.department : "Bilinmeyen";
              }}
            ></Column>
            {userData?.type === "1" && (
              <Column
                field="type"
                body={(rowData) => {
                  return rowData.type === "1" ? "Admin" : "Çalışan";
                }}
                header="Kullanıcı Tipi"
              ></Column>
            )}
          </DataTable>
        </Card>
      </div>
      <Dialog
        visible={addModal}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        style={{ width: "60vw" }}
        header="Kullanıcı Ekle"
        onHide={hideDialog}
        modal
      >
        <div className="confirmation-content">
          <div className=" jc-space-around">
            <div className="col-6 " style={{ display: "inline-block" }}>
              <InputText
                type="text"
                className="p-inputtext-lg w-full"
                placeholder="Ad Soyad"
                onChange={(e) => {
                  setName(e.target.value);
                  setInputErrors({ ...inputErrors, name: false });
                }}
                value={name}
              />
              {inputErrors.name && (
                <small style={{ color: "red" }}> Lütfen ad-soyad giriniz</small>
              )}
              <InputText
                type="text"
                className="p-inputtext-lg w-full"
                placeholder="Email"
                onChange={(e) => {
                  setEmail(e.target.value);
                  setInputErrors({ ...inputErrors, email: false });
                }}
                value={email}
                style={{ marginTop: "1rem" }}
              />
              {inputErrors.email && (
                <small style={{ color: "red" }}>Lütfen email giriniz</small>
              )}
              <Dropdown
                filter
                options={
                  Array.isArray(departments) && departments.length > 0
                    ? departments.map((dep) => ({
                        label: dep.department,
                        value: dep.id,
                      }))
                    : []
                }
                value={dep}
                placeholder="Departman"
                onChange={(e) => {
                  setDep(e.target.value);
                  setInputErrors({ ...inputErrors, dep: false });
                }}
                style={{
                  border: "1px solid gray",
                  marginTop: "1rem",
                  width: "100%",
                }}
              />
              {inputErrors.dep && (
                <small style={{ color: "red" }}>Lütfen departman seçiniz</small>
              )}
            </div>

            <div className="col-6" style={{ display: "inline-block" }}>
              <InputText
                type="text"
                className="p-inputtext-lg w-full"
                placeholder="Parola"
                onChange={(e) => {
                  setPassword(e.target.value);
                  setInputErrors({ ...inputErrors, password: false });
                }}
                value={password}
              />
              {inputErrors.password && (
                <small style={{ color: "red" }}>Lütfen parola giriniz</small>
              )}
              <InputText
                type="text"
                className="p-inputtext-lg w-full"
                placeholder="Telefon"
                onChange={(e) => {
                  setPhone(e.target.value);
                  setInputErrors({ ...inputErrors, phone: false });
                }}
                value={phone}
                style={{ marginTop: "1rem" }}
              />
              {inputErrors.phone && (
                <small style={{ color: "red" }}>Lütfen telefon giriniz</small>
              )}

              <Dropdown
                filter
                options={userType}
                placeholder="Kullanıcı Tipi"
                value={type}
                className="w-full"
                onChange={(e) => {
                  setType(e.value);
                  setInputErrors({ ...inputErrors, type: false });
                }}
                style={{
                  border: "1px solid gray",
                  marginTop: "1rem",
                }}
              />
              {inputErrors.type && (
                <small style={{ color: "red" }}>Lütfen kullanıcı tipi seçiniz</small>
              )}
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Button
                  label="Ekle"
                  onClick={newCreate}
                  className="btn-excel-two"
                  style={{
                    marginTop: "1rem",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </Flex>
  );
}
