import React from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";

export default function Main({ service, license, domains, hosting }) {

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("tr-TR");
    return formattedDate;
  };

  let descriptionServices = "";

  descriptionServices +=
    license && license.length
      ? `Linsans: ${license[0].package}, Bitiş Tarihi: ${formatDate(
          license[0].renewal_date
        )}\n`
      : "";

  descriptionServices +=
    service && service.length
      ? `Servis: , Bitiş Tarihi: ${formatDate(service[0].renewal_date)}\n`
      : "";

  descriptionServices +=
    domains && domains.length
      ? `Domain: ${domains[0].domain_name}, Bitiş Tarihi: ${formatDate(
          domains[0].renewal_date
        )}\n`
      : "";

  descriptionServices +=
    hosting && hosting.length
      ? `Hosting: ${hosting[0].domain_name}, Bitiş Tarihi: ${formatDate(
          hosting[0].renewal_date
        )}\n`
      : "";

  const cardData = [
    {
      id: 1,
      header: "Aktif Ürün/Hizmetler",
      buttonName: "Hepsini Görüntüle",
      description: descriptionServices,
      color: "outline-success",
      to: "",
      image: "",
    },
    {
      id: 2,
      header: "Yeni Destek Talepleri",
      buttonName: "Yeni Destek Talebi",
      description: "Eğer yardıma ihtiyacınız varsa destek talebi oluşturun.",
      color: "outline-warning",
      to: "/customer/support",
      image: "",
    },
    {
      id: 3,
      header: "Son Haberler",
      buttonName: "Hepsini Görüntüle",
      color: "outline-info",
      description: "",
      to: "",
      image: "",
      visibility: "0.5",
    },
    {
      id: 4,
      header: "Ödemeler",
      buttonName: "Ödemeleri Görüntüle",
      description: "",
      color: "outline-danger",
      to: "/customer/payment",
      image: "",
    },
    {
      id: 5,
      header: "Raporlar",
      buttonName: "Raporları Görüntüle",
      description: "",
      color: "outline-primary",
      to: "/customer/reports",
      image: "",
    },
    {
      id: 6,
      header: "Güvenlik",
      buttonName: "Hepsini Görüntüle",
      description: "",
      color: "success",
      to: "/customer/security",
      image: "",
    },
  ];

  return (
    <div className="p-3 flex flex-wrap justify-content-between align-items-center h-100">
      {cardData?.map((card) => (
        <Card
          key={card.id}
          title={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h5 className="ml-2" style={{ fontSize: "16px", margin: 2 }}>{card?.header}</h5>
              <Link to={card?.to}>
                <Button
                  style={{
                    marginTop: "0.4rem",
                    height: "2rem",
                    fontSize: "15px",
                    marginRight: "0.2rem",
                  }}
                  className="btn-excel-two"
                  label={card?.buttonName}
                />
              </Link>
            </div>
          }
          className="custom-card"
          style={{
            marginTop: "1rem",
            width: "100%",
            height:"auto",
            minHeight: "10rem", 
            maxWidth: "25rem",
            opacity: card?.visibility,
            backgroundImage: `url(${card?.image})`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            position: "relative",
          
          }}
        >
          <div
            className="m-0 card-content"
            style={{
              fontSize: "15px",
              fontWeight: "bold",
              marginLeft: "10px",
            }}
          >
            {card?.description && (
              <>
                {card?.description.split("\n").map((line, index) => (
                  <div className="ml-2" key={index}>{line}</div>
                ))}
              </>
            )}
          </div>
        </Card>
      ))}
    </div>
  );
}
