import React, { useState } from "react";
import { Splitter, SplitterPanel } from "primereact/splitter";
import TrafficSourceGraphPage from "./components/TrafficSourceGraphPage";

import TrafficSourceGraphTable from "./components/TrafficSourceGraphTable";
import TrafficSourceGraphAppearance from "./components/TrafficSourceGraphAppearance";

export default function TrafficSourceGraph({
  uniqueCustomerId,
  selectedMonth,
  selectedCustomer,
  months,
}) {

    const [tableKey, setTableKey] = useState(0);
    const [appearanceKey, setAppearanceKey] = useState(0);
   

    const updateTableData = () => {
      setTableKey((prevKey) => prevKey + 1);
      setAppearanceKey((prevKey) => prevKey + 1);
    };


  const DataTypes = [
    { name: "Reklamlardan Gelen Trafik", value: "1" },
    { name: "Direkt", value: "2" },
    { name: "Doğal Arama", value: "3" },
    { name: "Referans", value: "4" },
    { name: "Sosyal", value: "5" },
    { name: "Diğer", value: "6" },
  ];
  return (
    <Splitter>
      <SplitterPanel>
        <TrafficSourceGraphPage
          selectedMonth={selectedMonth}
          updateTableData={updateTableData}
          selectedCustomer={selectedCustomer}
          uniqueCustomerId={uniqueCustomerId}
          DataTypes={DataTypes}
        />
      </SplitterPanel>
      <SplitterPanel size={75} style={{ display: "block", padding: "1rem" }}>
        <TrafficSourceGraphTable
          months={months}
          key={tableKey}
          updateTableData={updateTableData}
          selectedMonth={selectedMonth}
          selectedCustomer={selectedCustomer}
          uniqueCustomerId={uniqueCustomerId}
          DataTypes={DataTypes}
        />
        <br></br>
        <TrafficSourceGraphAppearance
          months={months}
          appearanceKey={appearanceKey}
          uniqueCustomerId={uniqueCustomerId}
          DataTypes={DataTypes}
        />
      </SplitterPanel>
    </Splitter>
  );
}
