import React from 'react'
import WebSiteMain from './components/WebSiteMain'

export default function UserWebSite({ uniqueCustomerId, selectedMonth, selectedCustomer ,months , browser }) {
  return (
    <div>
      <WebSiteMain
        uniqueCustomerId={uniqueCustomerId}
        selectedCustomer={selectedCustomer}
        selectedMonth={selectedMonth}
        months={months}
        browser={browser}
      />
    </div>
  );
}
