import React from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import EmployeesSidebar from "../components/employees-sidebar/EmployeesSidebar";
import EmployeesReportsPage from "./EmployeesReportsPage";

export default function EmployeesReports() {
  return (
    <Grid
      templateAreas={`
                  "nav main"
                 `}
      gridTemplateRows={"auto 1fr "}
      gridTemplateColumns={"auto 1fr"}
      h="auto"
      color="blackAlpha.700"
      fontWeight="bold"
    >
     

      <GridItem area={"nav"}>
        <EmployeesSidebar />
      </GridItem>

      <GridItem mt="5" area={"main"}>
        <EmployeesReportsPage />
      </GridItem>
    </Grid>
  );
}
