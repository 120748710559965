// index.js
import React from "react";
import { createRoot } from "react-dom/client";
import ".././src/_ekdizayn/css/index.css";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./app/auth/core/AuthContext";
import App from "./App";
import "primeicons/primeicons.css";
import { PrimeReactProvider } from "primereact/api";
import "primeflex/primeflex.css";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "../src/_ekdizayn/css/App.css";
import store from "./app/redux/store";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
createRoot(document.getElementById("root")).render(
  
  <ChakraProvider>
    <PrimeReactProvider>
    <Router>
      <AuthProvider>
        <React.StrictMode>
            <Provider store={store}>
              <App />
            </Provider>
        </React.StrictMode>
      </AuthProvider>
      </Router>
    </PrimeReactProvider>
  </ChakraProvider>
);

reportWebVitals();
