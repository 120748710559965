import React, { useEffect, useState } from "react";
import { GetSecurity } from "../../../../../../../core/Services";
import { Paginator } from "primereact/paginator";
import { useAuth } from "../../../../../../../auth/core/AuthContext";
import CountryFlag from "react-country-flag";

export default function CustomerSecurityTable({selectedMonth}) {
  const [security, setSecurity] = useState([]);
  const { userData } = useAuth();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);

  const countries = require("../../../../employees/employees-reports/securit-report/components/countries.json");
  useEffect(() => {
    GetSecurity(userData?.unique_id, selectedMonth)
      .then((data) => {
        setSecurity(data.data);
      })
      .catch((error) => {
        console.log("Ülke Veri Çekme Hatası", error);
      });
  }, [userData?.unique_id, selectedMonth]);

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  return (
    <div className="w-full">
      <div className="mb-3 font-bold text-xl">
        En çok engellenen ilk 10 ülke
      </div>
      <table
        style={{
          fontFamily: "arial, sans-serif",
          borderCollapse: "collapse",
          width: "100%",
          
        }}
      >
        <thead>
          <tr>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            ></th>

            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              Ülke
            </th>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              Engellenmiş IP Adresi Toplamı
            </th>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              Engelleme Sayısı:
            </th>
          </tr>
        </thead>
        <tbody>
          {security?.slice(first, first + rows)?.map((security, index) => (
            <tr
              key={security?.id}
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              <td
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                {index + 1 + first}
              </td>

              <td
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CountryFlag
                  countryCode={security.country}
                  svg
                  style={{ marginRight: "8px" }}
                />
                {
                  countries?.find(
                    (dataType) => dataType.code === security.country
                  )?.name
                }
              </td>
              <td
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                {security?.blocked_ıp_address}
              </td>
              <td
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                {security?.blocked_number}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Paginator
        first={first}
        rows={rows}
        totalRecords={security?.length}
        onPageChange={onPageChange}
      />
    </div>
  );
}
