// src/App.js
import React from "react";
import AppRoutes from "./app/routing/AppRoutes";
import { AuthProvider } from "./app/auth/core/AuthContext";
import Version from "./_ekdizayn/layout/version/Version";


function App() {
  
  return (
    <AuthProvider>
      <AppRoutes />
      <Version/>
    </AuthProvider>
  );
}

export default App;
