import React, { useEffect, useState } from "react";
import { SocialMediaView } from "../../../../../../core/Services";
import { useAuth } from "../../../../../../auth/core/AuthContext";
import { Card, CardBody, CardHeader } from "@chakra-ui/react";


export default function SocialMedia({selectedMonth}) {
  const { userData } = useAuth();
  const [view, setView] = useState([]);


  useEffect(() => {
    SocialMediaView(userData?.unique_id, selectedMonth)
      .then((data) => {
        setView(data); // Gruplanmış verileri state'e atama
      })
      .catch((error) => {
        console.log("Müşteri Çekme Hatası", error);
      });
  }, [selectedMonth, userData?.unique_id]);
 
  const viewArray = Array.isArray(view) ? view : [];
  const foundTotal = (type) => {
    const foundItem = viewArray.find((item) => item?.type === type);
    return foundItem ? foundItem.total_follower : 0;
  };

  const foundNew = (type) => {
    const foundItem = viewArray.find((item) => item?.type === type);
    return foundItem ? foundItem.new_follower : 0;
  };
  const foundAccess = (type) => {
    const foundItem = viewArray.find((item) => item?.type === type);
    return foundItem ? foundItem.access : 0;
  };
  const foundInteraction = (type) => {
    const foundItem = viewArray.find((item) => item?.type === type);
    return foundItem ? foundItem.interaction : 0;
  };
  const foundProfileVisit = (type) => {
    const foundItem = viewArray.find((item) => item?.type === type);
    return foundItem ? foundItem.profile_visit : 0;
  };
    const foundClick = (type) => {
      const foundItem = viewArray.find((item) => item?.type === type);
      return foundItem ? foundItem.click : 0;
    };
  return (
    <div>
      <div className="mb-3 font-bold text-xl">Sosyal Medya</div>
      <div className="row" style={{ display: "flex" }}>
        <div className="col-3">
          <Card
            style={{ height: "auto", background: "#662549", padding: "5px" }}
          >
            <CardHeader color={"white"} textAlign={"center"}>
              <i className="pi pi-instagram" style={{ fontSize: "1rem" }} />{" "}
              INSTAGRAM
            </CardHeader>
            <CardBody
              background={"white"}
              textAlign={"center"}
              overflow={"hidden"}
            >
              <strong style={{ color: "green" }}>Toplam Takipçi</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid green",
                  padding: "4px",
                }}
              >
                <h3>{foundTotal("1")}</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "blue" }}>Yeni Takipçi</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid blue",
                  padding: "4px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <h3>{foundNew("1")}</h3>
              </div>{" "}
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "orange" }}>Erişim</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid orange",
                  padding: "4px",
                }}
              >
                <h3>{foundAccess("1")}</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "red" }}>Etkileşim</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid red",
                  padding: "4px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <h3>{foundInteraction("1")}</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "purple" }}>Profil Ziyaretleri</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid purple",
                  padding: "4px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <h3>{foundProfileVisit("1")}</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "brown" }}>Tıklamalar</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid brown",
                  padding: "4px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <h3>{foundClick("1")}</h3>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-3">
          <Card
            style={{ height: "auto", background: "#662549", padding: "5px" }}
          >
            <CardHeader color={"white"} textAlign={"center"}>
              <i className="pi pi-facebook" style={{ fontSize: "1rem" }} />{" "}
              FACEBOOK
            </CardHeader>
            <CardBody
              background={"white"}
              textAlign={"center"}
              overflow={"hidden"}
            >
              <strong style={{ color: "green" }}>Toplam Takipçi</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid green",
                  padding: "4px",
                }}
              >
                <h3>{foundTotal("2")}</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "blue" }}>Yeni Takipçi</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid blue",
                  padding: "4px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <h3>{foundNew("2")}</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "orange" }}>Erişim</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid orange",
                  padding: "4px",
                }}
              >
                <h3>{foundAccess("2")}</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "red" }}>Etkileşim</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid red",
                  padding: "4px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <h3>{foundInteraction("2")}</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "purple" }}>Profil Ziyaretleri</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid purple",
                  padding: "4px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <h3>{foundProfileVisit("2")}</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "brown" }}>Tıklamalar</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid brown",
                  padding: "4px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <h3>{foundClick("2")}</h3>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-3">
          <Card
            style={{ height: "auto", background: "#662549", padding: "5px" }}
          >
            <CardHeader color={"white"} textAlign={"center"}>
              <i className="pi pi-youtube" style={{ fontSize: "1rem" }} />{" "}
              YOUTUBE
            </CardHeader>
            <CardBody
              background={"white"}
              textAlign={"center"}
              overflow={"hidden"}
            >
              <strong style={{ color: "green" }}>Toplam Takipçi</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid green",
                  padding: "4px",
                }}
              >
                <h3>{foundTotal("3")}</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "blue" }}>Yeni Takipçi</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid blue",
                  padding: "4px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <h3>{foundNew("3")}</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "orange" }}>Erişim</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid orange",
                  padding: "4px",
                }}
              >
                <h3>{foundAccess("3")}</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "red" }}>Etkileşim</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid red",
                  padding: "4px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <h3>{foundInteraction("3")}</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "purple" }}>Profil Ziyaretleri</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid purple",
                  padding: "4px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <h3>{foundProfileVisit("3")}</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "brown" }}>Tıklamalar</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid brown",
                  padding: "4px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <h3>{foundClick("3")}</h3>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-3">
          <Card
            style={{ height: "auto", background: "#662549", padding: "5px" }}
          >
            <CardHeader color={"white"} textAlign={"center"}>
              <i className="pi pi-linkedin" style={{ fontSize: "1rem" }} />{" "}
              LINKEDIN
            </CardHeader>
            <CardBody
              background={"white"}
              textAlign={"center"}
              overflow={"hidden"}
            >
              <strong style={{ color: "green" }}>Toplam Takipçi</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid green",
                  padding: "4px",
                }}
              >
                <h3>{foundTotal("4")}</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "blue" }}>Yeni Takipçi</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid blue",
                  padding: "4px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <h3>{foundNew("4")}</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "orange" }}>Erişim</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid orange",
                  padding: "4px",
                }}
              >
                <h3>{foundAccess("4")}</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "red" }}>Etkileşim</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid red",
                  padding: "4px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <h3>{foundInteraction("4")}</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "purple" }}>Profil Ziyaretleri</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid purple",
                  padding: "4px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <h3>{foundProfileVisit("4")}</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "brown" }}>Tıklamalar</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid brown",
                  padding: "4px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <h3>{foundClick("4")}</h3>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
}
