import React, { useEffect, useState } from "react";
import { Chart } from "primereact/chart";
import { GetAgeGraph } from "../../../../../../../../core/Services";

export default function AgeGraphAppearance({ uniqueCustomerId, DataTypes , appearanceKey }) {
  const [ageGraphData, setAgeGraphData] = useState({});
  const [ageGraphOptions, setAgeGraphOptions] = useState({});
  const [ageGraph, setAgeGraph] = useState([]);

  useEffect(() => {
    GetAgeGraph(uniqueCustomerId)
      .then((data) => {
        setAgeGraph(data.data);
      })
      .catch((error) => {
        console.log("Ülke Veri Çekme Hatası", error);
      });
  }, [uniqueCustomerId, appearanceKey]);

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const data = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [
            documentStyle.getPropertyValue("--blue-500"),
            documentStyle.getPropertyValue("--yellow-500"),
            documentStyle.getPropertyValue("--green-500"),
          ],
          hoverBackgroundColor: [
            documentStyle.getPropertyValue("--blue-400"),
            documentStyle.getPropertyValue("--yellow-400"),
            documentStyle.getPropertyValue("--green-400"),
          ],
        },
      ],
    };

    // Gelen veriyi dönüştürme
    ageGraph?.forEach((item) => {
      const dataTypeName = DataTypes.find(
        (dt) => dt.value === item.age
      )?.name;
      data.labels.push(dataTypeName ? dataTypeName : item.ageGraph);
      data.datasets[0].data.push(parseInt(item.value));
    });

    const options = {
      cutout: "60%",
    };

    setAgeGraphData(data);
    setAgeGraphOptions(options);

  }, [DataTypes, ageGraph]);

  return (
    <div>
      <div className="mb-3 font-bold text-xl ml-5">Görünüm:</div>
      <Chart
        style={{ width: "30rem", height: "20rem" }}
        type="doughnut"
        data={ageGraphData}
        options={ageGraphOptions}
      />
    </div>
  );
}
