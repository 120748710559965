import React, { useEffect, useState } from "react";
import {
  DeleteCity,
  GetCity,
  UpdateCity,
} from "../../../../../../../../core/Services";
import { BsFillTrashFill } from "react-icons/bs";
import { BsFillPencilFill } from "react-icons/bs";
import { Dialog } from "primereact/dialog";
import { Button } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";

import { Paginator } from "primereact/paginator";

export default function CityTable({ uniqueCustomerId, countriees, tableKey, months }) {
  const [city, setCity] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [editMode, setEditMode] = useState(null);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const toast = useToast();

  useEffect(() => {
    GetCity(uniqueCustomerId)
      .then((data) => {
        setCity(data.data);
      })
      .catch((error) => {
        console.log("Ülke Veri Çekme Hatası", error);
      });
  }, [uniqueCustomerId, tableKey, deleteSuccess]);

  useEffect(() => {
    if (deleteSuccess) {
      toast({
        title: "Başarılı",
        description: "Veri başarıyla silindi.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      setDeleteSuccess(false);
    }
  }, [deleteSuccess, toast]);

  const handleDelete = (cityId) => {
    setSelectedCity(cityId);
    setDisplayConfirmation(true);
  };

  const onDeleteConfirmation = () => {
    DeleteCity(selectedCity);
    setDeleteSuccess(true);
    setSelectedCity("");
    setDisplayConfirmation(false);
  };

  const onHide = () => {
    setDisplayConfirmation(false);
  };

  const handleEdit = (cityId) => {
    setEditMode(cityId);
  };

  const handleSave = (editedCity) => {
    UpdateCity(editedCity);
    setEditMode(null);
  };

  const handleInputChange = (event, cityId) => {
    const { name, value } = event.target;
    const updatedcity = city?.map((city) =>
      city.id === cityId ? { ...city, [name]: value } : city
    );
    setCity(updatedcity);
  };
  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };
  return (
    <div>
      <div className="mb-3 font-bold text-xl">Tablo Görünümü :</div>
      <table
        style={{
          fontFamily: "arial, sans-serif",
          borderCollapse: "collapse",
          width: "80%",
        }}
      >
        <thead>
          <tr>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            ></th>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              Dönem
            </th>
           
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              Şehirler
            </th>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              Ziyaretçi Sayısı
            </th>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              İşlemler
            </th>
          </tr>
        </thead>
        <tbody>
          {city &&
            city?.slice(first, first + rows)?.map((city, index) => (
              <tr
                key={city.id}
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                <td
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  {index + 1 }
                </td>
                <td
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  {months?.find((months) => months.value === city.month)
                    ?.name || ""}
                </td>
                <td
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  {editMode === city.id ? (
                    <input
                      type="text"
                      name="city"
                      value={city.city}
                      onChange={(e) => handleInputChange(e, city.id)}
                    />
                  ) : (
                    city.city
                  )}
                </td>
                <td
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  {editMode === city.id ? (
                    <input
                      type="text"
                      name="visitor"
                      value={city.visitor}
                      onChange={(e) => handleInputChange(e, city.id)}
                    />
                  ) : (
                    city.visitor
                  )}
                </td>
                <td
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  {editMode === city.id ? (
                    <button onClick={() => handleSave(city)}>Kaydet</button>
                  ) : (
                    <button onClick={() => handleEdit(city.id)}>
                      <BsFillPencilFill />
                    </button>
                  )}
                  <button
                    className="ml-2"
                    onClick={() => handleDelete(city.id)}
                  >
                    <BsFillTrashFill />
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <Paginator
        first={first}
        rows={rows}
        totalRecords={city?.length}
        onPageChange={onPageChange}
      />
      <Dialog
        visible={displayConfirmation}
        style={{ width: "450px" }}
        onHide={onHide}
        position="center"
        header="İçerik Düzenleme"
      >
        <p>Veriyi silmek istediğinize emin misiniz?</p>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "1rem",
            marginTop: "1rem",
          }}
        >
          <Button colorScheme="red" onClick={onHide}>
            Hayır
          </Button>
          <Button colorScheme="green" onClick={onDeleteConfirmation}>
            Evet
          </Button>
        </div>
      </Dialog>
    </div>
  );
}
