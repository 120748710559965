import React, { useEffect, useState, useMemo } from "react";
import { Flex } from "@chakra-ui/react";
import NavItem from "../NavItem/NavItem";
import { BsPeople } from "react-icons/bs";
import { BsStickies } from "react-icons/bs";
import { BsHouse } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import { BsClipboardData } from "react-icons/bs";
import { BsCloudCheck } from "react-icons/bs";
import { BsPersonVcard } from "react-icons/bs";
import { BsShieldLock } from "react-icons/bs";
import { BsCreditCard2Back } from "react-icons/bs";
import { BsPencil } from "react-icons/bs";
import { GetCurrentData } from "../../../../../../../core/Services";
export default function SidebarPage() {
  const [navSize] = useState("large");
  const [activeNavItem, setActiveNavItem] = useState("");
  const [currentData, setCurrentData] = useState("");
  const location = useLocation();

  const subItemsOperation = useMemo(
    () => [
      { to: "", title: "Performans" },
      { to: "", title: "Reklamlar" },
      { to: "", title: "Faydalar" },
    ],
    []
  );

  useEffect(() => {
    GetCurrentData()
    .then((data)=> {
      setCurrentData(data?.data)
    })
    .catch((error)=>{
      console.log("Güncel Veri Çekme Hatası", error);
    })
    if (checkToValue(subItemsOperation, location.pathname)) {
      handleNavItemClick("Satış");
    } else {
      handleNavItemClick("Ana Sayfa");
    }
  }, [location.pathname, subItemsOperation]);

  const checkToValue = (items, targetTo) => {
    return items.some((item) => item.to === targetTo);
  };

  const handleNavItemClick = (title) => {
    setActiveNavItem(title);
  };
  const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const formattedDate = dateTime.toLocaleDateString("tr-TR");
    const formattedTime = dateTime.toLocaleTimeString("tr-TR");
    return `${formattedDate} ${formattedTime}`;
  };

  return (
    <Flex
      className="not-to-print"
      pos="sticky"
      backgroundColor="#f5f5f5"
      h="100vh"
      mt="5"
      boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
      borderRadius={navSize === "small" ? "0" : "0"}
      w={navSize === "small" ? "120px" : "200px"}
      flexDir="column"
      justifyContent="space-between"
      overflowY="auto"
      maxHeight="100%"
    >
      <Flex
        p="5%"
        flexDir="column"
        w="100%"
        alignItems={navSize === "small" ? "center" : "flex-start"}
        as="nav"
      >
        <button
          className="w-full"
          onClick={() => handleNavItemClick("Ana Sayfa")}
        >
          <NavItem
            navSize={navSize}
            icon={BsHouse}
            title="Ana Sayfa"
            to="/customer/detail"
            active={activeNavItem === "Ana Sayfa"}
          />
        </button>

        <button
          className="w-full"
          onClick={() => handleNavItemClick("Bilgiler")}
        >
          <NavItem
            navSize={navSize}
            icon={BsPersonVcard}
            title="Bilgiler"
            to="/customer/information"
            active={activeNavItem === "Bilgiler"}
          />
        </button>

        <button
          className="w-full"
          onClick={() => handleNavItemClick("Raporlar")}
        >
          <NavItem
            navSize={navSize}
            icon={BsClipboardData}
            to="/customer/reports"
            title="Raporlar"
            active={activeNavItem === "Raporlar"}
          />
        </button>
        <button
          className="w-full"
          onClick={() => handleNavItemClick("Hizmetler")}
        >
          <NavItem
            navSize={navSize}
            icon={BsStickies}
            title="Hizmetler"
            to="/customer/services"
            active={activeNavItem === "Hizmetler"}
          />
        </button>
        <button className="w-full" onClick={() => handleNavItemClick("Destek")}>
          <NavItem
            navSize={navSize}
            icon={BsPeople}
            title="Destek"
            to="/customer/support"
            active={activeNavItem === "Destek"}
          />
        </button>
        <button
          className="w-full"
          onClick={() => handleNavItemClick("Planlayıcı")}
        >
          <NavItem
            navSize={navSize}
            icon={BsPencil}
            title="Planlayıcı"
            active={activeNavItem === "Planlayıcı"}
          />
        </button>
        <button
          className="w-full"
          onClick={() => handleNavItemClick("Güvenlik")}
        >
          <NavItem
            navSize={navSize}
            icon={BsShieldLock}
            title="Güvenlik"
            to="/customer/security"
            active={activeNavItem === "Güvenlik"}
          />
        </button>

        <button className="w-full" onClick={() => handleNavItemClick("Ödeme")}>
          <NavItem
            navSize={navSize}
            icon={BsCreditCard2Back}
            title="Ödeme"
            to="/customer/payment"
            active={activeNavItem === "Ödeme"}
          />
        </button>

        <button
          className="w-full"
          onClick={() => handleNavItemClick("Dokümanlar")}
        >
          <NavItem
            navSize={navSize}
            icon={BsCloudCheck}
            title="Dokümanlar"
            to=""
            active={activeNavItem === "Dokümanlar"}
          />
        </button>
        <div className="mt-2">
          <small className="text-xs">
            Son Güncelleme Tarihi: {formatDateTime(currentData)}
          </small>
        </div>
      </Flex>
    </Flex>
  );
}
