// src/app/modules/auth/core/AuthContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import {useNavigate } from "react-router-dom";
const baseEndpoint =
  process.env.REACT_APP_BASE_ENDPOINT || "http://localhost:8000";
  const loginWithToken =
    process.env.REACT_APP_loginWithToken_PATH || "/loginWithToken";
const apiPath = process.env.REACT_APP_API_PATH || "/api";
const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [user] = useState(null);
  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [userFirst, setUserFirst] = useState(null);
  const storedUserData = localStorage.getItem("userData");
  useEffect(() => {
    if (storedUserData) {
      const parsedUserData = JSON.parse(storedUserData);
      setUserData(parsedUserData);
      setUserRole(parsedUserData?.authorization);
    } else {
      setUserRole(userFirst);
    }
  }, [userFirst, storedUserData]);

  const login = async (email, password) => {
    try {
      const response = await fetch(`${baseEndpoint}${apiPath}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          email,
          password,
        }),
      });

      if (response.ok) {
        setLoggedIn(true);
        const userResponse = await fetch(`${baseEndpoint}${apiPath}/user`, {
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        });

        if (response.ok) {
          const userDataResponse = await userResponse.json();
          localStorage.setItem("userData", JSON.stringify(userDataResponse));
          setUserData(userDataResponse);
          setUserFirst(userDataResponse?.authorization);
        } else {
          console.error(
            "Kullanıcı bilgileri alınamadı:",
            userResponse.statusText
          );
          return false;
        }

        const data = await response.json();
        const token = data.message;
        localStorage.setItem("token", token);

        setLoggedIn(true);

        return data;
      } else {
        console.error("Giriş başarısız:", response.statusText);
        return false;
      }
    } catch (error) {
      console.error("Giriş başarısız:", error.message);
      return false;
    }
  };

const LoginWithToken = async (id) => {
  try {
   
    if (isLoggedIn) {
      await logout();
      console.error("Kullanıcı oturum açık değil.");
      
    }

    const response = await fetch(`${baseEndpoint}${apiPath}${loginWithToken}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        id,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      const token = data.message;
      localStorage.setItem("token", token);
      setLoggedIn(true);

      const userResponse = await fetch(`${baseEndpoint}${apiPath}/user`, {
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (userResponse.ok) {
        const userDataResponse = await userResponse.json();
        localStorage.setItem("userData", JSON.stringify(userDataResponse));
        setUserData(userDataResponse);
        setUserFirst(userDataResponse?.authorization);
        navigate("/customer/detail");
        return data;
      } else {
        console.error(
          "Kullanıcı bilgileri alınamadı:",
          userResponse.statusText
        );
        return false;
      }
    } else {
      console.error("Giriş başarısız:", response.statusText);
      return false;
    }
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};


  const logout = async () => {
    try {
      const response = await fetch(`${baseEndpoint}${apiPath}/logout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (response.ok) {
        localStorage.removeItem("userData");
        localStorage.removeItem("token");
        setLoggedIn(false);
        setUserData(null);
        setUserRole("");
        navigate("/");
        return true;
      } else {
        console.error("Çıkış başarısız:", response.statusText);
        return false;
      }
    } catch (error) {
      console.error("Çıkış başarısız:", error.message);
      return false;
    }
  };
  const register = async (name, email, password) => {
    try {
      const response = await axios.post(`${baseEndpoint}${apiPath}/register`, {
        name,
        email,
        password,
      });

      const token = response.data.message;
      localStorage.setItem("token", token);

      setLoggedIn(true);

      return true;
    } catch (error) {
      console.error("Registration failed:", error);
      return false;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        login,
        logout,
        register,
        user,
        userData,
        userRole,
        LoginWithToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
