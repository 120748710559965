import React, { useEffect, useState } from "react";

import { Grid, GridItem } from "@chakra-ui/react";
import NavBar from "../../../_ekdizayn/layout/Navbar/Navbar";
import Sidebar from "../../../_ekdizayn/layout/Sidebar/Sidebar";
import { TabView, TabPanel } from "primereact/tabview";
import Datatables from "./components/DataTables";
import { TemplatesService } from "../../core/Services";
import AddTemplate from "./components/AddTemplate";
export default function Templates() {
  const [offerTemplates, setOfferTemplates] = useState([]);
  const [mailTemplates, setMailTemplates] = useState([]);
  const [smsTemplates, setSmsTemplates] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await TemplatesService();

        setOfferTemplates(data?.offer_templates);
        setMailTemplates(data?.mail_templates);
        setSmsTemplates(data?.sms_templates);
      } catch (error) {
        console.error("Error fetching template data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Grid
      templateAreas={`"header header"
    "nav main"
   `}
      gridTemplateRows={"auto 1fr "}
      gridTemplateColumns={"auto 1fr"}
      h="auto"
      color="blackAlpha.700"
      fontWeight="bold"
    >
      <GridItem area={"header"}>
        <NavBar />
      </GridItem>
      <GridItem area={"nav"}>
        <Sidebar />
      </GridItem>
      <GridItem area={"main"} p={5}>
        <TabView>
          <TabPanel header="Teklif Şablonları" leftIcon="pi pi-file-pdf mr-2">
            <Datatables templates={offerTemplates} />
          </TabPanel>
          <TabPanel header="Sms Şablonları" leftIcon="pi pi-envelope mr-2">
            <Datatables templates={smsTemplates} />
          </TabPanel>
          <TabPanel header="Mail Şablonları" leftIcon="pi pi-inbox mr-2">
            <Datatables templates={mailTemplates} />
          </TabPanel>
          <TabPanel header="Şablon Ekle" leftIcon="pi pi-plus mr-2">
            <AddTemplate />
          </TabPanel>
        </TabView>
      </GridItem>
    </Grid>
  );
}
