import React from "react";
import { useAuth } from "../../../../app/auth/core/AuthContext";

export default function VersiyonBar({
  customerVersionBar,
  employeesVersionBar,
}) {
  const { userData } = useAuth();

  return (
    <>
      {userData?.authorization === "1" ? (
        <div
          style={{
            backgroundColor: "#292b33",
            bottom: "0",
          }}
          className="border-noround h-auto mb-0 p-0 w-full"
        >
          <p className="d-flex h-auto text-white text-center m-0 text-sm">
            {customerVersionBar}
          </p>
        </div>
      ) : userData?.authorization === "0" ? (
        <div
          style={{
            backgroundColor: "#292b33",
            bottom: "0",
          }}
          className=" border-noround h-auto mb-0 p-0 w-full"
        >
          <p className="d-flex h-auto text-white text-center m-0 text-sm p-0">
            {employeesVersionBar}
          </p>
        </div>
      ) : null}
    </>
  );
}
