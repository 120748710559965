import { Input, InputGroup, InputLeftAddon } from "@chakra-ui/react";
import { Button } from "primereact/button";
import { Chips } from "primereact/chips";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React, { useRef } from "react";
import { UpdateSupplier } from "../../../../core/Services";

export default function SupplierUpdateDialog({
  responsiblePerson,
  setResponsiblePerson,
  company,
  setCompany,
  address,
  setAdress,
  phone,
  setPhone,
  activityArea,
  setActivityArea,
  displayUpdateDialog,
  setDisplayUpdateDialog,
  selectedSupplier,
  setSelectedSupplier
}) {
  const toast = useRef(null);

  const updateButton = () => {
    return (
      <Button
        label="Kaydet"
        style={{ width: "8rem" }}
        onClick={updateSupplier}
        className="btn-excel-two"
      />
    );
  };
  const hideUpdateDialog = () => {
    setDisplayUpdateDialog(false);
    setCompany("");
    setActivityArea(null);
    setAdress("");
    setResponsiblePerson("");
    setPhone("");
    setSelectedSupplier("");
  };

  const updateSupplier = async () => {
    await UpdateSupplier(selectedSupplier, responsiblePerson, company, address, phone, activityArea);
    setDisplayUpdateDialog(false);
    setResponsiblePerson("");
    setCompany("");
    setAdress("");
    setPhone("");
    setActivityArea("");

  };
  return (
    <div>
      <Dialog
        visible={displayUpdateDialog}
        breakpoints={{ "960px": "70vw", "641px": "50vw" }}
        style={{ width: "50vw" }}
        header="Tedarikçi Düzenle"
        onHide={hideUpdateDialog}
        modal
        footer={updateButton}
      >
        <div className="confirmation-content" style={{ display: "flex" }}>
          <div className="col-6" style={{ display: "inline-block" }}>
            <div className="mb-3">
              <InputText
                type="text"
                value={company}
                onChange={(e) => {
                  setCompany(e.target.value);
                }}
                className="p-inputtext-lg w-full"
                placeholder="Tedarikçi "
              />
            </div>
            <div className="mb-3">
              <InputText
                type="text"
                value={address}
                onChange={(e) => {
                  setAdress(e.target.value);
                }}
                className="p-inputtext-lg w-full"
                placeholder="Adres"
              />
            </div>
            <div className="mb-3">
              <Chips
                value={activityArea}
                className="w-full p-inputtext"
                onChange={(e) => {
                  setActivityArea(e.value);
                }}
                placeholder="Sorumluluk Alanı"
                style={{
                  border: "1px solid ",
                }}
              />
            </div>
          </div>
          <div className="col-6" style={{ display: "inline-block" }}>
            <div className="mb-3">
              <InputText
                type="text"
                value={responsiblePerson}
                onChange={(e) => {
                  setResponsiblePerson(e.target.value);
                }}
                className="p-inputtext-lg w-full"
                placeholder="Tedarikçi Sorumlusu"
              />
            </div>
            <div>
              <InputGroup>
                <InputLeftAddon>+90</InputLeftAddon>
                <Input
                  type="tel"
                  border="1px gray solid"
                  placeholder="Numara giriniz"
                  value={phone}
                  onChange={(e) => {
                    const inputNumber = e.target.value.replace(/\D/g, ""); 
                    const trimmedNumber = inputNumber.slice(0, 10); 
                    setPhone(trimmedNumber);
                  }}
                />
              </InputGroup>
            </div>
          </div>
        </div>
      </Dialog>
      <Toast ref={toast} />
    </div>
  );
}
