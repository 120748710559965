import React, { useState } from "react";
import { Button, Input, useToast } from "@chakra-ui/react";
import { AddOtherAccounts } from "../../../../../../../core/Services";
import { Message } from "primereact/message";


export default function OtherAccounts({ uniqueCustomerId, selectedCustomer , updateTableData }) {
  const [userName, setUserName] = useState("");
  const [platform, setPlatform] = useState("");
  const [password, setPassword] = useState("");
  const [followerCount, setFollowerCount] = useState("");
  const [mail, setMail] = useState("");
  const [openingDate, setOpeningDate] = useState("");
  const [error, setError] = useState(false);
  const toast = useToast();

  const handleSave = () => {
    if (!selectedCustomer || !platform || !password || !userName ) {
      setError(true);
      toast({
        title: "Hata",
        description: "Lütfen tüm alanları doldurunuz.",
        status: "error",
        duration: 3000,
        position: "top-right",
        isClosable: true,
      });

      return;
    }

     AddOtherAccounts(
       uniqueCustomerId,
       userName,
       platform,
       password,
       mail,
       openingDate,
       followerCount
     )
       .then((response) => {
         if (response.message === "Hata") {
           toast({
             title: "Hata",
             description: "Kullanıcya Ait Bu Ay Verisi Zaten Mevcut",
             status: "error",
             duration: 3000,
             position: "top-right",
             isClosable: true,
           });
         } else {
           setPlatform("");
           setPassword("");
           setOpeningDate("");
           setFollowerCount("");
           setUserName("");
           setMail("");
           setError(false);

           toast({
             title: "Başarılı",
             description: "Veri başarılı bir şekilde kaydedildi.",
             status: "success",
             duration: 3000,
             position: "top-right",
             isClosable: true,
           });
           updateTableData();
         }
       })
       .catch((error) => {
         console.error("Backend'e gönderme hatası:", error);
         toast({
           title: "Hata",
           description: "Veri gönderilirken bir hata oluştu.",
           status: "error",
           duration: 3000,
           position: "top-right",
           isClosable: true,
         });
       });
   };

  return (
    <div>
      <div className="font-bold text-xl mt-5">Platform Giriniz:</div>
      <div className="mt-2">
        <Input
          placeholder="Platform Giriniz"
          w="20rem"
          name="value"
          value={platform}
          onChange={(e) => setPlatform(e.target.value)}
          borderColor={error && !platform ? "red" : ""}
        />
      </div>
      <div>
        <div className="font-bold text-xl mt-5">Kullanıcı Adı:</div>
        <div className="mt-2">
          <Input
            placeholder="Ad-Soyad Giriniz"
            w="20rem"
            name="value"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            borderColor={error && !userName ? "red" : ""}
          />
        </div>
      </div>

      <div className="font-bold text-xl mt-5">Şifre Giriniz:</div>
      <div className="mt-2">
        <Input
          placeholder="Şifre Giriniz"
          w="20rem"
          name="value"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          borderColor={error && !password ? "red" : ""}
        />
      </div>
      <div className="mt-5 flex justify-content-end">
        <Button onClick={handleSave}> Kaydet</Button>
      </div>
      <Message className="mt-4 w-full" severity="info" text="Info Message" />
    </div>
  );
}
