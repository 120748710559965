import React from "react";
import { TabView, TabPanel } from "primereact/tabview";
import ViewVisitorChart from "../view-visitor-chart/Page";
import SourceAds from "../source-ads/Page";
import DeviceAds from "../device-ads/Page";
import AdsCosts from "../ads-costs/Page";
import AdsRival from "../ads-rival/Page";
export default function AdsMain({
  uniqueCustomerId,
  selectedMonth,
  selectedCustomer,
  months,
}) {
  
  const tabContents = [
    {
      title: "Performans",
      content: (
        <ViewVisitorChart
          selectedMonth={selectedMonth}
          selectedCustomer={selectedCustomer}
          uniqueCustomerId={uniqueCustomerId}
          months={months}
        />
      ),
    },
    {
      title: "Kaynak",
      content: (
        <SourceAds
          selectedMonth={selectedMonth}
          selectedCustomer={selectedCustomer}
          uniqueCustomerId={uniqueCustomerId}
          months={months}
        />
      ),
    },
    {
      title: "Anahtar Kelimeler",
      content: (
        <AdsCosts
          selectedMonth={selectedMonth}
          selectedCustomer={selectedCustomer}
          uniqueCustomerId={uniqueCustomerId}
          months={months}
        />
      ),
    },
    {
      title: "Cihazlar",
      content: (
        <DeviceAds
          selectedMonth={selectedMonth}
          selectedCustomer={selectedCustomer}
          uniqueCustomerId={uniqueCustomerId}
          months={months}
        />
      ),
    },
    {
      title: "Açık Arttırma Bilgileri",
      content: (
        <AdsRival
          selectedMonth={selectedMonth}
          selectedCustomer={selectedCustomer}
          uniqueCustomerId={uniqueCustomerId}
          months={months}
        />
      ),
    },
  ];

  return (
    <div className="card" style={{ width: "80vw" }}>
      <TabView>
        {tabContents?.map((tab) => {
          return (
            <TabPanel key={tab.title} header={tab.title}>
              <p className="m-0">{tab.content}</p>
            </TabPanel>
          );
        })}
      </TabView>
    </div>
  );
}
