import React, { useEffect, useRef, useState } from "react";
import {
  AddBrand,
  AddTimeBrand,
  DeleteBrand,
  UpdateBrand,
} from "../../../../core/Services";
import { Card, CardHeader, Flex, Heading } from "@chakra-ui/react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { addLocale } from "primereact/api";
import { Dropdown } from "primereact/dropdown";
import { SelectButton } from "primereact/selectbutton";
import { Toast } from "primereact/toast";
import { useSelector } from "react-redux";
import options from "../../../../../_ekdizayn/api/owner.json"
import brandsStatus from "../../../../../_ekdizayn/api/brand-status.json"
import { Chips } from "primereact/chips";
function useInterval(callback, delay) {
  const savedCallback = useRef();
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default function Brand() {
  const [brand, setBrand] = useState([]);
  const [niceClasses, setNiceClasses] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [cus, setCus] = useState("");
  const [applicationNumber, setApplicationNumber] = useState("");
  const [brandStatus, setBrandStatus] = useState("");
  const [brandName, setBrandName] = useState("");
  const [owner, setOwner] = useState(null);
  const [startingDate, setStartingDate] = useState(null);
  const [renewalDate, setRenewalDate] = useState(null);
  const [dialogs, setDialogs] = useState({});
  const [displayDeleteDialog, setDisplayDeleteDialog] = useState(false);
  const [dialogsVisibleTime, setDialogsVisibleTime] = useState(false);
  const [visibilityDialog, setVisibilityDialog] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const brandData = useSelector((state) => state.TallyReduce.value);
  const toast = useRef(null);
  const [inputErrors, setInputErrors] = useState({
    applicationNumber: false,
    brandName: false,
    cus: false,
    owner: false,
    startingDate: false,
    renewalDate: false,
    brandStatus:false,
  });
  const [time, setTime] = useState(10);
   const filterBrandName = (value) => {
     let matchingObjects = [];
     if (Array.isArray(brandData.brand) && brandData.brand.length > 0) {
       matchingObjects = brandData.brand.filter((item) =>
         item.brand.toLowerCase().includes(value.toLowerCase())
       );
     }
     setBrand(matchingObjects);
   };
  
  useInterval(() => {
    setBrand((prevTally) => {
      if (!prevTally || !prevTally.length) {
        return prevTally;
      }
      const updatedTally = prevTally.map((item) => {
        const remainingDays = calculateRemainingDays(item.renewal_date);
        return {
          ...item,
          remainingDays,
        };
      });

      return updatedTally;
    });
  }, 24 * 60 * 60 * 1000);

 

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    return new Date(dateString).toLocaleString("tr-TR", options);
  };
  addLocale("tr", {
    firstDayOfWeek: 1,
    showMonthAfterYear: true,
    dayNames: [
      "pazar",
      "pazartesi",
      "salı",
      "çarşamba",
      "perşembe",
      "cuma",
      "cumartesi",
    ],
    dayNamesShort: ["paz", "pzt", "sal", "çar", "per", "cum", "cmt"],
    dayNamesMin: ["P", "Pzt", "S", "Ç", "P", "C", "Cmt"],
    monthNames: [
      "ocak",
      "şubat",
      "mart",
      "nisan",
      "mayıs",
      "haziran",
      "temmuz",
      "ağustos",
      "eylül",
      "ekim",
      "kasım",
      "aralık",
    ],
    monthNamesShort: [
      "oca",
      "şub",
      "mar",
      "nis",
      "may",
      "haz",
      "tem",
      "ağu",
      "eyl",
      "eki",
      "kas",
      "ara",
    ],
    today: "Bugün",
    clear: "Temizle",
  });

  const hideDialog = () => {
    setBrandName("");
    setCus("");
    setApplicationNumber("")
    setBrandStatus("");
    setNiceClasses(null)
    setStartingDate(null);
    setRenewalDate(null);
    setOwner("");
    setAddModal(false);
  };
  
  const handleCompletebrandName = async () => {
    try {
      if (!selectedBrand) {
        console.error("Düzenlenecek marka bulunamadı.");
        return;
      }

      const formattedStartingDate = formatDate(startingDate);
      const formattedRenewalDate = formatDate(renewalDate);

      await UpdateBrand(
        selectedBrand?.id,
        applicationNumber,
        brandName,
        cus,
        owner,
        formattedStartingDate,
        brandStatus,
        niceClasses,
        formattedRenewalDate
      );
      setDialogs(false);
    } catch (error) {
      console.error("Hata:", error.message);
    }
  };

  const handleAddTime = async () => {
    try {
      await AddTimeBrand(selectedBrand?.id, time);
      setDialogs(false);
    } catch (error) {
      console.error("Hata:", error.message);
    }
  };

  const hideDeleteDialog = () => {
    setDisplayDeleteDialog(false);
    setCus("");
    setApplicationNumber("");
    setBrandName("");
  
  };
  const handleDeleteBrand = async () => {
    await DeleteBrand(selectedBrand?.id);
    setDisplayDeleteDialog(false);
     setCus("");
     setApplicationNumber("");
     setBrandName("");
   
  };

  const EditBodyTemplate = (tasks) => {
    const isVisible = dialogs[tasks.id] || false;

    return (
      <React.Fragment>
        <div style={{ display: "flex" }}>
          <div style={{ color: "#f39f5a" }}>
            <Button
              severity="warning"
              onClick={() => {
                setSelectedBrand(tasks);
                setDialogs((prevDialogs) => ({
                  ...prevDialogs,
                  [tasks.id]: true,
                }));
                setApplicationNumber(tasks.application_number);
                setBrandName(tasks.brand || "");
                setCus(
                  customerList?.find(
                    (dept) => dept.id.toString() === tasks.customer
                  )?.id || ""
                );

                setBrandStatus(
                  brandsStatus?.find(
                    (brand) => brand.value.toString() === tasks.status
                  )?.value || ""
                );

                const formattedNiceClasses = tasks.nice_classes.split(", ");
                setNiceClasses(formattedNiceClasses);
                setOwner(tasks.owner || "");
                const startingDateValue = tasks.starting_date
                  ? new Date(tasks.starting_date)
                  : new Date();
                const renewalDateValue = tasks.renewal_date
                  ? new Date(tasks.renewal_date)
                  : new Date();

                setStartingDate(startingDateValue);
                setRenewalDate(renewalDateValue);
              }}
              icon="pi pi-pencil"
            />
          </div>
          <div style={{ color: "#ae445a" }}>
            <Button
              icon="pi pi-trash"
              onClick={() => {
                setSelectedBrand(tasks);
                setApplicationNumber(tasks?.application_number)
                setBrandName(tasks?.brand)
                  if (tasks?.customer) {
                    const customer = customerList.find(
                      (dept) => dept.id.toString() === tasks.customer
                    );

                    if (customer) {
                      const fullName = customer.title;
                      const splittedName = fullName.split(" ");
                      const firstTwoWords = splittedName.slice(0, 2).join(" ");
                      setCus(firstTwoWords);
                    } else {
                      setCus("Bilinmeyen");
                    }
                  }
                setDisplayDeleteDialog(true);
              }}
              severity="danger"
            />
          </div>
          {/* <div style={{ color: "#00d9ff" }}>
            <Button
              icon="pi pi-clone"
              onClick={() => {
                setVisibilityDialog(true);
              }}
              severity="info"
            />
          </div> */}
        </div>
        <Dialog
          header="İçerik Düzenleme"
          visible={isVisible}
          style={{ width: "50vw" }}
          onHide={() => {
            setDialogs((prevDialogs) => ({
              ...prevDialogs,
              [tasks.id]: false,
            }));
            setSelectedBrand(null);
            hideDialog();
          }}
          footer={
            <div>
              <Button
                label="Kaydet"
                icon="pi pi-check"
                onClick={handleCompletebrandName}
                className="btn-excel-two"
                style={{ marginLeft: "15px" }}
              />
            </div>
          }
        >
          <div className="confirmation-content">
            <div className="p-grid p-fluid">
              <div className="p-col-6">
                <InputText
                  type="text"
                  className="w-full p-inputtext-lg mt-1 mb-3 "
                  placeholder="Başvuru Numarası"
                  defaultValue={applicationNumber}
                  onChange={(e) => setApplicationNumber(e.target.value)}
                />
              </div>
              <div className="p-col-6" style={{ marginBottom: "1rem" }}>
                <InputText
                  type="text"
                  className="w-full p-inputtext-lg "
                  placeholder="Marka Adı"
                  defaultValue={brandName}
                  onChange={(e) => setBrandName(e.target.value)}
                />
              </div>

              <div className="p-col-6" style={{ marginBottom: "1rem" }}>
                <Dropdown
                  filter
                  options={customerList?.map((dep) => ({
                    label: dep.title,
                    value: dep.id,
                  }))}
                  value={cus}
                  placeholder="Müşteri Adı "
                  className="w-full"
                  onChange={(e) => setCus(e.target.value)}
                  style={{
                    border: "1px solid gray",
                  }}
                />
              </div>

              <div className="p-col-6">
                <Calendar
                  placeholder="İlk Kayıt Tarihi"
                  locale="tr"
                  className="w-full"
                  style={{
                    border: "1px solid gray",
                    padding: "0.5rem",

                    marginBottom: "1rem",
                    borderRadius: "6px 6px 6px 6px",
                  }}
                  value={startingDate || null}
                  onChange={(e) => setStartingDate(e.value)}
                  dateFormat="dd/mm/yy"
                  showTime={false}
                />
              </div>
              <div className="p-col-6">
                <Dropdown
                  filter
                  options={brandsStatus?.map((brand) => ({
                    label: brand.label,
                    value: brand.value,
                  }))}
                  value={brandStatus}
                  placeholder="Durumu"
                  className="w-full"
                  onChange={(e) => setBrandStatus(e.target.value)}
                  style={{
                    border: "1px solid gray",
                    marginBottom: "1rem",
                  }}
                />
              </div>
              <div className="p-col-6">
                <Chips
                  value={niceClasses}
                  className="p-inputtext w-full"
                  onChange={handleNiceClasses}
                  placeholder="Nice Sınıfları"
                  style={{
                    marginBottom: "1rem",
                    border: "1px solid ",
                  }}
                />
              </div>
              <div className="p-col-6">
                <Calendar
                  placeholder="Yenileme Tarihi"
                  locale="tr"
                  style={{
                    border: "1px solid gray",

                    padding: "0.5rem",
                    marginBottom: "1rem",
                    borderRadius: "6px 6px 6px 6px",
                  }}
                  className="w-full"
                  value={renewalDate}
                  onChange={(e) => setRenewalDate(e.value)}
                  dateFormat="dd/mm/yy"
                  showTime={false}
                />
              </div>
              <div className="p-col-6" style={{ marginBottom: "1rem" }}>
                <SelectButton
                  style={{
                    border: "1px solid gray",
                  }}
                  value={owner}
                  className="w-full"
                  onChange={(e) => setOwner(e.value)}
                  optionLabel="label"
                  options={options}
                />
              </div>
            </div>
          </div>
        </Dialog>
      </React.Fragment>
    );
  };
  const AddTimeTemplate = (tasks) => {
    const [isVisibleTime, setIsVisibleTime] = useState(
      dialogsVisibleTime[tasks.id] || false
    );

    return (
      <React.Fragment>
        <div style={{ color: "green" }}>
          <Button
            icon="pi pi-calendar-plus"
            onClick={() => {
              setSelectedBrand(tasks);
              setIsVisibleTime(true);
            }}
            severity="success"
          />
        </div>
        <Dialog
          header="Ek Süre"
          visible={isVisibleTime}
          style={{ width: "50vw" }}
          onHide={() => {
            setDialogsVisibleTime((prevDialogs) => ({
              ...prevDialogs,
              [tasks.id]: false,
            }));
            setSelectedBrand(null);
            setIsVisibleTime(false);
          }}
          footer={
            <div>
              <Button
                label="Onayla"
                icon="pi pi-check"
                onClick={handleAddTime}
                className="btn-excel-two"
                style={{ marginLeft: "15px" }}
              />
            </div>
          }
        >
          <div>
            {" "}
            <Dropdown
              options={[
                { label: "10 Yıl", value: 10 }
              ]}
              value={time}
              placeholder="Ek Süre"
              onChange={(e) => setTime(e.target.value)}
              style={{
                border: "1px solid gray",
                width: "65%",
              }}
            />
          </div>
        </Dialog>
      </React.Fragment>
    );
  };

  const newCreate = async () => {
   
    if (
      !applicationNumber ||
      !brandName ||
      !cus ||
      !startingDate ||
      !renewalDate ||
      !owner || 
      
      !brandStatus
    ) {
      setInputErrors({
        applicationNumber: !applicationNumber,
        brandName: !brandName,
        cus: !cus,
        owner: !owner,
        startingDate: !startingDate,
        renewalDate: !renewalDate,
        brandStatus: !brandStatus,
      });
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Lütfen boş kısımları doldurun.",
      });
      return;
    }

    const formattedStartingDate = formatDate(startingDate);
    const formattedRenewalDate = formatDate(renewalDate);

    await AddBrand(
      applicationNumber,
      brandName,
      cus,
      owner,
      formattedStartingDate,
      brandStatus,
      niceClasses,
      formattedRenewalDate
    );
    setAddModal(false);
    setBrandName("");
    setApplicationNumber("")
    setNiceClasses(null)
    setBrandStatus("")
    setCus("");
    setOwner("");
    setStartingDate(null);
    setRenewalDate(null);
    setAddModal(false);
  };

  const calculateRemainingDays = (renewalDate) => {
    const ONE_DAY = 24 * 60 * 60 * 1000;
    const renewalDateObject = new Date(renewalDate);
    const startingDateObject = new Date();

    const timeDiff = renewalDateObject - startingDateObject;
    const daysDiff = Math.ceil(timeDiff / ONE_DAY);

    return daysDiff;
  };
const handleNiceClasses = (e) => {
  setNiceClasses(e.value);
};
  useEffect(() => {
    if (brandData) {
      const fetchData = () => {
        const sortedData = [...brandData?.brand].sort((a, b) => {
          const remainingDaysA = calculateRemainingDays(a.renewal_date);
          const remainingDaysB = calculateRemainingDays(b.renewal_date);
          return remainingDaysA - remainingDaysB;
        });
        setBrand(sortedData);
        setCustomerList(brandData.customers);
      };
      fetchData();
    }
  }, [brandData, dialogs]);

  const handleCloseDialog = () => {
    
    setVisibilityDialog(false);
  };
  return (
    <Flex mt="5" justifyContent="center" alignItems="center">
      <div
        className="card"
        style={{
          overflow: "auto",
          boxSizing: "border-box",
          width: "100%",
          height: "100%",
          position: "relative",
          padding: "1rem",
        }}
      >
        <Card>
          <CardHeader>
            <Flex justifyContent="space-between" alignItems="center">
              <Heading size="md">Marka</Heading>
              <div>
                <span
                  className="p-input-icon-right"
                  style={{
                    border: "solid 1px gray",
                    borderRadius: "6px 6px 6px 6px",
                    padding: "0.2rem",
                  }}
                >
                  <i className="pi pi-search" />
                  <InputText
                    placeholder="Marka Ara"
                    onChange={(e) => filterBrandName(e.target.value)}
                  />
                </span>
                <Button
                  style={{ marginLeft: "1rem" }}
                  label="Yeni"
                  onClick={() => {
                    setAddModal(true);
                  }}
                  className="btn-excel-two"
                />
              </div>
            </Flex>
          </CardHeader>
          <DataTable
            stripedRows
            size="small"
            dataKey="id"
            tableStyle={{ minWidth: "50rem" }}
            rows={15}
            value={brand}
            rowsPerPageOptions={[15, 30, 60, 100]}
            scrollHeight="65vh"
            scrollable
            paginator
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate=" {totalRecords} markadan {first} - {last} arası gösteriliyor"
          >
            <Column body={EditBodyTemplate} header="İşlemler"></Column>
            <Column
              field="application_number"
              header="Başvuru Numarası"
            ></Column>
            <Column field="brand" header="Marka Adı"></Column>
            <Column
              field="customer"
              header="Müşteri Adı"
              body={(rowData) => {
                const customer = customerList.find(
                  (dept) => dept.id.toString() === rowData.customer
                );

                if (customer) {
                  const fullName = customer.title;
                  const splittedName = fullName.split(" ");
                  const firstTwoWords = splittedName.slice(0, 2).join(" ");
                  return firstTwoWords;
                } else {
                  return "Bilinmeyen";
                }
              }}
            ></Column>
            <Column
              field="owner"
              body={(rowData) => {
                const owner = options.find(
                  (owner) => owner.value === rowData.owner
                );
                if (owner) {
                  return owner.label;
                } else {
                  return "Bilinmeyen";
                }
              }}
              header="Marka Sahibi"
            ></Column>
            <Column
              field="starting_date"
              header="Başvuru Tarihi(İlk Kayıt)"
              body={(rowData) => formatDate(rowData.starting_date)}
            ></Column>
            <Column
              field="status"
              header="Durumu"
              body={(rowData) => {
                const status = brandsStatus.find(
                  (status) => status?.value === rowData?.status
                );
                if (status) {
                  return status.label;
                } else {
                  return "Bilinmeyen";
                }
              }}
            ></Column>
            <Column field="nice_classes" header="Nice Sınıfları"></Column>
            <Column
              field="renewal_date"
              header="Yenileme Tarihi"
              body={(rowData) => formatDate(rowData.renewal_date)}
            ></Column>
            <Column
              header="Kalan Süre"
              body={(rowData) => {
                const remainingDays = calculateRemainingDays(
                  rowData.renewal_date
                );

                let backgroundColor;
                let textColor;

                if (remainingDays >= 30) {
                  backgroundColor = "#4CAF50";
                  textColor = "black";
                } else if (remainingDays >= 7) {
                  backgroundColor = "#FFC107";
                  textColor = "black";
                } else {
                  backgroundColor = "#F44336";
                  textColor = "black";
                }

                const style = {
                  backgroundColor,
                  borderRadius: "5px",
                  color: textColor,
                  padding: "5px",
                  display: "inline-block",
                };

                return <span style={style}>{remainingDays} gün</span>;
              }}
            ></Column>
            <Column body={AddTimeTemplate} header="Ek Süre"></Column>
          </DataTable>
        </Card>
      </div>
      <Dialog
        visible={addModal}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        style={{ width: "50%" }}
        header="Marka Ekle"
        onHide={hideDialog}
        modal
      >
        <div className="confirmation-content">
          <div className="p-grid p-fluid">
            <div className="p-col-12">
              <div style={{ marginBottom: "1rem" }}>
                <InputText
                  type="text"
                  className="p-inputtext-lg w-full"
                  placeholder="Başvuru Numarası"
                  defaultValue={applicationNumber}
                  onChange={(e) => {
                    setApplicationNumber(e.target.value);
                    console.log(applicationNumber);
                    setInputErrors({
                      ...inputErrors,
                      applicationNumber: false,
                    });
                  }}
                />
                {inputErrors.applicationNumber && (
                  <small style={{ color: "red" }}>
                    Lütfen başvuru numarası giriniz.
                  </small>
                )}
              </div>
              <div style={{ marginBottom: "1rem" }}>
                <InputText
                  type="text"
                  className="p-inputtext-lg w-full "
                  placeholder="Marka Adı"
                  value={brandName}
                  onChange={(e) => {
                    setBrandName(e.target.value);
                    setInputErrors({ ...inputErrors, brandName: false });
                  }}
                />
                {inputErrors.brandName && (
                  <small style={{ color: "red" }}>
                    Lütfen marka adı giriniz.
                  </small>
                )}
              </div>

              <div style={{ marginBottom: "1rem" }}>
                <Dropdown
                  filter
                  options={customerList?.map((dep) => ({
                    label: dep.title,
                    value: dep.id,
                  }))}
                  value={cus}
                  placeholder="Müşteri Adı "
                  className="w-full"
                  onChange={(e) => {
                    setCus(e.target.value);
                    setInputErrors({ ...inputErrors, cus: false });
                  }}
                  style={{
                    border: "1px solid gray",
                  }}
                />
                {inputErrors.cus && (
                  <small style={{ color: "red" }}>Lütfen müşteri seçiniz</small>
                )}
              </div>

              <div style={{ marginBottom: "1rem" }}>
                <Calendar
                  placeholder="İlk Kayıt Tarihi"
                  locale="tr"
                  style={{
                    border: "1px solid gray",
                    padding: "0.5rem",
                    borderRadius: "6px 6px 6px 6px",
                  }}
                  value={startingDate}
                  onChange={(e) => {
                    setStartingDate(e.value);
                    setInputErrors({ ...inputErrors, startingDate: false });
                  }}
                  dateFormat="dd/mm/yy"
                  showTime={false}
                  className="w-full"
                />
                {inputErrors.startingDate && (
                  <small style={{ color: "red" }}>
                    Lütfen ilk kayıt tarihi seçiniz
                  </small>
                )}
              </div>
              <div style={{ marginBottom: "1rem" }}>
                <Dropdown
                  filter
                  options={brandsStatus?.map((brand) => ({
                    label: brand.label,
                    value: brand.value,
                  }))}
                  value={brandStatus}
                  className="w-full"
                  placeholder="Durumu"
                  onChange={(e) => {
                    setBrandStatus(e.target.value);
                    setInputErrors({ ...inputErrors, brandStatus: false });
                  }}
                  style={{
                    border: "1px solid gray",
                  }}
                />
                {inputErrors.brandStatus && (
                  <small style={{ color: "red" }}>Lütfen durum seçiniz.</small>
                )}
              </div>
              <div style={{ marginBottom: "1rem" }}>
                <Chips
                  value={niceClasses}
                  className="p-inputtext w-full"
                  onChange={handleNiceClasses}
                  placeholder="Nice Sınıfları"
                  style={{
                    border: "1px solid ",
                  }}
                />
              </div>

              <div style={{ marginBottom: "1rem" }}>
                <Calendar
                  placeholder="Yenileme Tarihi"
                  locale="tr"
                  style={{
                    border: "1px solid gray",
                    padding: "0.5rem",
                    borderRadius: "6px 6px 6px 6px",
                  }}
                  value={renewalDate}
                  onChange={(e) => {
                    setRenewalDate(e.value);
                    setInputErrors({ ...inputErrors, renewalDate: false });
                  }}
                  dateFormat="dd/mm/yy"
                  className="w-full"
                  showTime={false}
                />
                {inputErrors.renewalDate && (
                  <small style={{ color: "red" }}>
                    Lütfen yenileme tarihi seçiniz.
                  </small>
                )}
              </div>
              <div style={{ marginBottom: "1rem" }}>
                <SelectButton
                  style={{
                    border: "1px solid gray",
                  }}
                  value={owner}
                  className="w-full"
                  onChange={(e) => {
                    setOwner(e.value);
                    setInputErrors({ ...inputErrors, owner: false });
                  }}
                  optionLabel="label"
                  options={options}
                />
                {inputErrors.owner && (
                  <small style={{ color: "red" }}>Lütfen aitlik seçiniz.</small>
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1rem",
          }}
        >
          <Button
            label="Ekle"
            onClick={newCreate}
            style={{ width: "8rem" }}
            className="btn-excel-two"
          />
        </div>
      </Dialog>
      <Dialog
        visible={displayDeleteDialog}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        style={{ width: "60vw" }}
        header="Marka  Sil"
        onHide={hideDeleteDialog}
        modal
      >
        <div className="confirmation-content">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <p>Markayı Silmek İstediğinize Emin Misiniz?</p>
          </div>
          <div className="confirmation-content">
            <div className="confirmation-content">
              <div className="confirmation-content">
                <div className="field">
                  <label htmlFor="name" className="font-bold">
                    Başvuru Numarası: {applicationNumber}
                  </label>
                </div>

                <div className="field">
                  <label htmlFor="name" className="font-bold">
                    Marka Adı: {brandName}
                  </label>
                </div>
                <div className="field">
                  <label htmlFor="name" className="font-bold">
                    Müşeteri Adı: {cus}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "1rem",
            }}
          >
            <Button
              label="Hayır"
              onClick={hideDeleteDialog}
              className="btn-excel-two"
              style={{ marginRight: "1rem" }}
            />
            <Button
              label="Evet"
              onClick={handleDeleteBrand}
              className="btn-excel-two"
            />
          </div>
        </div>
      </Dialog>
      <Dialog
        header="Pasife Al"
        visible={visibilityDialog}
        style={{ width: "50vw" }}
        onHide={() => setVisibilityDialog(false)}
        footer={
          <div>
            <Button
              label="Kapat"
              icon="pi pi-times"
              onClick={handleCloseDialog}
              className="btn-excel-two"
            />
            <Button
              label="Markayı Pasife Al"
              icon="pi pi-check"
              className="btn-excel-two"
              style={{ marginLeft: "15px" }}
            />
          </div>
        }
      ></Dialog>
      <Toast ref={toast} />
    </Flex>
  );
}
