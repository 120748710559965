import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PrivateRoutes from "./PrivateRoutes";
import Login from "../auth/Login";
import Dashboard from "../modules/dashboard/Dashboard";
import Employees from "../modules/Chancellery/employees/Employees";
import Costs from "../modules/Chancellery/costs/Costs";
import Permissions from "../modules/Chancellery/permissions/Permissions";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import CustomerPage from "../modules/Sales/customerList/CustomerPage";
import OfferPage from "../modules/Sales/offers/OffersPage";
import Departments from "../modules/Chancellery/departments/Departments";
import Tally from "../modules/Planning/tally/Tally";
import Tasks from "../modules/Operation/tasks/Tasks";
import Support from "../modules/Operation/support/Support";
import Jobs from "../modules/Operation/jobs/Jobs";
import Product from "../modules/Planning/products/ProductPage";
import Manufacturing from "../modules/Planning/manufacturing/Manufacturing";
import Note from "../modules/user-operation/note/Note";
import ToDoList from "../modules/user-operation/todo/TodoListPage";
import CalendarPage from "../modules/user-operation/calendar/CalendarPage";
import Chat from "../modules/user-operation/chat/Chat";
import AnnouncePage from "../modules/Chancellery/announces/AnnouncePage";
import DataTransferPage from "../modules/user-menu/settings/DataTransfer";
import UserSettings from "../modules/user-settings/UserSettings";
import CustomerPanel from "../modules/Panel/customerPanel/CustomerPanel";
import CustomerInformation from "../modules/Panel/customerPanel/customer/customer-information/CustomerInformation";
import CustomerSupport from "../modules/Panel/customerPanel/customer/customer-support/CustomerSupport";
import { useAuth } from "../auth/core/AuthContext";
import OrdersPage from "../modules/Sales/orders/OrdersPage";
import JobDetailPage from "../modules/Operation/jobs/JobDetailPage";
import SmsPage from "../modules/Chancellery/net-gsm/SmsPage";
import SmsReportsPage from "../modules/Chancellery/net-gsm/SmsReportsPage";
import MailReportsPage from "../modules/Chancellery/mails/MailReportsPage";
import MailPersonsPage from "../modules/Chancellery/mails/MailPersonsPage";
import Settings from "../modules/user-menu/settings/Settings";
import EmployeesReports from "../modules/Panel/customerPanel/employees/employees-reports/EmployeesReports";
import CustomerReports from "../modules/Panel/customerPanel/customer/customer-reports/CustomerReports";
import MyFilesPage from "../modules/user-menu/settings/MyFiles";
import EmployeesInformation from "../modules/Panel/customerPanel/employees/employees-information/EmployeesInformation";

import SmsPersonsPage from "../modules/Chancellery/net-gsm/SmsPersonsPage";
import EmployeesCustomers from "../modules/Panel/customerPanel/employees/employees-customers/EmployeesCustomers";
import EmployeesPayment from "../modules/Panel/customerPanel/employees/employees-payment/EmployeesPayment";
import MarketingChasePage from "../modules/Sales/marketing-chase/MarketingChasePage";
import CustomerSecurity from "../modules/Panel/customerPanel/customer/customer-security/CustomerSecurity";
import CustomerPayment from "../modules/Panel/customerPanel/customer/customer-payment/CustomerPayment";
import CustomerServices from "../modules/Panel/customerPanel/customer/customer-services/CustomerServices";
import Services from "../modules/Planning/services/Services";
import Quotation from "../modules/Sales/quotation/Quotation";
import MailPage from "../modules/Chancellery/mails/MailPage";
import CustomerQuotation from "../modules/customer-quotation/CustomerQuotation";
import Kvkk from "../modules/kvkk/Kvkk";
import Success from "../modules/payment/Success";
import Failure from "../modules/payment/Failure";
import Supplier from "../modules/Chancellery/Supplier/Supplier";
import Templates from "../modules/templates/Templates";
import Entegration from "../modules/user-operation/entegrations/Entegration";
import Guarantees from "../modules/Planning/guarantee/tally/Guarantees";
const AppRoutes = () => {
  const { userRole } = useAuth();

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/talep" element={<CustomerQuotation />} />
      <Route path="/kvkk" element={<Kvkk />} />
      <Route path="/payment/success" element={<Success />} />
      <Route path="/payment/fail" element={<Failure />} />
      <Route element={<PrivateRoutes userRole={userRole} />}>
        <Route
          path="/dashboard"
          element={userRole === "0" ? <Dashboard /> : <Navigate to="/*" />}
        />
        <Route
          path="/employees-list"
          element={userRole === "0" ? <Employees /> : <Navigate to="/*" />}
        />
        <Route
          path="/permissions"
          element={userRole === "0" ? <Permissions /> : <Navigate to="/*" />}
        />
        <Route
          path="/tasks"
          element={userRole === "0" ? <Tasks /> : <Navigate to="/*" />}
        />
        <Route
          path="/customer-list"
          element={userRole === "0" ? <CustomerPage /> : <Navigate to="/*" />}
        />
        <Route
          path="/departments"
          element={userRole === "0" ? <Departments /> : <Navigate to="/*" />}
        />
        <Route
          path="/tally"
          element={userRole === "0" ? <Tally /> : <Navigate to="/*" />}
        />
        <Route
          path="/support"
          element={userRole === "0" ? <Support /> : <Navigate to="/*" />}
        />
        <Route
          path="/marketingChase"
          element={
            userRole === "0" ? <MarketingChasePage /> : <Navigate to="/*" />
          }
        />
        <Route
          path="/jobs"
          element={userRole === "0" ? <Jobs /> : <Navigate to="/*" />}
        />
        <Route
          path="/costs"
          element={userRole === "0" ? <Costs /> : <Navigate to="/*" />}
        />
        <Route
          path="/products"
          element={userRole === "0" ? <Product /> : <Navigate to="/*" />}
        />
        <Route
          path="/manufacturing"
          element={userRole === "0" ? <Manufacturing /> : <Navigate to="/*" />}
        />
        <Route
          path="/offers"
          element={userRole === "0" ? <OfferPage /> : <Navigate to="/*" />}
        />
        <Route
          path="/dashboard/note"
          element={userRole === "0" ? <Note /> : <Navigate to="/*" />}
        />
        <Route
          path="/dashboard/todo"
          element={userRole === "0" ? <ToDoList /> : <Navigate to="/*" />}
        />
        <Route
          path="/dashboard/chat"
          element={userRole === "0" ? <Chat /> : <Navigate to="/*" />}
        />
        <Route
          path="/dashboard/calendar"
          element={userRole === "0" ? <CalendarPage /> : <Navigate to="/*" />}
        />
        <Route
          path="/announcement"
          element={userRole === "0" ? <AnnouncePage /> : <Navigate to="/*" />}
        />
        <Route
          path="/settings"
          element={userRole === "0" ? <Settings /> : <Navigate to="/*" />}
        />
        <Route
          path="/user/settings"
          element={userRole === "0" ? <UserSettings /> : <Navigate to="/*" />}
        />
        <Route
          path="/user/templates"
          element={userRole === "0" ? <Templates /> : <Navigate to="/*" />}
        />
        <Route
          path="/user/entegrations"
          element={userRole === "0" ? <Entegration /> : <Navigate to="/*" />}
        />
        <Route
          path="/customer/detail"
          element={
            userRole === "1" || userRole === "0" ? (
              <CustomerPanel />
            ) : (
              <Navigate to="/*" />
            )
          }
        />
        <Route
          path="/customer/information"
          element={
            userRole === "1" ? <CustomerInformation /> : <Navigate to="/*" />
          }
        />
        <Route
          path="/customer/support"
          element={
            userRole === "1" ? <CustomerSupport /> : <Navigate to="/*" />
          }
        />
        <Route
          path="/customer/reports"
          element={
            userRole === "1" ? <CustomerReports /> : <Navigate to="/*" />
          }
        />
        <Route
          path="/orders"
          element={userRole === "0" ? <OrdersPage /> : <Navigate to="/*" />}
        />
        <Route
          path="/jobs/details"
          element={userRole === "0" ? <JobDetailPage /> : <Navigate to="/*" />}
        />
        <Route
          path="/sms"
          element={userRole === "0" ? <SmsPage /> : <Navigate to="/*" />}
        />
        <Route
          path="/sms/reports"
          element={userRole === "0" ? <SmsReportsPage /> : <Navigate to="/*" />}
        />
        <Route
          path="/sms/persons"
          element={userRole === "0" ? <SmsPersonsPage /> : <Navigate to="/*" />}
        />
        <Route
          path="/settings/data-transfer"
          element={
            userRole === "0" ? <DataTransferPage /> : <Navigate to="/*" />
          }
        />
        <Route
          path="/customer/information/edit"
          element={
            userRole === "0" ? <EmployeesInformation /> : <Navigate to="/*" />
          }
        />
        <Route
          path="/customer/reports/edit"
          element={
            userRole === "0" ? <EmployeesReports /> : <Navigate to="/*" />
          }
        />
        <Route
          path="/email"
          element={userRole === "0" ? <MailPage /> : <Navigate to="/" />}
        />
        <Route
          path="/email/reports"
          element={userRole === "0" ? <MailReportsPage /> : <Navigate to="/" />}
        />
        <Route
          path="/email/persons"
          element={userRole === "0" ? <MailPersonsPage /> : <Navigate to="/" />}
        />
        <Route
          path="/my-files"
          element={userRole === "0" ? <MyFilesPage /> : <Navigate to="/" />}
        />
        <Route
          path="/panel/customers/add"
          element={
            userRole === "0" ? <EmployeesCustomers /> : <Navigate to="/*" />
          }
        />
        <Route
          path="/customer/payment/edit"
          element={
            userRole === "0" ? <EmployeesPayment /> : <Navigate to="/*" />
          }
        />
        <Route
          path="/customer/security"
          element={
            userRole === "1" ? <CustomerSecurity /> : <Navigate to="/*" />
          }
        />
        <Route
          path="/customer/payment"
          element={
            userRole === "1" ? <CustomerPayment /> : <Navigate to="/*" />
          }
        />
        <Route
          path="/customer/services"
          element={
            userRole === "1" ? <CustomerServices /> : <Navigate to="/*" />
          }
        />
        <Route
          path="/services"
          element={userRole === "0" ? <Services /> : <Navigate to="/*" />}
        />
        <Route
          path="/talepler"
          element={userRole === "0" ? <Quotation /> : <Navigate to="/*" />}
        />
        <Route
          path="/supplier-add"
          element={userRole === "0" ? <Supplier /> : <Navigate to="/*" />}
        />
        <Route
          path="/guarantee"
          element={userRole === "0" ? <Guarantees /> : <Navigate to="/*" />}
        />
      </Route>
      <Route path="*" element={<ErrorsPage />} />
    </Routes>
  );
};

export default AppRoutes;
