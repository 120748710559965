import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Flex } from "@chakra-ui/react";
import { Tag } from "primereact/tag";
import { useSelector } from "react-redux";
import { useAuth } from "../../../../auth/core/AuthContext";
export default function DahsboardTableAdminTask() {
  const [tasks, setTasks] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [users, setUsers] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [customers, setCustomers] = useState([]);
  const dt = useRef(null);
  const { userData } = useAuth();
  const dashboardData = useSelector((state) => state.DashboardReduce.value);

  const workingBodyTemplate = (rowData) => {
    const getStatusLabel = (working_condition) => {
      switch (working_condition) {
        case "0":
          return "Öncelikli";

        case "1":
          return "Normal";

        case "2":
          return "Kritik";

        default:
          return null;
      }
    };

    const statusLabel = getStatusLabel(rowData.working_condition);

    return <Tag value={statusLabel} severity={getSeverity(rowData)}></Tag>;
  };

  const getSeverity = (tasks) => {
    switch (tasks?.working_condition) {
      case "0":
        return "warning";

      case "1":
        return "success";

      case "2":
        return "danger";

      default:
        return null;
    }
  };

  const statusBodyTemplate = (rowData) => {
    const getStatusLabel = (status) => {
      switch (status) {
        case 0:
          return "Boşta";
        case 1:
          return "İşlemde";
        case 2:
          return "Tamamlamdı";
        default:
          return null;
      }
    };

    const statusLabel = getStatusLabel(rowData.status);

    return <Tag value={statusLabel} severity={getStatus(rowData)}></Tag>;
  };

  const getStatus = (tasks) => {
    switch (tasks?.status) {
      case 0:
        return "danger";
      case 1:
        return "warning";
      case 2:
        return "success";
      default:
        return null;
    }
  };

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false,
    };
    return new Date(dateString).toLocaleString("tr-TR", options);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setTasks(dashboardData.tasks);
        setDepartments(dashboardData.departments);
        setCustomers(dashboardData.customers);
        setUsers(dashboardData.users);
      } catch (error) {}
    };

    fetchData();
  }, [dashboardData]);

  useEffect(() => {
    const updatedFilteredTasks = tasks?.filter(
      (task) =>
        Number(task?.tasks_id) === 1 &&
        (task?.designated_person?.includes(",")
          ? task?.designated_person
              ?.split(",")
              ?.includes(userData?.id?.toString())
          : task?.designated_person === userData?.id?.toString())
    );
    setFilteredTasks(updatedFilteredTasks);
  }, [tasks, userData]);

  return (
    <Flex justifyContent="center" alignItems="center">
      <div
        className="card"
        style={{
          overflow: "auto",
          boxSizing: "border-box",
          width: "100%",
          height: "100%",
          position: "relative",
          padding: "1rem",
        }}
      >
        <DataTable
          stripedRows
          size="small"
          tableStyle={{ minWidth: "50rem" }}
          ref={dt}
          value={filteredTasks}
          dataKey="id"
          scrollHeight="25vh"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="{totalRecords} görevden {first} - {last} arası gösteriliyor"
        >
          <Column
            field="title"
            header="Müşteri"
            body={(rowData) => {
              const cust = customers?.find(
                (dept) => dept.id.toString() === rowData.name
              );
              if (cust) {
                // Başlığı boşluklara göre bölerek ilk iki kelimeyi al
                const firstTwoWords =
                  cust.title && cust.title.split(" ").slice(0, 2).join(" ");
                return firstTwoWords;
              } else {
                return "Bilinmeyen";
              }
            }}
          ></Column>

          <Column
            field="desc"
            header="Açıklama"
            style={{ minWidth: "6rem" }}
          ></Column>
          <Column
            field="working_concition"
            header="Durum"
            body={workingBodyTemplate}
            style={{ minWidth: "4rem" }}
            filterMatchMode="equals"
            filterPlaceholder="Filter by Status"
          ></Column>
          <Column
            field="department"
            header="Departman"
            body={(rowData) => {
              const department = departments?.find(
                (dept) => dept.id.toString() === rowData.department
              );
              return department ? department.department : rowData.department;
            }}
            style={{ minWidth: "6rem" }}
          ></Column>
          <Column
            header="Atanan Kişi"
            body={(rowData) => {
              const designatedPersons = rowData?.designated_person.split(","); // Virgüllerle ayrılmış kimlikleri diziye ayır

              const assignedUsers = designatedPersons.map(
                (designatedPerson) => {
                  const user = users?.find(
                    (usr) => usr.id.toString() === designatedPerson.trim()
                  );

                  return user ? user.name : "Bilinmeyen"; // Kullanıcı adını bul veya "Unknown User" döndür
                }
              );

              return assignedUsers.join(", "); // Virgülle ayrılmış kullanıcı adlarını birleştir ve sütuna yazdır
            }}
            style={{ minWidth: "6rem" }}
          ></Column>
          <Column
            field="created_at"
            header="Oluşturulma Tarihi"
            body={(rowData) => formatDate(rowData.created_at)}
            style={{ minWidth: "6rem" }}
          ></Column>
          <Column
            field="status"
            header="Durum"
            body={statusBodyTemplate}
            style={{ minWidth: "6rem" }}
          ></Column>
        </DataTable>
      </div>
    </Flex>
  );
}
