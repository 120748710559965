import React, { useState } from "react";
import { Splitter, SplitterPanel } from "primereact/splitter";
import PagesGraphPage from "./components/PagesGraphPage";
import PagesGraphTable from "./components/PagesGraphTable";

export default function PagesGraph({
  uniqueCustomerId,
  selectedMonth,
  selectedCustomer,
  months,
  browser,
}) {


  const [tableKey, setTableKey] = useState(0);

  const updateTableData = () => {
    setTableKey((prevKey) => prevKey + 1);
  };

  return (
    <Splitter style={{ height: "auto" }}>
      <SplitterPanel>
        <PagesGraphPage
          updateTableData={updateTableData}
          selectedMonth={selectedMonth}
          selectedCustomer={selectedCustomer}
          uniqueCustomerId={uniqueCustomerId}
         
          browser={browser}
        />
      </SplitterPanel>
      <SplitterPanel size={75} style={{ display: "block", padding: "1rem" }}>
        <PagesGraphTable
          months={months}
          key={tableKey}
          browser={browser}
          uniqueCustomerId={uniqueCustomerId}
        />
      </SplitterPanel>
    </Splitter>
  );
}
