import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  AnnounceService,
  AddAnnounceService,
  WasReadService,
} from "../../../../core/Services";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Card, CardHeader, Flex, Heading } from "@chakra-ui/react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { BreadCrumb } from "primereact/breadcrumb";
import { useAuth } from "../../../../auth/core/AuthContext";
import { InputTextarea } from "primereact/inputtextarea";
import { Checkbox } from "primereact/checkbox";
import { MultiSelect } from "primereact/multiselect";

export default function Announce() {
  const [users, setUsers] = useState([]);
  const [announces, setAnnounces] = useState([]);
  const [addModal, setAddModal] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [from, setFrom] = useState("");
  const [assignedEmp, setAssignedEmp] = useState("");
  const [visible, setVisible] = useState(false);
  const [, setSelectedData] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [dep, setDep] = useState("");
  const [checked, setChecked] = useState(true);
  const [checkedTwo, setCheckedTwo] = useState(false);
  const [checkedThree, setCheckedThree] = useState(false);
  const [loginUser, setLoginUser] = useState(false);
  const [dialogsDelete, setDialogsDelete] = useState({});
  const { userData } = useAuth();
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    return new Date(dateString).toLocaleString("tr-TR", options);
  };
  const hideDialog = () => {
    setVisible(false);
    setAddModal(false);
    setDep("");
    setName("");
    setEmail("");
    setAssignedEmp("");

    setDialogsDelete(false);
  };
  const accessors = (rowData) => {
    const visible = dialogsDelete[rowData.id] || false;
    let readers = rowData.readers;
    let isRead = false;
    if (readers?.includes(loginUser.id)) {
      isRead = true;
    }
    return (
      <div style={{ display: "flex" }}>
        <div style={{ color: isRead ? "green" : "#ae445a" }}>
          <Button
            icon="pi pi-bars"
            onClick={() => {
              setSelectedData(rowData);
              setDialogsDelete((prevDialogs) => ({
                ...prevDialogs,
                [rowData.id]: true,
              }));
              const user = users.find(
                (dept) => dept.id.toString() === rowData.user_id
              );
              if (user) {
                setFrom(user.name); // Örnek olarak kullanıcı adını alınabilir, bu örnekte varsayılan olarak "name" kullanılmıştır
              }
            }}
            severity={isRead ? "success" : "danger"}
          />
        </div>
        <Dialog
          header="Duyuru Detayları"
          visible={visible}
          style={{ width: "50vw" }}
          onHide={hideDialog}
          footer={
            <div>
              <Button
                label="Okundu olarak işaretle"
                icon="pi pi-check"
                onClick={() => {
                  hideDialog();
                  WasReadService(loginUser.id, rowData.id);
                }}
                className="btn-excel-two"
                style={{ marginLeft: "15px", display: isRead ? "none" : "" }}
              />
            </div>
          }
        >
          <div style={{ display: "flex" }}>
            <strong style={{ marginRight: "1rem" }}>Duyuruyu Yapan : </strong>
            <p> {from}</p>
          </div>
          <div style={{ display: "flex", marginTop: "1rem" }}>
            <strong style={{ marginRight: "1rem" }}>Konu : </strong>
            <p> {rowData.subject}</p>
          </div>
          <div style={{ display: "flex", marginTop: "1rem" }}>
            <strong style={{ marginRight: "1rem" }}>Açıklama : </strong>
            <p> {rowData.description}</p>
          </div>
          <div style={{ display: "flex", marginTop: "1rem" }}>
            <strong style={{ marginRight: "1rem" }}>Tarih : </strong>
            <p> {formatDate(rowData.created_at)}</p>
          </div>
        </Dialog>
      </div>
    );
  };

  const newCreate = async () => {
    let requestBody = {
      user_id: loginUser.id,
      subject: name,
      email,
      checked,
      dep,
      assignedEmp,
    };
    await AddAnnounceService(requestBody);
    setAddModal(false);
    setEmail("");
    setDep("");
    setName("");
    setAssignedEmp("");
  };

  const toast = useRef(null);
  useEffect(() => {
    setLoginUser(JSON.parse(localStorage.getItem("userData")));
    let logData = JSON.parse(localStorage.getItem("userData"));

    AnnounceService().then((data) => {
      setDepartments(data.departments);
      setUsers(data.users);

      if (logData.type === "1") {
        setAnnounces(data.announces.reverse());
      } else {
        // Aim_user nesnesi içerisinde logData.id değerini içeren duyuruları filtreleyerek yeni bir dizi oluşturun
        const filteredAnnounces = data.announces
          ?.map((announce) => {
            const aimUserIds = JSON.parse(announce.aim_user);

            if (aimUserIds.includes(logData.id)) {
              return announce;
            }
            return null;
          })
          .filter(Boolean); // Boş değerleri filtreleyerek null olmayanları alın

        if (filteredAnnounces.length > 0) {
          // Eğer filtrelenmiş duyuru varsa, setAnnounces ile güncelleyin
          setAnnounces(filteredAnnounces.reverse());
        } else {
          // Filtrelenmiş duyuru bulunamazsa, setAnnounces(null) ile duyuruları sıfırlayın
          setAnnounces(null);
        }
      }
    });
  }, [addModal, visible, dialogsDelete]);

  const getTurkishLabel = (url) => {
    switch (url) {
      case "/announcement":
        return "Duyurular";
      // Diğer durumlar için gerektiğinde eşleme yapabilirsiniz
      default:
        return "Sayfa"; // Varsayılan olarak "Sayfa" kullanılabilir veya istediğiniz bir şeyi kullanabilirsiniz
    }
  };
  const url = "/announcement";
  const turkishLabel = getTurkishLabel(url); // "Ürünler"

  const itemsBread = [{ label: turkishLabel, url: url }];
  const home = { icon: "pi pi-home", url: "/dashboard" };
  return (
    <Flex mt="5" justifyContent="center" alignItems="center">
      <div
        className="card"
        style={{
          overflow: "auto",
          boxSizing: "border-box",
          width: "100%",
          height: "100%",
          position: "relative",
          padding: "1rem",
        }}
      >
        <Card>
          <BreadCrumb
            model={itemsBread}
            home={home}
            style={{ padding: "1rem" }}
          />
          <CardHeader>
            <Flex justifyContent="space-between" alignItems="center">
              <Heading size="md">Duyurular</Heading>
              <div>
                {userData?.type === "1" && (
                  <Button
                    label="Yeni"
                    onClick={() => setAddModal(true)}
                    className="btn-excel-two"
                  />
                )}
              </div>
            </Flex>
          </CardHeader>
          <Toast ref={toast} />

          <DataTable
            stripedRows
            value={announces}
            size="small"
            dataKey="id"
            tableStyle={{ minWidth: "50rem" }}
            rows={15}
            rowsPerPageOptions={[15, 30, 60, 100]}
            scrollable
            paginator
            selectionMode="single"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate=" {totalRecords} duyurudan {first} - {last} arası gösteriliyor"
          >
            <Column
              style={{ width: "6%" }}
              field="accessor"
              body={(rowData) => accessors(rowData)}
              header="İşlemler"
            ></Column>
            <Column
              field="user_id"
              body={(rowData) => {
                const employee = users?.find(
                  (emp) => emp.id === Number(rowData.user_id)
                );
                return employee ? employee.name : "Bilinmeyen";
              }}
              header="Kimden"
            ></Column>
            <Column
              field="subject"
              header="Konu"
              body={(rowData) => {
                if (rowData.subject) {
                  const shortenedTitle =
                    rowData.subject.length > 15
                      ? rowData.subject.substring(0, 15) + "..."
                      : rowData.subject;
                  return shortenedTitle;
                } else {
                  return "Bilinmeyen";
                }
              }}
              style={{ minWidth: "8rem" }}
            />
            <Column
              field="description"
              header="Açıklama"
              body={(rowData) => {
                if (rowData.description) {
                  const shortenedTitle =
                    rowData.description.length > 15
                      ? rowData.description.substring(0, 15) + "..."
                      : rowData.description;
                  return shortenedTitle;
                } else {
                  return "Bilinmeyen";
                }
              }}
              style={{ minWidth: "8rem" }}
            />
            {userData?.type === "1" && (
              <Column
                style={{ width: "24%" }}
                field="aim_user"
                body={(rowData) => {
                  const designatedPersons = JSON.parse(
                    rowData?.aim_user || "[]"
                  );

                  const assignedUsers = designatedPersons.map(
                    (designatedPerson) => {
                      const userId = designatedPerson.toString().trim(); // ID'yi dizeye dönüştürüp boşlukları temizle
                      const user = users.find(
                        (usr) => usr.id.toString() === userId
                      );
                      return user ? user.name : "Bilinmeyen";
                    }
                  );

                  const assignedUsersString = assignedUsers.join(", "); // Virgülle ayrılmış kullanıcı adlarını birleştir

                  return assignedUsersString;
                }}
                header="Hedef"
              ></Column>
            )}
            <Column
              field="created_at"
              body={(rowData) => formatDate(rowData.created_at)}
              header="Tarih"
            ></Column>
          </DataTable>
        </Card>
      </div>
      <Dialog
        visible={addModal}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        style={{ width: "60vw" }}
        header="Duyuru Oluştur"
        onHide={hideDialog}
        modal
      >
        <div className="confirmation-content">
          <div className=" jc-space-around">
            {/* Sol Sütun */}
            <div className="col-12 " style={{ display: "inline-block" }}>
              <InputText
                type="text"
                className="p-inputtext-lg w-full"
                placeholder="Konu"
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
              <InputTextarea
                type="text"
                rows={5}
                cols={30}
                className="p-inputtext-lg w-full"
                placeholder="Açıklama"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                style={{ marginTop: "1rem" }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: "1rem",
                }}
              >
                {" "}
                <div>
                  {" "}
                  <label>Genel </label>{" "}
                  <Checkbox
                    onChange={(e) => {
                      setChecked(true);
                      setCheckedTwo(false);
                      setCheckedThree(false);
                      setDep("");
                      setAssignedEmp("");
                    }}
                    checked={checked}
                  ></Checkbox>
                </div>
                <div>
                  {" "}
                  <label>Departman </label>
                  <Checkbox
                    onChange={(e) => {
                      setChecked(false);
                      setCheckedTwo(true);
                      setCheckedThree(false);
                      setAssignedEmp("");
                    }}
                    checked={checkedTwo}
                  ></Checkbox>
                </div>
                <div>
                  <label>Kişiler </label>{" "}
                  <Checkbox
                    onChange={(e) => {
                      setChecked(false);
                      setCheckedTwo(false);
                      setDep("");
                      setCheckedThree(true);
                    }}
                    checked={checkedThree}
                  ></Checkbox>
                </div>
              </div>

              {checkedTwo && (
                <Dropdown
                  filter
                  options={
                    Array.isArray(departments) && departments.length > 0
                      ? departments.map((dep) => ({
                          label: dep.department,
                          value: dep.id,
                        }))
                      : []
                  }
                  value={dep}
                  placeholder="Departman"
                  onChange={(e) => setDep(e.target.value)}
                  style={{
                    border: "1px solid gray",
                    marginTop: "1rem",
                    width: "100%",
                  }}
                />
              )}
              {checkedThree && (
                <MultiSelect
                  filter
                  value={assignedEmp}
                  options={
                    Array.isArray(users) && users.length > 0
                      ? users.map((employ) => ({
                          label: employ.name,
                          value: employ.id,
                        }))
                      : []
                  }
                  placeholder="Görevliler"
                  className="w-full "
                  style={{
                    border: "1px solid gray",
                    width: "100%",
                    marginTop: "1rem",
                  }}
                  onChange={(e) => setAssignedEmp(e.target.value)}
                />
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button
              label="Ekle"
              onClick={newCreate}
              className="btn-excel-two"
            />
          </div>
        </div>
      </Dialog>
    </Flex>
  );
}
