import { InputText } from "primereact/inputtext";
import React from "react";

export default function Search() {
  return (
    <div className="p-3 d-flex align-items-center h-100 w-100">
      <div
        className="search-container"
        style={{
          display: "flex",
          alignItems: "center",
          border: "solid 1px gray",
          borderRadius: "6px",
          padding: "0.2rem",
          width: "100%",
        }}
      >
        <i
          className="pi pi-search search-icon"
          style={{
            marginRight: "0.5rem",
            color: "gray",
          }}
        />
        <InputText
          className="search-input"
          style={{
            flex: "1",
            minWidth: "0", // Gerekirse ekleyin
          }}
          placeholder="Sorunuzu girin ve bilgi merkezinden yanıtları arayın..."
        />
      </div>
    </div>
  );
}
