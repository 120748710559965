import React from 'react'
import { Grid, GridItem } from '@chakra-ui/react'
import GuaranteePage from './GuaranteePage'
import NavBar from '../../../../../_ekdizayn/layout/Navbar/Navbar';
import Sidebar from '../../../../../_ekdizayn/layout/Sidebar/Sidebar';

export default function Guarantees() {
  return (
    <Grid
      templateAreas={`"header header"
                      "nav main"
                     `}
      gridTemplateRows={"auto 1fr "}
      gridTemplateColumns={"auto 1fr"}
      h="auto"
      color="blackAlpha.700"
      fontWeight="bold"
    >
      <GridItem area={"header"}>
        <NavBar />
      </GridItem>
      <GridItem area={"nav"}>
        <Sidebar />
      </GridItem>
      <GridItem mt="5" area={"main"}>
        <GuaranteePage />
      </GridItem>
    </Grid>
  );
}
