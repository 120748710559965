import React, { useEffect, useState } from "react";
import { Chart } from "primereact/chart";

export default function AgeChart({ageGraph}) {
  const [ageGraphData, setAgeGraphData] = useState({});
  const [ageGraphOptions, setAgeGraphOptions] = useState({});
  const DataTypes = [
    { name: "18-24 Yaş", value: "1" },
    { name: "25-34 Yaş", value: "2" },
    { name: "35-44 Yaş", value: "3" },
    { name: "45-54 Yaş", value: "4" },
    { name: "55+ Yaş", value: "5" },
    { name: "Diğerleri", value: "6" },
  ];


  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const data = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [
            documentStyle.getPropertyValue("--blue-500"),
            documentStyle.getPropertyValue("--yellow-500"),
            documentStyle.getPropertyValue("--green-500"),
          ],
          hoverBackgroundColor: [
            documentStyle.getPropertyValue("--blue-400"),
            documentStyle.getPropertyValue("--yellow-400"),
            documentStyle.getPropertyValue("--green-400"),
          ],
        },
      ],
    };

    // Gelen veriyi dönüştürme
    ageGraph?.forEach((item) => {
      const dataTypeName = DataTypes.find((dt) => dt.value === item.age)?.name;
      data.labels.push(dataTypeName ? dataTypeName : item.ageGraph);
      data.datasets[0].data.push(parseInt(item.value));
    });

    const options = {
      cutout: "60%",
    };

    setAgeGraphData(data);
    setAgeGraphOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ageGraph]);

  return (
    <div>
      <Chart
        style={{ width: "30rem", height: "20rem" }}
        type="doughnut"
        data={ageGraphData}
        options={ageGraphOptions}
      />
    </div>
  );
}
