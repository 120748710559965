import React, { useState } from "react";

import {
  AddExcelCustomerService,
  AddExcelEmployeeService,
  AddExcelProductService,
  AddExcelSmsUserService,
} from "../../../../core/Services";
import { useToast } from "@chakra-ui/react";
import { Card, Flex } from "@chakra-ui/react";

import { BreadCrumb } from "primereact/breadcrumb";
import Papa from "papaparse";
import { Button } from "primereact/button";
export default function DataTransferPage() {
  const toast = useToast();
  const [excelDatasCust, setexcelDatasCust] = useState([]);
  const ExcelUrlCustomer = "/example-excel-files/ek-customer-save.csv";
  const ExcelUrlEmployee = "/example-excel-files/ek-employee-save.csv";
  const ExcelUrlProduct = "/example-excel-files/ek-product-save.csv";
  const ExcelUrlSmsUser = "/example-excel-files/ek-sms-user-save.csv";
  const [excelDatasEmp, setexcelDatasEmp] = useState([]);
  const [excelDatasProd, setexcelDatasProd] = useState([]);
  const [excelDatasSms, setexcelDatasSms] = useState([]);

  // Users
  const handleFileEmployee = (e) => {
    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (result) {
        const mergedArray = result.data.map((d) => ({
          name: d.name,
          password: d.password,
          phone: d.phone,
          email: d.email,
        }));
        setexcelDatasEmp(mergedArray); // JSON.stringify kullanmıyoruz
      },
    });
  };
  const handleDownloadClickEmp = () => {
    // Örnek Excel dosyasını indirmek için bir bağlantı oluştur
    const link = document.createElement("a");
    link.href = ExcelUrlEmployee;
    link.setAttribute("download", "ornek_dosya.csv");
    document.body.appendChild(link);
    link.click();
    // Bağlantıyı kaldır
    document.body.removeChild(link);
  };

  const saveEmployees = async () => {
    await AddExcelEmployeeService(excelDatasEmp)
      .then((data) => {
        toast({
          title: "İşlem Başarılı",
          description: "Çalışanlar başarıyla eklendi",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      })
      .catch((e) => {
        toast({
          title: "İşlem Başarısız",
          description: e,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      });
  };
  // Customers
  const saveCustomer = async () => {
    await AddExcelCustomerService(excelDatasCust)
      .then((data) => {
        toast({
          title: "İşlem Başarılı",
          description: "Müşteriler başarıyla eklendi",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      })
      .catch((e) => {
        toast({
          title: "İşlem Başarısız",
          description: e,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      });
  };
  const handleFileCustomer = (e) => {
    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (result) {
        const mergedArray = result.data.map((d) => ({
          name: d.name,
          title: d.title,
          phone: d.phone,
          email: d.email,
          address: d.address,
          website: d.website,
          tax_administration: d.tax_administration,
          tax_number: d.tax_number,
          customer_type: d.customer_type,
        }));
        setexcelDatasCust(mergedArray); // JSON.stringify kullanmıyoruz
      },
    });
  };
  const handleDownloadClickCust = () => {
    // Örnek Excel dosyasını indirmek için bir bağlantı oluştur
    const link = document.createElement("a");
    link.href = ExcelUrlCustomer;
    link.setAttribute("download", "ornek_dosya.csv");
    document.body.appendChild(link);
    link.click();
    // Bağlantıyı kaldır
    document.body.removeChild(link);
  };
  //Products
  const saveProducts = async () => {
    await AddExcelProductService(excelDatasProd)
      .then((data) => {
        toast({
          title: "İşlem Başarılı",
          description: "Ürünler başarıyla eklendi",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      })
      .catch((e) => {
        toast({
          title: "İşlem Başarısız",
          description: e,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      });
  };
  const handleFileProduct = (e) => {
    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (result) {
        const mergedArray = result.data.map((d) => ({
          name: d.name,
          quantity: d.quantity,
          unit: d.unit,
          tax_percentage: d.tax_percentage,
          currency: d.currency,
          purchase_price: d.purchase_price,
          sale_price: d.sale_price,
          brand: d.brand,
        }));
        setexcelDatasProd(mergedArray); // JSON.stringify kullanmıyoruz
      },
    });
  };
  const handleDownloadClickProd = () => {
    const link = document.createElement("a");
    link.href = ExcelUrlProduct;
    link.setAttribute("download", "ornek_dosya.csv");
    document.body.appendChild(link);
    link.click();
    // Bağlantıyı kaldır
    document.body.removeChild(link);
  };
  //Sms Users
  const saveSmsUsers = async () => {
    await AddExcelSmsUserService(excelDatasSms)
      .then((data) => {
        toast({
          title: "İşlem Başarılı",
          description: "Sms Kullanıcıları başarıyla eklendi",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      })
      .catch((e) => {
        toast({
          title: "İşlem Başarısız",
          description: e,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      });
  };
  const handleFileSmsUser = (e) => {
    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (result) {
        const mergedArray = result.data.map((d) => ({
          phone: d.phone,
          name: d.name,
          surname: d.surname,
          desc: d.desc,
          title: d.title,
          group: d.group,
        }));
        setexcelDatasSms(mergedArray); // JSON.stringify kullanmıyoruz
      },
    });
  };
  const handleDownloadClickSmsUser = () => {
    const link = document.createElement("a");
    link.href = ExcelUrlSmsUser;
    link.setAttribute("download", "ornek_dosya.csv");
    document.body.appendChild(link);
    link.click();
    // Bağlantıyı kaldır
    document.body.removeChild(link);
  };

  const getTurkishLabel = (url) => {
    switch (url) {
      case "/settings/data-transfer":
        return "Veri Aktarımı";
      // Diğer durumlar için gerektiğinde eşleme yapabilirsiniz
      default:
        return "Sayfa"; // Varsayılan olarak "Sayfa" kullanılabilir veya istediğiniz bir şeyi kullanabilirsiniz
    }
  };
  const url = "/settings/data-transfer";
  const turkishLabel = getTurkishLabel(url); // "Ürünler"

  const itemsBread = [{ label: turkishLabel, url: url }];
  const home = { icon: "pi pi-home", url: "/dashboard" };
  return (
    <Flex mt="5" justifyContent="center" alignItems="center">
      <div
        className="card"
        style={{
          overflow: "auto",
          boxSizing: "border-box",
          width: "100%",
          height: "100%",
          position: "relative",
          padding: "1rem",
        }}
      >
        <BreadCrumb
          model={itemsBread}
          home={home}
          style={{ padding: "1rem" }}
        />

        {/* **********************    Customer  ******************************* */}
        <div style={{ display: "flex" }}>
          <div className="col-6">
            {" "}
            <Card padding={4}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "auto",
                  justifyContent: "space-around",
                }}
              >
                <div>
                  <h6>Müşteri Dosyası Ekle (.csv)</h6>
                  <input
                    type="file"
                    name="file"
                    accept=".csv"
                    onChange={handleFileCustomer}
                    style={{ display: "block", marginTop: "1rem" }}
                  />
                </div>
                <Button
                  onClick={saveCustomer}
                  style={{
                    backgroundColor: "#662549",
                    color: "white",
                    padding: "1rem",
                    height: "2rem",
                  }}
                >
                  Kaydet
                </Button>
              </div>
            </Card>
          </div>
          <div className="col-6 ">
            {" "}
            <Card padding={4} textAlign={"center"}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "auto",
                  justifyContent: "space-around",
                }}
              >
                <h5>Örnek Müşteri Dosyası İndir (.csv)</h5>

                <Button
                  onClick={handleDownloadClickCust}
                  style={{
                    backgroundColor: "#662549",
                    color: "white",
                    padding: "1rem",
                    height: "2rem",
                  }}
                >
                  İndir
                </Button>
              </div>
              <p style={{ color: "gray", marginTop: "0.43rem" }}>
                Başlıklar örnekteki gibi olmalıdır.
              </p>
            </Card>
          </div>
        </div>

        {/* **********************    Employee  ******************************* */}

        <div style={{ display: "flex" }}>
          <div className="col-6">
            {" "}
            <Card padding={4}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "auto",
                  justifyContent: "space-around",
                }}
              >
                <div>
                  <h6>Çalışan Dosyası Ekle (.csv)</h6>
                  <input
                    type="file"
                    name="file"
                    accept=".csv"
                    onChange={handleFileEmployee}
                    style={{ display: "block", marginTop: "1rem" }}
                  />
                </div>
                <Button
                  onClick={saveEmployees}
                  style={{
                    backgroundColor: "#662549",
                    color: "white",
                    padding: "1rem",
                    height: "2rem",
                  }}
                >
                  Kaydet
                </Button>
              </div>
            </Card>
          </div>
          <div className="col-6 ">
            {" "}
            <Card padding={4} textAlign={"center"}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "auto",
                  justifyContent: "space-around",
                }}
              >
                <h5>Örnek Çalışan Dosyası İndir (.csv)</h5>

                <Button
                  onClick={handleDownloadClickEmp}
                  style={{
                    backgroundColor: "#662549",
                    color: "white",
                    padding: "1rem",
                    height: "2rem",
                  }}
                >
                  İndir
                </Button>
              </div>
              <p style={{ color: "gray", marginTop: "0.43rem" }}>
                Başlıklar örnekteki gibi olmalıdır.
              </p>
            </Card>
          </div>
        </div>

        {/* **********************    Product  ******************************* */}

        <div style={{ display: "flex" }}>
          <div className="col-6">
            {" "}
            <Card padding={4}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "auto",
                  justifyContent: "space-around",
                }}
              >
                <div>
                  <h6>Ürün Dosyası Ekle (.csv)</h6>
                  <input
                    type="file"
                    name="file"
                    accept=".csv"
                    onChange={handleFileProduct}
                    style={{ display: "block", marginTop: "1rem" }}
                  />
                </div>
                <Button
                  onClick={saveProducts}
                  style={{
                    backgroundColor: "#662549",
                    color: "white",
                    padding: "1rem",
                    height: "2rem",
                  }}
                >
                  Kaydet
                </Button>
              </div>
            </Card>
          </div>
          <div className="col-6 ">
            {" "}
            <Card padding={4} textAlign={"center"}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "auto",
                  justifyContent: "space-around",
                }}
              >
                <h5>Örnek Ürün Dosyası İndir (.csv)</h5>

                <Button
                  onClick={handleDownloadClickProd}
                  style={{
                    backgroundColor: "#662549",
                    color: "white",
                    padding: "1rem",
                    height: "2rem",
                  }}
                >
                  İndir
                </Button>
              </div>
              <p style={{ color: "gray", marginTop: "0.43rem" }}>
                Başlıklar örnekteki gibi olmalıdır.
              </p>
            </Card>
          </div>
        </div>
        {/* **********************    Sms User  ******************************* */}

        <div style={{ display: "flex" }}>
          <div className="col-6">
            {" "}
            <Card padding={4}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "auto",
                  justifyContent: "space-around",
                }}
              >
                <div>
                  <h6>Sms Rehberi Ekle (.csv)</h6>
                  <input
                    type="file"
                    name="file"
                    accept=".csv"
                    onChange={handleFileSmsUser}
                    style={{ display: "block", marginTop: "1rem" }}
                  />
                </div>
                <Button
                  onClick={saveSmsUsers}
                  style={{
                    backgroundColor: "#662549",
                    color: "white",
                    padding: "1rem",
                    height: "2rem",
                  }}
                >
                  Kaydet
                </Button>
              </div>
            </Card>
          </div>
          <div className="col-6 ">
            {" "}
            <Card padding={4} textAlign={"center"}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "auto",
                  justifyContent: "space-around",
                }}
              >
                <h5>Örnek Sms Rehberi İndir (.csv)</h5>

                <Button
                  onClick={handleDownloadClickSmsUser}
                  style={{
                    backgroundColor: "#662549",
                    color: "white",
                    padding: "1rem",
                    height: "2rem",
                  }}
                >
                  İndir
                </Button>
              </div>
              <p style={{ color: "gray", marginTop: "0.43rem" }}>
                Başlıklar örnekteki gibi olmalıdır.
              </p>
            </Card>
          </div>
        </div>
      </div>
    </Flex>
  );
}
