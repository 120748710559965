import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Card, CardHeader, Flex, Heading } from "@chakra-ui/react";
import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { useToast } from "@chakra-ui/react";
import { Dialog } from "primereact/dialog";
import { MultiSelect } from "primereact/multiselect";
import { MailPersonService, SendMailService } from "../../../../core/Services";
import { FileUpload } from "primereact/fileupload";
import { useAuth } from "../../../../auth/core/AuthContext";
import { Dropdown } from "primereact/dropdown";
export default function Mail() {
  const [formattedFileContent, setFormattedFileContent] = useState(null);
  const [checked, setChecked] = useState(true);
  const [checkedTwo, setCheckedTwo] = useState(false);
  const [checkedThree, setCheckedThree] = useState(false);
  const [modal, setModal] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [guide, setGuide] = useState([]);
  const [content, setContent] = useState("");
  const [subject, setSubject] = useState("");
  const [temp, setTemp] = useState("");
  const toast = useToast();
  const { userData } = useAuth();
  const onUpload = (event) => {
    const uploadedFile = event.files && event.files[0];

    if (uploadedFile) {
      const reader = new FileReader();

      reader.onload = async (e) => {
        const fileContent = e.target.result;

        // Convert file content to Base64
        const formattedContent = btoa(fileContent);

        // Update the state variable with the Base64 content
        await setFormattedFileContent(formattedContent);
      };

      reader.readAsBinaryString(uploadedFile);
    }
  };
  const [toWho, setToWho] = useState([]);
  const [mailList, setMailList] = useState([]);
  const addMail = () => {
    if (toWho !== "") {
      setMailList((prevList) => [...prevList, toWho]);
      setToWho([]);
    }
  };
  const sendMail = () => {
    const combinedList = [...toWho, ...mailList];

    const byWho = userData?.id;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let isValid = true;
    toWho?.forEach((email) => {
      if (!emailRegex.test(email) || subject === "" || content === "") {
        isValid = false;
        return false;
      }
    });
    if (!isValid) {
      toast({
        title: "Başarısız!",
        description:
          "Lütfen geçerli bir mail adresi, konu ve içerik girdiğinize emin olun.",
        status: "error",
        duration: 3000,
        position: "top-right",
        isClosable: true,
      });
      setModal(false);
    } else {
      try {
        SendMailService(
          combinedList,
          byWho,
          subject,
          content,
          formattedFileContent
        ).then((data) => {
          if (data?.status === 200) {
            toast({
              title: "Başarılı!",
              description: "Mailiniz başarıyla gönderildi.",
              status: "success",
              duration: 3000,
              position: "top-right",
              isClosable: true,
            });
            setContent("");
            setSubject("");
            setFormattedFileContent("");
            setToWho([]);
            setMailList([]);
            setModal(false);
          } else {
            toast({
              title: "Başarısız!",
              description: "Mailiniz gönderilirken bir hata oluştu.",
              status: "error",
              duration: 3000,
              position: "top-right",
              isClosable: true,
            });
            setModal(false);
          }
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    MailPersonService()
      .then((data) => {
        setCustomers(data?.customers);
        setGuide(data?.mail_persons);
        setTemplates(data?.templates);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [modal]);

  return (
    <Card p={2} className="w-full">
      <CardHeader>
        <Flex justifyContent="space-between" alignItems="center">
          <Heading size="md">Email Gönder</Heading>
        </Flex>
      </CardHeader>
      <div
        style={{
          display: "flex",
        }}
      >
        <div className="col-4">
          <div style={{ display: "flex", alignItems: "center" }}>
            <label>Müşterilere</label> <div style={{ width: "1rem" }} />
            <Checkbox
              onChange={() => {
                setCheckedThree(false);
                setCheckedTwo(false);
                setChecked(true);
              }}
              checked={checked}
            ></Checkbox>
            <div style={{ width: "4rem" }} />
            <label>Rehber</label>
            <div style={{ width: "1rem" }} />
            <Checkbox
              onChange={() => {
                setChecked(false);
                setCheckedThree(false);
                setCheckedTwo(true);
              }}
              checked={checkedTwo}
            ></Checkbox>
            <div style={{ width: "4rem" }} />
            <label>Kişi</label>
            <div style={{ width: "1rem" }} />
            <Checkbox
              onChange={() => {
                setChecked(false);
                setCheckedTwo(false);
                setCheckedThree(true);
              }}
              checked={checkedThree}
            ></Checkbox>
          </div>
          {checked && (
            <MultiSelect
              filter
              value={toWho}
              options={
                Array.isArray(customers) && customers.length > 0
                  ? customers?.map((cust) => ({
                      label: cust.name,
                      value: cust.email,
                    }))
                  : []
              }
              placeholder="Seçiniz"
              className="w-full "
              style={{
                border: "1px solid gray",
                width: "100%",
                marginTop: "1rem",
              }}
              onChange={(e) => setToWho(e.target.value)}
            />
          )}
          {checkedTwo && (
            <MultiSelect
              filter
              value={toWho}
              options={
                Array.isArray(guide) && guide.length > 0
                  ? guide?.map((cust) => ({
                      label: cust.name,
                      value: cust.mail,
                    }))
                  : []
              }
              placeholder="Seçiniz"
              className="w-full "
              style={{
                border: "1px solid gray",
                marginTop: "1rem",
              }}
              onChange={(e) => setToWho(e.target.value)}
            />
          )}
          {checkedThree && (
            <div style={{ display: "flex", marginTop: "1rem" }}>
              {" "}
              <InputText
                type="text"
                className="p-inputtext-lg w-full"
                placeholder="Mail Adresi Giriniz"
                onChange={(e) => setToWho(e.target.value)}
                style={{
                  border: "1px solid gray",
                }}
                value={toWho}
              />
              <div style={{ width: "1rem" }} />
              <Button
                className="btn-excel-two"
                onClick={addMail}
                icon="pi pi-plus"
              />
            </div>
          )}
          <div className="p-4">
            {" "}
            <ul>
              {mailList.map((mail, index) => (
                <li key={index} style={{ color: "dimgray" }}>
                  {mail}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="col-8">
          <Dropdown
            filter
            options={
              templates && templates?.length > 0
                ? templates?.map((dep) => ({
                    label: dep.name,
                    value: dep.id,
                  }))
                : []
            }
            placeholder="Şablon"
            value={temp}
            onChange={(e) => {
              const desc = templates?.find(
                (template) => template.id === e.value
              )?.description;
              setContent(desc);
              setTemp(e.value);
            }}
            style={{
              border: "1px solid gray",
              width: "100%",
              height: "2.5rem",
            }}
          />
          <InputText
            type="text"
            className="p-inputtext-lg w-full mt-1"
            placeholder="Konu"
            value={subject}
            onChange={(e) => {
              setSubject(e.target.value);
            }}
          />
          <InputTextarea
            type="text"
            value={content}
            rows={5}
            onChange={(e) => {
              setContent(e.target.value);
            }}
            cols={30}
            className="p-inputtext-lg w-full mt-1"
            placeholder="İçerik"
          />
          <FileUpload
            chooseLabel="Dosya Seç"
            style={{ textAlign: "left" }}
            accept=".pdf"
            maxFileSize={3000000}
            auto
            pt={{
              content: { className: "surface-ground" },
              message: {
                root: {
                  className: "w-1rem",
                },
              },
            }}
            uploadHandler={onUpload}
            customUpload
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          marginTop: "1rem",
        }}
      >
        <Button
          style={{ marginTop: "2rem" }}
          label="Email Gönder"
          onClick={() => setModal(true)}
          className="btn-excel-two"
        />
      </div>
      <Dialog
        visible={modal}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        style={{ width: "60vw" }}
        header="Mail'i göndermek istediğinize emin misiniz?"
        onHide={() => setModal(false)}
        modal
        footer={
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "1rem",
            }}
          >
            <Button
              label="İptal"
              onClick={() => setModal(false)}
              className="btn-excel-two"
              style={{ marginRight: "1rem" }}
            />
            <Button
              label="Gönder"
              onClick={sendMail}
              className="btn-excel-two"
            />
          </div>
        }
      ></Dialog>
    </Card>
  );
}
