import React, { useEffect, useState } from "react";
import { Card, CardHeader, Flex, Heading } from "@chakra-ui/react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MailService } from "../../../../core/Services";

export default function Reports() {
  const [mails, setMails] = useState([]);
  const [users, setUsers] = useState([]);
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    return new Date(dateString).toLocaleString("tr-TR", options);
  };

  useEffect(() => {
    MailService()
      .then((data) => {
        setMails(data?.mails);
        setUsers(data?.users);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  }, []);

  return (
    <Card p={2} className="w-full">
      <CardHeader>
        <Flex justifyContent="space-between" alignItems="center">
          <Heading size="md">Email Raporu</Heading>
        </Flex>
      </CardHeader>
      <DataTable
        stripedRows
        size="small"
        value={mails}
        dataKey="id"
        tableStyle={{ minWidth: "50rem" }}
        rows={15}
        rowsPerPageOptions={[15, 30, 60, 100]}
        scrollable
        paginator
        selectionMode="single"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate=" {totalRecords} mailden {first} - {last} arası gösteriliyor"
      >
        <Column
          field="by_who"
          body={(rowData) => {
            const employee = users?.find(
              (emp) => emp.id === Number(rowData.by_who)
            );
            return employee ? employee.name : "Bilinmeyen";
          }}
          header="Gönderen Kişi"
        ></Column>
        <Column field="to_who" header="Gönderilen Kişi"></Column>
        <Column field="subject" header="Konu" style={{ minWidth: "8rem" }} />
        <Column field="content" header="İçerik" style={{ minWidth: "8rem" }} />

        <Column
          field="created_at"
          body={(rowData) => formatDate(rowData.created_at)}
          header="Tarih"
        ></Column>
      </DataTable>
    </Card>
  );
}
