import { Grid, GridItem } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import AboutCustomer from "./components/aboutCustomer/AboutCustomer";
import Search from "./components/search/Search";
import DashboardCard from "./components/box/DashboardCard";
import Main from "./components/main/Main";
import Service from "./components/service/Service";
import Promotion from "./components/promotion/Promotion";
import { CustomerPanelByUniqueId, GetTracksAndLicenceByUniqueId } from "../../../../../core/Services";
import { useAuth } from "../../../../../auth/core/AuthContext";

export default function CustomerPages() {

    const [customer, setCustomer] = useState("");
    const [service, setService] = useState("");
    const [hosting, setHosting] = useState("");
    const [domains, setDomains] = useState("");
    const [license, setLicense] = useState("");
    const { userData } = useAuth();

    useEffect(() => {
      if (userData?.unique_id) {
        CustomerPanelByUniqueId(userData?.unique_id)
          .then((data) => {
            setCustomer(data);
          })
          .catch((error) => {
            console.log("Customer Hatası", error);
          });
      }
          if (userData?.unique_id) {
            GetTracksAndLicenceByUniqueId(userData?.unique_id)
              .then((data) => {
                setService(data.services);
                setDomains(data.domains);
                setLicense(data.license);
                setHosting(data.hosting);
              })
              .catch((error) => {
                console.log("Customer Hatası", error);
              });
          }
    }, [userData?.unique_id]);

  return (
    <Grid
      h="100vh"
      templateRows="repeat(21, 1fr)"
      templateColumns="repeat(10, 1fr)"
      gap={2}
      mt={5}
      p="0.2rem"
    >
      <GridItem rowSpan={4} colSpan={8}>
        <DashboardCard />
      </GridItem>
      <GridItem  rowSpan={21} colSpan={2}>
        <div >
          <AboutCustomer customer={customer}/>
        </div>
       <div>
        <Service/>
       </div>
      <div>
      <Promotion/>
      </div>
      </GridItem>
      <GridItem rowSpan={1} colSpan={8}>
        <Search/>
      </GridItem>
      <GridItem rowSpan={16} colSpan={8}>
      <Main license={license} domains={domains} hosting={hosting} service={service} />
      </GridItem>
    </Grid>
  );
}
