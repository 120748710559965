import React from "react";
import AdsMain from "./components/AdsMain";

export default function AdsReport({
  uniqueCustomerId,
  selectedMonth,
  selectedCustomer,
  months,
}) {
  return (
    <div>
      <AdsMain
        selectedMonth={selectedMonth}
        selectedCustomer={selectedCustomer}
        uniqueCustomerId={uniqueCustomerId}
        months={months}
      />
    </div>
  );
}
