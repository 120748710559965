import React, { useEffect, useState } from "react";
import { useToast } from "@chakra-ui/react";
import { Paginator } from "primereact/paginator";
import { GetRivalAds } from "../../../../../../../core/Services";
import { useAuth } from "../../../../../../../auth/core/AuthContext";

export default function Editing({ months, selectedMonth }) {
  const { userData } = useAuth();
  const uniqueCustomerId = userData?.unique_id;
  const [viewVisitor, setViewVisitor] = useState([]);

  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(5);
  const toast = useToast();

  useEffect(() => {
    GetRivalAds(uniqueCustomerId, selectedMonth)
      .then((data) => {
        setViewVisitor(data.data);
      })
      .catch((error) => {
        console.log("Ülke Veri Çekme Hatası", error);
      });
  }, [uniqueCustomerId, deleteSuccess, selectedMonth]);

  useEffect(() => {
    if (deleteSuccess) {
      toast({
        title: "Başarılı",
        description: "Veri başarıyla silindi.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      setDeleteSuccess(false);
    }
  }, [deleteSuccess, toast]);

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  return (
    <div className="w-full">
      <table
        width="100%"
        style={{
          fontFamily: "arial, sans-serif",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            ></th>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              Dönem
            </th>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              Url
            </th>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              Gösterim Payı (%)
            </th>
          </tr>
        </thead>
        <tbody>
          {viewVisitor &&
            viewVisitor?.slice(first, first + rows)?.map((visitor, index) => (
              <tr
                key={visitor.id}
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                <td
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  {index + 1 + first}
                </td>
                <td
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  {months?.find((month) => month.value === visitor.month)?.name}
                </td>
                <td
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  {" "}
                  {visitor.url}
                </td>
                <td
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  {visitor.show}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <Paginator
        first={first}
        rows={rows}
        totalRecords={viewVisitor?.length}
        onPageChange={onPageChange}
      />
    </div>
  );
}
