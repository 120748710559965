import React from 'react'
import CustomerSecurityTable from "./components/CustomerSecurityTable";

export default function Security({selectedMonth}) {
  return (
    <div>
      <CustomerSecurityTable selectedMonth={selectedMonth} />
    </div>
  );
}
