import React, { useEffect, useState } from "react";
import { Flex, Text, Icon, Menu, MenuButton } from "@chakra-ui/react";

import { FiChevronDown, FiChevronUp } from "react-icons/fi";

import { Link, useLocation } from "react-router-dom";

export default function NavItem({
  icon,
  title,
  active,
  navSize,
  to,
  subItems,
}) {
  const [showSubItems, setShowSubItems] = useState(false);

  const handleItemClick = () => {
    setShowSubItems(!showSubItems);
  };
  useEffect(() => {
    if (active === false) {
      setShowSubItems(false);
    } else {
      setShowSubItems(true);
    }
  }, [active]);
  const SubNavItem = ({ to, navSize, title, isCurrentPage }) => {
    const location = useLocation();
    isCurrentPage = location.pathname === to;
    return (
      <Flex
        mt={3}
        flexDir="column"
        w="100%"
        alignItems={navSize === "small" ? "center" : "flex-start"}
      >
        <Menu>
          <MenuButton
            as={Link}
            to={to}
            textDecoration="none"
            style={{
              background: isCurrentPage ? "#662549" : "",
              color: isCurrentPage ? "white" : "",
            }}
            color="#FFFFFF"
            _hover={{ textDecoration: "none", backgroundColor: "#AEC8CA" }}
            w={navSize === "large" && "100%"}
          >
            <Flex ml={2} borderRadius={8} alignItems="center">
              <Text
                ml={5}
                style={{ fontWeight: "bold" }}
                display={navSize === "small" ? "none" : "flex"}
                position="relative"
              >
                <span
                  style={{
                    position: "absolute",
                    left: "-10px",
                    top: "-50%",
                    fontSize: "1.5em",
                  }}
                >
                  .
                </span>
                {title}
              </Text>
            </Flex>
          </MenuButton>
        </Menu>
      </Flex>
    );
  };

  return (
    <Flex
      mt={30}
      flexDir="column"
      w="100%"
      alignItems={navSize === "small" ? "center" : "flex-start"}
    >
      <Menu>
        <MenuButton
          as={Link}
          to={to}
          textDecoration="none"
          color="#FFFFFF"
          _hover={{ textDecoration: "none", backgroundColor: "#AEC8CA" }}
          w={navSize === "large" && "100%"}
          onClick={handleItemClick}
        >
          <Flex
            p={2}
            borderRadius={8}
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex alignItems="center">
              <Icon
                as={icon}
                fontSize="xl"
                color={active ? "#82AAAD" : "#000000"}
              />
              <Text ml={5} display={navSize === "small" ? "none" : "flex"}>
                {title}
              </Text>
            </Flex>
            {subItems && (
              <Icon
                as={showSubItems && active ? FiChevronUp : FiChevronDown}
                fontSize="16px"
                color="#000000"
                ml={2}
              />
            )}
          </Flex>
        </MenuButton>

        {showSubItems && active && (
          <>
            {subItems?.map((subItem, index) => (
              <SubNavItem
                key={index}
                to={subItem.to}
                navSize={navSize}
                icon={subItem.icon}
                title={subItem.title}
              />
            ))}
          </>
        )}
      </Menu>
    </Flex>
  );
}
