import React, { useEffect, useState } from "react";
import { Chart } from "primereact/chart";
import { GetAds } from "../../../../../../../../core/Services";

export default function ChartJ({ uniqueCustomerId, months , appearanceKey }) {
  const [viewVisitorData, setViewVisitorData] = useState(null);

  useEffect(() => {
    GetAds(uniqueCustomerId)
      .then((response) => {
        const data = response.data;
        const chartData = {
          labels: months.map((month) => month.name), // Ay isimleri
          datasets: [
            {
              label: "Tıklamalar",
              data: months.map((month) => {
                const item = data.find((d) => d.month === month.value);
                return item ? item.clicks : null;
              }),
              borderColor: "blue",
              backgroundColor: "transparent",
              tension: 0.4,
            },
            {
              label: "Gösterim Sayısı",
              data: months.map((month) => {
                const item = data.find((d) => d.month === month.value);
                return item ? item.shows : null;
              }),
              borderColor: "orange",
              backgroundColor: "transparent",
              tension: 0.4,
            },
          ],
        };
        setViewVisitorData(chartData);
      })
      .catch((error) => {
        console.log("ViewVisitor Hatası", error);
      });
  }, [uniqueCustomerId, months, appearanceKey]);

  return (
    <div>
      <div className="mb-3 font-bold ml-5">Görünüm:</div>
      {viewVisitorData && (
        <Chart
          type="line"
          data={viewVisitorData}
          options={{
            maintainAspectRatio: false,
            aspectRatio: 1.5, // Gerekirse ayarlayın
            plugins: {
              legend: {
                position: "bottom",
              },
            },
            scales: {
              x: {
                title: {
                  display: true,
                  text: "Aylar",
                },
              },
              y: {
                title: {
                  display: true,
                  text: "Sayı",
                },
              },
            },
          }}
        />
      )}
    </div>
  );
}
