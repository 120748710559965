import { Card, CardHeader } from "@chakra-ui/card";
import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { BsFillFilePdfFill } from "react-icons/bs";
import { Dropdown } from "primereact/dropdown";
import { Column } from "primereact/column";
import { Flex, Heading, List, ListIcon, ListItem } from "@chakra-ui/layout";
import { MdCheckCircle, MdSettings } from "react-icons/md";
import { useAuth } from "../../../../auth/core/AuthContext";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import {
  AddFileService,
  AddFileTypeService,
  DeleteFileService,
  FileService,
} from "../../../../core/Services";

export default function MyFilesPage() {
  const [formattedFileContent, setFormattedFileContent] = useState(null);
  const [displayAddDialog, setDisplayAddDialog] = useState(false);
  const [name, setName] = useState("");
  const [dialogsDelete, setDialogsDelete] = useState("");
  const [type, setType] = useState("");
  const [fileTypes, setFileTypes] = useState([]);
  const [files, setFiles] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [user, setUser] = useState({});
  const elements = document.getElementsByClassName(
    "p-button p-component p-button-icon-only p-button-text p-button-rounded p-button-danger"
  );

  // Iterate through the elements and add a click event handler
  for (const element of elements) {
    element.addEventListener("click", handleClick);
  }
  function handleClick() {
    setFormattedFileContent(null);
  }
  const onUpload = (event) => {
    const uploadedFile = event.files && event.files[0];

    if (uploadedFile) {
      const reader = new FileReader();

      reader.onload = async (e) => {
        const fileContent = e.target.result;

        // Convert file content to Base64
        const formattedContent = btoa(fileContent);

        // Update the state variable with the Base64 content
        await setFormattedFileContent(formattedContent);
      };

      reader.readAsBinaryString(uploadedFile);
    }
  };
  const { userData } = useAuth();

  const userFiles = user[0]?.files?.split(",").map(Number);
  const hideDialog = () => {
    setDialogsDelete(false);
    setSelectedData(false);
  };
  const accessors = (rowData) => {
    const visible = dialogsDelete[rowData.id] || false;

    return (
      <div style={{ display: "flex" }}>
        <div style={{ color: "#ae445a" }}>
          <Button
            icon="pi pi-trash"
            onClick={() => {
              setSelectedData(rowData);
              setDialogsDelete((prevDialogs) => ({
                ...prevDialogs,
                [rowData.id]: true,
              }));
            }}
            severity="danger"
          />
        </div>
        <Dialog
          header="Dosyayı silmek istediğinize emin misiniz?"
          visible={visible}
          style={{ width: "50vw" }}
          onHide={hideDialog}
          footer={
            <div>
              <Button
                label="İptal"
                icon="pi pi-times"
                onClick={hideDialog}
                className="btn-excel-two"
              />
              <Button
                label="Dosyayı Sil"
                icon="pi pi-check"
                onClick={() => {
                  DeleteFileService(
                    selectedData.owner,
                    selectedData.id,
                    selectedData.type
                  );
                  hideDialog();
                }}
                className="btn-excel-two"
                style={{ marginLeft: "15px" }}
              />
            </div>
          }
        ></Dialog>
      </div>
    );
  };
  const waiting = () => {
    return fileTypes?.map((data) => {
      // Check if the id value of the current data is included in the userFiles array
      if (userFiles?.includes(data.id)) {
        return (
          <ListItem key={data.name}>
            <ListIcon as={MdCheckCircle} color="green.500" />
            {data.name}
          </ListItem>
        );
      } else {
        return (
          <ListItem key={data.name}>
            <ListIcon as={MdSettings} color="red.500" />
            {data.name}
          </ListItem>
        );
      }
    });
  };

  const showAddDialog = () => {
    setName("");
    setDisplayAddDialog(true);
  };
  const hideAddDialog = () => {
    setDisplayAddDialog(false);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await FileService(userData?.id);
        setFileTypes(data?.fileTypes);
        setFiles(data?.files);
        setUser(data?.user);
      } catch (error) {
        console.error("Error fetching file data:", error);
      }
    };

    fetchData();
  }, [formattedFileContent, displayAddDialog, userData.id, dialogsDelete]);

  return (
    <div
      className="confirmation-content "
      style={{
        justifyContent: "center",
        display: "flex",
        height: "100vh",
        backgroundColor: "white",
        padding: "1rem",
      }}
    >
      <Card p={2} className="w-full">
        <CardHeader>
          <Flex justifyContent="space-between" alignItems="center">
            <Heading size="md">Yüklenmesi Gereken Evraklar</Heading>
          </Flex>
        </CardHeader>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {" "}
          <List spacing={3}>{waiting()}</List>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <label
            htmlFor="status"
            className="font-bold"
            style={{ marginTop: "3rem" }}
          >
            Evrak Tipi :{" "}
            <Dropdown
              filter
              options={
                Array.isArray(fileTypes) && fileTypes.length > 0
                  ? fileTypes.map((dep) => ({
                      label: dep.name,
                      value: dep.id,
                    }))
                  : []
              }
              onChange={(e) => setType(e.target.value)}
              optionLabel="label"
              value={type}
              placeholder="Evrak Tipi Seçiniz"
            />
          </label>{" "}
          {userData?.type === "1" ? (
            <Button
              style={{ marginTop: "3rem" }}
              label="Evrak Tipi Ekle"
              onClick={showAddDialog}
              className="btn-excel-two"
            />
          ) : null}
        </div>
        <div>
          <p
            style={{
              marginBottom: "0.2rem",
              marginTop: "2rem",
              textAlign: "left",
            }}
          >
            Evrak Ekle(Pdf):
          </p>
          <FileUpload
            chooseLabel="Dosya Seç"
            style={{ textAlign: "left" }}
            accept=".pdf"
            maxFileSize={3000000}
            auto
            pt={{
              content: { className: "surface-ground" },
              message: {
                root: {
                  className: "w-1rem",
                },
              },
            }}
            uploadHandler={onUpload}
            customUpload
          />{" "}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
              height: "3rem",
            }}
          >
            <Button
              label="Evrağı Kaydet"
              onClick={() => {
                AddFileService(userData.id, formattedFileContent, type);
                setType("");
                setFormattedFileContent("");
                handleClick();
              }}
              className="btn-excel"
            />
          </div>
        </div>
      </Card>
      <div style={{ width: "2rem" }} />
      <Card p={2} className="w-full">
        <CardHeader>
          <Flex justifyContent="space-between" alignItems="center">
            <Heading size="md">Dosyalarım</Heading>
          </Flex>
        </CardHeader>
        <DataTable
          stripedRows
          size="small"
          value={files}
          dataKey="id"
          rows={15}
          rowsPerPageOptions={[15, 30, 60, 100]}
          scrollHeight="65vh"
          scrollable
          paginator
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate=" {totalRecords} dosyadan {first} - {last} arası gösteriliyor"
        >
          <Column
            field="type"
            header="Belge Türü"
            body={(rowData) => {
              const fileType = fileTypes.find(
                (fileType) => fileType.id === Number(rowData.type)
              );
              return fileType ? fileType.name : "Bilinmeyen";
            }}
          ></Column>

          <Column
            field="document"
            body={(rowData) => (
              <a
                href={`data:application/pdf;base64,${rowData.file}`}
                download="document.pdf"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <BsFillFilePdfFill color="red" size={35} />
              </a>
            )}
            header="Belge"
          ></Column>
          <Column header="Sil" body={accessors}></Column>
        </DataTable>
      </Card>
      <Dialog
        visible={displayAddDialog}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        style={{ width: "30vw" }}
        header="Evrak Tipi Ekle"
        onHide={hideAddDialog}
        modal
        footer={
          <Button
            label="Ekle"
            onClick={() => {
              AddFileTypeService(name);
              hideAddDialog();
            }}
            className="btn-excel-two"
          />
        }
      >
        <div className="confirmation-content">
          <InputText
            type="text"
            className="p-inputtext-lg w-full"
            placeholder="Evrak Tipi Adı"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </div>
      </Dialog>
    </div>
  );
}
