import { Code, Input, InputGroup, InputLeftAddon } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Stack } from "react-bootstrap";
import { Button } from "primereact/button";
import { PaymentSettingsService } from "../../../../core/Services";
const PaymentSettings = ({ data }) => {
  const [merchantId, setMerchantId] = useState("");
  const [merchantKey, setMerchantKey] = useState("");
  const [merchantSalt, setMerchantSalt] = useState("");

  const savePaymentSettings = async () => {
    await PaymentSettingsService(merchantId, merchantKey, merchantSalt);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setMerchantId(data?.pay_merchant_id);
        setMerchantKey(data?.pay_merchant_key);
        setMerchantSalt(data?.pay_merchant_salt);
      } catch (error) {
        console.error("Error fetching template data:", error);
      }
    };

    fetchData();
  }, [data]);
  return (
    <div style={{ display: "flex" }}>
      <div className="col-4">
        <Stack spacing={4} sty>
          <InputGroup size="sm">
            <InputLeftAddon>Merchant Id </InputLeftAddon>
            <Input
              value={merchantId}
              onChange={(e) => setMerchantId(e.target.value)}
            />
          </InputGroup>
          <InputGroup size="sm" marginTop="1rem">
            <InputLeftAddon>Merchant Key</InputLeftAddon>
            <Input
              value={merchantKey}
              onChange={(e) => setMerchantKey(e.target.value)}
            />
          </InputGroup>
          <InputGroup size="sm" marginTop="1rem">
            <InputLeftAddon>Merchant Salt</InputLeftAddon>
            <Input
              value={merchantSalt}
              onChange={(e) => setMerchantSalt(e.target.value)}
            />
          </InputGroup>
          <Button
            onClick={savePaymentSettings}
            style={{
              backgroundColor: "#662549",
              color: "white",
              padding: "1rem",
              height: "2rem",
              marginTop: "1rem",
            }}
          >
            Kaydet
          </Button>
        </Stack>
      </div>
      <div className="col-8">
        <Stack direction="column">
          <Code
            colorScheme="blue"
            children="Sistemimizde ödeme seçeneği olarak PayTR entegrasyonu bulunmaktadır."
          />
          <Code
            colorScheme="blue"
            children="PayTR hesabınıza girerek sağda doldurmanız gereken bilgilere ulaşabilirsiniz."
          />
          <Code
            colorScheme="blue"
            children="Bilgilerin doğru olduğundan ve hesabınızdaki ödeme fonksiyonunun test durumunda olmadığından emin olmalısınız."
          />
        </Stack>
      </div>{" "}
    </div>
  );
};

export default PaymentSettings;
