import React, { useEffect, useState } from "react";
import TodoItems from "./TodoItems";
import Overview from "./Overview";
import { TabView, TabPanel } from "primereact/tabview";
import { useAuth } from "../../../../auth/core/AuthContext";
import { TodoListService } from "../../../../core/Services";
import UnCompleted from "./UnCompleted";

export default function TodoList() {
  const { userData } = useAuth();
  const [items, setItems] = useState([]);
  const [itemsOld, setItemsOld] = useState([]);
  const [statusTodo, setStatusTodo] = useState(1);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await TodoListService(userData?.id);
        setItems(data?.completed);
        setItemsOld(data?.un_completed);
      } catch (error) {
        console.error("Error fetching department data:", error);
      }
    };

    fetchData();
  }, [userData?.id, statusTodo, modal]);

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  const days = [
    { label: "Bugün", value: today.toISOString().split("T")[0] },
    { label: "Yarın", value: tomorrow.toISOString().split("T")[0] },
    ...Array.from({ length: 5 }, (_, i) => {
      const date = new Date(today);
      date.setDate(today.getDate() + i + 2);
      return {
        label: getDayName(date.getDay()),
        value: date.toISOString().split("T")[0],
      };
    }),
  ];

  function getDayName(day) {
    const dayNames = [
      "Pazar",
      "Pazartesi",
      "Salı",
      "Çarşamba",
      "Perşembe",
      "Cuma",
      "Cumartesi",
    ];
    return dayNames[day];
  }
  function formatDate(dateString) {
    const formattedDate = new Date(dateString).toLocaleDateString("tr-TR", {
      day: "2-digit",
      month: "long",
    });
    return formattedDate;
  }
  return (
    <div className="container" style={{ display: "flex" }}>
      <TabView className="w-full">
        <TabPanel header="Genel Bakış">
          <Overview items={items} days={days} />
        </TabPanel>
        <TabPanel header="Eski Görevler">
          <UnCompleted items={itemsOld} />
        </TabPanel>
        {days.map((day, index) => (
          <TabPanel
            key={index}
            header={`${day?.label} (${formatDate(day?.value)})`}
            value={day?.value}
          >
            <TodoItems
              day={day.value}
              userData={userData}
              statusTodo={statusTodo}
              setStatusTodo={setStatusTodo}
              items={items?.filter(
                (item) => item?.day?.split(" ")[0] === day?.value
              )}
              modal={modal}
              setModal={setModal}
            />
          </TabPanel>
        ))}
      </TabView>
    </div>
  );
}
