import React, { useState } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import {
  ChangeDayTodoService,
  DeleteTodoService,
} from "../../../../core/Services";
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";
export default function UnCompleted({ items }) {
  const [modal, setModal] = useState(false);
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedData, setSelectedData] = useState({});
  const bgStatusColor = (item) => {
    switch (Number(item)) {
      case 1:
        return "#ADD8E6";
      case 2:
        return "#F08080";
      case 3:
        return "#FFD700";
      case 4:
        return "#90EE90";
      case 5:
        return "#D8BFD8";
      default:
        return "";
    }
  };
  addLocale("tr", {
    firstDayOfWeek: 1,
    showMonthAfterYear: true,
    dayNames: [
      "Pazar",
      "Pazartesi",
      "Salı",
      "Çarşamba",
      "Perşembe",
      "Cuma",
      "Cumartesi",
    ],
    dayNamesShort: ["Paz", "Pts", "Sal", "Çar", "Per", "Cum", "Cts"],
    dayNamesMin: ["P", "P", "S", "Ç", "P", "C", "C"],
    monthNames: [
      "Ocak",
      "Şubat",
      "Mart",
      "Nisan",
      "Mayıs",
      "Haziran",
      "Temmuz",
      "Ağustos",
      "Eylül",
      "Ekim",
      "Kasım",
      "Aralık",
    ],
    monthNamesShort: [
      "Oca",
      "Şub",
      "Mar",
      "Nis",
      "May",
      "Haz",
      "Tem",
      "Ağu",
      "Eyl",
      "Eki",
      "Kas",
      "Ara",
    ],
    today: "Bugün",
    clear: "Temizle",
  });

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    return new Date(dateString).toLocaleString("tr-TR", options);
  };
  const date = new Date(selectedDay);
  const formattedDate = `${date.getFullYear()}/${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${date.getDate().toString().padStart(2, "0")} ${date
    .getHours()
    .toString()
    .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}:${date
    .getSeconds()
    .toString()
    .padStart(2, "0")}`;
  const temp0Items = items?.filter((data) =>
    data?.some((item) => item?.temp === 0)
  );
  const temp1Items = items?.filter((data) =>
    data?.some((item) => item?.temp === 1)
  );
  return (
    <div className="w-full" style={{ display: "flex" }}>
      <div style={{ width: "50%" }}>
        <strong>Tamamlanmayan Görevler</strong>
        {temp0Items?.map((data, index) => (
          <div key={index} style={{ width: "100%", padding: "0.1rem" }}>
            {data?.map((item, index) => (
              <div className="w-full flex" key={index}>
                <Card
                  style={{
                    width: "100%",
                    paddingLeft: "0.5rem",
                    padding: "0.7rem",
                    marginBottom: "0.2rem",
                    background: bgStatusColor(item?.status),
                    fontSize: `calc(10px + 0.3vw)`,
                  }}
                >
                  ({formatDate(item?.day)}) {item?.title}
                </Card>
                <Button
                  icon="pi pi-pencil"
                  onClick={() => {
                    setModal(true);
                    setSelectedData(item);
                  }}
                  severity="danger"
                />
              </div>
            ))}
          </div>
        ))}
      </div>
      <div style={{ width: "50%" }}>
        <strong>Tamamlanan Görevler</strong>
        {temp1Items?.map((data, index) => (
          <div key={index} style={{ width: "100%", padding: "0.1rem" }}>
            {data?.map((item, index) => (
              <div className="w-full flex" key={index}>
                <Card
                  style={{
                    width: "100%",
                    paddingLeft: "0.5rem",
                    padding: "0.7rem",
                    marginBottom: "0.2rem",
                    background: bgStatusColor(item?.status),
                    fontSize: `calc(10px + 0.3vw)`,
                  }}
                >
                  ({formatDate(item?.day)}) {item?.title}
                </Card>
                <Button
                  icon="pi pi-pencil"
                  onClick={() => {
                    setModal(true);
                    setSelectedData(item);
                  }}
                  severity="danger"
                />
              </div>
            ))}
          </div>
        ))}
      </div>
      <Dialog
        visible={modal}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        style={{ width: "45vw" }}
        header="Düzenleme"
        onHide={() => setModal(false)}
        modal
        footer={
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "1rem",
            }}
          >
            <Button
              label="İptal"
              onClick={() => setModal(false)}
              className="btn-excel-two"
              style={{ marginRight: "1rem" }}
            />
            <Button
              label="Sil"
              onClick={() => {
                DeleteTodoService(selectedData?.id, 1);
                setModal(false);
              }}
              className="btn-excel-two"
              style={{ marginRight: "1rem" }}
            />
            <Button
              label="Kaydet"
              onClick={() => {
                ChangeDayTodoService(selectedData?.id, formattedDate);
                setModal(false);
              }}
              className="btn-excel-two"
            />
          </div>
        }
      >
        <Calendar
          placeholder="Tarih"
          locale="tr"
          style={{
            border: "1px solid gray",
            padding: "0.5rem",
            width: "65%",
            marginBottom: "1rem",
            borderRadius: "6px 6px 6px 6px",
          }}
          value={selectedDay}
          onChange={(e) => setSelectedDay(e.value)}
          dateFormat="dd/mm/yy"
          showTime={false}
        />
      </Dialog>
    </div>
  );
}
