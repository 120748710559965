import { createSlice } from "@reduxjs/toolkit";

export const DepartmantSlice = createSlice({
  name: "DepartmantSlice",
  initialState: {
    value: null,
  },
  reducers: {
    addDepartmentData: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { addDepartmentData } = DepartmantSlice.actions;
export default DepartmantSlice.reducer;
