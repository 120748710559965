import React, { useEffect, useState } from "react";
import {
  DeleteMail,
  GetDomain,
  GetMail,
  UpdateMail,

} from "../../../../../../../core/Services";
import { BsFillTrashFill, BsPencil } from "react-icons/bs";
import { Dialog } from "primereact/dialog";
import { Button, Input } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { Paginator } from "primereact/paginator";
import { IoEyeOffSharp, IoEye } from "react-icons/io5";

export default function MailAccountsTable({ uniqueCustomerId, tableKey }) {
  const [mail, setMail] = useState([]);
  const [domain, setDomain] = useState([]);
  const [selectedMail, setSelectedMail] = useState(null);
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [first, setFirst] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [rows, setRows] = useState(10);
  const [editingId, setEditingId] = useState(null);
  const toast = useToast();

  useEffect(() => {
    GetMail(uniqueCustomerId)
      .then((data) => {
        setMail(data.data);
      })
      .catch((error) => {
        console.log("Mail Veri Çekme Hatası", error);
      });
  }, [uniqueCustomerId, deleteSuccess, tableKey]);

  useEffect(() => {
    GetDomain(uniqueCustomerId)
      .then((data) => {
        setDomain(data.data);
      })
      .catch((error) => {
        console.log("Domain Çekme Hatası", error);
      });
  }, [uniqueCustomerId]);

  useEffect(() => {
    if (deleteSuccess) {
      toast({
        title: "Başarılı",
        description: "Veri başarıyla silindi.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      setDeleteSuccess(false);
    }
  }, [deleteSuccess, toast]);

  const handleDelete = (id) => {
    setSelectedMail(id);
    setDisplayConfirmation(true);
  };

  const onDeleteConfirmation = () => {
    DeleteMail(selectedMail);
    setDeleteSuccess(true);
    setSelectedMail(null);
    setDisplayConfirmation(false);
  };

  const onHide = () => {
    setDisplayConfirmation(false);
  };

  const handleEdit = (id) => {
    setEditingId(id);
  };

  const handleSave = (editedData) => {
    UpdateMail(editedData).then(() => {
      setEditingId(null);
      toast({
        title: "Başarılı",
        description: "Veri başarıyla güncellendi.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    });
  };

  const handleCancel = () => {
    setEditingId(null);
  };
  const handleInputChange = (e, id, field) => {
    const { value } = e.target;
    setMail((prevState) =>
      prevState.map((item) =>
        item.id === id ? { ...item, [field]: value } : item
      )
    );
  };


 const renderRow = (rowData) => {
   if (editingId === rowData.id) {
     return (
       <tr key={rowData.id}>
         <td>
           <Input
             value={rowData.name}
             onChange={(e) => handleInputChange(e, rowData.id, "name")}
           />
         </td>
         <td>
           <Input
             value={rowData.mail}
             onChange={(e) => handleInputChange(e, rowData.id, "mail")}
           />
         </td>
         <td>
           <Input
             type={showPassword ? "text" : "password"}
             value={rowData.password}
             onChange={(e) => handleInputChange(e, rowData.id, "password")}
           />
           <button onClick={() => setShowPassword(!showPassword)}>
            
           </button>
         </td>
         <td>
           <Input
             value={rowData.size}
             onChange={(e) => handleInputChange(e, rowData.id, "size")}
           />
         </td>
         <td>
           <div style={{ display: "flex", gap: "0.5rem" }}>
             <Button
               style={{ width: "4rem", fontSize: "14px" }}
               colorScheme="green"
               onClick={() => handleSave(rowData)}
             >
               Kaydet
             </Button>
             <Button colorScheme="red" onClick={handleCancel}>
               İptal
             </Button>
           </div>
         </td>
       </tr>
     );
   } else {
     return (
       <tr key={rowData.id}>
         <td
           style={{
             border: "1px solid #dddddd",
             textAlign: "left",
             padding: "8px",
           }}
         >
           {rowData.name}
         </td>
         <td
           style={{
             border: "1px solid #dddddd",
             textAlign: "left",
             padding: "8px",
           }}
         >
           {rowData.mail}
         </td>
         <td
           style={{
             border: "1px solid #dddddd",
             textAlign: "left",
             padding: "8px",
           }}
         >
           {showPassword ? rowData.password : "********"}
         </td>
         <td
           style={{
             border: "1px solid #dddddd",
             textAlign: "left",
             padding: "8px",
           }}
         >
           {rowData.size}
         </td>
         <td
           style={{
             border: "1px solid #dddddd",
             textAlign: "left",
             padding: "8px",
           }}
         >
           <button className="ml-2" onClick={() => handleEdit(rowData.id)}>
             <BsPencil />
           </button>
           <button className="ml-2" onClick={() => handleDelete(rowData.id)}>
             <BsFillTrashFill />
           </button>
         </td>
       </tr>
     );
   }
 };


  const goToLink = () => {
    if (domain?.length > 0) {
      const processedLink = domain;
      window.open(`https://webmail.${processedLink}`, "_blank");
    } else {
      console.log("Domain bilgisi bulunamadı.");
    }
  };

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  return (
    <div>
      <div className="flex justify-content-between">
        <div className="mb-3 font-bold text-xl">Düzenleme:</div>
        <Button bgColor="#F39F5A" color="white" width="5rem" onClick={goToLink}>
          Link
        </Button>
      </div>
      <table
        className="w-full"
        style={{
          fontFamily: "arial, sans-serif",
          borderCollapse: "collapse",
          maxHeight: "80%",
        }}
      >
        <thead>
          <tr>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              Ad-Soyad
            </th>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              e-posta
            </th>
            <th
              style={{
                border: "1px solid #dddddd",
                padding: "10px",
                overflow: "hidden",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Parola
              <button onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <IoEyeOffSharp size="20px" /> : <IoEye />}
              </button>
            </th>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              Boyut
            </th>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              İşlemler
            </th>
          </tr>
        </thead>
        <tbody>
          {mail
            ?.slice(first, first + rows)
            ?.map((rowData) => renderRow(rowData))}
        </tbody>
      </table>
      <Paginator
        first={first}
        rows={rows}
        totalRecords={mail?.length}
        onPageChange={onPageChange}
      />
      <Dialog
        visible={displayConfirmation}
        style={{ width: "450px" }}
        onHide={onHide}
        position="center"
        header="İçerik Düzenleme"
      >
        <p>Veriyi silmek istediğinize emin misiniz?</p>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "1rem",
            marginTop: "1rem",
          }}
        >
          <Button colorScheme="red" onClick={onHide}>
            Hayır
          </Button>
          <Button colorScheme="green" onClick={onDeleteConfirmation}>
            Evet
          </Button>
        </div>
      </Dialog>
    </div>
  );
}
