import {  Box,  Flex,  } from '@chakra-ui/react';
export function Header(props) {

  
  return (
    <Flex
      bg='#12372A'
      justify='space-between'
      py='2'
      px='4'
      
      color='#54656f'
      {...props}
    >
      <Box display="flex" alignItems="center">
     
        {/* Kullanıcı Adı */}
        <Box
          color="white"
          fontSize="18px"
          ml="2"
          textAlign="center"
          fontWeight="bold"
        >
        {"Sohbetler"}
        </Box>
      </Box>

    </Flex>
  );
}

