import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Card, CardHeader, Flex, Heading } from "@chakra-ui/react";
import { useAuth } from "../../../../../../auth/core/AuthContext";
import { OrderPaymentService } from "../../../../../../core/Services";
import { Toast } from "primereact/toast";

export default function DepartmentPage() {
  const [orderPayments, setOrderPayments] = useState([]);
  const [customer, setCustomer] = useState({});
  const [selectedOrders, setSelectedOrders] = useState([]);
  const toast = useRef(null);
  const userData = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const orderPaymentData = await OrderPaymentService(
          userData?.userData?.unique_id
        );

        setOrderPayments(orderPaymentData?.offers);
        setCustomer(orderPaymentData?.customer);
      } catch (error) {
        console.error("Error fetching department data:", error);
      }
    };

    fetchData();
  }, [userData]);

  const getTotalPriceOfSelectedOrders = () => {
    let totalPrice = 0;
    selectedOrders.forEach((order) => {
      let tax =
        (parseFloat(order?.price) *
          parseFloat(order?.amount) *
          parseFloat(order?.tax_percent)) /
        100;
      totalPrice += parseFloat(order?.price) * parseFloat(order?.amount) + tax;
    });
    return totalPrice;
  };
  const paySelectedOrders = async () => {
    if (selectedOrders?.length === 0) {
      toast.current.show({
        severity: "error",
        detail: "Lütfen ürün ya da hizmet seçiniz.",
      });
    } else {
      const totalPrice = getTotalPriceOfSelectedOrders();
      const ordersJSON = JSON.stringify(selectedOrders);
      const customerJSON = encodeURIComponent(JSON.stringify(customer));
      const baseUrl = "https://cloud.ek.com.tr/payment?";
      const url = `${baseUrl}orders=${encodeURIComponent(
        ordersJSON
      )}&customer=${customerJSON}&totalPrice=${totalPrice}`;
      window.open(url, "_blank");
    }
  };

  return (
    <div
      className="card"
      style={{
        width: "100%",
        height: "100%",
        padding: "1rem",
      }}
    >
      <Card>
        <CardHeader>
          <Flex justifyContent="space-between" alignItems="center">
            <Heading size="md">Ödemelerim</Heading>
            <Button
              label="Ödemeye Geç"
              onClick={paySelectedOrders}
              className="btn-excel-two"
            />
          </Flex>
        </CardHeader>
        <Toast ref={toast} />
        <DataTable
          selectionMode={"checkbox"}
          selection={selectedOrders}
          onSelectionChange={(e) => setSelectedOrders(e.value)}
          stripedRows
          size="small"
          value={orderPayments}
          dataKey="id"
          tableStyle={{ minWidth: "50rem" }}
          rows={15}
          rowsPerPageOptions={[15, 30, 60, 100]}
          scrollHeight="65vh"
          scrollable
          paginator
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="{totalRecords} ödemeden {first} - {last} arası gösteriliyor"
        >
          <Column field="offer_no" header="Sipariş Numarası" />
          <Column field="product_name" header="Ürün / Hizmet" />
          <Column field="description" header="Açıklama" />
          <Column field="amount" header="Miktar" />
          <Column field="price" header="Fiyat" />
          <Column field="tax_percent" header="Vergi Yüzdesi" />
          <Column
            field={(rowData) => {
              let total =
                parseFloat(rowData?.price) * parseFloat(rowData?.amount);
              let tax =
                (parseFloat(rowData?.price) *
                  parseFloat(rowData?.amount) *
                  parseFloat(rowData?.tax_percent)) /
                100;
              return total + tax;
            }}
            header="Toplam"
          />
          <Column field="currency" header="Para Birimi" />
          <Column
            selectionMode="multiple"
            headerStyle={{ width: "3rem" }}
          ></Column>
        </DataTable>
      </Card>
    </div>
  );
}
