import React from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";

export default function Service() {
  return (
    <div className="p-3 d-flex flex-wrap justify-content-center align-items-center h-100">
      <Card
        style={{ width: "100%", opacity: "0.2", maxWidth: "30rem" }}
        title="Hizmet Paketlerim"
      >
        <p
          className="m-0"
          style={{ fontSize: "15px", fontWeight: "bold", marginLeft: "10px" }}
        >
          Sunucu (Paket Adı) <br />
          Yönetilen Hizmetler <br />
          ADA, Kü, 1000 <br />
          Turkey
        </p>
        <div style={{ textAlign: "center" }}>
          <Button
            style={{
              marginTop: "0.4rem",
              height: "1.5rem",
              width: "80%",
              fontSize: "15px",
              display: "inline-block",
              verticalAlign: "middle",
              lineHeight: "0rem",
            }}
            className="btn-excel-two"
          >
            Güncelle
          </Button>
        </div>
      </Card>
    </div>
  );
}
