import React, { useState } from "react";
import { Splitter, SplitterPanel } from "primereact/splitter";
import OtherAccounts from "./OtherAccounts";
import OtherAccountsTable from "./OtherAccountsTable";

export default function OtherAccountsApperance({ uniqueCustomerId, selectedCustomer }) {
   const [tableKey, setTableKey] = useState(0);

   const updateTableData = () => {
     setTableKey((prevKey) => prevKey + 1);
   };
  return (
    <div>
      <Splitter>
        <SplitterPanel size={25}>
          <OtherAccounts
            updateTableData={updateTableData}
            selectedCustomer={selectedCustomer}
            uniqueCustomerId={uniqueCustomerId}
          />
        </SplitterPanel>
        <SplitterPanel size={75}>
          <div className="col-12">
            <OtherAccountsTable key={tableKey} uniqueCustomerId={uniqueCustomerId} />
          </div>
        </SplitterPanel>
      </Splitter>
    </div>
  );
}
