import React, { useRef } from "react";
import { Stepper } from "primereact/stepper";
import { StepperPanel } from "primereact/stepperpanel";
import Package from "./Package";
import Form from "./Form";
import { Button } from "primereact/button";

export default function Page({
  packages,
  setPackages,
  selectedPackages,
  setSelectedPackages,
  selectedUrgency,
  setSelectedUrgency,
  form,
  setForm,
  selectedCounrtyPhone,
  setSelectedCounrtyPhone,
  selectedCookies,
  setSelectedCookies,
  errors,
  setErrors,
  onSubmitForm,
}) {
  const stepperRef = useRef(null);
  return (
    <div>
      <strong className=" text-4xl flex justify-content-center  align-items-center text-center ">
        Tüm İhtiyaçlarınızı tek bir yerden çözmeye hazır olun.
      </strong>
      <div className="card flex justify-content-center">
        <Stepper ref={stepperRef}>
          <StepperPanel header="Paket Seçimi">
            <Package
              selectedPackages={selectedPackages}
              setSelectedPackages={setSelectedPackages}
              packages={packages}
              setPackages={setPackages}
            />

            <div className="flex pt-4 justify-content-between">
              <div>
                <strong className="text-red-500">
                  Not: Birden fazla seçim yapabilirsiniz...
                </strong>
              </div>
              <Button
                label="İleri"
                icon="pi pi-arrow-right"
                iconPos="right"
                onClick={() => stepperRef.current.nextCallback()}
              />
            </div>
          </StepperPanel>
          <StepperPanel header="İletişim Formu">
            <Form
              selectedUrgency={selectedUrgency}
              setSelectedUrgency={setSelectedUrgency}
              form={form}
              errors={errors}
              setErrors={setErrors}
              setForm={setForm}
              selectedCounrtyPhone={selectedCounrtyPhone}
              setSelectedCounrtyPhone={setSelectedCounrtyPhone}
              selectedCookies={selectedCookies}
              setSelectedCookies={setSelectedCookies}
            />

            <div className="flex justify-content-between mt-2">
              <Button
                label="Geri"
                severity="secondary"
                icon="pi pi-arrow-left"
                onClick={() => stepperRef.current.prevCallback()}
              />
              <Button
                label="Gönder"
                className="btn-excel-two"
                icon="pi pi-arrow-right"
                iconPos="right"
                onClick={onSubmitForm}
              />
            </div>
          </StepperPanel>
          {/* <StepperPanel header="Son">
            <div className="flex w-auto  h-auto">
              <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">
                <Finished
                  selectedUrgency={selectedUrgency}
                  setSelectedUrgency={setSelectedUrgency}
                  form={form}
                  errors={errors}
                  setErrors={setErrors}
                  setForm={setForm}
                  selectedPackages={selectedPackages}
                  selectedCounrtyPhone={selectedCounrtyPhone}
                  setSelectedCounrtyPhone={setSelectedCounrtyPhone}
                />
              </div>
            </div>
            <div className="flex pt-4 justify-content-start">
              <Button
                label="Geri"
                severity="secondary"
                icon="pi pi-arrow-left"
                onClick={() => stepperRef.current.prevCallback()}
              />
            </div>
          </StepperPanel> */}
        </Stepper>
      </div>
    </div>
  );
}
