import React from "react";

import { Grid, GridItem } from "@chakra-ui/react";
import CustomerPages from "./customer-dashboard/CustomerPages";
import CustomerNavbar from "./components/Navbar/CustomerNavbar";
import CustomerSidebar from "./components/Sidebar/CustomerSidebar";

export default function CustomerPanelView() {
  return (
    <Grid
      templateAreas={`"header header"
                  "nav main"
                 `}
      gridTemplateRows={"auto 1fr "}
      gridTemplateColumns={"auto 1fr"}
      h="auto"
      color="blackAlpha.700"
      fontWeight="bold"
    >
      <GridItem area={"header"}>
        <CustomerNavbar />
      </GridItem>

      <GridItem area={"nav"}>
        <CustomerSidebar />
      </GridItem>

      <GridItem area={"main"}>
        <CustomerPages />
      </GridItem>
    </Grid>
  );
}
