import React from 'react'
import CustomerPaymentInformation from './components/CustomerPaymentInformation'

export default function CustomerPaymentPage() {
  return (
    <div>
        <CustomerPaymentInformation/>
    </div>
  )
}
