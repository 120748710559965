import { Flex, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card } from "primereact/card";
import { useSelector } from "react-redux";
import { Image } from "primereact/image";
import icon1 from "../images/icon1.png";
import icon2 from "../images/icon2.png";
import icon3 from "../images/icon3.png";
import { useAuth } from "../../../auth/core/AuthContext";
export default function DashboardBox() {
  const [jobs, setJobs] = useState(null);
  const [tasks, setTasks] = useState(null);
  const [supports, setSupports] = useState(null);
  const dashboardData = useSelector((state) => state.DashboardReduce.value);
  const { userData } = useAuth();
  const userId = userData.id;
  useEffect(() => {
    const fetchData = async () => {
      try {
        setTasks(
          dashboardData?.tasks?.filter((task) =>
            [1].includes(Number(task?.tasks_id))
          )?.length
        );
        setJobs(
          dashboardData?.jobs?.filter((jobs) =>
            [1].includes(Number(jobs?.jobs_id))
          )?.length
        );
        setSupports(dashboardData?.supports);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [dashboardData, userId]);
  return (
    <Flex direction="row" display="flex" align="center" justify="space-around">
      <Link to="/support">
        <Card
          style={{
            height: "7rem",
            borderRadius: "20px",
            border: "2px solid purple",
          }}
          className="md:w-15rem"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0.5rem",
              justifyContent: "center",
            }}
          >
            {" "}
            <Image src={icon2} alt="Image" width="33" />
            <div style={{ marginLeft: "2rem" }}>
              <Text fontSize="xl" fontWeight="bold" textAlign="center">
                Destekler : {supports}
              </Text>
              <Text fontSize="sm" textAlign="center">
                Detay Görüntüle
              </Text>
            </div>
          </div>
        </Card>
      </Link>
      <Link to="/tasks">
        <Card
          style={{
            height: "7rem",
            borderRadius: "20px",
            border: "2px solid pink",
            padding: "0rem important",
          }}
          className="md:w-15rem "
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0.5rem",
              justifyContent: "center",
            }}
          >
            {" "}
            <Image src={icon1} alt="Image" width="33" />
            <div style={{ marginLeft: "2rem" }}>
              <Text fontSize="xl" fontWeight="bold" textAlign="center">
                Görevler : {tasks}
              </Text>
              <Text fontSize="sm" textAlign="center">
                Detay Görüntüle
              </Text>
            </div>
          </div>
        </Card>
      </Link>
      <Link to="/jobs">
        <Card
          style={{
            height: "7rem",
            borderRadius: "20px",
            border: "2px solid orange",
          }}
          className="md:w-15rem"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0.5rem",
              justifyContent: "center",
            }}
          >
            {" "}
            <Image src={icon3} alt="Image" width="33" />
            <div style={{ marginLeft: "2rem" }}>
              <Text fontSize="xl" fontWeight="bold" textAlign="center">
                İşler : {jobs}
              </Text>
              <Text fontSize="sm" textAlign="center">
                Detay Görüntüle
              </Text>
            </div>
          </div>
        </Card>
      </Link>
    </Flex>
  );
}
