import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  AddSupportService,
  CompletedSupportService,
  DeleteTaskService,
  EditTaskService,
  TransferSupportService,
} from "../../../../../../../core/Services";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Card, Flex,  } from "@chakra-ui/react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Link } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";
import { useSelector } from "react-redux";
import { useAuth } from "../../../../../../../auth/core/AuthContext";

export default function CustomerSupportRequest({customer}) {
  const [addModal, setAddModal] = useState(false);
  const [visibleDialogs, setVisibleDialogs] = useState(false);
  const [displayDeleteDialog, setDisplayDeleteDialog] = useState(false);
  const [dep, setDep] = useState("");
  const [cus, setCus] = useState("");
  const [desc, setDesc] = useState("");
  const [taskStatus, setTaskStatus] = useState("");
  const [tasks, setTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [dialogs, setDialogs] = useState({});
  const isButtonDisable = false;
  const taskData = useSelector((state) => state.TaskReduce.value);
  const { userData } = useAuth();
  const customerData = useSelector((state) => state.CustomerReduce.value);

  const hideDialog = () => {
    setAddModal(false);
  };

  const subjects = [
    { label: "Destek", value: "Destek" },
    { label: "Satış", value: "Satış" },
    { label: "Muhasebe", value: "Muhasebe" },
    { label: "Şikayet", value: "Şikayet" },
    { label: "Diğer", value: "Diğer" },
  ];

  const workingBodyTemplate = (rowData) => {
    const getStatusLabel = (working_condition) => {
      switch (working_condition) {
        case "0":
          return "Öncelikli";

        case "1":
          return "Normal";

        case "2":
          return "Kritik";

        default:
          return null;
      }
    };

    const statusLabel = getStatusLabel(rowData.working_condition);

    return <Tag value={statusLabel} severity={getSeverity(rowData)}></Tag>;
  };
  const getSeverity = (jobs) => {
    switch (jobs.working_condition) {
      case "0":
        return "warning";

      case "1":
        return "success";

      case "2":
        return "danger";

      default:
        return null;
    }
  };

  const handleCompleteTask = async () => {
    try {
      if (!selectedTask) {
        console.error("Tamamlanacak destek bulunamadı.");
        return;
      }

      await CompletedSupportService(selectedTask.id, userData.name);
      setDialogs(false);
    } catch (error) {
      console.error("Hata:", error.message);
    }
  };
  const handleTransferTask = async () => {
    try {
      if (!selectedTask) {
        console.error("Tamamlanacak destek bulunamadı.");
        return;
      }

      await TransferSupportService(selectedTask.id);

      setDialogs(false); // Dialog'u kapat
    } catch (error) {
      console.error("Hata:", error.message);
    }
  };
  const getCustomerName = (rowData) => {
    const customer = customers?.find(
      (cust) => cust.id.toString() === rowData?.name
    );
    return customer ? customer.title : "Bilinmeyen";
  };

  const newCreate = async () => {
    await AddSupportService(cus, desc, dep, taskStatus);
    setAddModal(false);

    setCus("");
    setDesc("");
    setDep("");
    setTaskStatus("");
  };
  const toast = useRef(null);

  const statusType = [
    { label: "Normal", value: "1" },
    { label: "Öncelikli", value: "0" },
    { label: "Kritik", value: "2" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setTasks(taskData);

        setCustomers(customerData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [addModal, taskData, customerData]);


  const handleEditSupport = async () => {
    await EditTaskService(selectedId, cus, desc, dep, taskStatus);

    setDialogs(false);
    setSelectedId(null);
    setCus(null);
    setDesc(null);
    setDep(null);
    setTaskStatus(null);
  };

  const EditBodyTemplate = (tasks) => {
    const isVisible = dialogs[tasks.id] || false;
    useEffect(() => {
      if (isVisible) {
        setCus(
          customers?.find((dept) => dept.id.toString() === tasks.name)?.id || ""
        );
        setDep(tasks.department || "");
        setDesc(tasks.desc || "");
        setTaskStatus(tasks.working_condition || "");
      }
    }, [isVisible, tasks]);
    return (
      <React.Fragment>
        <div style={{ display: "flex" }}>
          <div style={{ color: "#f39f5a" }}>
            <Button
              severity="warning"
              onClick={() => {
                setSelectedTask(tasks); // Seçilen görevi state'e kaydet

                setDialogs((prevDialogs) => ({
                  ...prevDialogs,
                  [tasks.id]: true, // Görevin visible state'ini true yap
                }));
                setSelectedId(tasks.id);
             
              }}
              icon="pi pi-pencil"
            />
          </div>
          <div style={{ color: "#ae445a" }}>
            <Button
              icon="pi pi-trash"
              onClick={() => {
                setSelectedId(tasks.id);
                setDisplayDeleteDialog(true);
              }}
              severity="danger"
            />
          </div>
          {/* <div style={{ color: "#00d9ff" }}>
            <Button
              icon="pi pi-check"
              onClick={() => {
                setSelectedTask(tasks);
                setVisibleDialogs((prevDialogs) => ({
                  ...prevDialogs,
                  [tasks.id]: true, // Görevin visible state'ini true yap
                }));
              }}
              severity="info"
            />
          </div> */}
        </div>
        <Dialog
          header="İçerik Düzenleme"
          visible={isVisible}
          style={{ width: "50vw" }}
          onHide={() => {
            setDialogs((prevDialogs) => ({
              ...prevDialogs,
              [tasks.id]: false, // Görevin visible state'ini false yap
            }));
            setSelectedTask(null);
            hideDialog();
          }}
          footer={
            <div>
              <Button
                label="Kaydet"
                icon="pi pi-check"
                onClick={handleEditSupport}
                className="btn-excel-two"
                style={{ marginLeft: "15px" }}
              />
            </div>
          }
        >
          <div className="confirmation-content">
            <div className="p-grid p-fluid">
              <div className="p-col-12">
                <div className="p-col-6" style={{ marginBottom: "1rem" }}>
                  <Dropdown
                    filter
                    options={customers?.map((dep) => ({
                      label: dep.title,
                      value: dep.id,
                    }))}
                    value={cus}
                    placeholder="Müşteri Adı "
                    onChange={(e) => setCus(e.target.value)}
                    style={{
                      border: "1px solid gray",
                      width: "65%",
                    }}
                  />
                </div>
                <div className="p-col-6" style={{ marginBottom: "1rem" }}>
                  <Dropdown
                    filter
                    options={subjects}
                    value={dep}
                    placeholder="Konu"
                    onChange={(e) => setDep(e.target.value)}
                    style={{
                      border: "1px solid gray",
                      width: "65%",
                    }}
                  />
                </div>
                <div style={{ display: "flex", marginBottom: "1rem" }}>
                  <InputText
                    type="text"
                    className="p-inputtext-lg "
                    placeholder="Açıklama"
                    style={{ width: "65%" }}
                    defaultValue={desc}
                    onChange={(e) => setDesc(e.target.value)}
                  />
                </div>
                <div className="p-col-6" style={{ marginBottom: "1rem" }}>
                  <Dropdown
                    filter
                    options={statusType}
                    placeholder="Durum"
                    value={taskStatus}
                    onChange={(e) => setTaskStatus(e.value)}
                    style={{
                      border: "1px solid gray",
                      width: "65%",
                      marginBottom: "1rem",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </React.Fragment>
    );
  };

  const hideDeleteDialog = () => {
    setDisplayDeleteDialog(false);
    setSelectedId(null);
  };
  const handleDeletedSupport = async () => {
    await DeleteTaskService(selectedId);
    setDisplayDeleteDialog(false);
    setSelectedId(null);
  };
  return (
    <Flex display="flex" justify="flex-start">
      <div
        className="card"
        style={{
          overflow: "auto",
          boxSizing: "border-box",
          width: "100%",
          height: "100%",
          position: "relative",
          padding: "1rem",
        }}
      >
        <Card>
          <Toast ref={toast} />
          <DataTable
            stripedRows
            size="small"
            value={
              tasks &&
              tasks.filter(
                (tasks) =>
                  Number(tasks?.tasks_id) === 3 &&
                  tasks.unique_id === customer.unique_id
              )
            }
            dataKey="id"
            tableStyle={{ maxWidth: "85vw" }}
            rows={10}
            rowsPerPageOptions={[10, 30, 60, 100]}
            scrollHeight="65vh"
            scrollable
            paginator
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate=" {totalRecords} destekten {first} - {last} arası gösteriliyor"
          >
            <Column body={EditBodyTemplate} header="İşlemler"></Column>

            <Column
              header="Müşteri"
              field="name"
              body={(rowData) => {
                const cust = customers?.find(
                  (dept) => dept.id.toString() === rowData.name
                );
                if (cust) {
                  // Müşteri başlığını 30 karaktere kısalt
                  const shortenedTitle =
                    cust.title && cust.title.length > 25
                      ? cust.title.substring(0, 25) + "..."
                      : cust.title;
                  return shortenedTitle;
                } else {
                  return "Bilinmeyen";
                }
              }}
            ></Column>
            <Column
              style={{ maxWidth: "15rem" }}
              field="department"
              header="Konu"
            ></Column>
            <Column
              style={{ maxWidth: "20rem" }}
              field="desc"
              header="Açıklama"
            ></Column>
            <Column
              style={{ maxWidth: "10rem" }}
              field="priority"
              body={workingBodyTemplate}
              header="Durum"
            ></Column>
          </DataTable>
        </Card>
      </div>
      <Dialog
        visible={addModal}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        style={{ width: "50%" }}
        header="Destek Ekle"
        onHide={hideDialog}
        modal
      >
        <div className="confirmation-content">
          <div className="p-grid p-fluid">
            <div className="col-sm-12">
              <div style={{ display: "flex", marginBottom: "1rem" }}>
                <Dropdown
                  filter
                  onChange={(e) => setCus(e.target.value)}
                  options={
                    customers && customers.length > 0
                      ? customers.map((dep) => ({
                          label: dep.title,
                          value: dep.id,
                        }))
                      : []
                  }
                  value={cus}
                  placeholder="Müşteri Adı / Ünvanı"
                  style={{
                    border: "1px solid gray",
                    width: "100%",
                  }}
                />
                <div style={{ maxWidth: "0.4rem", marginLeft: "0.5rem" }} />
                <Button className="btn-plus " style={{ width: "3rem" }}>
                  <Link to="/customer-list">
                    <i className="pi  pi-plus" style={{ fontSize: "1rem" }}></i>
                  </Link>
                </Button>
              </div>
            </div>
            <div className="p-col-6" style={{ marginBottom: "1rem" }}>
              <Dropdown
                filter
                options={subjects}
                value={dep}
                placeholder="Konu"
                onChange={(e) => setDep(e.target.value)}
                style={{
                  border: "1px solid gray",
                  marginBottom: "1rem",
                  width: "100%",
                }}
              />
            </div>
            <div className="p-col-6" style={{ marginBottom: "1rem" }}>
              <Dropdown
                filter
                options={statusType}
                placeholder="Durum"
                value={taskStatus}
                onChange={(e) => setTaskStatus(e.value)}
                style={{
                  border: "1px solid gray",
                  width: "100%",
                  marginBottom: "1rem",
                }}
              />
            </div>
            <div className="p-col-6" style={{ marginBottom: "1rem" }}>
              <InputText
                type="text"
                className="p-inputtext-lg w-full"
                placeholder="Açıklama"
                onChange={(e) => setDesc(e.target.value)}
                value={desc}
                style={{
                  border: "1px solid gray",
                  marginBottom: "1rem",
                  width: "100%",
                }}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1rem",
          }}
        >
          <Button
            label="Ekle"
            disabled={isButtonDisable}
            onClick={newCreate}
            style={{ width: "15%" }}
            className="btn-excel-two"
          />
        </div>
      </Dialog>
      <Dialog
        header="Destek İçeriği"
        visible={visibleDialogs}
        style={{ width: "50vw" }}
        onHide={() => {
          setDialogs((prevDialogs) => ({
            ...prevDialogs,
            [tasks.id]: false, // Görevin visible state'ini false yap
          }));
          setSelectedId(null);
          setVisibleDialogs(false);
        }}
        footer={
          <div>
            <Button
              label="Görev Bölümüne Aktar"
              icon="pi pi-plus"
              onClick={handleTransferTask}
              className="btn-excel-two"
            />
            <Button
              label="Desteği Tamamla"
              icon="pi pi-check"
              onClick={handleCompleteTask}
              className="btn-excel-two"
              style={{ marginLeft: "15px" }}
            />
          </div>
        }
      >
        <div className="field">
          <label htmlFor="name" className="font-bold">
            Müşteri Adı: {getCustomerName(selectedTask)}
          </label>
        </div>
        <div className="field">
          <label htmlFor="description" className="font-bold">
            Konu : {selectedTask?.department}
          </label>
        </div>
        <div className="field">
          <label className="mb-3 font-bold">
            Açıklama: {selectedTask?.desc}
          </label>
        </div>
      </Dialog>
      <Dialog
        visible={displayDeleteDialog}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        style={{ width: "60vw" }}
        header="Alan Adı Sil"
        onHide={hideDeleteDialog}
        modal
      >
        <div className="confirmation-content">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <p>Desteği Silmek İstediğinize Emin Misiniz?</p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "1rem",
            }}
          >
            <Button
              label="Hayır"
              onClick={hideDeleteDialog}
              className="btn-excel-two"
              style={{ marginRight: "1rem" }}
            />
            <Button
              label="Evet"
              onClick={handleDeletedSupport}
              className="btn-excel-two"
            />
          </div>
        </div>
      </Dialog>
    </Flex>
  );
}
