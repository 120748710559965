import React, { useState } from "react";
import { AddCountryStatistics } from "../../../../../../../../core/Services";
import { Button, Input } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { Dropdown } from "primereact/dropdown";
import { Message } from "primereact/message";
 

export default function CountryVisitGraphPage({
  uniqueCustomerId,
  selectedMonth,
  selectedCustomer,
  countries,
  updateTableData,
}) {
  const [country, setCountry] = useState("");
  const [googleVisitor, setGoogleVisitor] = useState("");
  const [yandexVisitor, setYandexVisitor] = useState("");
  const [otherVisitor, setOtherVisitor] = useState("");
  const [validationError, setValidationError] = useState(false);

  const toast = useToast();

  const validateInputs = () => {
    if (!selectedCustomer || !country || !googleVisitor|| !yandexVisitor || !otherVisitor  || !selectedMonth) {
      setValidationError(true);
      return false;
    }
    setValidationError(false);
    return true;
  };

  const visitor =
    Number(googleVisitor) + Number(yandexVisitor) + Number(otherVisitor);
  const AddCountry = async () => {
    if (!validateInputs()) return;

    try {
      const response = await AddCountryStatistics(
        uniqueCustomerId,
        country,
        visitor,
        selectedMonth
      );

      if (response.message === "Hata") {
        toast({
          title: "Hata",
          description: "Kullanıcya Ait Bu Ay Verisi Zaten Mevcut",
          status: "error",
          duration: 3000,
          position: "top-right",
          isClosable: true,
        });
      } else {
        setCountry("");
        setGoogleVisitor("");
        setYandexVisitor("");
        setOtherVisitor("");


        toast({
          title: "Başarılı",
          description: "Ülke başarıyla eklendi.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        updateTableData();
      }
    } catch (error) {
      console.error("Hata oluştu:", error);
    
    }
  };

  return (
    <div>
      <div className="font-bold text-xl mt-4">
        Not:(Sadece Google ya da Yandex verisi giriniz. Girmediğiniz veriye 0
        değerini girin.)
      </div>
      <div>
        <div className="font-bold text-xl mt-4">Ülke Giriniz : </div>
        <div className="mt-2">
          <Dropdown
            style={{
              border: `2px solid `,
              borderRadius: "1rem",
              width: "30rem",
            }}
            value={country}
            options={countries.map((country, index) => ({
              label: country.name,
              value: country.code,
            }))}
            onChange={(e) => setCountry(e.value)}
            filter
            placeholder="Ülke Seçiniz"
          />
        </div>
      </div>

      <div>
        <div className="font-bold text-xl mt-4">
          Google Ziyaretçi Sayısı Giriniz :
        </div>
        <div className="mt-2">
          <Input
            filter
            onChange={(e) => {
              setGoogleVisitor(e.target.value);
            }}
            value={googleVisitor}
            placeholder="Ziyaretçi Sayısı"
            style={{
              border:
                validationError && !googleVisitor
                  ? "1px solid red"
                  : "1px solid gray",
              width: "30rem",
            }}
          />
        </div>
      </div>
      <div>
        <div className="font-bold text-xl mt-4">
          Yandex Ziyaretçi Sayısı Giriniz :
        </div>
        <div className="mt-2">
          <Input
            filter
            onChange={(e) => {
              setYandexVisitor(e.target.value);
            }}
            value={yandexVisitor}
            placeholder="Ziyaretçi Sayısı"
            style={{
              border:
                validationError && !yandexVisitor
                  ? "1px solid red"
                  : "1px solid gray",
              width: "30rem",
            }}
          />
        </div>
      </div>
      <div>
        <div className="font-bold text-xl mt-4">
          Diğer Ziyaretçi Sayısı Giriniz :
        </div>
        <div className="mt-2">
          <Input
            filter
            onChange={(e) => {
              setOtherVisitor(e.target.value);
            }}
            value={otherVisitor}
            placeholder="Ziyaretçi Sayısı"
            style={{
              border:
                validationError && !otherVisitor
                  ? "1px solid red"
                  : "1px solid gray",
              width: "30rem",
            }}
          />
        </div>
      </div>
      {validationError && (
        <div className="text-red-500 mt-2 text-sm">
          Lütfen boş alanları doldurunuz.
        </div>
      )}
      <div className="mt-3 flex justify-content-end">
        <Button width="8rem" onClick={AddCountry}>
          Kaydet
        </Button>
      </div>
      <Message
        className="mt-4 w-full"
        severity="info"
        text="Google Analytics > Raporlar > Kullanıcı > Demografik Grup Ayrıntıları"
      />
      <br />
    </div>
  );
}
