import React, { useState } from "react";
import ViewVisitorChartPage from "./components/AddData";
import { Splitter, SplitterPanel } from "primereact/splitter";
import ViewVisitorChartTable from "./components/Editing";
import ViewVisitorAppearance from "./components/Chart";

export default function Page({ uniqueCustomerId, selectedMonth, selectedCustomer, months }) {

  const [tableKey, setTableKey] = useState(0);
  const [appearanceKey, setAppearanceKey] = useState(0);

  const updateTableData = () => {
    setTableKey((prevKey) => prevKey + 1);
    setAppearanceKey((prevKey) => prevKey + 1);
  };

  const DataTypes = [
    { name: "Google Arama", value: "1" },
    { name: "Arama Ortakları", value: "2" },
    { name: "Görüntülü Reklam Ağı", value: "3" },
  ];

  
  return (
    <Splitter>
      <SplitterPanel>
        <ViewVisitorChartPage
          updateTableData={updateTableData}
          DataTypes={DataTypes}
          selectedMonth={selectedMonth}
          selectedCustomer={selectedCustomer}
          uniqueCustomerId={uniqueCustomerId}
        />
      </SplitterPanel>
      <SplitterPanel className="w-full">
        <div className="w-full">
          <ViewVisitorChartTable
            key={tableKey}
            updateTableData={updateTableData}
            months={months}
            DataTypes={DataTypes}
            uniqueCustomerId={uniqueCustomerId}
          />

          <ViewVisitorAppearance
            appearanceKey={appearanceKey}
            months={months}
            DataTypes={DataTypes}
            uniqueCustomerId={uniqueCustomerId}
          />
        </div>
      </SplitterPanel>
    </Splitter>
  );
}
