import {
  Box,
  Card,
  CardBody,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  TabPanel,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import Completed from "./components/Completed";
import Waiting from "./components/Waiting";
import InProgress from "./components/InProgress";
import { useDispatch } from "react-redux";
import { addJobData } from "../../../redux/JobSlice";
import { addUserData } from "../../../redux/UserSlice";
import { addDepartmentData } from "../../../redux/DepartmentSlice";
import { addCustomerData } from "../../../redux/CustomerSlice";
import { JobsService } from "../../../core/Services";
import { BreadCrumb } from "primereact/breadcrumb";
export default function JobsPage() {
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const jobData = await JobsService();

        // Güncellenmiş veriyi Redux store'a ekle
        dispatch(addJobData(jobData.jobs));
        dispatch(addUserData(jobData.users));
        dispatch(addDepartmentData(jobData.departments));
        dispatch(addCustomerData(jobData.customers));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [dispatch]);
  const getTurkishLabel = (url) => {
    switch (url) {
      case "/jobs":
        return "İşler";
      // Diğer durumlar için gerektiğinde eşleme yapabilirsiniz
      default:
        return "Sayfa"; // Varsayılan olarak "Sayfa" kullanılabilir veya istediğiniz bir şeyi kullanabilirsiniz
    }
  };
  const url = "/jobs";
  const turkishLabel = getTurkishLabel(url); // "Ürünler"

  const itemsBread = [{ label: turkishLabel, url: url }];
  const home = { icon: "pi pi-home", url: "/dashboard" };
  return (
    <Box mt="5">
      <Card>
        {" "}
        <BreadCrumb
          model={itemsBread}
          home={home}
          style={{ padding: "1rem" }}
        />
        <CardBody>
          <Tabs align="end" variant="enclosed">
            <TabList>
              <Tab>İşlemde</Tab> <Tab>Bekleyen</Tab>
              <Tab>Tamamlanan</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <InProgress />
              </TabPanel>
              <TabPanel>
                <Waiting />
              </TabPanel>
              <TabPanel>
                <Completed />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </CardBody>
      </Card>
    </Box>
  );
}
