import React, { useEffect, useState } from "react";
import Ornek from "../../../../../../../../_ekdizayn/logo/logo.png";

export default function Promotion() {
  const images = [ Ornek];
  const [currentImage, setCurrentImage] = useState(0);

  const nextSlide = () => {
    setCurrentImage(currentImage === images.length - 1 ? 0 : currentImage + 1);
  };

  const prevSlide = () => {
    setCurrentImage(currentImage === 0 ? images.length - 1 : currentImage - 1);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage(
        currentImage === images.length - 1 ? 0 : currentImage + 1
      );
    }, 3000);

    return () => clearInterval(interval);
  }, [currentImage, images.length]);

  return (
    <div className="p-3 d-flex flex-wrap justify-content-between align-items-center h-100">
      <div
        style={{
          height: "200px",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <img
          src={images[currentImage]}
          alt={`Resim ${currentImage + 1}`}
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            width: "auto",
            height: "auto",
            objectFit: "cover",
          }}
        />
        <div className="carousel-nav">
          <span onClick={prevSlide}>&lt;</span>
          <span onClick={nextSlide}>&gt;</span>
        </div>
      </div>
    </div>
  );
}
