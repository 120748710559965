import { createSlice } from "@reduxjs/toolkit";

export const ManufacturingSlice = createSlice({
  name: "ManufacturingSlice",
  initialState: {
    value: null,
  },
  reducers: {
    addManufacturingData: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { addManufacturingData } = ManufacturingSlice.actions;
export default ManufacturingSlice.reducer;
