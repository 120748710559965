import React, { useState } from "react";
import { Splitter, SplitterPanel } from "primereact/splitter";
import SecurityTable from "./components/SecurityTable";
import SecurityPage from "./components/SecurityPage";

export default function Security({
  uniqueCustomerId,
  selectedCustomer,
  selectedMonth,
  months,
}) {
  const countries = require("./components/countries.json");
    const [tableKey, setTableKey] = useState(0);

    const updateTableData = () => {
      setTableKey((prevKey) => prevKey + 1);
    };
  
  return (
    <Splitter>
      <SplitterPanel>
        <SecurityPage
          updateTableData={updateTableData}
          uniqueCustomerId={uniqueCustomerId}
          selectedCustomer={selectedCustomer}
          selectedMonth={selectedMonth}
          countries={countries}
        />
      </SplitterPanel>
      <SplitterPanel size={75} style={{ display: "block", padding: "1rem" }}>
          <SecurityTable
            key={tableKey}
            months={months}
            countries={countries}
            uniqueCustomerId={uniqueCustomerId}
          />
      </SplitterPanel>
    </Splitter>
  );
}
