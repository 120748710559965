import React, { useEffect, useState } from "react";
import SupplierDatatable from "./components/SupplierDatatable";
import { AllSupplier } from "../../../core/Services";
import SupplierAddDialog from "./components/SupplierAddDialog";
import SupplierUpdateDialog from "./components/SupplierUpdateDialog";
import SupplierDeleteDialog from "./components/SupplierDeleteDialog";

export default function SupplierPage() {
  const [supplier, setSupplier] = useState([]);
  const [responsiblePerson , setResponsiblePerson] = useState("")
  const [company, setCompany] = useState("");
  const [address, setAdress] = useState("");
  const [phone, setPhone] = useState("");
  const [activityArea, setActivityArea] = useState("");
  const [selectedSupplier, setSelectedSupplier] = useState(""); 
  const [displayAddDialog, setDisplayAddDialog] = useState(false);
  const [displayUpdateDialog, setDisplayUpdateDialog] = useState(false);
  const [displayDeleteDialog, setDisplayDeleteDialog] = useState(false);
  const showAddDialog = () => {
    setDisplayAddDialog(true);
  };
  useEffect(() => {
    AllSupplier()
      .then((data) => {
        setSupplier(data);
      })
      .catch((error) => {
        console.log("Tedarikçi Çekme Hatası", error);
      });
  }, []);
  return (
    <>
      <SupplierDatatable
        setDisplayUpdateDialog={setDisplayUpdateDialog}
        setDisplayDeleteDialog={setDisplayDeleteDialog}
        displayDeleteDialog={displayDeleteDialog}
        showAddDialog={showAddDialog}
        supplier={supplier}
        setCompany={setCompany}
        setAdress={setAdress}
        setPhone={setPhone}
        setActivityArea={setActivityArea}
        setResponsiblePerson={setResponsiblePerson}
        setSelectedSupplier={setSelectedSupplier}
      />
      <SupplierAddDialog
        responsiblePerson={responsiblePerson}
        setResponsiblePerson={setResponsiblePerson}
        company={company}
        setCompany={setCompany}
        address={address}
        setAdress={setAdress}
        phone={phone}
        setPhone={setPhone}
        activityArea={activityArea}
        setActivityArea={setActivityArea}
        displayAddDialog={displayAddDialog}
        setDisplayAddDialog={setDisplayAddDialog}
      />
      <SupplierUpdateDialog
        responsiblePerson={responsiblePerson}
        setResponsiblePerson={setResponsiblePerson}
        company={company}
        setCompany={setCompany}
        address={address}
        setAdress={setAdress}
        phone={phone}
        setPhone={setPhone}
        activityArea={activityArea}
        setActivityArea={setActivityArea}
        displayUpdateDialog={displayUpdateDialog}
        setDisplayUpdateDialog={setDisplayUpdateDialog}
        selectedSupplier={selectedSupplier}
        setSelectedSupplier={setSelectedSupplier}
      />
      <SupplierDeleteDialog
        setResponsiblePerson={setResponsiblePerson}
        setCompany={setCompany}
        setAdress={setAdress}
        setPhone={setPhone}
        setActivityArea={setActivityArea}
        displayDeleteDialog={displayDeleteDialog}
        setDisplayDeleteDialog={setDisplayDeleteDialog}
        selectedSupplier={selectedSupplier}
        setSelectedSupplier={setSelectedSupplier}
        responsiblePerson={responsiblePerson}
        company={company}
        address={address}
        phone={phone}
        activityArea={activityArea}
      />
    </>
  );
}
