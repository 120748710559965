import React, { useState } from "react";
import { Button, Input, useToast } from "@chakra-ui/react";
import { Dropdown } from "primereact/dropdown";
import { AddSecurity } from "../../../../../../../core/Services";
import { Message } from "primereact/message";

export default function SecurityPage({
  uniqueCustomerId,
  selectedCustomer, 
  selectedMonth,
  countries,
  updateTableData
}) {
 
  const [country, setCountry] = useState("");
  const [blockedIPAddress, setBlockedIPAddress] = useState("");
  const [blockNumber, setBlockNumber] = useState("");
  const [error, setError] = useState(false);
  const toast = useToast();


  const handleSave = () => {
    if (
      !selectedCustomer ||
      !selectedMonth ||
      !country ||
      !blockedIPAddress ||
      !blockNumber
    ) {
      setError(true);
      toast({
        title: "Hata",
        description: "Lütfen tüm alanları doldurunuz.",
        status: "error",
        duration: 3000,
        position: "top-right",
        isClosable: true,
      });
      return;
    }

    AddSecurity(
      uniqueCustomerId,
      country,
      blockedIPAddress,
      blockNumber,
      selectedMonth
    )
      .then((response) => {
        if (response.message === "Hata") {
          toast({
            title: "Hata",
            description: "Kullanıcya Ait Bu Ay Verisi Zaten Mevcut",
            status: "error",
            duration: 3000,
            position: "top-right",
            isClosable: true,
          });
        } else {
          setCountry("");
          setBlockedIPAddress("");
          setBlockNumber("");
          
          setError(false);

          toast({
            title: "Başarılı",
            description: "Veri başarılı bir şekilde kaydedildi.",
            status: "success",
            duration: 3000,
            position: "top-right",
            isClosable: true,
          });
          updateTableData();
        }
      })
      .catch((error) => {
        console.error("Backend'e gönderme hatası:", error);
        toast({
          title: "Hata",
          description: "Veri gönderilirken bir hata oluştu.",
          status: "error",
          duration: 3000,
          position: "top-right",
          isClosable: true,
        });
      });
  };

  return (
    <div>
      <div></div>
      <div className="font-bold text-xl mt-5"> Ülke: </div>
      <div className="mt-2">
        <Dropdown
          style={{
            border: `2px solid ${error && !country ? "red" : ""}`,
            borderRadius: "1rem",
            width: "30rem",
          }}
          value={country}
          options={countries.map((country, index) => ({
            label: country.name,
            value: country.code,
          }))}
          onChange={(e) => setCountry(e.value)}
          filter
          placeholder="Ülke Seçiniz"
        />
      </div>

      <div className="font-bold text-xl mt-5">
        Engellenmiş IP Adresi Toplamı:
      </div>
      <div className="mt-2">
        <Input
          placeholder="Değer Giriniz"
          w="30rem"
          name="value"
          value={blockedIPAddress}
          onChange={(e) => setBlockedIPAddress(e.target.value)}
          borderColor={error && !blockedIPAddress ? "red" : ""}
        />
      </div>

      <div className="font-bold text-xl mt-5">Engelleme Sayısı:</div>
      <div className="mt-2">
        <Input
          placeholder="Değer Giriniz"
          w="30rem"
          name="value"
          value={blockNumber}
          onChange={(e) => setBlockNumber(e.target.value)}
          borderColor={error && !blockNumber ? "red" : ""}
        />
      </div>

      <div className="mt-5 flex justify-content-end">
        <Button onClick={handleSave}> Kaydet</Button>
      </div>
      <Message
        className="mt-4 w-full"
        severity="info"
        text="Wordpress > Wordfence > Güvenlik Duvarı
        Wordfence Etkinliği Maili > En çok engellenen İlk 10 ülke"
      />
    </div>
  );
}
