import React, { useEffect, useState } from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import CustomerNavbar from "../components/Navbar/CustomerNavbar";
import CustomerSidebar from "../components/Sidebar/CustomerSidebar";
import CustomerInformationPage from "./CustomerInformationPage";
import { GetDomain, GetUserByUniqueId} from "../../../../../core/Services";
import { useAuth } from "../../../../../auth/core/AuthContext";


export default function CustomerInformation() {
 const {userData} = useAuth(); 
 const [domainName , setDomainName] = useState("");
 const [user, setUser] = useState([]);

  useEffect(() => {
   
    GetDomain(userData?.unique_id)
      .then((data) => {
        setDomainName(data.data);
      })
      .catch((error) => {
        console.error("Hata oluştu:", error);
      });

        if (userData?.unique_id) {
          GetUserByUniqueId(userData?.unique_id)
            .then((data) => {
              setUser(data);
            })
            .catch((error) => {
              console.log("Customer Hatası", error);
            });
        }        
  }, [userData?.unique_id]);

  
  return (
    <Grid
      templateAreas={`"header header"
                  "nav main"
                 `}
      gridTemplateRows={"auto 1fr "}
      gridTemplateColumns={"auto 1fr"}
      h="auto"
      color="blackAlpha.700"
      fontWeight="bold"
    >
      <GridItem area={"header"}>
        <CustomerNavbar />
      </GridItem>
      <GridItem area={"nav"}>
        <CustomerSidebar />
      </GridItem>
      <GridItem mt="5" area={"main"}>
        <CustomerInformationPage user={user} domainName={domainName} />
      </GridItem>
    </Grid>
  );
}
