import React from 'react'
import TallyPage from './TallyPage'
import Sidebar from '../../../../_ekdizayn/layout/Sidebar/Sidebar'
import NavBar from '../../../../_ekdizayn/layout/Navbar/components/Header'
import { Grid, GridItem } from '@chakra-ui/react'

export default function tally() {
    return (
        <Grid
      templateAreas={`"header header"
                      "nav main"
                     `}
      gridTemplateRows={'auto 1fr '}
      gridTemplateColumns={'auto 1fr'}
    
      h='auto'
      color='blackAlpha.700'
      fontWeight='bold'
    >
      <GridItem  area={'header'}>
        <NavBar/>
      </GridItem>
      <GridItem   area={'nav'}>
        <Sidebar/>
      </GridItem>
      <GridItem mt="5"  area={'main'}>
        <TallyPage/>
      </GridItem>
    </Grid>
      )
}
