import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  AddMarkettingChaseService,
  DeleteMarkettingChaseService,
  MarkettingChaseService,
  UpdateMarkettingChaseService,
} from "../../../../core/Services";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Card, CardHeader, Flex } from "@chakra-ui/react";
import { addLocale } from "primereact/api";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { BreadCrumb } from "primereact/breadcrumb";
import { InputTextarea } from "primereact/inputtextarea";
import { MultiSelect } from "primereact/multiselect";
import { RadioButton } from "primereact/radiobutton";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import serviceType from "../../../../../_ekdizayn/api/marketing-service.json";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import { useAuth } from "../../../../auth/core/AuthContext";
export default function MarketingChase() {
  const getTurkishLabel = (url) => {
    switch (url) {
      case "/marketingChase":
        return "Seyyar";
      default:
        return "Sayfa";
    }
  };
  const url = "/marketingChase";
  const turkishLabel = getTurkishLabel(url);
  const itemsBread = [{ label: turkishLabel, url: url }];
  const home = { icon: "pi pi-home", url: "/dashboard" };
  const [chases, setChases] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [customerData, setCustomerData] = useState({});
  const [source, setSource] = useState("");
  const [service, setService] = useState([]);
  const [status, setStatus] = useState(0);
  const [cus, setCus] = useState("");
  const [emp, setEmp] = useState("");
  const [notification, setNotification] = useState([]);
  const [date, setDate] = useState(new Date());
  const [desc, setDesc] = useState("");
  const [tableValue, setTableValue] = useState(0);
  const [meet, setMeet] = useState(null);
  const [defaultValue, setDefaultValue] = useState("");
  const [dialogs, setDialogs] = useState({});
  const [dialogsDelete, setDialogsDelete] = useState({});
  const [dialogsComplete, setDialogsComplete] = useState({});
  const [selectedData, setSelectedData] = useState([]);
  const [oldChases, setOldChases] = useState([]);
  const { userData } = useAuth();
  const by_who = userData?.id;
  const toast = useRef(null);
  const [inputErrors, setInputErrors] = useState({
    cus: false,
    emp: false,
    source: false,
    service: false,

    desc: false,
  });
  const saveChanges = async (id) => {
    await UpdateMarkettingChaseService(
      id,
      cus,
      emp,
      source,
      status,
      service,
      desc,
      date,
      meet,
      notification,
      by_who
    );
    setDialogs(false);
    hideDialog();
  };
  const handleNotificationChange = (value) => {
    if (
      notification &&
      Array.isArray(notification) &&
      notification?.includes(value)
    ) {
      setNotification(notification?.filter((item) => item !== value));
    } else {
      setNotification([...(notification || []), value]);
    }
  };

  const newCreate = async () => {
    let tempSource = false;

    let tempService = false;

    if (!cus || !emp || !desc) {
      if (
        source === null ||
        source === undefined ||
        source === false ||
        source === ""
      ) {
        tempSource = true;
      }

      if (!service || service.length === 0) {
        tempService = true;
      }

      setInputErrors({
        cus: !cus,
        emp: !emp,
        source: tempSource,
        service: tempService,

        desc: !desc,
      });

      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Lütfen boş kısımları doldurun.",
      });
      return;
    }

    await AddMarkettingChaseService(
      cus,
      emp,
      source,
      status,
      service,
      desc,
      date
    );
    setAddModal(false);
    setCus("");
    setEmp("");
    setSource("");
    setStatus("");
    setService([]);
    setDesc("");
    setDate("");
  };
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    return new Date(dateString).toLocaleString("tr-TR", options);
  };
  const hideDialog = () => {
    setAddModal(false);
    setDialogs(false);
    setDialogsDelete(false);
    setDialogsComplete(false);
    setSelectedData(false);
    setCus("");
    setSource("");
    setService([]);
    setEmp("");
    setStatus("");
    setDesc("");
    setDate("");
    setNotification("");
  };

  addLocale("tr", {
    firstDayOfWeek: 1,
    showMonthAfterYear: true,
    dayNames: [
      "pazar",
      "pazartesi",
      "salı",
      "çarşamba",
      "perşembe",
      "cuma",
      "cumartesi",
    ],
    dayNamesShort: ["paz", "pzt", "sal", "çar", "per", "cum", "cmt"],
    dayNamesMin: ["P", "Pzt", "S", "Ç", "P", "C", "Cmt"],
    monthNames: [
      "ocak",
      "şubat",
      "mart",
      "nisan",
      "mayıs",
      "haziran",
      "temmuz",
      "ağustos",
      "eylül",
      "ekim",
      "kasım",
      "aralık",
    ],
    monthNamesShort: [
      "oca",
      "şub",
      "mar",
      "nis",
      "may",
      "haz",
      "tem",
      "ağu",
      "eyl",
      "eki",
      "kas",
      "ara",
    ],
    today: "Bugün",
    clear: "Temizle",
  });

  const accept = () => {
    DeleteMarkettingChaseService(selectedData.id);
    hideDialog();
    toast.current.show({
      severity: "info",
      summary: "İşlem Başarılı!",
      detail: "Başarıyla silindi.",
      life: 3000,
    });
  };

  const confirmDelete = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Silmek istediğinize emin misiniz?",
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
      rejectClassName: "confirm-popup-reject",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      accept,
    });
  };

  const acceptComp = async () => {
    let id = selectedData?.id;
    await UpdateMarkettingChaseService(
      id,
      cus,
      emp,
      source,
      status,
      service,
      desc,
      date,
      meet,
      notification
    );
    hideDialog();
    toast.current.show({
      severity: "info",
      summary: "İşlem Başarılı!",
      life: 3000,
    });
  };
  const confirmComplete = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Kapatmak istediğinize emin misiniz?",
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
      rejectClassName: "confirm-popup-reject",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-success",
      accept: acceptComp,
    });
  };

  const accessors = (rowData) => {
    const editModal = dialogs[rowData.id] || false;
    const visible = dialogsDelete[rowData.id] || false;
    const completeModal = dialogsComplete[rowData.id] || false;
    return (
      <div style={{ display: "flex" }}>
        <div style={{ color: "#f39f5a" }}>
          <Button
            severity="warning"
            onClick={() => {
              setSelectedData(rowData);
              setDefaultValue(rowData.currency);
              setDialogs((prevDialogs) => ({
                ...prevDialogs,
                [rowData.id]: true,
              }));
              setCus(Number(rowData?.customer));
              setSource(Number(rowData?.source));
              setEmp(Number(rowData?.employee));
              setStatus(Number(rowData?.status));
              setDesc(rowData?.description);
              setDate(new Date(rowData?.date));
              const serviceArray = rowData?.service
                ?.split(",")
                .map((item) => parseInt(item.trim(), 10));
              setService(serviceArray);
              setCustomerData(
                customers?.find(
                  (dept) => Number(dept.id) === Number(rowData.customer)
                )
              );
              setMeet(Number(rowData?.meeting));
            }}
            icon="pi pi-pencil"
          />
        </div>
        <div style={{ color: "#00d9ff" }}>
          <Button
            icon="pi pi-eye"
            onClick={() => {
              setSelectedData(rowData);
              setDialogsDelete((prevDialogs) => ({
                ...prevDialogs,
                [rowData.id]: true,
              }));
              setCus(Number(rowData?.customer));
              setSource(Number(rowData?.source));
              const serviceArray = rowData?.service
                ?.split(",")
                .map((item) => parseInt(item.trim(), 10));
              setService(serviceArray);
              setEmp(Number(rowData?.employee));
              setStatus(Number(rowData?.status));
              setMeet(Number(rowData?.meeting));
              setDesc(rowData?.description);
              setDate(rowData?.date);
              setCustomerData(
                customers?.find(
                  (dept) => Number(dept.id) === Number(rowData.customer)
                )
              );
            }}
            severity="info"
          />
        </div>
        {tableValue === 1 && (
          <div style={{ color: "#692f7b" }}>
            <Button
              icon="pi pi-check"
              onClick={() => {
                setSelectedData(rowData);
                setDialogsComplete((prevDialogs) => ({
                  ...prevDialogs,
                  [rowData.id]: true,
                }));
                setCus(Number(rowData?.customer));
                setSource(Number(rowData?.source));
                const serviceArray = rowData?.service
                  ?.split(",")
                  .map((item) => parseInt(item.trim(), 10));
                setService(serviceArray);
                setEmp(Number(rowData?.employee));
                setStatus(Number(rowData?.status));
                setMeet(Number(rowData?.meeting));
                setDesc(rowData?.description);
                setDate(rowData?.date);
                setNotification(rowData?.notification);
              }}
              severity="dark"
            />
          </div>
        )}

        <Dialog
          header="Seyyar Bilgisi Detayları"
          visible={visible}
          style={{ width: "60vw" }}
          onHide={hideDialog}
        >
          <div className="flex mb-4">
            <div className="col-6">
              <strong>Müşteri Detayları</strong>
              <p>
                <span style={{ fontWeight: "bold" }}>Ad Soyad:</span>
                {customerData?.name}
              </p>

              <p>
                <span style={{ fontWeight: "bold" }}>Firma:</span>
                {customerData?.title}
              </p>

              <p>
                <span style={{ fontWeight: "bold" }}>Email:</span>
                {customerData?.email}
              </p>

              <p>
                <span style={{ fontWeight: "bold" }}>Tel:</span>
                {customerData?.phone}
              </p>
            </div>
            <div className="col-6">
              <strong>İş Detayları</strong>

              <p>
                <span style={{ fontWeight: "bold" }}>Açıklama:</span>{" "}
                {selectedData?.description}
              </p>

              <p>
                <span style={{ fontWeight: "bold" }}>Durum:</span>{" "}
                {
                  statusType?.find(
                    (dept) =>
                      Number(dept?.value) === Number(selectedData?.status)
                  )?.label
                }
              </p>

              <p>
                <span style={{ fontWeight: "bold" }}>Hizmet Talepleri:</span>{" "}
                {selectedData?.service && selectedData?.service.length > 0
                  ? selectedData?.service
                      .split(",")
                      .map((serviceId) => {
                        const service = serviceType?.find(
                          (dept) =>
                            Number(dept?.value) === Number(serviceId.trim())
                        );
                        return service ? service?.label : "Bilinmeyen";
                      })
                      .join(", ")
                  : "Servis bilgisi bulunamadı"}
              </p>
              <p>
                <span style={{ fontWeight: "bold" }}>Kaynak:</span>{" "}
                {
                  sourceType?.find(
                    (dept) =>
                      Number(dept?.value) === Number(selectedData?.source)
                  )?.label
                }
              </p>
              <p>
                <span style={{ fontWeight: "bold" }}>Oluşturma Tarihi:</span>{" "}
                {formatDate(selectedData?.date)}
              </p>
            </div>
          </div>
          <div className="flex justify-content-between align-items-center w-full">
            <div className="flex align-items-center">
              <Checkbox
                onChange={() => handleNotificationChange("0")}
                checked={notification?.includes("0")}
                disabled={selectedData?.notification?.includes("0")}
              />
              <p>Sms</p>
              <div style={{ width: "1rem" }} />
              <Checkbox
                onChange={() => handleNotificationChange("1")}
                checked={notification?.includes("1")}
                disabled={selectedData?.notification?.includes("1")}
              />{" "}
              <p>E-Posta</p>
              <div style={{ width: "1rem" }} />
              <Checkbox
                onChange={() => handleNotificationChange("2")}
                checked={notification?.includes("2")}
                disabled={selectedData?.notification?.includes("2")}
              />
              <p>Whatsapp</p>
              <div style={{ width: "1rem" }} />
              <Checkbox
                onChange={() => handleNotificationChange("3")}
                checked={notification?.includes("3")}
                disabled={selectedData?.notification?.includes("3")}
              />
              <p>Telefon</p>
            </div>

            <Button
              label="Bildirim Gönder"
              onClick={() => {
                saveChanges(selectedData?.id);
              }}
              className="btn-excel-two"
            />
          </div>
        </Dialog>
        <Dialog
          visible={editModal}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          style={{ width: "60vw" }}
          header="Seyyar Bilgisini Düzenle"
          onHide={hideDialog}
          modal
          footer={
            <div
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <ConfirmPopup />
              <Button
                label="Sil"
                onClick={confirmDelete}
                className="btn-excel-two"
                style={{ marginRight: "10px" }}
              />
              <Button
                label="Kaydet"
                onClick={() => {
                  saveChanges(rowData?.id);
                }}
                className="btn-excel-two"
              />
            </div>
          }
        >
          <div
            className="confirmation-content "
            style={{
              display: "flex",
            }}
          >
            <div className="col-6" style={{ display: "inline-block" }}>
              <div>Müşteri</div>
              <Dropdown
                filter
                options={customers?.map((dep) => ({
                  label: dep.title,
                  value: dep.id,
                }))}
                value={cus}
                placeholder="Müşteri"
                className="w-full "
                onChange={(e) => setCus(e.target.value)}
                style={{
                  border: "1px solid gray",
                  width: "65%",
                  marginBottom: "0.3rem",
                }}
              />
              <div>Kaynak</div>
              <Dropdown
                filter
                options={sourceType}
                placeholder="Kaynak"
                value={source}
                className="w-full "
                onChange={(e) => setSource(e.value)}
                style={{
                  border: "1px solid gray",
                  marginBottom: "0.3rem",
                }}
              />
              <div>Hizmet Talebi</div>
              <MultiSelect
                filter
                options={serviceType}
                placeholder="Hizmet Talebi"
                value={service}
                className="w-full "
                onChange={(e) => {
                  setService(e.value);
                }}
                style={{
                  border: "1px solid gray",
                }}
              />
              <Calendar
                value={date}
                placeholder="Tarih Seçiniz"
                onChange={(e) => {
                  setDate(e.value);
                }}
                showIcon
                dateFormat="dd/mm/yy"
                locale="tr"
                className="p-inputtext-lg w-full "
                icon={() => <i className="pi pi-calendar" />}
                style={{
                  marginTop: "1rem",
                }}
              />
            </div>
            <div className="col-6" style={{ display: "inline-block" }}>
              <div>Müşteri Temsilcisi</div>
              <Dropdown
                className="w-full "
                filter
                options={employees?.map((dep) => ({
                  label: dep.name,
                  value: dep.id,
                }))}
                value={emp}
                placeholder="Müşteri Temsilcisi"
                onChange={(e) => setEmp(e.value)}
                style={{
                  border: "1px solid gray",
                  marginBottom: "0.3rem",
                }}
              />
              <div>Durum</div>
              <Dropdown
                options={statusType}
                placeholder="Durum"
                value={status}
                className="w-full "
                onChange={(e) => setStatus(e.value)}
                style={{
                  border: "1px solid gray",
                  marginBottom: "0.3rem",
                }}
              />
              <div>Açıklama</div>
              <InputTextarea
                style={{ maxHeight: "6.6rem" }}
                type="text"
                placeholder="Açıklama"
                rows={3}
                className="p-inputtext-lg w-full "
                value={desc}
                onChange={(e) => setDesc(e.target.value)} // Başlık girişi için değeri güncelle
              />
            </div>
          </div>
          <strong>Toplantı Durumu </strong>
          <div className="flex align-items-center">
            <RadioButton onChange={() => setMeet(0)} checked={meet === 0} />
            <p>Online</p>
            <div style={{ width: "1rem" }} />
            <RadioButton
              onChange={() => setMeet(1)}
              checked={meet === 1}
            />{" "}
            <p>Yüzyüze</p>
            <div style={{ width: "1rem" }} />
            <RadioButton onChange={() => setMeet(2)} checked={meet === 2} />
            <p>İstenmiyor</p>
          </div>
        </Dialog>
        <Dialog
          visible={completeModal}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          style={{ width: "60vw" }}
          header="Seyyar Talebi Bilgisi"
          onHide={hideDialog}
          modal
          footer={
            <div
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <ConfirmPopup />
              <Button
                label="Talebi Kapat"
                onClick={confirmComplete}
                className="btn-excel-two"
                style={{ marginRight: "10px" }}
              />
            </div>
          }
        >
          <div
            className="confirmation-content "
            style={{
              display: "flex",
            }}
          >
            <div className="col-3" style={{ display: "inline-block" }}>
              <div>Durum</div>
              <Dropdown
                options={[
                  { label: "Alındı", value: 3 },
                  { label: "Alınamadı", value: 4 },
                ]}
                placeholder="Durum"
                value={status}
                className="w-full "
                onChange={(e) => setStatus(e.value)}
                style={{
                  border: "1px solid gray",
                  marginBottom: "0.3rem",
                }}
              />
            </div>{" "}
            <div className="col-9" style={{ display: "inline-block" }}>
              <div>Açıklama</div>
              <InputTextarea
                style={{ maxHeight: "6.6rem" }}
                type="text"
                placeholder="Açıklama"
                rows={3}
                className="p-inputtext-lg w-full "
                value={desc}
                onChange={(e) => setDesc(e.target.value)} // Başlık girişi için değeri güncelle
              />
            </div>
          </div>
        </Dialog>
      </div>
    );
  };

  const sourceType = [
    { label: "Whatsapp", value: 0 },
    { label: "İletişim Formu", value: 1 },
    { label: "Telefon", value: 2 },
    { label: "Referans", value: 3 },
    { label: "Sosyal Medya", value: 4 },
  ];
  const statusType = [
    { label: "Beklemede", value: 0 },
    { label: "Onayda", value: 1 },
  ];

  const statusTypeCompleted = [
    { label: "Alındı", value: 3 },
    { label: "Alınamadı", value: 4 },
  ];

  const communicationTemplate = (rowData) => {
    const renderNotificationTags = () => {
      const notifications = rowData?.notification?.split(",")?.filter(Boolean);
      return notifications?.map((notification, index) => (
        <>
          <Tag
            key={`notification_${index}`}
            value={getStatusLabel(notification)}
            severity={getSeverity(notification)}
          />
          <br />
        </>
      ));
    };

    return <div>{renderNotificationTags()}</div>;
  };

  const getStatusLabel = (notification) => {
    switch (notification) {
      case "0":
        return "Sms";

      case "1":
        return "E-Posta";

      case "2":
        return "Whatsapp";

      case "3":
        return "Telefon";

      default:
        return null;
    }
  };

  const getSeverity = (notification) => {
    switch (notification) {
      case "0":
        return "warning";

      case "1":
        return "info";

      case "2":
        return "success";

      case "3":
        return null;

      default:
        return null;
    }
  };

  useEffect(() => {
    MarkettingChaseService().then((data) => {
      setChases(data?.marketting_chases);
      setOldChases(data?.marketting_chases);
      setCustomers(data?.customers);
      setEmployees(data?.employees);
    });
  }, [addModal, selectedData, dialogs, defaultValue]);

  const getWeatherText = (time) => {
    let color;
    let text;

    if (time === 0) {
      color = "#CC0000";
      text = "Sıcak (" + time + ")";
    } else if (time === 1) {
      color = "#FF9966";
      text = "Ilık (" + time + ")";
    } else if (time === 2) {
      color = "#CCCCFF";
      text = "Soğuk (" + time + ")";
    } else {
      color = "#09bef0";
      text = "Buz (" + time + ")";
    }

    return (
      <div
        style={{
          backgroundColor: color,
          color: "white",
          padding: "5px",
          borderRadius: "5px",
          textAlign: "center",
        }}
      >
        {text}
      </div>
    );
  };

  const filterChase = (value) => {
    let matchingCustomers = [];
    let matchingChases = [];
    const lowerCaseValue = value.toLowerCase();

    if (value === "") {
      setChases(oldChases);
    } else {
      if (Array.isArray(customers) && customers?.length > 0) {
        matchingCustomers = customers?.filter(
          (item) =>
            item?.name?.toLowerCase().includes(lowerCaseValue) ||
            item?.title?.toLowerCase().includes(lowerCaseValue) ||
            item?.key_words?.toLowerCase().includes(lowerCaseValue)
        );

        const matchingCustomerIds = matchingCustomers?.map((customer) =>
          parseInt(customer.id, 10)
        );

        matchingChases = chases?.filter((data) =>
          matchingCustomerIds?.includes(parseInt(data.customer, 10))
        );
      }
      setChases(matchingChases);
    }
  };

  return (
    <Flex mt="5" justifyContent="center" alignItems="center">
      <div
        className="card"
        style={{
          overflow: "auto",
          boxSizing: "border-box",
          width: "100%",
          height: "100%",
          position: "relative",
          padding: "1rem",
        }}
      >
        <BreadCrumb
          model={itemsBread}
          home={home}
          style={{ padding: "1rem" }}
        />
        <Card>
          <CardHeader>
            <Flex justifyContent="space-between" alignItems="center">
              <div className="flex align-items-center">
                <RadioButton
                  onChange={() => setTableValue(0)}
                  checked={tableValue === 0}
                />
                <p>Beklemede</p>
                <div style={{ width: "1rem" }} />
                <RadioButton
                  onChange={() => setTableValue(1)}
                  checked={tableValue === 1}
                />{" "}
                <p>Onayda</p>
                <div style={{ width: "1rem" }} />
                <RadioButton
                  onChange={() => setTableValue(2)}
                  checked={tableValue === 2}
                />
                <p>Tamamlandı</p>
              </div>
              <div style={{ display: "flex" }}>
                <span
                  className="p-input-icon-right"
                  style={{
                    border: "solid 1px gray",
                    borderRadius: "6px 6px 6px 6px",
                    padding: "0.2rem",
                  }}
                >
                  <i className="pi pi-search" />
                  <InputText
                    placeholder="Müşteri Ara"
                    onChange={(e) => filterChase(e.target.value)}
                  />
                </span>
                <div style={{ width: "1rem" }} />
                <Button
                  label="Yeni"
                  onClick={() => setAddModal(true)}
                  className="btn-excel-two"
                />
              </div>
            </Flex>
          </CardHeader>
          <Toast ref={toast} />

          <DataTable
            stripedRows
            value={chases?.filter((chase) => {
              const status = Number(chase?.status);
              if (tableValue === 0) {
                return status === 0;
              } else if (tableValue === 1) {
                return status === 1;
              } else if (tableValue === 2) {
                return status !== 0 && status !== 1;
              }
              return false;
            })}
            size="small"
            dataKey="id"
            tableStyle={{ minWidth: "50rem" }}
            rows={15}
            rowsPerPageOptions={[15, 30, 60, 100]}
            scrollable
            paginator
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate=" {totalRecords} üründen {first} - {last} arası gösteriliyor"
          >
            <Column
              field="accessor"
              body={accessors}
              header="İşlemler"
            ></Column>
            {tableValue === 2 && (
              <Column
                field="status"
                body={(rowData) => {
                  const ser = statusTypeCompleted?.find(
                    (dept) => Number(dept?.value) === Number(rowData?.status)
                  );
                  return ser?.label;
                }}
                header="Durum"
              ></Column>
            )}

            {tableValue !== 2 && (
              <Column
                body={(rowData) => {
                  const updatedDate = new Date(rowData.date);
                  const today = new Date();
                  const differenceInTime =
                    today.getTime() - updatedDate.getTime();
                  const differenceInDays =
                    differenceInTime / (1000 * 3600 * 24);
                  return getWeatherText(Math.floor(differenceInDays));
                }}
                header="Geçen Süre"
              ></Column>
            )}

            <Column
              field="employee"
              header="Müşteri Temsilcisi"
              body={(rowData) => {
                const employee = rowData?.employee?.split(",");
                const assignedUsers = employee?.map((emp) => {
                  const user = employees?.find(
                    (usr) => usr.id.toString() === emp.trim()
                  );
                  return user ? user.name : "Bilinmeyen";
                });
                return assignedUsers?.join(", ");
              }}
            ></Column>
            <Column
              field="notification"
              header="İletişim"
              style={{ width: "9rem" }}
              body={communicationTemplate}
            ></Column>
            <Column
              field="customer"
              body={(rowData) => {
                const cust = customers?.find(
                  (dept) => Number(dept.id) === Number(rowData.customer)
                );
                if (cust) {
                  const firstTwoWords =
                    cust?.title &&
                    cust?.title?.split(" ")?.slice(0, 2)?.join(" ");
                  return firstTwoWords;
                } else {
                  return "Bilinmeyen";
                }
              }}
              header="Müşteri(Firma)"
            ></Column>
            <Column
              field="source"
              header="Kaynak"
              body={(rowData) => {
                const ser = sourceType?.find(
                  (dept) => Number(dept?.value) === Number(rowData?.source)
                );
                return ser?.label;
              }}
            ></Column>

            <Column
              field="service"
              body={(rowData) => {
                const serviceIds = rowData?.service?.split(",");

                const services = serviceIds?.map((serviceId) => {
                  const service = serviceType?.find(
                    (item) => item.value === parseInt(serviceId.trim())
                  );
                  return service ? service.label : "Bilinmeyen";
                });

                return services?.join(", ");
              }}
              header="Hizmet Talepleri"
            ></Column>

            <Column field="description" header="Açıklama"></Column>
            <Column
              field="date"
              body={(rowData) => formatDate(rowData.date)}
              header="Tarih"
            ></Column>
          </DataTable>
        </Card>
      </div>
      <Dialog
        visible={addModal}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        style={{ width: "60vw" }}
        header="Seyyar Bilgisi Ekle"
        onHide={hideDialog}
        modal
      >
        <div
          className="confirmation-content "
          style={{
            display: "flex",
          }}
        >
          <div className="col-6" style={{ display: "inline-block" }}>
            <Dropdown
              filter
              options={customers?.map((dep) => ({
                label: dep.title,
                value: dep.id,
              }))}
              value={cus}
              placeholder="Müşteri"
              className="w-full "
              onChange={(e) => {
                setCus(e.target.value);
                setInputErrors({ ...inputErrors, cus: false });
              }}
              style={{
                border: "1px solid gray",
                width: "100%",
              }}
            />
            {inputErrors.cus && (
              <small style={{ color: "red" }}>Lütfen müşteri seçiniz.</small>
            )}
            <Dropdown
              filter
              options={sourceType}
              placeholder="Kaynak"
              value={source}
              className="w-full "
              onChange={(e) => {
                setSource(e.target.value);
                setInputErrors({ ...inputErrors, source: false });
                console.log(e);
              }}
              style={{
                border: "1px solid gray",
                marginTop: "1rem",
              }}
            />
            {inputErrors.source && (
              <small style={{ color: "red" }}>Lütfen kaynak seçiniz.</small>
            )}
            <MultiSelect
              filter
              options={serviceType}
              placeholder="Hizmet Talebi"
              value={service}
              className="w-full "
              onChange={(e) => {
                setService(e.value);
                setInputErrors({ ...inputErrors, service: false });
              }}
              style={{
                border: "1px solid gray",
                marginTop: "1rem",
              }}
            />
            {inputErrors.service && (
              <small style={{ color: "red" }}>
                Lütfen hizmet talebi seçiniz.
              </small>
            )}
            <Calendar
              value={date}
              placeholder="Tarih Seçiniz"
              locale="tr"
              dateFormat="dd/mm/yy"
              onChange={(e) => {
                setDate(e.value);
              }}
              showIcon
              className="p-inputtext-lg w-full "
              icon={() => <i className="pi pi-calendar" />}
              style={{
                marginTop: "1rem",
              }}
            />
          </div>
          <div className="col-6" style={{ display: "inline-block" }}>
            <Dropdown
              className="w-full "
              filter
              options={employees?.map((dep) => ({
                label: dep.name,
                value: dep.id,
              }))}
              value={emp}
              placeholder="Müşteri Temsilcisi "
              onChange={(e) => {
                setEmp(e.target.value);
                setInputErrors({ ...inputErrors, emp: false });
              }}
              style={{
                border: "1px solid gray",
                width: "65%",
              }}
            />
            {inputErrors.emp && (
              <small style={{ color: "red" }}>Lütfen temsilci seçiniz.</small>
            )}
            <Dropdown
              options={statusType}
              placeholder="Durum"
              value={status}
              className="w-full "
              onChange={(e) => {
                setStatus(e.value);
                setInputErrors({ ...inputErrors, status: false });
              }}
              style={{
                border: "1px solid gray",
                marginTop: "1rem",
              }}
            />
            {inputErrors.status && (
              <small style={{ color: "red" }}>Lütfen durum seçiniz.</small>
            )}
            <InputTextarea
              style={{ maxHeight: "6.6rem", marginTop: "1rem" }}
              type="text"
              placeholder="Açıklama"
              rows={3}
              className="p-inputtext-lg w-full "
              value={desc}
              onChange={(e) => {
                setDesc(e.target.value);
                setInputErrors({ ...inputErrors, desc: false });
              }}
            />
            {inputErrors.desc && (
              <small style={{ color: "red" }}>Lütfen açıklama giriniz.</small>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "1rem",
              }}
            >
              <Button
                label="Ekle"
                onClick={newCreate}
                className="btn-excel-two"
              />
            </div>
          </div>
        </div>
      </Dialog>
    </Flex>
  );
}
