import React, { useEffect, useState } from "react";
import { Fieldset } from "primereact/fieldset";
import { Dropdown } from "primereact/dropdown";
import { BsEmojiSmile } from "react-icons/bs";
import { BsCalendar2Event } from "react-icons/bs";
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";
import { InputTextarea } from "primereact/inputtextarea";
import { BsPencilSquare } from "react-icons/bs";
import { FileUpload } from "primereact/fileupload";
import { Box, Button } from "@chakra-ui/react";
import { AddPermissions, UserService } from "../../../../core/Services";
import { useAuth } from "../../../../auth/core/AuthContext";
import { MultiSelect } from "primereact/multiselect";

export default function NewPermissions() {
  const [dates, setDates] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [userAll, setUserAll] = useState([]);
  const [selectedUniqueId, setSelectedUniqueId] = useState([]);
  const [users, setUsers] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [noteContent, setNoteContent] = useState("");
  const [formattedFileContent, setFormattedFileContent] = useState(null);
  const { userData } = useAuth();

  useEffect(() => {
    UserService()
      .then((data) => {
        setUserAll(data.users);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {}, [formattedFileContent]);

  const onUpload = (event) => {
    const uploadedFile = event.files && event.files[0];

    if (uploadedFile) {
      const reader = new FileReader();

      reader.onload = async (e) => {
        const fileContent = e.target.result;

        // Convert file content to Base64
        const formattedContent = btoa(fileContent);

        // Update the state variable with the Base64 content
        await setFormattedFileContent(formattedContent);
      };

      reader.readAsBinaryString(uploadedFile);
    }
  };

  const options = [
    { label: "Yıllık", value: "Yillik" },
    { label: "Mazeret İzni", value: "Mazeret" },
    { label: "Ücretsiz İzin", value: "Ucretsiz Izin" },
    { label: "Diğer İzinler", value: "Diger Izinler" },
  ];

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    return new Date(dateString).toLocaleString("tr-TR", options);
  };

  addLocale("tr", {
    firstDayOfWeek: 1,
    showMonthAfterYear: true,
    dayNames: [
      "pazar",
      "pazartesi",
      "salı",
      "çarşamba",
      "perşembe",
      "cuma",
      "cumartesi",
    ],
    dayNamesShort: ["paz", "pzt", "sal", "çar", "per", "cum", "cmt"],
    dayNamesMin: ["P", "Pzt", "S", "Ç", "P", "C", "Cmt"],
    monthNames: [
      "ocak",
      "şubat",
      "mart",
      "nisan",
      "mayıs",
      "haziran",
      "temmuz",
      "ağustos",
      "eylül",
      "ekim",
      "kasım",
      "aralık",
    ],
    monthNamesShort: [
      "oca",
      "şub",
      "mar",
      "nis",
      "may",
      "haz",
      "tem",
      "ağu",
      "eyl",
      "eki",
      "kas",
      "ara",
    ],
    today: "Bugün",
    clear: "Temizle",
  });

  const newCreate = async () => {
    const formattedStartingDate = formatDate(startDate);
    const formattedRenewalDate = formatDate(endDate);

    // Eğer kullanıcı adminse ve kişi seçilmişse, selectedEmployee olarak users'ı kullan, aksi halde userData?.id'yi kullan
    let selectedEmployee =
      userData?.type === "1" && users ? users : userData?.id;
    let uniqueId = userData?.type === "1" && selectedUniqueId ? selectedUniqueId : userData?.unique_id;
    await AddPermissions(
      selectedEmployee,
      uniqueId,
      selectedType,
      noteContent,
      formattedStartingDate,
      formattedRenewalDate,
      formattedFileContent
    );
    setSelectedType(null);
    setNoteContent("");
    setDates(null);
  };

  return (
    <Box>
      <Fieldset
        legend="İzin Talep Formu"
        style={{ overflowY: "scroll", height: "80vh" }}
      >
        {userData?.type === "1" && (
          <div>
            <div>
              <p
                style={{
                  marginBottom: "0.2rem",
                  marginTop: "2rem",
                  textAlign: "left",
                }}
              >
                Kişi/Kişiler:
              </p>
            </div>
            <div className="p-inputgroup flex-1">
              <span className="p-inputgroup-addon">
                <BsEmojiSmile />
              </span>
              <MultiSelect
                value={users}
                filter
                options={
                  Array.isArray(userAll) && userAll.length > 0
                    ? userAll
                        .filter((user) => user.authorization === "0") // Sadece authorization değeri 0 olanları filtrele
                        .map((user) => ({
                          label: user.name,
                          value: user.id,
                          unique_id: user.unique_id,
                        }))
                    : []
                }
                placeholder="Kişiler"
                className="w-full"
                style={{
                  border: "1px solid gray",
                  width: "100%",
                  marginBottom: "1rem",
                }}
                onChange={(e) => {
                  const selectedValues = e.target.value;
                  const selectedUniqueIds = selectedValues.map(
                    (value) =>
                      userAll.find((user) => user.id === value).unique_id
                  );
                  setUsers(selectedValues);
                  setSelectedUniqueId(selectedUniqueIds);
                }}
              />
            </div>
          </div>
        )}

        <div>
          <p
            style={{
              marginBottom: "0.2rem",
              marginTop: "2rem",
              textAlign: "left",
            }}
          >
            Tip:
          </p>
        </div>
        <div className="p-inputgroup flex-1">
          <span className="p-inputgroup-addon">
            <BsEmojiSmile />
          </span>
          <Dropdown
            filter
            value={selectedType}
            options={options}
            onChange={(e) => setSelectedType(e.value)}
            style={{ textAlign: "left" }}
          />
        </div>
        <div>
          <p
            style={{
              marginBottom: "0.2rem",
              marginTop: "2rem",
              textAlign: "left",
            }}
          >
            İzin Başlangıç Tarihi-İşe Başlama Tarihi:
          </p>
        </div>
        <div className="p-inputgroup flex-1">
          <span className="p-inputgroup-addon">
            <BsCalendar2Event />
          </span>
          <div
            style={{ width: "100%" }}
            className="card flex justify-content-center p-dropdown "
          >
            <Calendar
              value={dates}
              selectionMode="range"
              locale="tr"
              onChange={(e) => {
                const selectedDate = e.value;
                if (selectedDate) {
                  if (selectedDate.length === 1) {
                    // If only one date is selected, set it as the start date
                    setStartDate(selectedDate[0]);
                    setEndDate(null); // Reset end date
                  } else if (selectedDate.length === 2) {
                    // If two dates are selected, set the first as start and the second as end date
                    setStartDate(selectedDate[0]);
                    setEndDate(selectedDate[1]);
                  }
                } else {
                  // If e.value is null, clear the selected dates
                  setStartDate(null);
                  setEndDate(null);
                }
                setDates(selectedDate);
              }}
              dateFormat="dd/mm/yy"
              showButtonBar
              readOnlyInput
            />
          </div>
        </div>

        <div>
          <p
            style={{
              marginBottom: "0.2rem",
              marginTop: "2rem",
              textAlign: "left",
            }}
          >
            Not:
          </p>
        </div>
        <div className="p-inputgroup flex-1">
          <span className="p-inputgroup-addon">
            <BsPencilSquare />
          </span>
          <div
            style={{ width: "100%" }}
            className="card flex justify-content-center "
          >
            <InputTextarea
              value={noteContent}
              onChange={(e) => setNoteContent(e.target.value)}
              rows={5}
              cols={30}
            />
          </div>
        </div>
        <div>
          <p
            style={{
              marginBottom: "0.2rem",
              marginTop: "2rem",
              textAlign: "left",
            }}
          >
            Dosya Ekle(Pdf):
          </p>
          <FileUpload
            chooseLabel="Dosya Seç"
            style={{ textAlign: "left" }}
            accept=".pdf"
            maxFileSize={2000000}
            auto
            pt={{
              content: { className: "surface-ground" },
              message: {
                root: {
                  className: "w-1rem",
                },
              },
            }}
            uploadHandler={onUpload}
            customUpload
          />{" "}
        </div>
        <Button
          backgroundColor="#662549"
          color="white"
          w="20%"
          onClick={newCreate}
        >
          Oluştur
        </Button>
      </Fieldset>
       
    </Box>
  );
}
