import React from "react";
import ViewVisitorAppearance from "./components/Chart";

export default function Page({ customers }) {
  const months = [
    { name: "Ocak", value: "01" },
    { name: "Şubat", value: "02" },
    { name: "Mart", value: "03" },
    { name: "Nisan", value: "04" },
    { name: "Mayıs", value: "05" },
    { name: "Haziran", value: "06" },
    { name: "Temmuz", value: "07" },
    { name: "Ağustos", value: "08" },
    { name: "Eylül", value: "09" },
    { name: "Ekim", value: "10" },
    { name: "Kasım", value: "11" },
    { name: "Aralık", value: "12" },
  ];

  const DataTypes = [
    { name: "Tıklamalar", value: "1" },
    { name: "Gösterim Sayısı", value: "2" },
  ];
  return <ViewVisitorAppearance months={months} DataTypes={DataTypes} />;
}
