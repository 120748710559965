import React, { useState } from "react";
import { Button, Input, useToast } from "@chakra-ui/react";
import { AddMail} from "../../../../../../..//core/Services";
import { Message } from "primereact/message";

export default function MailAccount({ uniqueCustomerId, selectedCustomer , updateTableData }) {
  
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [size, setSize] = useState("");
  const [error, setError] = useState(false);
  const toast = useToast();

  const handleSave = () => {
    if (!selectedCustomer || !mail || !password || !name || !size) {
      setError(true);
      toast({
        title: "Hata",
        description: "Lütfen tüm alanları doldurunuz.",
        status: "error",
        duration: 3000,
        position: "top-right",
        isClosable: true,
      });
      return;
    }
   
    AddMail(uniqueCustomerId, name, mail, password, size)
      .then((response) => {
        if (response.message === "Hata") {
          toast({
            title: "Hata",
            description: "Kullanıcya Ait Bu Ay Verisi Zaten Mevcut",
            status: "error",
            duration: 3000,
            position: "top-right",
            isClosable: true,
          });
        } else {
          setMail("");
          setPassword("");
          setName("");
          setSize("");
          setError(false);

          toast({
            title: "Başarılı",
            description: "Veri başarılı bir şekilde kaydedildi.",
            status: "success",
            duration: 3000,
            position: "top-right",
            isClosable: true,
          });
          updateTableData();
        }
      })
      .catch((error) => {
        console.error("Backend'e gönderme hatası:", error);
        toast({
          title: "Hata",
          description: "Veri gönderilirken bir hata oluştu.",
          status: "error",
          duration: 3000,
          position: "top-right",
          isClosable: true,
        });
      });
  };

  return (
    <div>
      <div>
        <div className="font-bold text-xl mt-5">Ad-Soyad:</div>
        <div className="mt-2">
          <Input
            placeholder="Ad-Soyad Giriniz"
            w="20rem"
            name="value"
            value={name}
            onChange={(e) => setName(e.target.value)}
            borderColor={error && !name ? "red" : ""}
          />
        </div>
      </div>

      <div className="font-bold text-xl mt-5">Mail Giriniz:</div>
      <div className="mt-2">
        <Input
          placeholder="Mail Giriniz"
          w="20rem"
          name="value"
          value={mail}
          onChange={(e) => setMail(e.target.value)}
          borderColor={error && !mail ? "red" : ""}
        />
      </div>

      <div className="font-bold text-xl mt-5">Şifre Giriniz:</div>
      <div className="mt-2">
        <Input
          placeholder="Şifre Giriniz"
          w="20rem"
          name="value"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          borderColor={error && !password ? "red" : ""}
        />
      </div>
      <div className="font-bold text-xl mt-5">Boyut Giriniz:</div>
      <div className="mt-2">
        <Input
          placeholder="Boyut Giriniz"
          w="20rem"
          name="value"
          value={size}
          onChange={(e) => setSize(e.target.value)}
          borderColor={error && !size ? "red" : ""}
        />
      </div>
      <div className="mt-5 flex justify-content-end">
        <Button onClick={handleSave}> Kaydet</Button>
      </div>
      <Message className="mt-4 w-full" severity="info" text="Info Message" />
    </div>
  );
}
