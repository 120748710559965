import React, { useEffect, useState } from "react";
import { Chart } from "primereact/chart";
import { GetViewVisitor } from "../../../../../../../../core/Services";

export default function ViewVisitorAppearance({
  uniqueCustomerId,
  months,
  DataTypes,
  appearanceKey 
}) {
  const [viewVisitor, setViewVisitor] = useState({});
  const [viewVisitorOptions, setViewVisitorOptions] = useState({});

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
      },
    };
    setViewVisitorOptions(options);
  }, []);

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    GetViewVisitor(uniqueCustomerId)
      .then((response) => {
        const data = response.data;
        const orderedLabels = ["1", "2", "3"];
        const datasets = orderedLabels?.map((label) => {
          const name = DataTypes.find((m) => m.value === label);
          const filteredData = data?.filter((item) => item.label === label);
          const values = months?.map((month) => {
            const dataItem = filteredData.find(
              (item) => item.month === month.value
            );
            return dataItem ? parseInt(dataItem.value) : null;
          });
          return {
            label: name ? name.name : "",
            data: values,
            fill: false,
            tension: 0.4,
            borderColor: documentStyle.getPropertyValue(
              name && name.value === "1"
                ? "--blue-500"
                : name && name.value === "2"
                ? "--teal-500"
                : "--orange-500"
            ),
          };
        });
        const newData = {
          labels: months?.map((month) => month.name),
          datasets,
        };
        setViewVisitor(newData);
      })
      .catch((error) => {
        console.log("ViewVisitor Hatası", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uniqueCustomerId, appearanceKey]);

  return (
    <div>
      <div className="mb-3 font-bold ml-5">Görünüm:</div>
      <Chart type="line" data={viewVisitor} options={viewVisitorOptions} />
    </div>
  );
}
