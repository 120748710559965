import { createSlice } from "@reduxjs/toolkit";

export const TaskSlice = createSlice({
  name: "TaskSlice",
  initialState: {
    value: null,
  },
  reducers: {
    addTaskData: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { addTaskData } = TaskSlice.actions;
export default TaskSlice.reducer;
