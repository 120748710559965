import React, { useEffect, useState } from "react";
import NoteSideBar from "./components/NoteSideBar";
import "./css/Note.css";
import NoteMain from "./components/NoteMain";
import { AddNote, DeleteNote, PrivateNote } from "../../../core/Services";
import { useAuth } from "../../../auth/core/AuthContext";

export default function NotePage() {
  const [notes, setNotes] = useState([]);
  const { userData } = useAuth();

  const [activeNote, setActiveNote] = useState(false);
  const onAddNote = async () => {
    try {
      // Yeni notu oluştur
      const newNote = {};

      // AddNote fonksiyonunu çağır ve dönen değeri al
      const returnedNote = await AddNote(userData?.id);

      // Eğer dönen değer varsa, onu newNote'a ata
      if (returnedNote) {
        Object.assign(newNote, returnedNote);
      }

      // Yeni notu notlar listesine ekle
      setNotes([newNote, ...notes]);
    } catch (error) {
      // Hata yönetimi
      console.error("Not ekleme işlemi sırasında bir hata oluştu:", error);
    }
  };

  const onUpdateNote = (updatedNote) => {
    const updatedNotesArray = notes.map((note) => {
      if (note.id === activeNote) {
        return updatedNote;
      }

      return note;
    });

    setNotes(updatedNotesArray);
  };

  const onDeleteNote = async (idToDelete) => {
    try {
      await DeleteNote(idToDelete);

      setNotes(notes.filter((note) => note.id !== idToDelete));
    } catch (error) {
      console.error("Not silinirken bir hata oluştu:", error);
    }
  };

  const getActiveNote = () => {
    return notes?.find((note) => note.id === activeNote);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await PrivateNote(userData?.id);
        setNotes(data);
      } catch (error) {
        console.error("Notlar alınırken hata oluştu:", error);
      }
    };

    fetchData();
  }, [userData]);

  return (
    <div className="App">
      <NoteSideBar
        notes={notes}
        onAddNote={onAddNote}
        onDeleteNote={onDeleteNote}
        activeNote={activeNote}
        setActiveNote={setActiveNote}
      />

      <NoteMain activeNote={getActiveNote()} onUpdateNote={onUpdateNote} />
    </div>
  );
}
