import React, { useEffect, useState } from "react";
import Fullcalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import {
  AddCalendarService,
  CalendarService,
  DeleteCalendarService,
  EditCalendarService,
} from "../../../../core/Services";
import trLocale from "@fullcalendar/core/locales/tr";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Text } from "@chakra-ui/react";
export default function Calendar() {
  const [addModal, setAddModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(false);
  const [subject, setSubject] = useState("");
  const [desc, setDesc] = useState("");
  const [calendar, setCalendar] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [time, setTime] = useState("");
  const [timeTwo, setTimeTwo] = useState("");
  const [editMode, setEditMode] = useState(false);
  let logData = JSON.parse(localStorage.getItem("userData"));

  const newCreate = async () => {
    let requestBody = {
      user_id: logData.id,
      subject,
      desc,
      start_date: selectedDate.startStr,
      end_date: selectedDate.endStr,
      time,
      timeTwo,
    };
    await AddCalendarService(requestBody);
    setAddModal(false);
    setSubject("");
    setDesc("");
    setTime("");
    setTimeTwo("");
  };
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    return new Date(dateString).toLocaleString("tr-TR", options);
  };
  useEffect(() => {
    CalendarService(logData.id).then((data) => {
      setCalendar(data);
    });
  }, [addModal, editModal, logData.id, rowData]);
  return (
    <div style={{ padding: "3rem" }}>
      <Fullcalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        selectable
        editable
        select={(e) => {
          setSelectedDate(e);
          setAddModal(true);
        }}
        locale={trLocale}
        eventResize={(e) => {
          let data = {
            cal_id: e.event._def.extendedProps.cal_id,
            start: e.event._instance.range.start,
            end: e.event._instance.range.end,
          };
          EditCalendarService(data);
        }}
        eventDrop={(e) => {
          let data = {
            cal_id: e.event._def.extendedProps.cal_id,
            start: e.event._instance.range.start,
            end: e.event._instance.range.end,
          };
          EditCalendarService(data);
        }}
        events={calendar}
        eventColor="#ae445a"
        eventClick={(e) => {
          setEditModal(true);
          setRowData(e.event._def.extendedProps);
        }}
        initialView={"dayGridMonth"}
        headerToolbar={{
          start: "today prev,next", // will normally be on the left. if RTL, will be on the right
          center: "title",
          end: "dayGridMonth,timeGridWeek,timeGridDay", // will normally be on the right. if RTL, will be on the left
        }}
        height={"90vh"}
      />
      <Dialog
        header="Etkinlik Ekle"
        visible={addModal}
        style={{ width: "40vw" }}
        onHide={() => setAddModal(false)}
        footer={
          <div>
            <Button
              label="İptal"
              icon="pi pi-times"
              onClick={() => setAddModal(false)}
              className="btn-excel-two"
            />
            <Button
              label="Ekle"
              icon="pi pi-check"
              onClick={() => {
                newCreate();
                setAddModal(false);
              }}
              className="btn-excel-two"
              style={{ marginLeft: "15px" }}
            />
          </div>
        }
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          {formatDate(selectedDate.startStr)} ---
          {formatDate(selectedDate.endStr)}
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <InputText
            type="text"
            className="p-inputtext-lg"
            placeholder="Başlangıç Saati (HH:MM)"
            onChange={(e) => {
              const input = e.target.value;
              if (/^[0-9:]*$/.test(input)) {
                setTime(input);
              }
            }}
            value={time}
            style={{ marginTop: "1rem", marginRight: "1.4rem" }}
          />
          <InputText
            type="text"
            className="p-inputtext-lg"
            placeholder="Bitiş Saati (HH:MM)"
            onChange={(e) => {
              const input = e.target.value;
              if (/^[0-9:]*$/.test(input)) {
                setTimeTwo(input);
              }
            }}
            value={timeTwo}
            style={{ marginTop: "1rem" }}
          />
        </div>
        <InputText
          type="text"
          className="p-inputtext-lg w-full"
          placeholder="Konu"
          onChange={(e) => setSubject(e.target.value)}
          value={subject}
          style={{ marginTop: "1rem" }}
        />
        <InputTextarea
          type="text"
          rows={5}
          cols={30}
          className="p-inputtext-lg w-full"
          placeholder="Açıklama"
          onChange={(e) => setDesc(e.target.value)}
          value={desc}
          style={{ marginTop: "1rem" }}
        />
      </Dialog>
      <Dialog
        visible={editModal}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        style={{ width: "60vw" }}
        header={
          <Text>
            {rowData.subject} ({rowData.time_start} - {rowData.time_end})
          </Text>
        }
        onHide={() => {
          setEditModal(false);
          setEditMode(false);
        }}
        modal
        footer={
          <div>
            <Button
              label="İptal"
              icon="pi pi-times"
              onClick={() => {
                setEditMode(false);
                setEditModal(false);
              }}
              className="btn-excel-two"
            />
            <Button
              label="Sil"
              icon="pi pi-check"
              onClick={() => {
                DeleteCalendarService(rowData.cal_id);
                setEditModal(false);
                setEditMode(false);
              }}
              className="btn-excel-two"
              style={{ marginLeft: "15px" }}
            />
            {editMode ? (
              <Button
                label="Kaydet"
                icon="pi pi-pencil"
                onClick={() => {
                  let data = {
                    cal_id: rowData.cal_id,
                    subject,
                    desc,
                    time,
                    timeTwo,
                  };
                  EditCalendarService(data);
                  setEditModal(false);
                }}
                className="btn-excel-two"
                style={{ marginLeft: "15px" }}
              />
            ) : (
              <Button
                label="Düzenle"
                icon="pi pi-pencil"
                onClick={() => {
                  setEditMode(true);
                  setSubject(rowData.subject);
                  setTime(rowData.time_start);
                  setTimeTwo(rowData.time_end);
                  setDesc(rowData.description);
                }}
                className="btn-excel-two"
                style={{ marginLeft: "15px" }}
              />
            )}
          </div>
        }
      >
        <div className="confirmation-content">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {editMode ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <InputText
                    type="text"
                    className="p-inputtext-lg"
                    placeholder="Başlangıç Saati (HH:MM)"
                    onChange={(e) => {
                      const input = e.target.value;
                      if (/^[0-9:]*$/.test(input)) {
                        setTime(input);
                      }
                    }}
                    value={time}
                    style={{ marginTop: "1rem", marginRight: "1.4rem" }}
                  />
                  <InputText
                    type="text"
                    className="p-inputtext-lg"
                    placeholder="Bitiş Saati (HH:MM)"
                    onChange={(e) => {
                      const input = e.target.value;
                      if (/^[0-9:]*$/.test(input)) {
                        setTimeTwo(input);
                      }
                    }}
                    value={timeTwo}
                    style={{ marginTop: "1rem" }}
                  />
                </div>
                <InputText
                  type="text"
                  className="p-inputtext-lg w-full"
                  placeholder="Konu"
                  onChange={(e) => setSubject(e.target.value)}
                  value={subject}
                  style={{ marginTop: "1rem" }}
                />
                <InputTextarea
                  rows={5}
                  cols={30}
                  className="p-inputtext-lg w-full"
                  placeholder="Açıklama"
                  onChange={(e) => setDesc(e.target.value)}
                  value={desc}
                  style={{ marginTop: "1rem" }}
                />
              </div>
            ) : (
              <text>{rowData.description}</text>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
}
