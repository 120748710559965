import React, { useEffect, useRef, useState } from "react";
import {
  ManufacturingService,
  addManufacturing,
  deleteManufacturing,
  updateManufacturing,
} from "../../../../core/Services";
import { Card, CardHeader, Flex, Heading } from "@chakra-ui/react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { addLocale } from "primereact/api";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { BreadCrumb } from "primereact/breadcrumb";
import { InputNumber } from "primereact/inputnumber";
import { BsFilePdf } from "react-icons/bs";
import { Chips } from "primereact/chips";
import { useAuth } from "../../../../auth/core/AuthContext";
import paymentStatus from "../../../../../_ekdizayn/api/payment-status.json";
import statusType from "../../../../../_ekdizayn/api/manufacturing-status-type.json";
import currencyType from "../../../../../_ekdizayn/api/currency-type.json";
export default function ManufacturingPage() {
  const [manufacturing, setManufacturing] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const [products, setProducts] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [quantity, setQuantity] = useState("");
  const [saleAmount, setSaleAmount] = useState("");
  const [specifications, setSpecifications] = useState([]);
  const [collection, setCollection] = useState("");
  const [invoice, setInvoice] = useState("");
  const [history, setHistory] = useState("");
  const [searchStatusType, setSearchStatusType] = useState("");
  const [constituent, setConstituent] = useState("");
  const [piece, setPiece] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [productName, setProductName] = useState("");
  const [manufacturingsCode, setManufacturingsCode] = useState("");
  const [cus, setCus] = useState("");
  const [product, setProduct] = useState("");
  const [unit, setUnit] = useState("");
  const [features, setFeatures] = useState([]);
  const [supplier, setSupplier] = useState("");
  const [bill, setBill] = useState("");
  const [moneyType, setMoneyType] = useState("");
  const [status, setStatus] = useState("");
  const [payment, setPayment] = useState("");
  const [date, setDate] = useState(null);
  const [cost, setCost] = useState(null);
  const [sale, setSale] = useState(null);
  const [dialogs, setDialogs] = useState({});
  const [selectedId, setSelectedId] = useState(null);
  const [displayDeleteDialog, setDisplayDeleteDialog] = useState(false);
  const [pdfViewingModal, setPdfViewingModal] = useState(false);
  const [selectedManu, setSelectedManu] = useState(null);
  const { userData } = useAuth();
  const toast = useRef(null);
  const [inputErrors, setInputErrors] = useState({
    date: false,
    cus: false,
    product: false,
    quantity: false,
    unit: false,
    supplier: false,
    cost: false,
    sale: false,
    bill: false,
    status: false,
    payment: false,
  });

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    return new Date(dateString).toLocaleString("tr-TR", options);
  };
  addLocale("tr", {
    firstDayOfWeek: 1,
    showMonthAfterYear: true,
    dayNames: [
      "pazar",
      "pazartesi",
      "salı",
      "çarşamba",
      "perşembe",
      "cuma",
      "cumartesi",
    ],
    dayNamesShort: ["paz", "pzt", "sal", "çar", "per", "cum", "cmt"],
    dayNamesMin: ["P", "Pzt", "S", "Ç", "P", "C", "Cmt"],
    monthNames: [
      "ocak",
      "şubat",
      "mart",
      "nisan",
      "mayıs",
      "haziran",
      "temmuz",
      "ağustos",
      "eylül",
      "ekim",
      "kasım",
      "aralık",
    ],
    monthNamesShort: [
      "oca",
      "şub",
      "mar",
      "nis",
      "may",
      "haz",
      "tem",
      "ağu",
      "eyl",
      "eki",
      "kas",
      "ara",
    ],
    today: "Bugün",
    clear: "Temizle",
  });

  const isButtonDisable = false;

  const hideDialog = () => {
    setSupplier("");
    setHistory("");
    setBill("");
    setStatus("");
    setInvoice("")
    setSaleAmount("");
    setSpecifications(null);
    setCollection("");
    setPiece("")
    setPayment("");
    setCost(null);
    setProductName("");
    setCompanyName("");
    setFeatures(null);
    setSupplierName("");
    setConstituent("");
    setSale(null);
    setMoneyType("");
    setQuantity("");
    setUnit("");
    setFeatures("");
    setCus("");
    setDate(null);
    setProduct("");
    setAddModal(false);
    setPdfViewingModal(false);
  };

  const handleCompleteManu = async () => {
    try {
      if (!selectedManu) {
        console.error("Düzenlenecek görev bulunamadı.");
        return;
      }

      const formattedDate = formatDate(date);
      await updateManufacturing(
        selectedManu.id,
        formattedDate,
        cus,
        product,
        quantity,
        unit,
        features,
        supplier,
        cost,
        sale,
        bill,
        status,
        payment
      );

      setDialogs(false);
    } catch (error) {
      console.error("Hata:", error.message);
    }
  };

  const hideDeleteDialog = () => {
    setDisplayDeleteDialog(false);
    setFeatures(null)
    setSelectedId(null);
  };
  const handleDeletedManufacturing = async () => {
    await deleteManufacturing(selectedId);
    setDisplayDeleteDialog(false);
    setSelectedId(null);
  };

  const EditBodyTemplate = (tasks) => {
    const isVisible = dialogs[tasks.id] || false;
    const viewingModal = pdfViewingModal[tasks.id] || false;
    useEffect(() => {
      if (isVisible) {
        setQuantity(tasks.quantity || "");

        setCus(
          customerList.find((dept) => dept.id.toString() === tasks.customer)
            ?.id || ""
        );
        setProduct(
          products.find((pro) => pro.id.toString() === tasks.product)?.id || ""
        );
        setSupplier(
          supplierList.find((sup) => sup.id.toString() === tasks.supplier)
            ?.id || ""
        );
        setStatus(tasks?.status || "");
        setPayment(tasks?.payment || "");
        setCost(tasks?.cost_amount || "");
        setSale(tasks?.sales_amount || "");
        setBill(tasks?.bill || "");
        setUnit(tasks?.unit || "");

        const formattedFeatures = tasks.features.split(", ");
        setFeatures(formattedFeatures);

        const startingDateValue = tasks.starting_date
          ? new Date(tasks.starting_date)
          : new Date();
        setDate(startingDateValue);
      }
    }, [isVisible, tasks]);
    const modalContentRef = useRef(null);
    const printModalContent = () => {
      const content = modalContentRef.current.innerHTML;
      const printWindow = window.open("", "", "width=800,height=800");
      printWindow.document.write(`
        <html>
          <head>
            <style>
              body {
                margin: 0;
                padding: 0;
                font-family: Arial, sans-serif;
                font-size: 12px;
              }
              #footer {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
              }
              @media print {
                body {
                  size: A4;
                }
              }
            </style>
          </head>
          <body>
            ${content}
          </body>
        </html>
      `);
      printWindow.print();
      printWindow.close();
       setSpecifications(null);
       setInvoice("");        
      setCollection("");
      setHistory("");
      setSaleAmount("")
      setProductName("");
      setSupplierName("");
      setCompanyName("");
    };

    return (
      <React.Fragment>
        <div style={{ display: "flex" }}>
          <div style={{ color: "#f39f5a" }}>
            <Button
              severity="warning"
              onClick={() => {
                setSelectedManu(tasks);
                setDialogs((prevDialogs) => ({
                  ...prevDialogs,
                  [tasks.id]: true,
                }));
              }}
              icon="pi pi-pencil"
            />
          </div>
          <div style={{ color: "#ae445a" }}>
            <Button
              icon="pi pi-trash"
              onClick={() => {
                setManufacturingsCode(tasks.manufacturings_code);
                setSelectedId(tasks.id);
                setCus(tasks.cus);
                setDisplayDeleteDialog(true);
              }}
              severity="danger"
            />
          </div>
          <div style={{ color: "#ae445a" }}>
            <Button
              icon=<BsFilePdf size="17" />
              onClick={() => {
                setManufacturingsCode(tasks?.manufacturings_code);
                setSelectedId(tasks?.id);
                setCus(tasks?.cus);
                setSpecifications(tasks?.features)
                setFeatures(tasks?.features);
                setInvoice(tasks?.bill);
                setHistory(tasks?.date);
                setPiece(tasks.quantity)
                setSaleAmount(tasks?.sales_amount);
                setPdfViewingModal((prevDialogs) => ({
                  ...prevDialogs,
                  [tasks.id]: true,
                }));
              }}
            />
          </div>
        </div>
        <Dialog
          visible={viewingModal}
          style={{ width: "50vw" }}
          onHide={() => {
            setPdfViewingModal((prevDialogs) => ({
              ...prevDialogs,
              [tasks.id]: false,
            }));
            setSelectedManu(null);
            hideDialog();
          }}
          footer={
            <div>
              <Button
                label="PDF'E DÖNÜŞTÜR"
                icon="pi pi-check"
                onClick={printModalContent}
                className="btn-excel-two"
              />
            </div>
          }
        >
          <div ref={modalContentRef} className="confirmation-content">
            <div
              className="font-bold mb-2"
              style={{
                display: "flex",
                justifyContent: "center",
                color: "red",
              }}
            ></div>

            <div className="field">
              <label htmlFor="name" className="font-bold">
                İş Emri No: {manufacturingsCode}
              </label>
            </div>
            <div className="field">
              <label htmlFor="description" className="font-bold">
                Üretimi Oluşturan : {constituent}
              </label>
            </div>

            <div className="field">
              <label className="mb-3 font-bold">Firma Adı: {companyName}</label>
            </div>
            <div className="field">
              <label className="mb-3 font-bold">Ürün: {productName}</label>
            </div>
            <div className="field">
              <label className="mb-3 font-bold">Adet: {piece}</label>
            </div>
            <div className="field">
              <label className="mb-3 font-bold">Özellikler: {specifications}</label>
            </div>
            <div className="field">
              <label className="mb-3 font-bold">
                Tedarikçi: {supplierName}
              </label>
            </div>
            <div className="field">
              <label className="mb-3 font-bold">Satış Tutarı: {saleAmount}</label>
            </div>
            <div className="field">
              <label className="mb-3 font-bold">Fatura: {invoice}</label>
            </div>
            <div className="field">
              <label className="mb-3 font-bold">Tahsilat: {collection}</label>
            </div>
            <div className="field">
              <label className="mb-3 font-bold">Tarih: {history}</label>
            </div>
          </div>
        </Dialog>
        <Dialog
          header="İçerik Düzenleme"
          visible={isVisible}
          style={{ width: "50vw" }}
          onHide={() => {
            setDialogs((prevDialogs) => ({
              ...prevDialogs,
              [tasks.id]: false,
            }));
            setSelectedManu(null);
            hideDialog();
          }}
          footer={
            <div>
              <Button
                label="Kaydet"
                icon="pi pi-check"
                onClick={handleCompleteManu}
                className="btn-excel-two"
              />
            </div>
          }
        >
          <div className="confirmation-content" style={{ display: "flex" }}>
            <div className="col-6" style={{ display: "inline-block" }}>
              <p>İlk Kayıt Tarihi</p>
              <Calendar
                placeholder="İlk Kayıt Tarihi"
                locale="tr"
                style={{
                  border: "1px solid gray",
                  padding: "0.5rem",
                  width: "100%",
                  marginBottom: "0.3rem",
                  borderRadius: "6px 6px 6px 6px",
                }}
                value={date}
                onChange={(e) => setDate(e.value)}
                dateFormat="dd/mm/yy"
                showTime={false}
              />
              <p>Müşteri Adı</p>
              <Dropdown
                options={customerList?.map((dep) => ({
                  label: dep.title,
                  value: dep.id,
                }))}
                value={cus}
                filter
                placeholder="Müşteri Adı "
                onChange={(e) => setCus(e.target.value)}
                style={{
                  border: "1px solid gray",
                  width: "100%",
                  marginBottom: "0.3rem",
                }}
              />
              <p>Ürün</p>
              <Dropdown
                options={products?.map((pro) => ({
                  label: pro.name,
                  value: pro.id,
                }))}
                value={product}
                filter
                placeholder="Ürün "
                onChange={(e) => setProduct(e.target.value)}
                style={{
                  border: "1px solid gray",
                  width: "100%",
                  marginBottom: "0.3rem",
                }}
              />
              <p>Adet Giriniz</p>
              <InputNumber
                className="p-component-custom w-full"
                style={{
                  marginBottom: "0.3rem",
                  padding: "1rem",
                }}
                placeholder="Adet Giriniz"
                value={quantity}
                onValueChange={(e) => setQuantity(e.target.value)}
                mode="decimal"
                min={0}
              />
              <p>Birim</p>
              <InputText
                type="text"
                className="p-inputtext-lg w-full"
                placeholder="Birim"
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
                style={{
                  marginBottom: "0.3rem",
                }}
              />
              <p>Özellikler</p>
              <Chips
                value={features}
                className="p-inputtext"
                onChange={handleFeatureAdd}
                placeholder="Özellikler"
                style={{
                  width: "85%",
                  marginTop: "1rem",
                  border: "1px solid ",
                }}
              />
            </div>
            <div className="col-6" style={{ display: "inline-block" }}>
              <p className="pt-2">Tedarikçi</p>
              <Dropdown
                options={supplierList?.map((sup) => ({
                  label: sup.company,
                  value: sup.id,
                }))}
                value={supplier}
                placeholder="Tedarikçi"
                onChange={(e) => {
                  setSupplier(e.value);
                  setInputErrors({ ...inputErrors, supplier: false });
                }}
                style={{
                  border: "1px solid gray",
                  width: "85%",
                  marginTop: "0.5rem",
                }}
              />
              <p>Maliyet Tutarı Giriniz</p>
              <InputNumber
                className="p-component-custom w-full"
                style={{
                  padding: "0.5rem",
                  marginBottom: "0.3rem",
                  border: "1px solid gray",
                }}
                placeholder="Maliyet Tutarı Giriniz"
                value={cost}
                onValueChange={(e) => setCost(e.target.value)}
                mode="decimal"
                min={0}
              />
              <p>Satış Tutarı Giriniz</p>
              <InputNumber
                className="p-component-custom w-full"
                style={{
                  padding: "0.5rem",
                  marginBottom: "0.3rem",
                  border: "1px solid gray",
                  width: "70%",
                }}
                placeholder="Satış Tutarı Giriniz"
                value={sale}
                onValueChange={(e) => setSale(e.target.value)}
                mode="decimal"
                min={0}
              />
              <p>Fatura</p>
              <InputText
                type="text"
                className="p-inputtext-lg"
                placeholder="Fatura"
                style={{
                  width: "100%",
                  padding: "0.5rem",
                  marginBottom: "0.3rem",
                }}
                value={bill}
                onChange={(e) => setBill(e.target.value)}
              />
              <p>Durum</p>
              <Dropdown
                options={statusType?.map((status) => ({
                  label: status.label,
                  value: status.value,
                }))}
                placeholder="Durum"
                value={status}
                onChange={(e) => setStatus(e.value)}
                style={{
                  border: "1px solid gray",
                  width: "100%",
                  marginBottom: "0.3rem",
                }}
              />
              Ödeme Durumu
              <Dropdown
                options={paymentStatus}
                placeholder="Ödeme Durumu"
                value={payment}
                onChange={(e) => setPayment(e.target.value)}
                style={{
                  border: "1px solid gray",
                  width: "85%",
                }}
              />
            </div>
          </div>
        </Dialog>
      </React.Fragment>
    );
  };

  const newCreate = async () => {
    if (
      !date ||
      !cus ||
      !product ||
      !quantity ||
      !unit ||
      !supplier ||
      !cost ||
      !sale ||
      !bill ||
      !status ||
      !payment
    ) {
      setInputErrors({
        date: !date,
        cus: !cus,
        product: !product,
        quantity: !quantity,
        unit: !unit,
        supplier: !supplier,
        cost: !cost,
        sale: !sale,
        bill: !bill,
        status: !status,
        payment: !payment,
      });
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Lütfen boş kısımları doldurun.",
      });
      return;
    }

    const formattedDate = formatDate(date);

    await addManufacturing(
      formattedDate,
      cus,
      product,
      quantity,
      unit,
      features,
      supplier,
      cost,
      sale,
      bill,
      status,
      payment,
      userData?.id
    );

    setAddModal(false);
    setQuantity("");
    setBill("");
    setSupplier("");
    setMoneyType("");
    setStatus("");
    setPayment("");
    setCost(null);
    setSale(null);
    setUnit("");
    setCus("");
    setProduct("");
    setDate(null);
    setAddModal(false);
  };

  useEffect(() => {
    ManufacturingService()
      .then((data) => {
        setManufacturing(data?.manufacturing);
        setCustomerList(data?.customers);
        setProducts(data?.products);
        setSupplierList(data?.supplier);
        setEmployees(data?.employees);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  }, [dialogs, addModal]);

  const getTurkishLabel = (url) => {
    switch (url) {
      case "/manufacturing":
        return "Üretim";
      default:
        return "Sayfa";
    }
  };
  const url = "/manufacturing";
  const turkishLabel = getTurkishLabel(url); //

  const itemsBread = [{ label: turkishLabel, url: url }];
  const home = { icon: "pi pi-home", url: "/dashboard" };

  const getStatusInfo = (status) => {
    let statusLabel, statusColor;

    switch (status) {
      case "0":
        statusLabel = "Beklemede";
        statusColor = "red";
        break;
      case "1":
        statusLabel = "İşlemde";
        statusColor = "purple";
        break;
      case "2":
        statusLabel = "Tamamlandı";
        statusColor = "green";
        break;
      case "3":
        statusLabel = "İptal/İade";
        statusColor = "blue";
        break;

      default:
        statusLabel = "Bilinmeyen Durum";
        statusColor = "black";
    }

    return { statusLabel, statusColor };
  };
  //   const handleAddFeature = () => {
  //   if (newFeature.trim()) {
  //     setFeatures([...features, newFeature.trim()]);
  //     setNewFeature('');
  //   }
  // };
  const handleFeatureAdd = (e) => {
    setFeatures(e.value);
  };
  const handleStatusFilterChange = (e) => {
    setSearchStatusType(e.value); // Duruma göre filtre değerini güncelle
  };

  // Duruma göre filtrelenmiş veri
  const filteredData = manufacturing.filter((item) => {
    return searchStatusType === "" || item.status === searchStatusType;
  });

  return (
    <Flex mt="5" justifyContent="center" alignItems="center">
      <div
        className="card"
        style={{
          overflow: "auto",
          boxSizing: "border-box",
          width: "100%",
          height: "100%",
          position: "relative",
          padding: "1rem",
        }}
      >
        <Card>
          <BreadCrumb
            model={itemsBread}
            home={home}
            style={{ padding: "1rem" }}
          />
          <CardHeader>
            <Flex justifyContent="space-between" alignItems="center">
              <Heading size="md">Üretim</Heading>
              <div className="flex justify-content-center">
                <div className=" mr-3">
                  <Dropdown
                    filter
                    value={searchStatusType}
                    showClear
                    options={statusType}
                    placeholder="Duruma Göre Filtrele"
                    className="w-full "
                    style={{
                      border: "1px solid gray",
                      width: "100%",
                      marginBottom: "1rem",
                    }}
                    onChange={handleStatusFilterChange}
                  />
                </div>
                <div>
                  <Button
                    label="Yeni"
                    onClick={() => setAddModal(true)}
                    className="btn-excel-two"
                  />
                </div>
              </div>
            </Flex>
          </CardHeader>
          <DataTable
            stripedRows
            size="small"
            dataKey="id"
            tableStyle={{ minWidth: "50rem" }}
            rows={15}
            value={filteredData}
            rowsPerPageOptions={[15, 30, 60, 100]}
            scrollHeight="65vh"
            scrollable
            paginator
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate=" {totalRecords} üretimden {first} - {last} arası gösteriliyor"
          >
            <Column body={EditBodyTemplate} header="İşlemler"></Column>

            <Column field="manufacturings_code" header="İş Emri No"></Column>
            <Column
              field="constituent"
              header="Üretimi Oluşturan"
              body={(rowData) => {
                const constituent = employees.find(
                  (emp) => emp.id.toString() === rowData.constituent
                );
                setConstituent(constituent?.name);
                return constituent ? constituent.name : "Bilinmeyen";
              }}
            ></Column>
            <Column
              field="customer"
              body={(rowData) => {
                const customer = customerList.find(
                  (dept) => dept.id.toString() === rowData.customer
                );
                if (customer) {
                  const titleWords = customer.title.split(" ");
                  if (titleWords.length > 1) {
                    return titleWords[0] + " " + titleWords[1];
                  } else {
                    setCompanyName(customer?.title);
                    return customer.title;
                  }
                } else {
                  return "Bilinmeyen Müşteri";
                }
              }}
              header="Firma Adı"
            ></Column>
            <Column
              field="product"
              header="Ürün"
              body={(rowData) => {
                const prod = products.find(
                  (pro) => pro.id.toString() === rowData.product
                );
                setProductName(prod?.name);
                return prod ? prod.name : "Bilinmeyen";
              }}
            ></Column>
            <Column field="quantity" header="Adet"></Column>
            <Column field="features" header="Özellikler"></Column>
            <Column
              field="supplier"
              body={(rowData) => {
                const supplier = supplierList?.find(
                  (sup) => sup.id.toString() === rowData.supplier
                );
                if (supplier) {
                  setSupplierName(supplier?.company);
                  return supplier?.company;
                } else {
                  return "Bilinmeyen Tedarikçi";
                }
              }}
              header="Tedarikçi"
            ></Column>
            <Column
              field="cost_amount"
              body={(rowData) => {
                const prod = products?.find(
                  (pro) => pro.id.toString() === rowData.product
                );
                const currencySymbol =
                  currencyType.find((item) => item.value === prod?.currency)
                    ?.label || "₺";
                return rowData?.cost_amount + currencySymbol;
              }}
              header="Maliyet Tutarı"
            />

            <Column
              field="sales_amount"
              header="Satış Tutarı"
              body={(rowData) => {
                const prod = products?.find(
                  (pro) => pro.id.toString() === rowData.product
                );
                const currencySymbol =
                  currencyType.find((item) => item.value === prod?.currency)
                    ?.label || "₺";
                return rowData?.sales_amount + currencySymbol;
              }}
            ></Column>
            <Column
              field="profit_loss"
              header="Kar / Zarar"
              body={(rowData) => {
                const salesAmount = parseFloat(rowData.sales_amount) || 0;
                const costAmount = parseFloat(rowData.cost_amount) || 0;
                const profitLoss = salesAmount - costAmount;
                const prod = products?.find(
                  (pro) => pro.id.toString() === rowData.product
                );
                const currencySymbol =
                  currencyType.find((item) => item.value === prod?.currency)
                    ?.label || "₺";
                return profitLoss + " " + currencySymbol;
              }}
            />

            <Column field="bill" header="Fatura"></Column>
            <Column
              field="payment"
              header="Tahsilat"
              body={(rowData) => {
                const payment = paymentStatus?.find(
                  (pay) => pay.value.toString() === rowData.payment
                );
                if (payment) {
                  setCollection(payment?.label);
                  return payment?.label;
                } else {
                  return "Bilinmeyen Tahsilat";
                }
              }}
            ></Column>
            <Column field="date" header="Tarih"></Column>
            <Column
              field="status"
              header="Durumu"
              body={(rowData) => {
                const { statusLabel, statusColor } = getStatusInfo(
                  rowData.status
                );
                return (
                  <span
                    style={{
                      backgroundColor: statusColor,
                      color: "white",
                      padding: "0.5rem",
                      borderRadius: "4px",
                    }}
                  >
                    {statusLabel}
                  </span>
                );
              }}
            ></Column>
          </DataTable>
        </Card>
      </div>
      <Dialog
        visible={addModal}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        style={{ width: "50%" }}
        header="Üretim Ekle"
        onHide={hideDialog}
        modal
      >
        <div className="confirmation-content" style={{ display: "flex" }}>
          <div className="col-6" style={{ display: "inline-block" }}>
            <div>
              <Calendar
                placeholder="İlk Kayıt Tarihi"
                locale="tr"
                style={{
                  border: "1px solid gray",
                  padding: "0.5rem",
                  width: "85%",
                  marginBottom: "1rem",
                  borderRadius: "6px 6px 6px 6px",
                }}
                value={date}
                onChange={(e) => {
                  setDate(e.value);
                  setInputErrors({ ...inputErrors, date: false });
                }}
                dateFormat="dd/mm/yy"
                showTime={false}
              />
              <div>
                {inputErrors.date && (
                  <small style={{ color: "red" }}>Lütfen tarih seçiniz.</small>
                )}
              </div>
            </div>
            <div>
              <Dropdown
                options={customerList?.map((dep) => ({
                  label: dep.title,
                  value: dep.id,
                }))}
                value={cus}
                filter
                placeholder="Müşteri Adı "
                onChange={(e) => {
                  setCus(e.target.value);
                  setInputErrors({ ...inputErrors, cus: false });
                }}
                style={{
                  border: "1px solid gray",
                  width: "85%",
                }}
              />
              <div>
                {inputErrors.cus && (
                  <small style={{ color: "red" }}>
                    Lütfen müşteri seçiniz.
                  </small>
                )}
              </div>
            </div>
            <div>
              <Dropdown
                options={products?.map((pro) => ({
                  label: pro.name,
                  value: pro.id,
                }))}
                value={product}
                filter
                placeholder="Ürün "
                onChange={(e) => {
                  const selectedProductId = e.target.value;
                  const selectedProduct = products?.find(
                    (pro) => pro.id === selectedProductId
                  );
                  if (selectedProduct) {
                    setProduct(selectedProduct.id);
                    setUnit(selectedProduct.unit);
                    const selectedCurrencyType = currencyType?.find(
                      (currency) => currency.value === selectedProduct.currency
                    );
                    if (selectedCurrencyType) {
                      setMoneyType(selectedCurrencyType.label);
                    } else {
                      setMoneyType("");
                      console.error("Uygun para birimi simgesi bulunamadı!");
                    }
                  }
                  setInputErrors({ ...inputErrors, product: false });
                }}
                style={{
                  border: "1px solid gray",
                  width: "85%",
                  marginTop: "1rem",
                }}
              />
              <div>
                {inputErrors.product && (
                  <small style={{ color: "red" }}>Lütfen ürün seçiniz.</small>
                )}
              </div>
            </div>
            <div>
              <InputNumber
                className="p-component-custom"
                style={{ marginTop: "1rem", padding: "0.5rem", width: "60%" }}
                placeholder="Adet Giriniz"
                value={quantity}
                onValueChange={(e) => {
                  setQuantity(e.target.value);
                  setInputErrors({ ...inputErrors, quantity: false });
                }}
                mode="decimal"
                min={0}
              />

              <InputText
                type="text"
                className="p-inputtext-lg"
                onChange={(e) => setUnit(e.target.value)}
                placeholder="Birim"
                style={{
                  width: "20%",
                  height: "13%",
                  marginTop: "1rem",
                  marginLeft: "1rem",
                }}
                value={unit}
              />
              <div>
                {inputErrors.quantity && (
                  <small style={{ color: "red" }}>Lütfen adet giriniz.</small>
                )}
              </div>
            </div>
            <Chips
              value={features}
              className="p-inputtext"
              onChange={handleFeatureAdd}
              placeholder="Özellikler"
              style={{ width: "85%", marginTop: "1rem", border: "1px solid " }}
            />
            {/* <InputText
              value={newFeature}
              className="p-inputtext-lg"
              onChange={(e) => setNewFeature(e.target.value)}
              placeholder="Yeni Özellik Ekle"
              style={{ width: "85%", marginTop: "0.5rem" }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleAddFeature();
                }
              }}
            /> */}
          </div>
          <div className="p-col-6" style={{ display: "inline-block" }}>
            <div>
              <Dropdown
                options={supplierList?.map((sup) => ({
                  label: sup.company,
                  value: sup.id,
                }))}
                value={supplier}
                placeholder="Tedarikçi"
                onChange={(e) => {
                  setSupplier(e.value);
                  setInputErrors({ ...inputErrors, supplier: false });
                }}
                style={{
                  border: "1px solid gray",
                  width: "85%",
                  marginTop: "0.5rem",
                }}
              />
              <div>
                {inputErrors.supplier && (
                  <small style={{ color: "red" }}>
                    Lütfen tedarikçi seçiniz.
                  </small>
                )}
              </div>
            </div>
            <div>
              <InputNumber
                className="p-component-custom"
                style={{ marginTop: "1rem", padding: "0.5rem", width: "60%" }}
                placeholder="Maliyet Tutarı Giriniz"
                value={cost}
                onValueChange={(e) => {
                  setCost(e.target.value);
                  setInputErrors({ ...inputErrors, cost: false });
                }}
                mode="decimal"
                min={0}
              />

              <InputText
                type="text"
                className="p-inputtext-lg"
                placeholder="Birim"
                style={{
                  width: "20%",
                  height: "15%",
                  marginTop: "1rem",
                  marginLeft: "1rem",
                }}
                value={moneyType}
              />
              <div>
                {inputErrors.cost && (
                  <small style={{ color: "red" }}>
                    Lütfen maliyet giriniz.
                  </small>
                )}
              </div>
            </div>
            <div>
              <InputNumber
                className="p-component-custom"
                style={{ marginTop: "1rem", padding: "0.5rem", width: "60%" }}
                placeholder="Satış Tutarı Giriniz"
                value={sale}
                onValueChange={(e) => {
                  setSale(e.target.value);
                  setInputErrors({ ...inputErrors, sale: false });
                }}
                mode="decimal"
                min={0}
              />
              <InputText
                type="text"
                className="p-inputtext-lg"
                placeholder="Birim"
                style={{
                  width: "20%",
                  height: "15%",
                  marginTop: "1rem",
                  marginLeft: "1rem",
                }}
                value={moneyType}
              />
              <div>
                {inputErrors.sale && (
                  <small style={{ color: "red" }}>
                    Lütfen satış tutarı giriniz.
                  </small>
                )}
              </div>
            </div>
            <div>
              <InputText
                type="text"
                className="p-inputtext-lg"
                placeholder="Fatura"
                style={{ width: "85%", marginTop: "1rem" }}
                value={bill}
                onChange={(e) => {
                  setBill(e.target.value);
                  setInputErrors({ ...inputErrors, bill: false });
                }}
              />
              <div>
                {inputErrors.sale && (
                  <small style={{ color: "red" }}>
                    Lütfen fatura bilgisi giriniz.
                  </small>
                )}
              </div>
            </div>
            <Dropdown
              options={statusType?.map((status) => ({
                label: status.label,
                value: status.value,
              }))}
              placeholder="Durum"
              value={status}
              onChange={(e) => {
                setStatus(e.value);
                setInputErrors({ ...inputErrors, status: false });
              }}
              style={{
                border: "1px solid gray",
                width: "85%",
                marginTop: "1rem",
              }}
            />
            <div>
              {inputErrors.status && (
                <small style={{ color: "red" }}>Lütfen durum seçiniz.</small>
              )}
            </div>
            <Dropdown
              options={paymentStatus}
              placeholder="Ödeme Durumu"
              value={payment}
              onChange={(e) => {
                setPayment(e.target.value);
                setInputErrors({ ...inputErrors, payment: false });
              }}
              style={{
                border: "1px solid gray",
                width: "85%",
                marginTop: "1rem",
              }}
            />
            <div>
              {inputErrors.payment && (
                <small style={{ color: "red" }}>
                  Lütfen ödeme durumu seçiniz.
                </small>
              )}
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "2rem",
          }}
        >
          <Button
            label="Ekle"
            disabled={isButtonDisable}
            onClick={newCreate}
            style={{ width: "15%" }}
            className="btn-excel-two"
          />
        </div>
      </Dialog>
      <Dialog
        visible={displayDeleteDialog}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        style={{ width: "60vw" }}
        header="Üretim Sil"
        onHide={hideDeleteDialog}
        modal
      >
        <div className="confirmation-content">
          <div
            className="font-bold mb-2"
            style={{
              display: "flex",
              justifyContent: "center",
              color: "red",
            }}
          >
            <p>Üretimi Silmek İstediğinize Emin Misiniz?</p>
          </div>

          <div className="field">
            <label htmlFor="name" className="font-bold">
              İş Emri No: {manufacturingsCode}
            </label>
          </div>
          <div className="field">
            <label htmlFor="description" className="font-bold">
              Üretimi Oluşturan : {constituent}
            </label>
          </div>
          <div className="field">
            <label className="mb-3 font-bold">Firma Adı: {companyName} </label>
          </div>
          <div className="field">
            <label className="mb-3 font-bold">Ürün: {productName}</label>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "1rem",
            }}
          >
            <Button
              label="Hayır"
              onClick={hideDeleteDialog}
              className="btn-excel-two"
              style={{ marginRight: "1rem" }}
            />
            <Button
              label="Evet"
              onClick={handleDeletedManufacturing}
              className="btn-excel-two"
            />
          </div>
        </div>
      </Dialog>
      <Toast ref={toast} />
    </Flex>
  );
}
