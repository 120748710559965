import { Textarea } from '@chakra-ui/react';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React from 'react'
import { UpdateGuarantee } from '../../../../../core/Services';


export default function UpdateGuaranteeDialog({
  onChangeInput,
  toast,
  form,
  setForm,
  customer,
  editDialog,
  setEditDialog,
  selectedId,
  setSelectedId,
}) {
  const updateGuarantee = () => {
    UpdateGuarantee(selectedId, form)
      .then((response) => {
        if (response.message === "Hata") {
          toast({
            title: "Hata",
            description: "Kullanıcya Ait Veri Mevcut",
            status: "error",
            duration: 3000,
            position: "top-right",
            isClosable: true,
          });
        } else {
          toast({
            title: "Başarılı",
            description: "Veri başarılı bir şekilde kaydedildi.",
            status: "success",
            duration: 3000,
            position: "top-right",
            isClosable: true,
          });
          setForm(null);
          setSelectedId("");
        }
      })
      .catch((error) => {
        console.error("Backend'e gönderme hatası:", error);
        toast({
          title: "Hata",
          description: "Veri gönderilirken bir hata oluştu.",
          status: "error",
          duration: 3000,
          position: "top-right",
          isClosable: true,
        });
      });
  };
  const editButton = () => {
    return (
      <Button
        label="Kaydet"
        icon="pi pi-check"
        onClick={updateGuarantee}
        className="btn-excel-two"
        style={{ width: "9rem" }}
      />
    );
  };
  return (
    <Dialog
      header="İçerik Düzenleme"
      visible={editDialog}
      onHide={() => {
        setEditDialog(false);
        setForm(null);
        setSelectedId("");
      }}
      style={{ width: "50vw" }}
      footer={editButton}
    >
      <div className="confirmation-content">
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <div style={{ marginBottom: "1rem" }}>
              <small className="font-bold">Firma Adı:</small>
              <Dropdown
                filter
                options={customer.map((cus) => ({
                  label: cus?.title,
                  value: cus?.id,
                }))}
                value={form?.companyName}
                className="w-full"
                name="companyName"
                onChange={onChangeInput}
                placeholder="Firma Adı"
                style={{
                  border: "1px solid gray",
                }}
              />
            </div>
            <div
              style={{
                marginBottom: "1rem",
              }}
            >
              <small className="font-bold">Bölge:</small>
              <InputText
                filter
                value={form?.region}
                className="p-inputtext-lg w-full"
                name="region"
                onChange={onChangeInput}
                placeholder="Bölge"
                style={{
                  border: "1px solid gray",
                }}
              />
            </div>
            <div
              style={{
                marginBottom: "1rem",
              }}
            >
              <small className="font-bold">Ürün Açıklaması:</small>
              <Textarea
                filter
                value={form?.productDescription}
                className="w-full"
                name="productDescription"
                onChange={onChangeInput}
                placeholder="Ürün Açıklama"
                style={{
                  border: "1px solid gray",
                }}
              />
            </div>
            <div
              style={{
                marginBottom: "1rem",
              }}
            >
              <small className="font-bold">Net Geçiş Ölçüsü:</small>
              <InputText
                filter
                value={form?.netPassageSize}
                className="p-inputtext-lg w-full"
                name="netPassageSize"
                onChange={onChangeInput}
                placeholder="Net Geçiş Ölçüsü"
                style={{
                  border: "1px solid gray",
                }}
              />
            </div>
            <div
              style={{
                marginBottom: "1rem",
              }}
            >
              <small className="font-bold">Opsiyon:</small>
              <InputText
                filter
                value={form?.option}
                className="p-inputtext-lg w-full"
                name="option"
                onChange={onChangeInput}
                placeholder="Opsiyon"
                style={{
                  border: "1px solid gray",
                }}
              />
            </div>
            <div
              style={{
                marginBottom: "1rem",
              }}
            >
              <small className="font-bold">İlk Kayıt Tarihi:</small>
              <Calendar
                value={form?.firstRegistrationDate}
                placeholder="İlk Kayıt Tarihi"
                className="w-full"
                name="firstRegistrationDate"
                onChange={onChangeInput}
                locale="tr"
                style={{
                  border: "1px solid gray",
                  padding: "0.5rem",
                  borderRadius: "6px 6px 6px 6px",
                }}
                dateFormat="dd/mm/yy"
                showTime={false}
                firstDayOfWeek={1}
              />
            </div>
            <div
              style={{
                marginBottom: "1rem",
              }}
            >
              <small className="font-bold">Bitiş Tarihi:</small>
              <Calendar
                placeholder="Bitiş  Tarihi"
                value={form?.endDate}
                name="endDate"
                onChange={onChangeInput}
                className="w-full"
                locale="tr"
                style={{
                  border: "1px solid gray",
                  padding: "0.5rem",
                  borderRadius: "6px 6px 6px 6px",
                }}
                dateFormat="dd/mm/yy"
                showTime={false}
                firstDayOfWeek={1}
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
