import React, { useEffect, useState } from "react";
import DomainServices from "./components/DomainServices";
import HostingServices from "./components/HostingServices";
import License from "./components/License";
import Services from "./components/Services";
import { Message } from "primereact/message";
import Brand from "./components/Brand";

export default function CustomerServicesPage({
  service,
  license,
  hosting,
  domains,
  brand
}) {
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (
      service?.length ||
      hosting?.length ||
      license?.length ||
      domains?.length ||
      brand?.length
    ) {
      setDataLoaded(true);
    }
  }, [brand?.length, domains?.length, hosting?.length, license?.length, service?.length]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("tr-TR");
    return formattedDate;
  };

  return (
    <div className="p-3 w-100">
      <p className="font-bold text-3xl mb-2 underline">Hizmetler</p>

      {dataLoaded ? (
        <div className="p-3 gap-1 flex justify-content-start flex-wrap">
          <div>
            <DomainServices formatDate={formatDate} domains={domains} />
          </div>
          <div>
            <HostingServices formatDate={formatDate} hosting={hosting} />
          </div>
          <div>
            <License formatDate={formatDate} license={license} />
          </div>
          <div>
            <Services formatDate={formatDate} service={service} />
          </div>
          <div>
            <Brand formatDate={formatDate} brand={brand} />
          </div>
        </div>
      ) : (
        <div className="flex align-items-center justify-content-center flex-wrap">
          <Message
            severity="info"
            text="Aktif olarak aldığınız hizmet bulunmamaktadır."
          />
        </div>
      )}
    </div>
  );
}
