import React from "react";
import Sidebar from "../../../../_ekdizayn/layout/Sidebar/Sidebar";
import NavBar from "../../../../_ekdizayn/layout/Navbar/components/Header";
import { Grid, GridItem } from "@chakra-ui/react";
import SupplierPage from "./SupplierPage";

export default function Supplier() {
  return (
    <Grid
      templateAreas={`"header header"
                      "nav main"
                     `}
      gridTemplateRows={"auto 1fr "}
      gridTemplateColumns={"auto 1fr"}
      h="auto"
      color="blackAlpha.700"
      fontWeight="bold"
    >
      <GridItem area={"header"}>
        <NavBar />
      </GridItem>
      <GridItem area={"nav"}>
        <Sidebar />
      </GridItem>
      <GridItem className="mt-4" area={"main"}>
        <SupplierPage />
      </GridItem>
    </Grid>
  );
}
