import React, { useEffect, useRef, useState } from "react";
import {
  AddTimeService,
  AddTrack,
  DeleteTrack,
  modernizeTrack,
  visibileDomainNameTracks,
} from "../../../../core/Services";
import { Card, CardHeader, Flex, Heading } from "@chakra-ui/react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import domainPlatform from "../../../../../_ekdizayn/api/tally-platform.json"
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { addLocale } from "primereact/api";
import { Dropdown } from "primereact/dropdown";
import { SelectButton } from "primereact/selectbutton";
import { Toast } from "primereact/toast";
import { useSelector } from "react-redux";
function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default function DomainName() {
  const [tally, setTally] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [service_type] = useState("0");
  const [customerList, setCustomerList] = useState([]);
  const [cus, setCus] = useState("");
  const [domainName, setDomainName] = useState("");
  const [owner, setOwner] = useState(null);
  const [platform, setPlatform] = useState("");
  const [startingDate, setStartingDate] = useState(null);
  const [renewalDate, setRenewalDate] = useState(null);
  const [dialogs, setDialogs] = useState({});
  const [selectedName, setSelectedName] = useState(null);
  const [displayDeleteDialog, setDisplayDeleteDialog] = useState(false);
  const [dialogsVisibleTime, setDialogsVisibleTime] = useState(false);
  const [visibilityDialog, setVisibilityDialog] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const tallyData = useSelector((state) => state.TallyReduce.value);
  const toast = useRef(null);
  const [inputErrors, setInputErrors] = useState({
    domainName: false,
    platform: false,
    cus: false,
    owner: false,
    startingDate: false,
    renewalDate: false,
  });
  const [time, setTime] = useState(1);
  const filterHostName = (value) => {
    let matchingObjects = [];
    if (Array.isArray(tallyData.tracks) && tallyData.tracks.length > 0) {
      matchingObjects = tallyData.tracks.filter((item) =>
        item.domain_name.toLowerCase().includes(value.toLowerCase())
      );
    }
    setTally(matchingObjects);
  };

  useInterval(() => {
    setTally((prevTally) => {
      if (!prevTally || !prevTally.length) {
        return prevTally;
      }
      const updatedTally = prevTally.map((item) => {
        const remainingDays = calculateRemainingDays(item.renewal_date);
        return {
          ...item,
          remainingDays,
        };
      });

      return updatedTally;
    });
  }, 24 * 60 * 60 * 1000);

    const options = [
      { label: "Müşteri", value: "Müşteri" },
      { label: "Ek Dizayn", value: "Ek Dizayn" },
    ];

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    return new Date(dateString).toLocaleString("tr-TR", options);
  };
  addLocale("tr", {
    firstDayOfWeek: 1,
    showMonthAfterYear: true,
    dayNames: [
      "pazar",
      "pazartesi",
      "salı",
      "çarşamba",
      "perşembe",
      "cuma",
      "cumartesi",
    ],
    dayNamesShort: ["paz", "pzt", "sal", "çar", "per", "cum", "cmt"],
    dayNamesMin: ["P", "Pzt", "S", "Ç", "P", "C", "Cmt"],
    monthNames: [
      "ocak",
      "şubat",
      "mart",
      "nisan",
      "mayıs",
      "haziran",
      "temmuz",
      "ağustos",
      "eylül",
      "ekim",
      "kasım",
      "aralık",
    ],
    monthNamesShort: [
      "oca",
      "şub",
      "mar",
      "nis",
      "may",
      "haz",
      "tem",
      "ağu",
      "eyl",
      "eki",
      "kas",
      "ara",
    ],
    today: "Bugün",
    clear: "Temizle",
  });

  const hideDialog = () => {
    setDomainName("");
    setPlatform("");
    setCus("");
    setStartingDate(null);
    setRenewalDate(null);
    setOwner("");
    setAddModal(false);
  };
  const handleCompleteVisibile = async () => {
    try {
      if (!selectedName) {
        console.error("Pasife alınacak domain bulunamadı.");
        return;
      }
      await visibileDomainNameTracks(selectedName);
      setVisibilityDialog(false);
    } catch (error) {
      console.error("Hata:", error.message);
    }
  };
  const handleCompleteDomainName = async () => {
    try {
      if (!selectedTask) {
        console.error("Düzenlenecek görev bulunamadı.");
        return;
      }

      const formattedStartingDate = formatDate(startingDate);
      const formattedRenewalDate = formatDate(renewalDate);

      await modernizeTrack(
        selectedTask.id,
        domainName,
        platform,
        cus,
        owner,
        formattedStartingDate,
        formattedRenewalDate
      );
      setDialogs(false);
    } catch (error) {
      console.error("Hata:", error.message);
    }
  };

  const handleAddTime = async () => {
    try {
      await AddTimeService(selectedTask.id, time);
      setDialogs(false);
    } catch (error) {
      console.error("Hata:", error.message);
    }
  };

  const hideDeleteDialog = () => {
    setDisplayDeleteDialog(false);
    setDomainName("");
    setSelectedName(null);
  };
  const handleDeletedDomain = async () => {
    await DeleteTrack(selectedName);
    setDomainName("");
    setDisplayDeleteDialog(false);
    setSelectedName(null);
  };

  const EditBodyTemplate = (tasks) => {
    const isVisible = dialogs[tasks.id] || false;

    return (
      <React.Fragment>
        <div style={{ display: "flex" }}>
          <div style={{ color: "#f39f5a" }}>
            <Button
              severity="warning"
              onClick={() => {
                setSelectedTask(tasks);
                setDialogs((prevDialogs) => ({
                  ...prevDialogs,
                  [tasks.id]: true,
                }));
                setDomainName(tasks.domain_name || "");
                setPlatform(tasks.platform || "");
                setCus(
                  customerList?.find(
                    (dept) => dept.id.toString() === tasks.customer
                  )?.id || ""
                );

                setOwner(tasks.owner || "");
                const startingDateValue = tasks.starting_date
                  ? new Date(tasks.starting_date)
                  : new Date();
                const renewalDateValue = tasks.renewal_date
                  ? new Date(tasks.renewal_date)
                  : new Date();

                setStartingDate(startingDateValue);
                setRenewalDate(renewalDateValue);
              }}
              icon="pi pi-pencil"
            />
          </div>
          <div style={{ color: "#ae445a" }}>
            <Button
              icon="pi pi-trash"
              onClick={() => {
                setDomainName(tasks?.domain_name);
                setSelectedName(tasks.domain_name);
                setDisplayDeleteDialog(true);
                if (tasks?.platform) {
                  const platform = domainPlatform.find(
                    (plat) => plat?.value === tasks?.platform
                  );
                  if (platform) {
                    setPlatform(platform?.label);
                  } else {
                    setPlatform("Bilinmeyen");
                  }
                }
                if (tasks?.customer) {
                  const customer = customerList.find(
                    (dept) => dept.id.toString() === tasks.customer
                  );

                  if (customer) {
                    const fullName = customer.title;
                    const splittedName = fullName.split(" ");
                    const firstTwoWords = splittedName.slice(0, 2).join(" ");
                    setCus(firstTwoWords);
                  } else {
                    setCus("Bilinmeyen");
                  }
                }
              }}
              severity="danger"
            />
          </div>
          <div style={{ color: "#00d9ff" }}>
            <Button
              icon="pi pi-clone"
              onClick={() => {
                setSelectedName(tasks.domain_name);
                setVisibilityDialog(true);
              }}
              severity="info"
            />
          </div>
        </div>
        <Dialog
          header="İçerik Düzenleme"
          visible={isVisible}
          style={{ width: "50vw" }}
          onHide={() => {
            setDialogs((prevDialogs) => ({
              ...prevDialogs,
              [tasks.id]: false,
            }));
            setSelectedTask(null);
            hideDialog();
          }}
          footer={
            <div>
              <Button
                label="Kaydet"
                icon="pi pi-check"
                onClick={handleCompleteDomainName}
                className="btn-excel-two"
                style={{ width: "9rem" }}
              />
            </div>
          }
        >
          <div className="confirmation-content">
            <div className="p-grid p-fluid">
              <div className="p-col-12">
                <div style={{ display: "flex", marginBottom: "1rem" }}>
                  <InputText
                    type="text"
                    className="p-inputtext-lg w-full "
                    placeholder="Alan Adı"
                    defaultValue={domainName}
                    onChange={(e) => setDomainName(e.target.value)}
                  />
                </div>
              </div>
              <div style={{ marginBottom: "1rem" }}>
                <Dropdown
                  filter
                  options={domainPlatform}
                  value={platform}
                  className="w-full"
                  placeholder="Platform"
                  onChange={(e) => setPlatform(e.value)}
                  style={{
                    border: "1px solid gray",
                  }}
                />
              </div>
              <div style={{ marginBottom: "1rem" }}>
                <Dropdown
                  filter
                  options={customerList?.map((dep) => ({
                    label: dep.title,
                    value: dep.id,
                  }))}
                  value={cus}
                  className="w-full"
                  placeholder="Müşteri Adı "
                  onChange={(e) => setCus(e.target.value)}
                  style={{
                    border: "1px solid gray",
                  }}
                />
              </div>

              <Calendar
                placeholder="İlk Kayıt Tarihi"
                locale="tr"
                style={{
                  border: "1px solid gray",
                  padding: "0.5rem",
                  marginBottom: "1rem",
                  borderRadius: "6px 6px 6px 6px",
                }}
                className="w-full"
                value={startingDate || null}
                onChange={(e) => setStartingDate(e.value)}
                dateFormat="dd/mm/yy"
                showTime={false}
              />

              <Calendar
                placeholder="Yenileme Tarihi"
                locale="tr"
                style={{
                  border: "1px solid gray",
                  padding: "0.5rem",
                  marginBottom: "1rem",
                  borderRadius: "6px 6px 6px 6px",
                }}
                className="w-full"
                value={renewalDate}
                onChange={(e) => setRenewalDate(e.value)}
                dateFormat="dd/mm/yy"
                showTime={false}
              />

              <div style={{ marginBottom: "1rem" }}>
                <SelectButton
                  style={{
                    border: "1px solid gray",
                  }}
                  value={owner}
                  className="w-full"
                  onChange={(e) => setOwner(e.value)}
                  optionLabel="label"
                  options={options}
                />
              </div>
            </div>
          </div>
        </Dialog>
      </React.Fragment>
    );
  };
  const AddTimeTemplate = (tasks) => {
    const [isVisibleTime, setIsVisibleTime] = useState(
      dialogsVisibleTime[tasks.id] || false
    );

    return (
      <React.Fragment>
        <div style={{ color: "green" }}>
          <Button
            icon="pi pi-calendar-plus"
            onClick={() => {
              setSelectedTask(tasks);
              setIsVisibleTime(true);
            }}
            severity="success"
          />
        </div>
        <Dialog
          header="Ek Süre"
          visible={isVisibleTime}
          style={{ width: "50vw" }}
          onHide={() => {
            setDialogsVisibleTime((prevDialogs) => ({
              ...prevDialogs,
              [tasks.id]: false,
            }));
            setSelectedTask(null);
            setIsVisibleTime(false);
          }}
          footer={
            <div>
              <Button
                label="Onayla"
                icon="pi pi-check"
                onClick={handleAddTime}
                className="btn-excel-two"
                style={{ marginLeft: "15px" }}
              />
            </div>
          }
        >
          <div>
            {" "}
            <Dropdown
              options={[
                { label: "1 Yıl", value: 1 },
                { label: "2 Yıl", value: 2 },
                { label: "3 Yıl", value: 3 },
                { label: "4 Yıl", value: 4 },
                { label: "5 Yıl", value: 5 },
              ]}
              value={time}
              placeholder="Ek Süre"
              onChange={(e) => setTime(e.target.value)}
              style={{
                border: "1px solid gray",
                width: "65%",
              }}
            />
          </div>
        </Dialog>
      </React.Fragment>
    );
  };

  const newCreate = async () => {
    if (
      !cus ||
      !platform ||
      !domainName ||
      !startingDate ||
      !renewalDate ||
      !owner
    ) {
      setInputErrors({
        cus: !cus,
        platform: !platform,
        domainName: !domainName,
        startingDate: !startingDate,
        renewalDate: !renewalDate,
        owner: !owner,
      });
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Lütfen boş kısımları doldurun.",
      });
      return;
    }

    const formattedStartingDate = formatDate(startingDate);
    const formattedRenewalDate = formatDate(renewalDate);

    await AddTrack(
      service_type,
      domainName,
      platform,
      cus,
      owner,
      formattedStartingDate,
      formattedRenewalDate
    );
    setAddModal(false);

    setDomainName("");
    setCus("");
    setPlatform("");
    setOwner("");
    setStartingDate(null);
    setRenewalDate(null);
    setAddModal(false);
  };

  const calculateRemainingDays = (renewalDate) => {
    const ONE_DAY = 24 * 60 * 60 * 1000;
    const renewalDateObject = new Date(renewalDate);
    const startingDateObject = new Date();

    const timeDiff = renewalDateObject - startingDateObject;
    const daysDiff = Math.ceil(timeDiff / ONE_DAY);

    return daysDiff;
  };

  useEffect(() => {
    if (tallyData) {
      const fetchData = () => {
        const sortedData = [...tallyData?.tracks].sort((a, b) => {
          const remainingDaysA = calculateRemainingDays(a.renewal_date);
          const remainingDaysB = calculateRemainingDays(b.renewal_date);
          return remainingDaysA - remainingDaysB;
        });
        setTally(sortedData);
        setCustomerList(tallyData.customers);
      };
      fetchData();
    }
  }, [tallyData, dialogs]);

  const handleCloseDialog = () => {
    setVisibilityDialog(false);
  };
  return (
    <Flex mt="5" justifyContent="center" alignItems="center">
      <div
        className="card"
        style={{
          overflow: "auto",
          boxSizing: "border-box",
          width: "100%",
          height: "100%",
          position: "relative",
          padding: "1rem",
        }}
      >
        <Card>
          <CardHeader>
            <Flex justifyContent="space-between" alignItems="center">
              <Heading size="md">Alan Adı</Heading>
              <div>
                <span
                  className="p-input-icon-right"
                  style={{
                    border: "solid 1px gray",
                    borderRadius: "6px 6px 6px 6px",
                    padding: "0.2rem",
                  }}
                >
                  <i className="pi pi-search" />
                  <InputText
                    placeholder="Alan Adı Ara"
                    onChange={(e) => filterHostName(e.target.value)}
                  />
                </span>
                <Button
                  style={{ marginLeft: "1rem" }}
                  label="Yeni"
                  onClick={() => {
                    setAddModal(true);
                  }}
                  className="btn-excel-two"
                />
              </div>
            </Flex>
          </CardHeader>
          <DataTable
            stripedRows
            size="small"
            dataKey="id"
            tableStyle={{ minWidth: "50rem" }}
            rows={15}
            value={
              tally &&
              Array.isArray(tally) &&
              tally.filter(
                (item) =>
                  item?.service_type === "0" && item?.domain_visibility === "0"
              )
            }
            rowsPerPageOptions={[15, 30, 60, 100]}
            scrollHeight="65vh"
            scrollable
            paginator
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate=" {totalRecords} alanadından {first} - {last} arası gösteriliyor"
          >
            <Column body={EditBodyTemplate} header="İşlemler"></Column>
            <Column field="domain_name" header="Alan Adı"></Column>
            <Column
              field="platform"
              body={(rowData) => {
                const platform = domainPlatform.find(
                  (plat) => plat.value.toString() === rowData.platform
                );
                if (platform) {
                  return platform.label;
                } else {
                  return "Bilinmeyen";
                }
              }}
              header="Platform"
            ></Column>
            <Column
              field="customer"
              header="Müşteri Adı"
              body={(rowData) => {
                const customer = customerList.find(
                  (dept) => dept.id.toString() === rowData.customer
                );

                if (customer) {
                  const fullName = customer.title;
                  const splittedName = fullName.split(" ");
                  const firstTwoWords = splittedName.slice(0, 2).join(" ");
                  return firstTwoWords;
                } else {
                  return "Bilinmeyen";
                }
              }}
            ></Column>
            <Column field="owner" header="Sahibi"></Column>
            <Column
              field="starting_date"
              header="İlk Kayıt Tarihi"
              body={(rowData) => formatDate(rowData.starting_date)}
            ></Column>
            <Column
              field="renewal_date"
              header="Yenileme Tarihi"
              body={(rowData) => formatDate(rowData.renewal_date)}
            ></Column>
            <Column
              header="Kalan Süre"
              body={(rowData) => {
                const remainingDays = calculateRemainingDays(
                  rowData.renewal_date
                );

                let backgroundColor;
                let textColor;

                if (remainingDays >= 30) {
                  backgroundColor = "#4CAF50";
                  textColor = "black";
                } else if (remainingDays >= 7) {
                  backgroundColor = "#FFC107";
                  textColor = "black";
                } else {
                  backgroundColor = "#F44336";
                  textColor = "black";
                }

                const style = {
                  backgroundColor,
                  borderRadius: "5px",
                  color: textColor,
                  padding: "5px",
                  display: "inline-block",
                };

                return <span style={style}>{remainingDays} gün</span>;
              }}
            ></Column>
            <Column body={AddTimeTemplate} header="Ek Süre"></Column>
          </DataTable>
        </Card>
      </div>
      <Dialog
        visible={addModal}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        style={{ width: "50%" }}
        header="Alan Adı Ekle"
        onHide={hideDialog}
        modal
      >
        <div className="confirmation-content">
          <div className="p-grid p-fluid">
            <div className="p-col-12">
              <div style={{ marginBottom: "1rem" }}>
                <InputText
                  type="text"
                  className="p-inputtext-lg w-full "
                  placeholder="Alan Adı"
                  value={domainName}
                  onChange={(e) => {
                    setDomainName(e.target.value);
                    setInputErrors({ ...inputErrors, domainName: false });
                  }}
                />
                {inputErrors.domainName && (
                  <small style={{ color: "red" }}>
                    Lütfen alan adı giriniz .
                  </small>
                )}
              </div>
              <div style={{ marginBottom: "1rem" }}>
                <Dropdown
                  filter
                  options={domainPlatform}
                  value={platform}
                  className="w-full"
                  placeholder="Platform"
                  onChange={(e) => {
                    setPlatform(e.value);
                    setInputErrors({ ...inputErrors, platform: false });
                  }}
                  style={{
                    border: "1px solid gray",
                  }}
                />
                {inputErrors.platform && (
                  <small style={{ color: "red" }}>
                    Lütfen platform seçiniz .
                  </small>
                )}
              </div>
              <div style={{ marginBottom: "1rem" }}>
                <Dropdown
                  filter
                  options={customerList?.map((dep) => ({
                    label: dep.title,
                    value: dep.id,
                  }))}
                  value={cus}
                  className="w-full"
                  placeholder="Müşteri Adı "
                  onChange={(e) => {
                    setCus(e.target.value);
                    setInputErrors({ ...inputErrors, cus: false });
                  }}
                  style={{
                    border: "1px solid gray",
                  }}
                />
                {inputErrors.cus && (
                  <small style={{ color: "red" }}>
                    Lütfen müşteri seçiniz .
                  </small>
                )}
              </div>
              <div style={{ marginBottom: "1rem" }}>
                <Calendar
                  placeholder="İlk Kayıt Tarihi"
                  locale="tr"
                  className="w-full"
                  style={{
                    border: "1px solid gray",
                    padding: "0.5rem",
                    borderRadius: "6px 6px 6px 6px",
                  }}
                  value={startingDate}
                  onChange={(e) => {
                    setStartingDate(e.value);
                    setInputErrors({ ...inputErrors, startingDate: false });
                  }}
                  dateFormat="dd/mm/yy"
                  showTime={false}
                />
                {inputErrors.startingDate && (
                  <small style={{ color: "red" }}>
                    Lütfen ilk kayıt tarihi seçiniz .
                  </small>
                )}
              </div>
              <div style={{ marginBottom: "1rem" }}>
                <Calendar
                  placeholder="Yenileme Tarihi"
                  locale="tr"
                  className="w-full"
                  style={{
                    border: "1px solid gray",
                    padding: "0.5rem",
                    borderRadius: "6px 6px 6px 6px",
                  }}
                  value={renewalDate}
                  onChange={(e) => {
                    setRenewalDate(e.value);
                    setInputErrors({ ...inputErrors, renewalDate: false });
                  }}
                  dateFormat="dd/mm/yy"
                  showTime={false}
                />
                {inputErrors.renewalDate && (
                  <small style={{ color: "red" }}>
                    Lütfen yenileme tarihi seçiniz .
                  </small>
                )}
              </div>
              <SelectButton
                style={{
                  border: "1px solid gray",
                  marginBottom: "1rem",
                }}
                value={owner}
                onChange={(e) => {
                  setOwner(e.value);
                  setInputErrors({ ...inputErrors, owner: false });
                }}
                optionLabel="label"
                className="w-full"
                options={options}
              />
              {inputErrors.owner && (
                <small style={{ color: "red" }}>
                  Lütfen aitlik belirtiniz.
                </small>
              )}
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1rem",
          }}
        >
          <Button
            label="Ekle"
            onClick={newCreate}
            style={{ width: "8rem" }}
            className="btn-excel-two"
          />
        </div>
      </Dialog>
      <Dialog
        visible={displayDeleteDialog}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        style={{ width: "60vw" }}
        header="Alan Adı Sil"
        onHide={hideDeleteDialog}
        modal
      >
        <div className="confirmation-content">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <p>Alan Adını Silmek İstediğinize Emin Misiniz?</p>
          </div>
          <div className="confirmation-content">
            <div className="field">
              <label htmlFor="name" className="font-bold">
                Alan Adı: {domainName}
              </label>
            </div>
            <div className="field">
              <label htmlFor="name" className="font-bold">
                Platform: {platform}
              </label>
            </div>
            <div className="field">
              <label htmlFor="name" className="font-bold">
                Müşteri: {cus}
              </label>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "1rem",
            }}
          >
            <Button
              label="Hayır"
              onClick={hideDeleteDialog}
              className="btn-excel-two"
              style={{ marginRight: "1rem" }}
            />
            <Button
              label="Evet"
              onClick={handleDeletedDomain}
              className="btn-excel-two"
            />
          </div>
        </div>
      </Dialog>
      <Dialog
        header="Pasife Al"
        visible={visibilityDialog}
        style={{ width: "50vw" }}
        onHide={() => setVisibilityDialog(false)}
        footer={
          <div>
            <Button
              label="Kapat"
              icon="pi pi-times"
              onClick={handleCloseDialog}
              className="btn-excel-two"
            />
            <Button
              label="Domaini Pasife Al"
              icon="pi pi-check"
              onClick={handleCompleteVisibile}
              className="btn-excel-two"
              style={{ marginLeft: "15px" }}
            />
          </div>
        }
      ></Dialog>
      <Toast ref={toast} />
    </Flex>
  );
}
