import { createSlice } from "@reduxjs/toolkit";

export const JobSlice = createSlice({
  name: "JobSlice",
  initialState: {
    value: null,
  },
  reducers: {
    addJobData: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { addJobData } = JobSlice.actions;
export default JobSlice.reducer;
