import React, { useEffect, useState } from "react";
import { Chart } from "primereact/chart";
import { GetDeviceAds } from "../../../../../../../core/Services";
import { useAuth } from "../../../../../../../auth/core/AuthContext";

export default function ChartJ({ months, selectedMonth }) {
  const { userData } = useAuth();
  const uniqueCustomerId = userData?.unique_id;

  const [viewVisitorData, setViewVisitorData] = useState([]);
  const [chartOptions, setChartOptions] = useState();

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    GetDeviceAds(uniqueCustomerId, selectedMonth)
      .then((response) => {
        const data = response.data;
        const type1Data = data.find((entry) => entry.type === "1");
        const type2Data = data.find((entry) => entry.type === "2");
        const type3Data = data.find((entry) => entry.type === "3");
        const datalist = {
          labels: ["Maliyet", "Gösterim", "Tıklamalar"],
          datasets: [
            {
              label: "Telefonlar",
              backgroundColor: documentStyle.getPropertyValue("--blue-500"),
              borderColor: documentStyle.getPropertyValue("--blue-500"),
              data: [type1Data?.cost, type1Data?.show, type1Data?.clicks],
            },
            {
              label: "Tabletler",
              backgroundColor: documentStyle.getPropertyValue("--pink-500"),
              borderColor: documentStyle.getPropertyValue("--pink-500"),
              data: [type2Data?.cost, type2Data?.show, type2Data?.clicks],
            },
            {
              label: "Bilgisayarlar",
              backgroundColor: documentStyle.getPropertyValue("--orange-500"),
              borderColor: documentStyle.getPropertyValue("--orange-500"),
              data: [type3Data?.cost, type3Data?.show, type3Data?.clicks],
            },
           
          ],
        };
        const options = {
          indexAxis: "y",
          maintainAspectRatio: false,
          aspectRatio: 0.8,
          plugins: {
            legend: {
              labels: {
                fontColor: textColor,
              },
            },
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary,
                font: {
                  weight: 500,
                },
              },
              grid: {
                display: false,
                drawBorder: false,
              },
            },
            y: {
              ticks: {
                color: textColorSecondary,
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false,
              },
            },
          },
        };

        setViewVisitorData(datalist);
        setChartOptions(options);
      })
      .catch((error) => {
        console.log("ViewVisitor Hatası", error);
      });
  }, [uniqueCustomerId, months, selectedMonth]);

  return (
    <div>
      {viewVisitorData && (
        <Chart type="bar" data={viewVisitorData} options={chartOptions} />
      )}
    </div>
  );
}
