import React from "react";
import { TabView, TabPanel } from "primereact/tabview";
import Website from "../web-site/Website";
import SocialMedia from "../social-media/SocialMedia";
import Maps from "../maps/Maps";
import AdsPerformance from "../../../customer/ads/AdsReport";
import BenefitsReport from "../benefits/BenefitsReport";
import Security from "../security/Security";
import Overview from "../overview/Overview";
import { Button } from "primereact/button";

export default function Main({ selectedMonth }) {
  const tabContents = [
    {
      title: "Genel Bakış",
      content: <Overview />,
    },
    { title: "Web Sitesi", content: <Website selectedMonth={selectedMonth} /> },
    {
      title: "Sosyal Medya",
      content: <SocialMedia selectedMonth={selectedMonth} />,
    },
    { title: "Haritalar", content: <Maps selectedMonth={selectedMonth} /> },
    {
      title: "Reklamlar",
      content: <AdsPerformance selectedMonth={selectedMonth} />,
    },
    { title: "Güvenlik", content: <Security selectedMonth={selectedMonth} /> },
    {
      title: "Faydalar",
      content: <BenefitsReport selectedMonth={selectedMonth} />,
    },
  ];

  const printCard = () => {
    const otherElements = document.querySelectorAll(".not-to-print");
    const scale = 0.8; // Örnek ölçek

    otherElements.forEach((element) => element.classList.add("hidden"));
    window.print({ scale });
    otherElements.forEach((element) => element.classList.remove("hidden"));
  };

  return (
    <div className="mt-2 ml-2">
      <Button
        label="Yazdır"
        onClick={printCard}
        icon="pi pi-print"
        className="btn-excel-two not-to-print"
      />
      <div className="card mt-3 ">
        <TabView>
          {tabContents?.map((tab) => {
            return (
              <TabPanel key={tab.title} header={tab.title}>
                {tab.content}
              </TabPanel>
            );
          })}
        </TabView>
      </div>
    </div>
  );
}
