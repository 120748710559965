import {
  Box,
  Card,
  CardBody,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  TabPanel,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import DomainName from "./components/DomainName";
import Hosting from "./components/Hosting";
import Licence from "./components/Licence";
import { useDispatch } from "react-redux";

import { addTallyData } from "../../../redux/TallySlice";
import { addLicenceData } from "../../../redux/LicenceSlice";

import { TrackService } from "../../../core/Services";
import { BreadCrumb } from "primereact/breadcrumb";
import Brand from "./components/Brand";
import { addBrandData } from "../../../redux/BrandSlice";

export default function TallyPage() {
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const tallyData = await TrackService();

        dispatch(addTallyData(tallyData));
        dispatch(addLicenceData(tallyData.licences));
        dispatch(addBrandData(tallyData.brand));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [dispatch]);

  const getTurkishLabel = (url) => {
    switch (url) {
      case "/tally":
        return "Çetele";
      
      default:
        return "Sayfa"; 
    }
  };
  const url = "/tally";
  const turkishLabel = getTurkishLabel(url); // "Ürünler"

  const itemsBread = [{ label: turkishLabel, url: url }];
  const home = { icon: "pi pi-home", url: "/dashboard" };
  return (
    <Box mt="5">
      <Card>
        <BreadCrumb
          model={itemsBread}
          home={home}
          style={{ padding: "1rem" }}
        />
        <CardBody>
          <Tabs align="end" variant="enclosed">
            <TabList>
              <Tab>Alanadı</Tab>
              <Tab>Hosting</Tab>
              {/* <Tab>Hizmet</Tab> */}
              <Tab>Marka</Tab>
              <Tab>Lisans</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <DomainName />
              </TabPanel>
              <TabPanel>
                <Hosting />
              </TabPanel>
              {/* <TabPanel>
                <Service />
              </TabPanel> */}
              <TabPanel>
                <Brand />
              </TabPanel>
              <TabPanel>
                <Licence />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </CardBody>
      </Card>
    </Box>
  );
}
