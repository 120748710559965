import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  UserService,
  AddUserService,
  DeleteUserService,
  EditUserService,
  CustomerService,
} from "../../../../../../core/Services";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Card, CardHeader, Flex, Heading } from "@chakra-ui/react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { BreadCrumb } from "primereact/breadcrumb";
import { useAuth } from "../../../../../../auth/core/AuthContext";
import { BsBoxArrowInRight } from "react-icons/bs";

export default function EmployeesCustomersPage() {
  const [users, setUsers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const { LoginWithToken } = useAuth();
  const [addCustomerModal, setAddCustomerModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [newLink, setNewLink] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState("");
  const [newName, setNewName] = useState("");
  const [is_auth, setIsAuth] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newPhone] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newType, setNewType] = useState("");
  const [visible, setVisible] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [dep, setDep] = useState("");
  const [newDep, setNewDep] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const { userData } = useAuth();
  const isButtonCustomerDisable =
    name === "" || email === "" || password === "";

  const hideDialog = () => {
    setEditModal(false);
    setAddCustomerModal(false);
    setType("");
    setLink("");
    setName("");
    setEmail("");
    setPassword("");
  };
  const accessors = (rowData) => {
     const edit = editModal[rowData.id] || false;
     const deletes = visible[rowData.id] || false;
    return (
      <div style={{ display: "flex" }}>
        <div style={{ color: "#f39f5a" }}>
          <Button
            severity="warning"
            onClick={() => {
              setEditModal((prevDialogs) => ({
                ...prevDialogs,
                [rowData.id]: true,
              }));
              setSelectedData(rowData);
              setNewType(rowData.type);
              setNewDep(rowData.department);
              setIsAuth(rowData.is_auth);
            }}
            icon={"pi pi-pencil"}
          />
        </div>
        <div style={{ color: "#ae445a" }}>
          <Button
            icon="pi pi-trash"
            onClick={() => {
              setVisible((prevDialogs) => ({
                ...prevDialogs,
                [rowData.id]: true,
              }));
              setName(rowData?.name);
              setEmail(rowData?.email);
              setSelectedData(rowData);
            }}
            severity="danger"
          />
        </div>
        <div style={{ color: "green" }}>
          <Button
            severity="danger"
            onClick={() => {
              setSelectedData(rowData);
              LoginPreview(rowData.id);
            }}
            icon=<BsBoxArrowInRight size="18" />
          />
        </div>
        <Dialog
          header="Kullanıcıyı silmek istediğinize emin misiniz?"
          visible={deletes}
          style={{ width: "50vw" }}
          onHide={() =>{
            setVisible(false);
            setName("");
            setEmail("");
          }}
          footer={
            <div>
              <Button
                label="İptal"
                icon="pi pi-times"
                onClick={() => {
                  setVisible(false);
                  setName("");
                  setEmail("");
                }}
                className="btn-excel-two"
              />
              <Button
                label="Kullanıcıyı Sil"
                icon="pi pi-check"
                onClick={() => {
                  DeleteUserService(selectedData.id);
                  setVisible(false);
                  setName("");
                  setEmail("");
                }}
                className="btn-excel-two"
                style={{ marginLeft: "15px" }}
              />
            </div>
          }
        >
          <div className="confirmation-content">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>Lisans Silmek İstediğinize Emin Misiniz?</p>
            </div>

            <div className="confirmation-content">
              <div className="confirmation-content">
                <div className="confirmation-content">
                  <div className="field">
                    <label htmlFor="name" className="font-bold">
                      İsim: {name}
                    </label>
                  </div>

                  <div className="field">
                    <label htmlFor="name" className="font-bold">
                      Email: {email}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
        <Dialog
          visible={edit}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          style={{ width: "60vw" }}
          header="Kullanıcı Düzenle"
          onHide={hideDialog}
          modal
        >
          <div className="confirmation-content">
            <div className=" jc-space-around">
              {/* Sol Sütun */}
              <div className="col-12 " style={{ display: "inline-block" }}>
                <InputText
                  type="text"
                  className="p-inputtext-lg w-full"
                  onChange={(e) => setNewEmail(e.target.value)}
                  style={{ marginTop: "1rem" }}
                  placeholder={selectedData.email}
                />
                <InputText
                  type="text"
                  className="p-inputtext-lg w-full"
                  style={{ marginTop: "1rem" }}
                  placeholder="Parola"
                  onChange={(e) => setNewPassword(e.target.value)}
                  defaultValue={selectedData.password}
                />
                <InputText
                  type="text"
                  value={newLink}
                  className="p-inputtext-lg w-full"
                  onChange={(e) => setNewLink(e.target.value)}
                  style={{ marginTop: "1rem" }}
                  placeholder="Alan Adı Giriniz"
                />
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    label="Kaydet"
                    onClick={() => saveChanges(selectedData.id)}
                    className="btn-excel-two"
                    style={{
                      marginTop: "1rem",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    );
  };
  const LoginPreview = async (id) => {
    await LoginWithToken(id);
  };

  const newCreate = async () => {
    let requestBody = {
      name,
      email,
      phone,
      password,
      department: dep,
      type,
      link,
    };
    await AddUserService(requestBody);
    setAddCustomerModal(false);
    setNewName("");
    setEmail("");
    setLink("");
    setPhone("");
    setPassword("");
    setDep("");
    setType("");
  };
  const saveChanges = async (id) => {
    let requestBody = {
      id,
      newName,
      newEmail,
      newPhone,
      newPassword,
      newDep,
      newType,
      is_auth,
      newLink,
    };
    await EditUserService(requestBody);
    setEditModal(false);
    setNewLink("");
    setNewName("");
    setEmail("");
    setPhone("");
    setPassword("");
    setDep("");
    setType("");
  };

  const toast = useRef(null);

  useEffect(() => {
    UserService().then((userData) => {
      const usersData = userData.users;
      CustomerService()
        .then((customerData) => {
          const customersData = customerData;
          const updatedUsers = usersData.map((user) => {
            const matchingCustomer = customersData.find(
              (customer) => customer.unique_id === user.unique_id
            );
            if (matchingCustomer) {
              return { ...user, title: matchingCustomer.title };
            } else {
              return user;
            }
          });
          setUsers(updatedUsers);
          setCustomers(customerData);
        })
        .catch((error) => {
          console.log("Customer Hatası", error);
        });
    });
  }, [visible, editModal, addCustomerModal]);

  const getTurkishLabel = (url) => {
    switch (url) {
      case "/panel/customers/add":
        return "Müşteriler";

      default:
        return "Sayfa";
    }
  };
  const url = "/panel/customers/add";
  const turkishLabel = getTurkishLabel(url);

  const itemsBread = [{ label: turkishLabel, url: url }];
  const home = { icon: "pi pi-home", url: "/dashboard" };

  const filteredUsers = users?.filter(
    (user) =>
      user?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
      user?.title?.toLowerCase()?.includes(searchValue?.toLowerCase())
  );

  return (
    <Flex mt="5" justifyContent="center" alignItems="center">
      <div
        className="card"
        style={{
          overflow: "auto",
          boxSizing: "border-box",
          width: "100%",
          height: "100%",
          position: "relative",
          padding: "1rem",
        }}
      >
        <Card>
          <BreadCrumb
            model={itemsBread}
            home={home}
            style={{ padding: "1rem" }}
          />
          <CardHeader>
            <Flex justifyContent="space-between" alignItems="center">
              <Heading size="md">Müşteriler</Heading>
              <div style={{ display: "flex" }}>
                <span
                  className="p-input-icon-right"
                  style={{
                    border: "solid 1px gray",
                    borderRadius: "6px 6px 6px 6px",
                    padding: "0.2rem",
                    marginRight: "1rem",
                  }}
                >
                  <i className="pi pi-search" />
                  <InputText
                    placeholder="Müşteri Ara"
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                </span>
                {userData?.type === "1" && (
                  <Button
                    label="Yeni Müşteri"
                    onClick={() => setAddCustomerModal(true)}
                    className="btn-excel-two"
                    style={{ marginRight: "2rem" }}
                  />
                )}
              </div>
            </Flex>
          </CardHeader>
          <Toast ref={toast} />
          <DataTable
            stripedRows
            value={
              filteredUsers &&
              filteredUsers.filter(
                (users) => Number(users?.authorization) === 1
              )
            }
            size="small"
            dataKey="id"
            tableStyle={{ minWidth: "50rem" }}
            rows={15}
            rowsPerPageOptions={[15, 30, 60, 100]}
            scrollable
            paginator
            selectionMode="single"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate=" {totalRecords} kullanıcıdan {first} - {last} arası gösteriliyor"
          >
            {userData?.type === "1" && (
              <Column
                style={{ width: "8rem" }}
                field="accessor"
                body={(rowData) => accessors(rowData)}
                header="İşlemler"
              ></Column>
            )}
            <Column
              field={(rowData) => {
                const firstTwoWords = rowData.title
                  .split(" ")
                  .slice(0, 2)
                  .join(" ");
                return rowData?.name + " / " + firstTwoWords;
              }}
              header="Ad Soyad / Ünvan"
            />
            <Column field="email" header="Email"></Column>
            {userData?.type === "1" && (
              <Column
                field="type"
                body={(rowData) => {
                  return rowData.type === "2"
                    ? "Müşteri"
                    : "Bilenmeyen Kullanıcı";
                }}
                header="Kullanıcı Tipi"
              ></Column>
            )}
          </DataTable>
        </Card>
      </div>
      <Dialog
        visible={addCustomerModal}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        style={{ width: "60vw" }}
        header="Müşteri Ekle"
        onHide={hideDialog}
        modal
        onClick={() => {
          setType("2");
        }}
      >
        <div className="confirmation-content">
          <div className=" jc-space-around">
            {/* Sol Sütun */}
            <div className="col-6 " style={{ display: "inline-block" }}>
              <Dropdown
                filter
                onChange={(e) => {
                  setName(e.target.value);
                }}
                options={
                  customers && customers.length > 0
                    ? customers.map((customer) => ({
                        label: customer.title,
                        value: customer.id,
                      }))
                    : []
                }
                value={name}
                placeholder="Müşteri Adı / Ünvanı"
                style={{
                  border: "1px solid gray",
                  width: "100%",
                }}
              />
              <InputText
                type="text"
                className="p-inputtext-lg w-full"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                style={{ marginTop: "1rem" }}
              />
            </div>

            {/* Sağ Sütun */}
            <div className="col-6" style={{ display: "inline-block" }}>
              <InputText
                type="text"
                className="p-inputtext-lg w-full"
                placeholder="Parola"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
              <InputText
                type="text"
                className="p-inputtext-lg w-full"
                placeholder="Alan Adı"
                onChange={(e) => setLink(e.target.value)}
                value={link}
                style={{ marginTop: "1.5rem" }}
              />

              <div style={{ display: "flex", justifyContent: "end" }}>
                <Button
                  label="Ekle"
                  disabled={isButtonCustomerDisable}
                  onClick={newCreate}
                  className="btn-excel-two"
                  style={{
                    marginTop: "1rem",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </Flex>
  );
}
