import React, { useState } from 'react'

import { Splitter, SplitterPanel } from "primereact/splitter";

import BestKeywordsGraphPage from './components/BestKeywordsGraphPage';
import BestKeywordsTable from './components/BestKeywordsTable';

export default function BestKeywordsGraph({
  uniqueCustomerId,
  selectedMonth,
  selectedCustomer,
  months,
  browser
}) {
 
  const [tableKey, setTableKey] = useState(0);
  const updateTableData = () => {
    setTableKey((prevKey) => prevKey + 1);
  };
  return (
    <Splitter style={{ height:"auto" }}>
      <SplitterPanel>
        <BestKeywordsGraphPage
          updateTableData={updateTableData}
          selectedMonth={selectedMonth}
          browser={browser}
          selectedCustomer={selectedCustomer}
          uniqueCustomerId={uniqueCustomerId}
        />
      </SplitterPanel>
      <SplitterPanel size={75} style={{ display: "block", padding: "1rem" }}>
        <BestKeywordsTable
          browser={browser}
          months={months}
          key={tableKey}
          uniqueCustomerId={uniqueCustomerId}
        />
      </SplitterPanel>
    </Splitter>
  );
}
