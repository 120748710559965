import React, { useState } from "react";
import Header from "./components/Header";
import Main from "./components/Main";
export default function CustomerReportsPage() {
    const [selectedMonth, setSelectedMonth] = useState(null);
    const months = [
      { name: "Ocak", value: "01" },
      { name: "Şubat", value: "02" },
      { name: "Mart", value: "03" },
      { name: "Nisan", value: "04" },
      { name: "Mayıs", value: "05" },
      { name: "Haziran", value: "06" },
      { name: "Temmuz", value: "07" },
      { name: "Ağustos", value: "08" },
      { name: "Eylül", value: "09" },
      { name: "Ekim", value: "10" },
      { name: "Kasım", value: "11" },
      { name: "Aralık", value: "12" },
    ];

    const handleChange = (e) => {
      const selectedValue = e.value;
      setSelectedMonth(selectedValue);
    };
    
  return (
    <div>
      <Header selectedMonth={selectedMonth} setSelectedMonth={setSelectedMonth} months={months} handleChange={handleChange} />
      <Main selectedMonth={selectedMonth}/>
    </div>
  );
}
