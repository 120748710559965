import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import React, { useRef } from "react";
import { DeleteSupplier } from "../../../../core/Services";

export default function SupplierDeleteDialog({
  setResponsiblePerson,
  setCompany,
  setAdress,
  displayDeleteDialog,
  setPhone,
  setDisplayDeleteDialog,
  setActivityArea,
  selectedSupplier,
  setSelectedSupplier,
  responsiblePerson,
  company,
  address,
  phone,
  activityArea
}) {
  const toast = useRef(null);

  const deleteButton = () => {
    return (
      <div>
        <Button
          label="Hayır"
          style={{ width: "8rem" }}
          onClick={hideDeleteDialog}
          className="btn-excel-two"
        />
        <Button
          label="Evet"
          style={{ width: "8rem" }}
          onClick={deleteSupplier}
          className="btn-excel-two ml-2"
        />
      </div>
    );
  };
  const hideDeleteDialog = () => {
    setDisplayDeleteDialog(false);
    setCompany("");
    setActivityArea(null);
    setAdress("");
    setResponsiblePerson("");
    setPhone("");
    setSelectedSupplier("");
  };

  const deleteSupplier = async () => {
    await DeleteSupplier(
      selectedSupplier
    );
    setDisplayDeleteDialog(false);
    setResponsiblePerson("");
    setCompany("");
    setAdress("");
    setPhone("");
    setActivityArea("");
  };
  return (
    <div>
      <Dialog
        visible={displayDeleteDialog}
        breakpoints={{ "960px": "70vw", "641px": "50vw" }}
        style={{ width: "50vw" }}
        header="Tedarikçi Sil"
        onHide={hideDeleteDialog}
        modal
        footer={deleteButton}
      >
        <div className="confirmation-content">
          <div className="font-bold mb-2" style={{ display: "flex", justifyContent: "center", color:"red" }}>
            <p>Tedarikçiyi Silmek İstediğinize Emin Misiniz?</p>
          </div>
          
          <div className="field">
            <label htmlFor="name" className="font-bold">
              Tedarikçi: {company}
            </label>
          </div>
          <div className="field">
            <label htmlFor="description" className="font-bold">
              Tedarikçi Sorumlusu : {responsiblePerson}
            </label>
          </div>
          <div className="field">
            <label className="mb-3 font-bold">Adres: {address}</label>
          </div>
          <div className="field">
            <label className="mb-3 font-bold">Telefon Numarası: {phone}</label>
          </div>
          <div className="field">
            <label className="mb-3 font-bold">Sorumluluk Alanı: {activityArea}</label>
          </div>
        </div>
      </Dialog>
      <Toast ref={toast} />
    </div>
  );
}
