import { MultiSelect } from "primereact/multiselect";

export default function Header({ months, handleChange, selectedMonth }) {
  return (
    <div
      className="not-to-print"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "16px",
        backgroundColor: "rgba(237, 237, 237, 0.4)",
        color: "black",
      }}
    >
      <p className="text-2xl font-bold font-italic text-black">Raporlar</p>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flex: "1",
          justifyContent: "center",
        }}
      >
        <p className="text-xl font-bold font-italic text-black p-1">
          Dönem Seç:
        </p>
        <MultiSelect
          style={{
            border: "2px solid teal",
            borderRadius: "1rem",
            maxWidth: "50rem",
            marginTop: "0.5rem",
          }}
          value={selectedMonth}
          onChange={handleChange}
          options={months}
          display="chip"
          optionLabel="name"
          placeholder="Ay Seçiniz"
        />
      </div>
    </div>
  );
}
