import React, { useState } from 'react'
import { Splitter, SplitterPanel } from "primereact/splitter";
import CityPage from './components/CityPage';
import CityTable from './components/CityTable';

export default function CityGraph({
  uniqueCustomerId,
  selectedMonth,
  selectedCustomer,
  months,
}) {
  const [tableKey, setTableKey] = useState(0);
  const updateTableData = () => {
    setTableKey((prevKey) => prevKey + 1);
  };

  return (
    <Splitter style={{ height: "auto" }}>
      <SplitterPanel>
        <CityPage
          updateTableData={updateTableData}
          selectedMonth={selectedMonth}
          selectedCustomer={selectedCustomer}
          uniqueCustomerId={uniqueCustomerId}
        />
      </SplitterPanel>
      <SplitterPanel size={75} style={{ display: "block", padding: "1rem" }}>
        <CityTable
          months={months}
          key={tableKey}
          uniqueCustomerId={uniqueCustomerId}
        />
      </SplitterPanel>
    </Splitter>
  );
}
