import React, { useEffect, useState } from 'react'
import BenefitsReportPage from './components/BenefitsReportPage'
import { CustomerPanelBenefits } from '../../../../../../core/Services';
import { useAuth } from '../../../../../../auth/core/AuthContext';

export default function BenefitsReport() {
  const{userData}=useAuth();
  const [benefits , setBenefits] = useState("");
    useEffect(() => {
      CustomerPanelBenefits(userData?.unique_id)
        .then((data) => {
          setBenefits(data);
        })
        .catch((error) => {
          console.log("Benefits Hatası", error);
        });
    }, [userData?.unique_id]);
  return (
    <div>
      <strong>Faydalar</strong>
      <BenefitsReportPage benefits={benefits} />
    </div>
  );
}
