import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Flex,
  Button,
  Stack,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import Logo from "./Logo";
import { useToast } from "@chakra-ui/react";
import { useAuth } from "../../../../app/auth/core/AuthContext";
import { BsClipboardCheck } from "react-icons/bs";
import { BsCalendar4Week } from "react-icons/bs";
import { BsPen } from "react-icons/bs";
import { BsEnvelope } from "react-icons/bs";
import { BsChatSquareText } from "react-icons/bs";
import { BsBell } from "react-icons/bs";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { OverlayPanel } from "primereact/overlaypanel";
import { AnnounceService } from "../../../../app/core/Services";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";

const Header = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <NavBarContainer {...props}>
      <Logo w="180px" />
      <MenuToggle toggle={toggle} isOpen={isOpen} />
      <MenuLinks isOpen={isOpen} />
    </NavBarContainer>
  );
};

const CloseIcon = () => (
  <svg width="24" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <title>Kapat</title>
    <path
      fill="black"
      d="M9.00023 7.58599L13.9502 2.63599L15.3642 4.04999L10.4142 8.99999L15.3642 13.95L13.9502 15.364L9.00023 10.414L4.05023 15.364L2.63623 13.95L7.58623 8.99999L2.63623 4.04999L4.05023 2.63599L9.00023 7.58599Z"
    />
  </svg>
);

const MenuIcon = () => (
  <svg
    width="24px"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="teal"
  >
    <title>Menu</title>
    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
  </svg>
);

const MenuToggle = ({ toggle, isOpen }) => {
  return (
    <Box display={{ base: "block", md: "none" }} onClick={toggle}>
      {isOpen ? <CloseIcon /> : <MenuIcon />}
    </Box>
  );
};

const MenuLinks = ({ isOpen }) => {
  const [navSize] = useState("");
  const toast = useToast();
  const { userData } = useAuth();
  const menu = useRef(null);
  const { logout } = useAuth();
  const handleButtonClick = async (e) => {
    const success = await logout();

    if (success) {
    } else {
      toast({
        title: "Giriş Başarısız",
        description: "Kullanıcı Adı ya da Parola Hatalı",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };
  const items = [
    {
      label: (
        <>
          <Button
            label="Ekle"
            className="w-full"
            style={{
              backgroundColor: "",
              color: "#1f1f1f",
            }}
          >
            Profilim
          </Button>

          <Link to="/settings">
            <Button
              label="Ekle"
              className="w-full"
              style={{
                backgroundColor: "",
                color: "#1f1f1f",
              }}
            >
              Ayarlar
            </Button>
          </Link>

          <Button
            label="Ekle"
            className="w-full"
            style={{
              backgroundColor: "",
              color: "#1f1f1f",
            }}
            onClick={handleButtonClick}
          >
            Çıkış Yap
          </Button>
        </>
      ),
    },
  ];

  const today = new Date();
  const options = {
    month: "long",
    day: "numeric",
    weekday: "long",
  };
  const formattedDate = today.toLocaleDateString("tr-TR", options);
  const op = useRef(null);
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    return new Date(dateString).toLocaleString("tr-TR", options);
  };
  const [announces, setAnnounces] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
   
    const fetchData = () => {
      let logData = JSON.parse(localStorage.getItem("userData"));

      AnnounceService().then((data) => {
        setUsers(data.users);
        if (logData.type === "1") {
          const filteredAnnounces = data.announces
            ?.map((announce) => {
              const readAnnounces = announce.readers;
              const aimUserIds = JSON.parse(announce.aim_user);
              if (
                !readAnnounces?.includes(logData.id) &&
                aimUserIds?.includes(logData.id)
              ) {
                return announce;
              }
              return null;
            })
            .filter(Boolean);

          if (filteredAnnounces.length > 0) {
            setAnnounces(filteredAnnounces.reverse());
          } else {
            setAnnounces(null);
          }
        } else {
          const filteredAnnounces = data.announces
            ?.map((announce) => {
              const aimUserIds = JSON.parse(announce.aim_user);
              const readAnnounces = announce.readers;

              if (
                aimUserIds?.includes(logData.id) &&
                !readAnnounces?.includes(logData.id)
              ) {
                return announce;
              }
              return null;
            })
            .filter(Boolean);

          if (filteredAnnounces.length > 0) {
            setAnnounces(filteredAnnounces.reverse());
          } else {
            setAnnounces(null);
          }
        }
      });
    };
    fetchData(); 
    const interval = setInterval(fetchData, 10 * 60 * 1000); 
    return () => clearInterval(interval); 
  }, [op]);

  return (
    <>
      <Box
        display={{ base: isOpen ? "block" : "none", md: "block" }}
        flexBasis={{ base: "100%", md: "auto" }}
      >
        <Stack
          spacing={8}
          align="center"
          justify={["center", "space-between", "flex-end", "flex-end"]}
          direction={["column", "row", "row", "row"]}
          pt={[4, 4, 0, 0]}
        >
          {/* İconlar */}
          <Link
            to="/dashboard/calendar"
            style={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <BsCalendar4Week size="1.5rem" />
          </Link>
          <Link to="/dashboard/note">
            <BsPen size="1.5rem" />
          </Link>
          <Link to="/dashboard/todo">
            <BsClipboardCheck size="1.5rem" />
          </Link>
          <Link target="blank" to="https://webmail.ek.com.tr/">
            <BsEnvelope size="1.5rem" />
          </Link>
          <Link to="/dashboard/chat" target="new_blank">
            <BsChatSquareText size="1.5rem" />
          </Link>
          <Link
            to="#"
            style={{
              alignItems: "baseline",
              display: "flex",
              justifyContent: "end",
            }}
            onClick={(e) => op.current.toggle(e)}
          >
            <BsBell size="1.5rem" />
            {announces && (
              <div
                style={{
                  width: "10px",
                  height: "10px",
                  background: "orange",
                  borderRadius: "50%",
                  display: "inline-block",
                  position: "absolute",
                }}
              ></div>
            )}
          </Link>{" "}
          <OverlayPanel ref={op}>
            <Link to="/announcement">
              <DataTable
                value={announces}
                stripedRows
                selectionMode="single"
                scrollable
                scrollHeight="400px"
                rows={5}
                // selection={selectedProduct} onSelectionChange={(e) => setSelectedProduct(e.value)} onRowClick={productSelect}
              >
                <Column
                  field="subject"
                  header="Duyurularınız"
                  body={(rowData) => {
                    if (rowData.subject) {
                      const shortenedTitle =
                        rowData.subject.length > 15
                          ? rowData.subject.substring(0, 15) + "..."
                          : rowData.subject;
                      return shortenedTitle;
                    } else {
                      return "Bilinmeyen";
                    }
                  }}
                  style={{ minWidth: "8rem" }}
                />

                <Column
                  field="user_id"
                  body={(rowData) => {
                    const employee = users?.find(
                      (emp) => emp.id === Number(rowData.user_id)
                    );
                    return employee ? employee.name : "Bilinmeyen";
                  }}
                  header="Kimden"
                ></Column>
                <Column
                  style={{ width: "24%" }}
                  field="created_at"
                  body={(rowData) => formatDate(rowData.created_at)}
                  header="Tarih"
                ></Column>
              </DataTable>{" "}
            </Link>
            <div style={{ display: "flex", justifyContent: "end" }}>
              {/* <Link to="/announcement">
                <Button
                  style={{ color: "white" }}
                  className="pi pi-megaphone"
                  colorScheme="orange"
                ></Button>
              </Link> */}
            </div>
          </OverlayPanel>
          <Stack align="center" justify="flex-start">
            <Flex align="center">
              <Flex
                flexDir="column"
                display={navSize === "small" ? "none" : "flex"}
                style={{ color: "black !important" }}
              >
                <div className="card flex justify-content-center">
                  <Menu model={items} popup ref={menu} id="popup_menu_left" />
                  <Menu>
                    <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                      <div>
                        <span>
                          Merhaba,{" "}
                          {userData?.name && userData.name.split(" ")[0]}
                        </span>
                        <br />
                        <Text fontSize="sm" color="gray">
                          {formattedDate}
                        </Text>
                      </div>
                    </MenuButton>
                    <MenuList>
                      <Link to="/user/settings">
                        {" "}
                        <MenuItem>Profilim</MenuItem>
                      </Link>

                      <Link to="/settings">
                        <MenuItem>Ayarlar</MenuItem>
                      </Link>
                      <MenuItem onClick={handleButtonClick}>Çıkış Yap</MenuItem>
                    </MenuList>
                  </Menu>
                </div>
              </Flex>
            </Flex>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

const NavBarContainer = ({ children, ...props }) => {
  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
      mb={-5}
      p={8}
      bg="#EDEDED"
      color="black"
      {...props}
    >
      {children}
    </Flex>
  );
};

export default Header;
