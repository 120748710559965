import { Card } from "primereact/card";
import brandStatus from "../../../../../../../_ekdizayn/api/brand-status.json"
import brandOwner from "../../../../../../../_ekdizayn/api/owner.json"
import React from "react";

export default function DomainServices({ brand, formatDate }) {
  return (
    <div>
      {brand?.length ? (
        <Card
          style={{ minWidth: "15rem" }}
          className="d-flex h-auto w-auto  text-center"
          title="Markalarım"
        >
          {brand &&
            brand.map((brand, index) => {
              const currentDate = new Date();
              const renewalDate = new Date(brand?.renewal_date);
              const timeDifference =
                renewalDate.getTime() - currentDate.getTime();
              const daysRemaining = Math.ceil(
                timeDifference / (1000 * 60 * 60 * 24)
              );

              return (
                <div className="flex flex-column" key={index}>
                  <div className="flex text-left align-items-center text-gray h-auto justify-content-start  p-2 h-auto bg-cyan-100	 font-bold border-round m-2">
                    <div className="w-100 ">
                      <div className="d-flex">
                        <span>Başvuru Numarası:</span>
                        <span>{brand?.application_number}</span>
                      </div>
                      <div className="d-flex">
                        <span>Marka Adı:</span>
                        <span>{brand?.brand}</span>
                      </div>
                      <div className="d-flex ">
                        <span>Nice Sınıfları:</span>
                        <span>{brand?.nice_classes}</span>
                      </div>
                      <div className="d-flex">
                        <span>Durumu:</span>
                        <span>
                          {brandStatus.map((bra) =>
                            bra?.value === brand?.status ? bra.label : null
                          )}
                        </span>
                      </div>
                      <div className="d-flex ">
                        <span>Yöneten:</span>
                        <span>{brandOwner.map((own)=>
                            own?.value === brand?.owner ? own.label : null
                        )}</span>
                      </div>
                      <div className="d-flex ">
                        <span>Kayıt:</span>
                        <span>{formatDate(brand?.starting_date)}</span>
                      </div>
                      <div className="d-flex ">
                        <span>Bitiş:</span>
                        <span>{formatDate(brand?.renewal_date)}</span>
                      </div>
                      <div className="d-flex">
                        <span>Kalan Süre:</span>
                        <span>{daysRemaining} gün</span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </Card>
      ) : null}
    </div>
  );
}
