import React, { useEffect, useState } from "react";
import { Editor } from "primereact/editor";
import { Button } from "primereact/button";
import { AddNoteEditor, GetNoteEditor } from "../../../../../../../core/Services";
import { useToast } from "@chakra-ui/react";
import { useAuth } from "../../../../../../../auth/core/AuthContext";
import { Message } from "primereact/message";

export default function NotesPages({ uniqueCustomerId, selectedCustomer }) {
  const [text, setText] = useState("");
  const [note , setNote]= useState("")
  const {userData} = useAuth();
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(false);
    const toast = useToast();

    useEffect(() => {
      GetNoteEditor(uniqueCustomerId)
        .then((data) => {
          setNote(data.data);
        })
        .catch((error) => {
          console.log("Note Çekme Hatası", error);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCustomer]);
  

  const handleSave = () => {
    if (
      !text ||
      !selectedCustomer 
    ) {
      setError(true);
      toast({
        title: "Hata",
        description: "Lütfen tüm alanları doldurunuz.",
        status: "error",
        duration: 3000,
        position: "top-right",
        isClosable: true,
      });
      return;
    }

    
    AddNoteEditor(uniqueCustomerId, text , userData?.unique_id)
      .then((response) => {
        if (response.message === "Hata") {
          toast({
            title: "Hata",
            description: "Kullanıcya Ait Bu Veri Zaten Mevcut",
            status: "error",
            duration: 3000,
            position: "top-right",
            isClosable: true,
          });
        } else {

          setError(false);

          toast({
            title: "Başarılı",
            description: "Veri başarılı bir şekilde kaydedildi.",
            status: "success",
            duration: 3000,
            position: "top-right",
            isClosable: true,
          });

          
        }
      })
      .catch((error) => {
        console.error("Backend'e gönderme hatası:", error);
        toast({
          title: "Hata",
          description: "Veri gönderilirken bir hata oluştu.",
          status: "error",
          duration: 3000,
          position: "top-right",
          isClosable: true,
        });
      });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Message className="mt-2 mb-1 w-full " severity="info" text="Info Message" />
      <div className="card" style={{ marginBottom: "1rem" }}>
        <Editor
          value={note}
          onTextChange={(e) => setText(e.htmlValue)}
          style={{ height: "520px" }}
        />
      </div>
      <div style={{ marginLeft: "auto" }}>
        <Button
          style={{
            width: "100%",
            maxWidth: "10rem",
            height: "100%",
            maxHeight: "5rem",
          }}
          onClick={handleSave}
          className="btn-excel-two"
        >
          Kaydet
        </Button>
      </div>
    </div>
  );
}
