import React from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

export default function QuotationConfirmation({
  quotation,
  packagesData,
  urgencyData,
  columns,
  formatDate,
  regulation,
}) {
  return (
    <div>
     
      <div className="flex p-4">
        <strong className="text-2xl font-italic font-medium">Talepler</strong>
      </div>
      <DataTable value={quotation && quotation?.filter((quat)=> quat?.visible === "0" )} tableStyle={{ minWidth: "50rem" }}>
        {columns.map((col, i) => (
          <Column
            key={col.field}
            field={col.field}
            header={col.header}
            body={(rowData) => {
              if (col.field === "package_id") {
                const packageIds = rowData[col.field]?.split(",");
                const packageTitles = packageIds?.map((id) => {
                  const packageData = packagesData.find((pkg) => pkg.id === id);
                  return packageData ? packageData.title : "";
                });
                const result = packageTitles?.join(", ");
                return result || "Paket seçilmedi";
              } else if (col.field === "urgency") {
                const urgency = rowData[col.field].split(",");
                const urgencyLabel = urgency.map((id) => {
                  const urgenData = urgencyData.find((urg) => urg.value === id);
                  return urgenData ? urgenData.label : "";
                });

                const urgencyColors = urgency.map((id) => {
                  const urgenData = urgencyData.find((urg) => urg.value === id);
                  return urgenData ? urgenData.color : "";
                });

                const urgencyLabels = urgencyLabel.map((label, index) => (
                  <span
                    key={index}
                    style={{
                      backgroundColor: urgencyColors[index],
                      padding: "0.30rem",
                      color: "white",
                      borderRadius: "0.25rem",
                    }}
                    className="font-bold"
                  >
                    {label}
                  </span>
                ));
                return urgencyLabels;
              } else if (col.field === "created_at") {
                return formatDate(rowData[col.field]);
              } else if (col.field === "operations") {
                return regulation(rowData);
              } else {
                return rowData[col.field];
              }
            }}
          />
        ))}
      </DataTable>
    </div>
  );
}

