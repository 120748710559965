import React, { useState } from "react";
import { Button, Input, useToast } from "@chakra-ui/react";
import { Dropdown } from "primereact/dropdown";
import { AddSocialMedia } from "../../../../../../../core/Services";
import { Message } from "primereact/message";

export default function AddDataPage({
  uniqueCustomerId,
  selectedMonth,
  selectedCustomer,
  DataTypes,
  updateTableData
}) {

  const [selectedDataType, setSelectedDataType] = useState("");
  const [interactions, setInteractions] = useState("");
  const [access, setAccess] = useState("");
  const [click, setClick] = useState("");
  const [profilVisit, setProfileVisit] = useState("");
  const [value, setValue] = useState("");
  const [totalFollower, setTotalFollower] = useState("");
  const [error, setError] = useState(false);
  const toast = useToast();



  const handleSave = () => {
    if (!selectedCustomer || !selectedMonth || !selectedDataType || !value) {
      setError(true);
      toast({
        title: "Hata",
        description: "Lütfen tüm alanları doldurunuz.",
        status: "error",
        duration: 3000,
        position: "top-right",
        isClosable: true,
      });
      return;
    }

    AddSocialMedia(
      uniqueCustomerId,
      selectedDataType,
      value,
      selectedMonth,
      totalFollower,
      access,
      interactions,
      profilVisit,
      click
    )
      .then((response) => {
        if (response.message === "Hata") {
          toast({
            title: "Hata",
            description: "Kullanıcya Ait Bu Ay Verisi Zaten Mevcut",
            status: "error",
            duration: 3000,
            position: "top-right",
            isClosable: true,
          });
        } else {
          setSelectedDataType("");
          setValue("");
          setAccess("");
          setProfileVisit("")
          setInteractions("");
          setError(false);
          setTotalFollower("");

          toast({
            title: "Başarılı",
            description: "Veri başarılı bir şekilde kaydedildi.",
            status: "success",
            duration: 3000,
            position: "top-right",
            isClosable: true,
          });
          updateTableData();
        }
      })
      .catch((error) => {
        console.error("Backend'e gönderme hatası:", error);
        toast({
          title: "Hata",
          description: "Veri gönderilirken bir hata oluştu.",
          status: "error",
          duration: 3000,
          position: "top-right",
          isClosable: true,
        });
      });
  };

  return (
    <div className="w-full">
      <div></div>
      <div className="mt-2 font-bold w-full">Veri Adı : </div>
      <div>
        <Dropdown
          style={{
            border: `1px solid ${error && !selectedDataType ? "red" : ""}`,
            borderRadius: "1rem",
          }}
          className="w-full"
          value={selectedDataType}
          options={DataTypes?.map((type) => ({
            label: type.name,
            value: type.value,
          }))}
          onChange={(e) => setSelectedDataType(e.value)}
          placeholder="Veri Adı Seçiniz"
        />
      </div>

      <div className="mt-2 font-bold w-full">Yeni Takipçi : </div>
      <div>
        <Input
          placeholder="Yeni Takipçi"
          className="w-full"
          name="value"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          borderColor={error && !value ? "red" : ""}
        />
      </div>
      <div className="mt-2 font-bold w-full">Toplam Takipçi : </div>
      <div>
        <Input
          placeholder="Toplam Takipçi"
          className="w-full"
          value={totalFollower}
          onChange={(e) => setTotalFollower(e.target.value)}
          borderColor={error && !value ? "red" : ""}
        />
      </div>

      <div className="mt-2 font-bold w-full">Erişim : </div>
      <div>
        <Input
          placeholder="Erişim"
          className="w-full"
          name="value"
          value={access}
          onChange={(e) => setAccess(e.target.value)}
          borderColor={error && !value ? "red" : ""}
        />
      </div>
      <div className="mt-2 font-bold w-full">Etkileşim : </div>
      <div>
        <Input
          placeholder="Etkileşim"
          className="w-full"
          value={interactions}
          onChange={(e) => setInteractions(e.target.value)}
          borderColor={error && !value ? "red" : ""}
        />
      </div>
      <div className="mt-2 font-bold w-full"> Ziyaretler : </div>
      <div>
        <Input
          placeholder="Profil Ziyaretleri"
          className="w-full"
          value={profilVisit}
          onChange={(e) => setProfileVisit(e.target.value)}
          borderColor={error && !value ? "red" : ""}
        />
      </div>
      <div className="mt-2 font-bold w-full"> Tıklamalar : </div>
      <div>
        <Input
          placeholder="Bağlantı Tıklamaları"
          className="w-full"
          value={click}
          onChange={(e) => setClick(e.target.value)}
          borderColor={error && !value ? "red" : ""}
        />
      </div>
      <div className="mt-5 flex justify-content-end">
        <Button onClick={handleSave}> Kaydet</Button>
      </div>
      <Message
        className="mt-4 w-full"
        severity="info"
        text="FB ve IG : Meta Business Suite > İstatistikler
        Youtube : YouTube > Studio > Analytics
        LinkedIn : LinkedIn > Sayfalarım > Analizler"
      />
    </div>
  );
}
