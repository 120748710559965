
import React from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import Guarantee from "./components/Guarantee";

export default function GuaranteePage() {


  const getTurkishLabel = (url) => {
    switch (url) {
      case "/guarantee":
        return "Garanti";

      default:
        return "Sayfa";
    }
  };
  const url = "/guarantee";
  const turkishLabel = getTurkishLabel(url);

  const itemsBread = [{ label: turkishLabel, url: url }];
  const home = { icon: "pi pi-home", url: "/dashboard" };
  return (
    <div mt="5">
      <BreadCrumb model={itemsBread} home={home} style={{ padding: "1rem" }} />
      <Guarantee />
    </div>
  );
}
