import React from "react";
import VersiyonBar from "./components/VersiyonBar";

export default function Version() {
  const customerVersionBar = (
    <p>
      COPYRIGHT <span>&#169;</span> EK PANEL VERS. 1.1 | BİR EK DİZAYN REKLAM
      AJANSI TEKNOLOJİSİDİR.
    </p>
  );

  const employeesVersionBar = (
    <p>
      COPYRIGHT <span>&#169;</span> EK CLOUD VERS. 1.1 | BİR EK DİZAYN REKLAM
      AJANSI TEKNOLOJİSİDİR.
    </p>
  );
  return (
    <VersiyonBar
      customerVersionBar={customerVersionBar}
      employeesVersionBar={employeesVersionBar}
    />
  );
}
