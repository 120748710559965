import React, { useEffect, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import MailAccountsApperance from "./components/mail-accounts/MailAccountsApperance";
import { Dropdown } from "primereact/dropdown";
import { Box, Flex } from "@chakra-ui/react";
import { CustomerService } from "../../../../../core/Services";
import OtherAccountsApperance from "./components/other-accounts/OtherAccountsApperance";
import Notes from "./components/notes/Notes";


export default function EmployeesInformationPage()
 {
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [error] = useState(false);
    const [uniqueCustomerId, setUniqueCustomerId] = useState("");
    const [customers, setCustomers] = useState([]);
    
    useEffect(() => {
      CustomerService()
        .then((data) => {
          setCustomers(data);
        })
        .catch((error) => {
          console.log("Müşteri Çekme Hatası", error);
        });
        
    }, []);
    
  

 
  return (
    <div className="card">
      <Flex
        as="nav"
        align="center"
        justify="center"
        wrap="wrap"
        w="100%"
        mb={-5}
        p={8}
        bg="rgba(237, 237, 237, 0.8)"
        color="black"
      >
        <Box>
          <div className="justify-content-center flex ">
            <div style={{ marginRight: "1rem" }}>
              <Dropdown
                showClear
                filter
                onChange={(e) => {
                  const selectedCustomerId = e.value;
                  const selectedCustomer = customers.find(
                    (customer) => customer.id === selectedCustomerId
                  );
                  setSelectedCustomer(selectedCustomerId);
                  setUniqueCustomerId(selectedCustomer?.unique_id);
                }}
                options={
                  customers && customers?.length > 0
                    ? customers?.map((customer) => ({
                        label: customer.title,
                        value: customer.id,
                      }))
                    : []
                }
                value={selectedCustomer}
                placeholder="Müşteri Adı / Ünvanı"
                style={{
                  border: `2px solid ${
                    error && !selectedCustomer ? "red" : ""
                  }`,
                  borderRadius: "1rem",
                  width: "20rem",
                }}
              />
            </div>
          </div>
        </Box>
      </Flex>
      <TabView>
        <TabPanel header="Mail Hesapları">
          <MailAccountsApperance
            uniqueCustomerId={uniqueCustomerId}
            selectedCustomer={selectedCustomer}
          />
        </TabPanel>
        <TabPanel header=" + Şifreler ">
          <OtherAccountsApperance
            uniqueCustomerId={uniqueCustomerId}
            selectedCustomer={selectedCustomer}
          />
        </TabPanel>
        <TabPanel header="Notlar">
          <Notes
            uniqueCustomerId={uniqueCustomerId}
            selectedCustomer={selectedCustomer}
          />
        </TabPanel>
      </TabView>
    </div>
  );
}
