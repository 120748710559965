import React, { useEffect, useState } from "react";
import { GetCity } from "../../../../../../../core/Services";
import { useAuth } from "../../../../../../../auth/core/AuthContext";


export default function CityList() {
  const [city, setCity] = useState([]);
  
  
  
  const {userData} = useAuth();
  

   const months = [
     { name: "Ocak", value: "01" },
     { name: "Şubat", value: "02" },
     { name: "Mart", value: "03" },
     { name: "Nisan", value: "04" },
     { name: "Mayıs", value: "05" },
     { name: "Haziran", value: "06" },
     { name: "Temmuz", value: "07" },
     { name: "Ağustos", value: "08" },
     { name: "Eylül", value: "09" },
     { name: "Ekim", value: "10" },
     { name: "Kasım", value: "11" },
     { name: "Aralık", value: "12" },
   ];

  useEffect(() => {
    GetCity(userData?.unique_id)
      .then((data) => {
        setCity(data.data);
      })
      .catch((error) => {
        console.log("Ülke Veri Çekme Hatası", error);
      });
  }, [userData?.unique_id]);



  return (
    <div>
      <table
        style={{
          fontFamily: "arial, sans-serif",
          borderCollapse: "collapse",
          width: "80%",
        }}
      >
        <thead>
          <tr>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            ></th>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              Dönem
            </th>

            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              Şehirler
            </th>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              Ziyaretçi Sayısı
            </th>
          </tr>
        </thead>
        <tbody>
          {city &&
            city?.map((city, index) => (
              <tr
                key={city.id}
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                <td
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  {index + 1}
                </td>
                <td
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  {months?.find((months) => months.value === city.month)
                    ?.name || ""}
                </td>
                <td
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  {city.city}
                </td>
                <td
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  {city.visitor}
                </td>
               
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}
