import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import phone from "../../../../_ekdizayn/api/phone.json";
import formUrgency from "../../../../_ekdizayn/api/urgency.json";
import cookiesText from "../../../../_ekdizayn/api/cookies.json";
import ReactFlagsSelect from "react-flags-select";
import { Card } from "primereact/card";
import { Checkbox } from "primereact/checkbox";
import { Link } from "react-router-dom";

export default function Form({
  selectedUrgency,
  setSelectedUrgency,
  form,
  setForm,
  errors,
  selectedCounrtyPhone,
  setSelectedCounrtyPhone,
  selectedCookies,
  setSelectedCookies,
}) {
  const [urgency, setUrgency] = useState([]);
  const [cookies, setCookies] = useState([]);
  const onChangeInput = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const [countryPhone, setCountryPhone] = useState([]);
  useEffect(() => {
    setUrgency(formUrgency);
    setCountryPhone(phone);
    setCookies(cookiesText);
  }, []);

  const handleCookieSelect = (cookie) => {
    if (selectedCookies.includes(cookie)) {
      setSelectedCookies(
        selectedCookies.filter((selectedPack) => selectedPack !== cookie)
      );
    } else {
      setSelectedCookies([...selectedCookies, cookie]);
    }
  };

  return (
    <Card
      style={{
        border: "1px solid gray",
      }}
      className=" pr-2 pl-2 pb-0 pt-0"
    >
      <div className="confirmation-content">
        <div className="grid">
          <div
            className="col-12 md:col-6 lg:col-6 "
            style={{ display: "inline-block" }}
          >
            <strong>Adınız:</strong>

            <InputText
              style={{
                border: "1px solid gray",
                padding: "0.5rem",
                width: "100%",
              }}
              name="name"
              value={form?.name}
              onChange={onChangeInput}
              placeholder="Adınız.."
            />
            <div className="flex justify-content-start">
              {errors.name && (
                <span className="text-red-500">{errors.name}</span>
              )}
            </div>

            <strong>E-posta:</strong>

            <InputText
              style={{
                border: "1px solid gray",
                padding: "0.5rem",
                width: "100%",
              }}
              name="email"
              value={form?.email}
              onChange={onChangeInput}
              placeholder="E-posta.."
            />

            {errors.email && (
              <span className="text-red-500">{errors.email}</span>
            )}

            <strong>Aciliyet durumu:</strong>

            <Dropdown
              value={selectedUrgency}
              onChange={(e) => setSelectedUrgency(e.value)}
              options={urgency}
              optionLabel="label"
              style={{
                border: "1px solid gray",
                width: "100%",
              }}
              placeholder="Aciliyet Durumu"
            />

            {errors.selectedUrgency && (
              <span className="text-red-500">{errors.selectedUrgency}</span>
            )}
          </div>
          <div
            className="col-12 md:col-6 lg:col-6 "
            style={{ display: "inline-block" }}
          >
            <strong>Soyadınız:</strong>

            <InputText
              style={{
                border: "1px solid gray",
                padding: "0.5rem",
                width: "100%",
              }}
              name="surname"
              value={form?.surname}
              onChange={onChangeInput}
              placeholder="Soyadınız.."
            />

            {errors.surname && (
              <span className="text-red-500">{errors.surname}</span>
            )}

            <strong>Telefon:</strong>

            <div className="flex gap-2" style={{ width: "100%" }}>
              <div style={{ width: "30%" }}>
                <ReactFlagsSelect
                  countries={Object.keys(countryPhone)}
                  customLabels={countryPhone}
                  placeholder="Ülke Seçiniz"
                  selected={selectedCounrtyPhone}
                  onSelect={(code) => setSelectedCounrtyPhone(code)}
                />
              </div>
              <div className="w-full">
                <InputText
                  style={{
                    border: "1px solid gray",
                    padding: "0.5rem",
                    width: "100%",
                  }}
                  name="phone"
                  value={form?.phone}
                  onChange={(e) => {
                    let inputValue = e.target.value;
                    if (!/^\d*$/.test(inputValue)) {
                      return;
                    }
                    if (inputValue.length > 10 || inputValue.startsWith("0")) {
                      return;
                    }
                    setForm({ ...form, phone: inputValue });
                  }}
                  placeholder="Telefon.."
                />

                {errors.phone && (
                  <span className="text-red-500">{errors.phone}</span>
                )}
              </div>
            </div>

            <strong>Projenizi Tamamlayın:</strong>

            <InputTextarea
              style={{
                width: "100%",
                border: "1px solid gray",
                padding: "0.5rem",
                height: "9rem",
              }}
              name="description"
              value={form?.description}
              onChange={onChangeInput}
              rows={5}
              cols={30}
            />
          </div>
        </div>
      </div>

      <div
        style={{ border: "1px solid gray", borderRadius: "10px" }}
        className="flex justify-content-center  align-items-center"
      >
        {cookies &&
          cookies.map((cookie, i) => (
            <div key={i} className="flex align-items-center">
              <Checkbox
                className="ml-2"
                inputId={`pack-checkbox-${cookie.id}`}
                checked={selectedCookies.includes(cookie.id)}
                onChange={() => handleCookieSelect(cookie.id)}
              />
              <Link
                to="https://ek.com.tr/kisisel-verilerin-korunmasi-aydinlatma-metni/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {cookie.name}
              </Link>
            </div>
          ))}
      </div>
      <div className="flex justify-content-center">
        {errors.selectedCookies && (
          <span className="text-red-500">{errors.selectedCookies}</span>
        )}
      </div>
    </Card>
  );
}
