import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Card, CardHeader, Flex, Heading } from "@chakra-ui/react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import {
  AddDeparment,
  DepartmentService,
  deleteDepartment,
  updateDepartment,
} from "../../../../core/Services";
import { BreadCrumb } from "primereact/breadcrumb";
import { useAuth } from "../../../../auth/core/AuthContext";

export default function DepartmentPage() {
  const [departments, setDepartments] = useState([]);
  const [displayAddDialog, setDisplayAddDialog] = useState(false);
  const [displayDeleteDialog, setDisplayDeleteDialog] = useState(false);
  const [newDepartment, setNewDepartment] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [dialogs, setDialogs] = useState({});
  const [selectedTask, setSelectedTask] = useState(null);
  const toast = useRef(null);
  const { userData } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const departmentData = await DepartmentService();
        setDepartments(departmentData);
      } catch (error) {
        console.error("Error fetching department data:", error);
      }
    };

    fetchData();
  }, [displayAddDialog, displayDeleteDialog, dialogs]);

  const showAddDialog = () => {
    setNewDepartment("");
    setDisplayAddDialog(true);
  };

  const hideAddDialog = () => {
    setDisplayAddDialog(false);
  };

  const hideDeleteDialog = () => {
    setDisplayDeleteDialog(false);
    setNewDepartment("");
    setSelectedId(null);
  };

  const handleCompleteTask = async () => {
    try {
      if (!selectedTask) {
        console.error("Düzenlenecek görev bulunamadı.");
        return;
      }
      await updateDepartment(selectedTask.id, newDepartment);
      setDialogs(false);
    } catch (error) {
      console.error("Hata:", error.message);
    }
  };

  const handleAddDepartment = async () => {
    try {
      const result = await AddDeparment(newDepartment);

      if (result) {
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "Departman başarıyla eklendi.",
        });
        hideAddDialog();
        const updatedDepartments = await DepartmentService();
        setDepartments(updatedDepartments);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: "Departman eklenirken bir hata oluştu.",
        });
      }
    } catch (error) {
      console.error("Error adding department:", error);
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Departman eklenirken bir hata oluştu.",
      });
    }
  };

  const handleDeleteDepartment = async () => {
    try {
      const result = await deleteDepartment(selectedId);

      if (result) {
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "Departman başarıyla silindi.",
        });
        hideDeleteDialog();
        const updatedDepartments = await DepartmentService();
        setDepartments(updatedDepartments);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: "Departman silinirken bir hata oluştu.",
        });
      }
    } catch (error) {
      console.error("Error deleting department:", error);
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Departman silinirken bir hata oluştu.",
      });
    }
  };
  const EditBodyTemplate = (tasks) => {
    const isVisible = dialogs[tasks.id] || false;

    useEffect(() => {
      if (isVisible) {
        setNewDepartment(tasks.department || "");
      }
    }, [isVisible, tasks]);

    return (
      <React.Fragment>
        <div style={{ display: "flex" }}>
          <div style={{ color: "#f39f5a" }}>
            <Button
              severity="warning"
              onClick={() => {
                setSelectedTask(tasks);
                setDialogs((prevDialogs) => ({
                  ...prevDialogs,
                  [tasks.id]: true,
                }));
              }}
              icon="pi pi-pencil"
            />
          </div>
          <div style={{ color: "#ae445a" }}>
            <Button
              icon="pi pi-trash"
              onClick={() => {
                setSelectedId(tasks.id);
                setDisplayDeleteDialog(true);
                setNewDepartment(tasks?.department);
              }}
              severity="danger"
            />
          </div>
        </div>
        <Dialog
          header="İçerik Düzenleme"
          visible={isVisible}
          style={{ width: "40vw" }}
          onHide={() => {
            setDialogs((prevDialogs) => ({
              ...prevDialogs,
              [tasks.id]: false,
            }));
            setSelectedTask(null);
          }}
          footer={
            <div>
              <Button
                label="Kaydet"
                icon="pi pi-check"
                onClick={handleCompleteTask}
                className="btn-excel-two"
              />
            </div>
          }
        >
          <div className="confirmation-content">
            <div className="p-grid p-fluid">
              <div className="p-col-12">
                <div>
                  <InputText
                    type="text"
                    className="p-inputtext-lg w-full"
                    placeholder="Departman Adı"
                    defaultValue={newDepartment}
                    onChange={(e) => setNewDepartment(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </React.Fragment>
    );
  };
  const getTurkishLabel = (url) => {
    switch (url) {
      case "/departments":
        return "Departmanlar";
      default:
        return "Sayfa";
    }
  };
  const url = "/departments";
  const turkishLabel = getTurkishLabel(url);

  const itemsBread = [{ label: turkishLabel, url: url }];
  const home = { icon: "pi pi-home", url: "/dashboard" };
  return (
    <Flex mt="5" justifyContent="center" alignItems="center">
      <div
        className="card"
        style={{
          overflow: "auto",
          boxSizing: "border-box",
          width: "100%",
          height: "100%",
          position: "relative",
          padding: "1rem",
        }}
      >
        <Card>
          <BreadCrumb
            model={itemsBread}
            home={home}
            style={{ padding: "1rem" }}
          />
          <CardHeader>
            <Flex justifyContent="space-between" alignItems="center">
              <Heading size="md">Departman Ekle</Heading>
              {userData?.type === "1" && (
                <div>
                  <Button
                    label="Yeni"
                    onClick={showAddDialog}
                    className="btn-excel-two"
                  />
                </div>
              )}
            </Flex>
          </CardHeader>
          <Toast ref={toast} />
          <DataTable
            stripedRows
            size="small"
            value={departments}
            dataKey="id"
            tableStyle={{ minWidth: "50rem" }}
            rows={15}
            rowsPerPageOptions={[15, 30, 60, 100]}
            scrollHeight="65vh"
            scrollable
            paginator
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{totalRecords} departmandan {first} - {last} arası gösteriliyor"
          >
            {userData?.type === "1" && (
              <Column
                style={{ width: "8rem" }}
                body={EditBodyTemplate}
                header="İşlemler"
              ></Column>
            )}
            <Column field="department" header="Departman" />
          </DataTable>
        </Card>
      </div>
      <Dialog
        visible={displayDeleteDialog}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        style={{ width: "60vw" }}
        header="Departman Sil"
        onHide={hideDeleteDialog}
        modal
      >
        <div className="confirmation-content">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <p>Departmanı silmek istediğinize emin misiniz?</p>
          </div>
          <div className="field">
            <label htmlFor="name" className="font-bold">
              Departman: {newDepartment}
            </label>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "1rem",
            }}
          >
            <Button
              label="Hayır"
              onClick={hideDeleteDialog}
              className="btn-excel-two"
              style={{ marginRight: "1rem" }}
            />
            <Button
              label="Evet"
              onClick={handleDeleteDepartment}
              className="btn-excel-two"
            />
          </div>
        </div>
      </Dialog>
      <Dialog
        visible={displayAddDialog}
        breakpoints={{ "960px": "55vw", "641px": "50vw" }}
        style={{ width: "40vw" }}
        header="Departman Ekle"
        onHide={hideAddDialog}
        modal
      >
        <div className="confirmation-content">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ flex: 1 }}>
              <InputText
                type="text"
                className="p-inputtext-lg w-full"
                placeholder="Departman Adı"
                onChange={(e) => setNewDepartment(e.target.value)}
                value={newDepartment}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "1rem",
            }}
          >
            <Button
              label="Ekle"
              onClick={handleAddDepartment}
              style={{ width: "8rem" }}
              className="btn-excel-two"
            />
          </div>
        </div>
      </Dialog>
    </Flex>
  );
}
