import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../../../auth/core/AuthContext";
import { FileUpload } from "primereact/fileupload";
import {
  EditUserService,
} from "../../../../../../core/Services";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Grid,
  GridItem,
  Heading,
  Input,
  Text,
  WrapItem,
} from "@chakra-ui/react";
import { Avatar } from "@chakra-ui/react";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { BsPatchCheck } from "react-icons/bs";
import { BsFillPersonFill } from "react-icons/bs";

export default function Profile({ user }) {
  const [name, setName] = useState("");
  const [formattedFileContent, setFormattedFileContent] = useState(null);
  const [image, setImage] = useState("");
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState(null);
  const [type, setType] = useState(null);
  const { userData } = useAuth();

  const onUpload = (event) => {
    const uploadedFile = event.files && event.files[0];
    if (uploadedFile) {
      const reader = new FileReader();

      reader.onload = async (e) => {
        const fileContent = e.target.result;

        // Convert file content to Base64
        const formattedContent = btoa(fileContent);

        // Update the state variable with the Base64 content
        await setFormattedFileContent(formattedContent);
      };

      reader.readAsBinaryString(uploadedFile);
    }
  };

  const updateUser = async () => {
    let requestBody = {
      id: userData?.id,
      newName: name,
      newEmail: mail,
      newImage: formattedFileContent,
      newPhone: phone,
      newPassword: password,
    };
    await EditUserService(requestBody);
    window.location.reload();
  };

  useEffect(() => {
    if (user) {
      setName(user?.name);
      setImage(user?.image);
      setMail(user?.email);
      setPhone(user?.phone);
    }

    if (userData?.type === "2") {
      setType("Müşteri");
    } else if (userData?.type === "0") {
      setType("Bilinmeyen");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <Card
        height="89vh"
        style={{ overflow: "scroll" }}
        backgroundColor="#white"
      >
        <CardHeader>
          <Heading size="sm">Kullanıcı Ayarları</Heading>
        </CardHeader>
        <CardBody>
          <Grid
            h="90vh"
            templateRows="repeat(7, 1fr)"
            templateColumns="repeat(2, 1fr)"
            gap={2}
          >
            <GridItem
              p="0.5rem"
              rowSpan={1}
              colSpan={2}
              bg="#e2e2e2"
              borderRadius="20px"
            >
              <div style={{ marginLeft: "1rem" }}>
                <WrapItem>
                  <Avatar
                    marginTop="1rem"
                    style={{ width: "10rem", height: "10rem" }}
                    src={`data:image/png;base64,${image}`}
                  />
                  <div style={{ display: "grid" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "2rem",
                      }}
                    >
                      <Text marginLeft="0.8rem" fontSize="1.5rem">
                        {name}
                      </Text>
                      <BsPatchCheck style={{ marginLeft: "0.5rem" }} />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "0.8rem",
                      }}
                    >
                      <BsFillEnvelopeFill style={{ marginLeft: "0.5rem" }} />
                      <Text fontSize="0.8rem" marginLeft="0.5rem">
                        {mail}
                      </Text>
                      <BsFillPersonFill style={{ marginLeft: "0.5rem" }} />
                      <Text fontSize="0.8rem">{type}</Text>
                    </div>
                  </div>
                </WrapItem>
              </div>
            </GridItem>
            <GridItem
              p="0.5rem"
              rowSpan={5}
              colSpan={2}
              bg="#e2e2e2"
              borderRadius="20px"
            >
              <div style={{ marginLeft: "1rem" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Text>Fotoğraf:</Text>
                  <Avatar
                    name={name}
                    src={`data:image/png;base64,${image}`}
                    style={{ width: "5rem", height: "5rem" }}
                    marginLeft="5rem"
                  ></Avatar>
                  <FileUpload
                    chooseLabel="Dosya Seç"
                    style={{
                      marginLeft: "1rem",
                      textAlign: "left",
                      color: "black",
                    }}
                    accept="image/*"
                    maxFileSize={2000000}
                    auto
                    mode="basic"
                    pt={{
                      content: { className: "surface-ground" },
                      message: {
                        root: {
                          className: "w-1rem",
                        },
                      },
                    }}
                    uploadHandler={onUpload}
                    customUpload
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    marginTop: "2rem",
                    border: "black",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Text>İsim:</Text>
                  <Input
                    onChange={(e) => setName(e.target.value)}
                    style={{
                      marginLeft: "1rem",
                      width: "100%",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                    value={name}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginTop: "2rem",
                    border: "black",
                  }}
                >
                  <Text>Email:</Text>
                  <Input
                    onChange={(e) => setMail(e.target.value)}
                    style={{ marginLeft: "0.5rem", width: "100%" }}
                    value={mail}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginTop: "2rem",
                    border: "black",
                  }}
                >
                  <Text>Telefon:</Text>
                  <Input
                    onChange={(e) => setPhone(e.target.value)}
                    style={{ marginLeft: "0.2rem", width: "100%" }}
                    value={phone}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginTop: "2rem",
                    border: "black",
                  }}
                >
                  <Text>Parola:</Text>
                  <Input
                    onChange={(e) => setPassword(e.target.value)}
                    style={{ marginLeft: "0.2rem", width: "100%" }}
                    value={password}
                  />
                </div>
              </div>
            </GridItem>
            <GridItem rowSpan={1} colSpan={2}>
              <div style={{ display: "flex", justifyContent: "right" }}>
                <Button
                  onClick={() => updateUser(userData?.id)}
                  bgColor="#e2e2e2"
                  border="2px solid white"
                  color="black"
                  borderRadius="20px"
                  width="40%"
                >
                  Kaydet
                </Button>
              </div>
            </GridItem>
          </Grid>
        </CardBody>
      </Card>
    </>
  );
}

