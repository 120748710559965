import { Card } from "primereact/card";
import React from "react";

export default function DomainServices({ domains, formatDate }) {
  return (
    <div>
      {domains.length ? (
        <Card
          style={{ minWidth: "15rem" }}
          className="d-flex h-auto w-auto  text-center"
          title="Alan Adlarım"
        >
          {domains &&
            domains.map((domain, index) => {
              const currentDate = new Date();
              const renewalDate = new Date(domain?.renewal_date);
              const timeDifference =
                renewalDate.getTime() - currentDate.getTime();
              const daysRemaining = Math.ceil(
                timeDifference / (1000 * 60 * 60 * 24)
              );

              return (
                <div className="flex flex-column" key={index}>
                  <div className="flex text-left align-items-center text-gray h-auto justify-content-start  p-2 h-auto bg-cyan-100	 font-bold border-round m-2">
                    <div className="w-100 ">
                      <div className="d-flex">
                        <span>Alan Adı:</span>
                        <span>{domain?.domain_name}</span>
                      </div>
                      <div className="d-flex ">
                        <span>Platform:</span>
                        <span>{domain?.platform}</span>
                      </div>
                      <div className="d-flex ">
                        <span>Yöneten:</span>
                        <span>{domain?.owner}</span>
                      </div>
                      <div className="d-flex ">
                        <span>Kayıt:</span>
                        <span>{formatDate(domain?.starting_date)}</span>
                      </div>
                      <div className="d-flex ">
                        <span>Bitiş:</span>
                        <span>{formatDate(domain?.renewal_date)}</span>
                      </div>
                      <div className="d-flex">
                        <span>Kalan Süre:</span>
                        <span>{daysRemaining} gün</span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </Card>
      ) : null}
    </div>
  );
}
