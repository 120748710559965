import React, { useEffect, useState } from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import CustomerNavbar from "../components/Navbar/CustomerNavbar";
import CustomerSidebar from "../components/Sidebar/CustomerSidebar";
import CustomerServicesPage from "./CustomerServicesPage";
import { useAuth } from "../../../../../auth/core/AuthContext";
import { GetTracksAndLicenceByUniqueId } from "../../../../../core/Services";

export default function CustomerServices() {
const [service, setService] = useState("");
const [hosting, setHosting] = useState("");
const [domains, setDomains] = useState("");
const [license, setLicense] = useState("");
const [brand, setBrand] = useState("");
const {userData} = useAuth();
  useEffect(() => {
    if (userData?.unique_id) {
      GetTracksAndLicenceByUniqueId(userData?.unique_id)
        .then((data) => {
          setService(data?.services);
          setDomains(data?.domains);
          setLicense(data?.license);
          setHosting(data?.hosting);
          setBrand(data?.brand)
        })
        .catch((error) => {
          console.log("Customer Hatası", error);
        });
    }
  }, [userData?.unique_id]);

  return (
    <Grid
      templateAreas={`"header header"
                  "nav main"
                 `}
      gridTemplateRows={"auto 1fr "}
      gridTemplateColumns={"auto 1fr"}
      h="auto"
      color="blackAlpha.700"
      fontWeight="bold"
    >
      <GridItem area={"header"}>
        <CustomerNavbar />
      </GridItem>

      <GridItem area={"nav"}>
        <CustomerSidebar />
      </GridItem>

      <GridItem mt="5" area={"main"}>
        
        <CustomerServicesPage brand={brand} license={license} domains={domains} hosting={hosting} service={service} />
      </GridItem>
    </Grid>
  );
}
