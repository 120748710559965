import React from "react";
import "../css/Note.css";
import ReactMarkdown from "react-markdown";
import { Button, useToast } from "@chakra-ui/react";
import { UpdateNote } from "../../../../core/Services";

export default function NoteMain({ activeNote, onUpdateNote }) {
  const toast = useToast();
  const onEditField = (key, value) => {
    onUpdateNote({
      ...activeNote,

      [key]: value,
    });
  };

  const onSaveNote = async () => {
    try {
      
      await UpdateNote(activeNote.id, activeNote.title, activeNote.description);

      toast({
        title: "Başarılı",
        description: "Notunuz başarıyla kaydedildi",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      console.error("Not güncellenirken bir hata oluştu:", error);
      toast({
        title: "Hata",
        description: "Notunuz kaydedilirken bir hata oluştu",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  if (!activeNote) return <div className="no-active-note">Not Seçilmedi</div>;

  return (
    <div className="app-main">
      <div className="app-main-note-edit">
        <input
          type="text"
          id="title"
          value={activeNote.title}
          onChange={(e) => onEditField("title", e.target.value)}
          autoFocus
        />
        <textarea
          id="description"
          placeholder="Notunuzu Buraya Yazın"
          onChange={(e) => onEditField("description", e.target.value)}
          value={activeNote.description}
        />
        <Button
          onClick={onSaveNote}
          style={{ width: "12%", height: "7%", borderRadius: "8px" }}
        >
          Kaydet
        </Button>
      </div>
      <div className="app-main-note-preview">
        <h1 className="preview-title">{activeNote.title}</h1>
        <ReactMarkdown className="markdown-preview">
          {activeNote.description || ""}
        </ReactMarkdown>
      </div>
    </div>
  );
}
