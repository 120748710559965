import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { OrderService, OfferContentService } from "../../../../core/Services";

import { Button } from "primereact/button";

import { Image } from "primereact/image";

import { Toast } from "primereact/toast";
import { Card, CardHeader, Flex, Heading } from "@chakra-ui/react";

import { Dialog } from "primereact/dialog";
import { BreadCrumb } from "primereact/breadcrumb";
import imageBlack from "../images/pdf-footer-black.png";
import imageBlue from "../images/pdf-footer-blue.png";
import blue from "../images/mavi.png";
import logo from "../images/logo.png";
export default function Orders() {
  const [customers, setCustomers] = useState([]);
  const [addModal, setAddModal] = useState(false);

  const [offers, setOffers] = useState("");

  const [dialogsPdf, setDialogsPdf] = useState({});
  // const [dialogsDelete, setDialogsDelete] = useState({});
  const [selectedData, setSelectedData] = useState([]);

  const [products, setProducts] = useState([]);

  const [selectedProd, setSelectedProd] = useState([]);

  const [totalAmount, setTotalAmount] = useState(0);
  const [KDVTax, setKDV] = useState("");
  const hideDialog = () => {
    setAddModal(false);

    setDialogsPdf(false);
    //setDialogsDelete(false);
    setSelectedData(false);

    setSelectedProd([]);
  };
  const getOfferData = async (reqBody) => {
    await OfferContentService(reqBody).then((data) => {
      setSelectedProd(data);
    });
  };
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    return new Date(dateString).toLocaleString("tr-TR", options);
  };
  const modalContentRef = useRef(null);
  const accessors = (rowData) => {
    // const visible = dialogsDelete[rowData.id] || false;
    const pdfModal = dialogsPdf[rowData.id] || false;

    const printModalContent = () => {
      const content = modalContentRef.current.innerHTML;
      const originalContents = document.body.innerHTML;

      document.body.innerHTML = content;
      window.print();

      document.body.innerHTML = originalContents;
    };
    return (
      <div>
        <div style={{ color: "#1d1a39" }}>
          <Button
            icon="pi pi-bars"
            onClick={() => {
              setSelectedData(rowData);

              setDialogsPdf((prevDialogs) => ({
                ...prevDialogs,
                [rowData.id]: true, // Görevin görünürlük state'ini true yap
              }));
              let reqBody = {
                id: rowData.offer_no,
              };
              getOfferData(reqBody);
            }}
            severity="#1d1a39"
          />
        </div>
        <Dialog
          visible={pdfModal}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          style={{ width: "60vw", height: "100vh" }}
          header="Pdf'e Dönüştür"
          onHide={hideDialog}
          modal
        >
          {" "}
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "1rem",
            }}
          >
            <Button
              label="Dönüştür"
              onClick={printModalContent}
              className="btn-excel-two"
            />
          </div>
          <div ref={modalContentRef}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <strong>SİPARİŞ</strong>
            </div>
            <div
              style={{
                padding: "1rem",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                {" "}
                <h6 style={{ marginTop: "0.5rem" }}>
                  Müşteri:{" "}
                  {selectedData.customer_id
                    ? customers.find(
                        (customer) =>
                          customer.id.toString() === selectedData.customer_id
                      )?.title || "Bilinmeyen"
                    : "Bilinmeyen"}
                </h6>
                <h6 style={{ marginTop: "0.5rem" }}>
                  Vergi Numarası:{" "}
                  {selectedData.customer_id
                    ? customers.find(
                        (customer) =>
                          customer.id.toString() === selectedData.customer_id
                      )?.tax_number || "Bilinmeyen"
                    : "Bilinmeyen"}
                </h6>
                <h6 style={{ marginTop: "0.5rem" }}>
                  {" "}
                  Vergi Dairesi:{" "}
                  {selectedData.customer_id
                    ? customers.find(
                        (customer) =>
                          customer.id.toString() === selectedData.customer_id
                      )?.tax_administration || "Bilinmeyen"
                    : "Bilinmeyen"}
                </h6>
                <h6 style={{ marginTop: "0.5rem" }}>
                  {" "}
                  Adres:{" "}
                  {selectedData.customer_id
                    ? customers.find(
                        (customer) =>
                          customer.id.toString() === selectedData.customer_id
                      )?.address || "Bilinmeyen"
                    : "Bilinmeyen"}
                </h6>
                <h6 style={{ marginTop: "0.5rem" }}>
                  {" "}
                  İletişim:{" "}
                  {selectedData.customer_id
                    ? customers.find(
                        (customer) =>
                          customer.id.toString() === selectedData.customer_id
                      )?.phone || "Bilinmeyen"
                    : "Bilinmeyen"}
                </h6>
                <h6 style={{ marginTop: "0.5rem" }}>
                  {" "}
                  Email:{" "}
                  {selectedData.customer_id
                    ? customers.find(
                        (customer) =>
                          customer.id.toString() === selectedData.customer_id
                      )?.phone || "Bilinmeyen"
                    : "Bilinmeyen"}
                </h6>
              </div>

              <div>
                {" "}
                <h6 style={{ marginTop: "0.5rem" }}>
                  {" "}
                  Sipariş No: {selectedData.offer_no}
                </h6>
                <h6 style={{ marginTop: "0.5rem" }}>
                  {" "}
                  Sipariş Tarihi: {formatDate(selectedData.date)}
                </h6>
                <h6 style={{ marginTop: "0.5rem" }}>Firma Ünvanı: EK Dizayn</h6>
                <h6 style={{ marginTop: "0.5rem" }}>Firma Adresi: No Idea</h6>
                <h6 style={{ marginTop: "0.5rem" }}>
                  Vergi Dairesi ve Numarası: No Idea
                </h6>
                <h6 style={{ marginTop: "0.5rem" }}>İletişim: 2342342334234</h6>
              </div>
            </div>
            <div style={{ padding: "1rem" }}>
              {" "}
              <DataTable value={selectedProd} size="small" dataKey="id">
                <Column
                  body={(rowData) => {
                    const cust = products.find(
                      (dept) => dept.product_no === rowData.product_id
                    );
                    if (cust) {
                      return cust.product_no;
                    } else {
                      return "Bilinmeyen";
                    }
                  }}
                  header="Ürün"
                  style={{ width: "20%" }}
                ></Column>
                <Column
                  body={(rowData) => {
                    const cust = products.find(
                      (dept) => dept.product_no === rowData.product_id
                    );
                    if (cust) {
                      return cust.name;
                    } else {
                      return "Bilinmeyen";
                    }
                  }}
                  header="Ürün"
                ></Column>
                <Column field="amount" header="Miktar"></Column>
                <Column
                  body={(rowData) => {
                    const cust = products.find(
                      (dept) => dept.product_no === rowData.product_id
                    );
                    if (cust) {
                      return cust.unit;
                    } else {
                      return "Bilinmeyen";
                    }
                  }}
                  header="Birim"
                ></Column>
                <Column field="price" header="Fiyat"></Column>
                <Column
                  field="tax_percent"
                  style={{ width: "14%" }}
                  header="KDV (%)"
                ></Column>
                <Column
                  field={(rowData) =>
                    Number(rowData.price) * Number(rowData.amount)
                  }
                  header="Tutar (KDV Hariç)"
                />
              </DataTable>
            </div>
            <div style={{ paddingLeft: "1rem" }}>
              <h6 style={{ marginTop: "0.5rem" }}>Açıklama: {rowData.note} </h6>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ paddingLeft: "1rem", marginTop: "2rem" }}>
                <h6 style={{ marginTop: "0.5rem" }}>Ticari Koşullar;</h6>
                <h6 style={{ marginTop: "0.5rem" }}>1-</h6>
                <h6 style={{ marginTop: "0.5rem" }}>2-</h6>
                <h6 style={{ marginTop: "0.5rem" }}>3-</h6>
              </div>
              <div
                style={{
                  paddingLeft: "1rem",
                  marginTop: "2rem",
                  paddingRight: "3rem",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {" "}
                  <h6 style={{ marginTop: "0.5rem" }}>Ara Toplam: </h6>{" "}
                  <h6 style={{ marginTop: "0.5rem" }}>{totalAmount} TL</h6>
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {" "}
                  <h6 style={{ marginTop: "0.5rem" }}>İndirim: </h6>{" "}
                  <h6 style={{ marginTop: "0.5rem" }}>0.00 TL</h6>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {" "}
                  <h6 style={{ marginTop: "0.5rem" }}>Toplam: </h6>{" "}
                  <h6 style={{ marginTop: "0.5rem" }}>{totalAmount} TL</h6>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {" "}
                  <h6 style={{ marginTop: "0.5rem" }}>KDV: </h6>{" "}
                  <h6 style={{ marginTop: "0.5rem" }}>% {KDVTax}</h6>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h6 style={{ marginTop: "1rem" }}>Genel Toplam: </h6>
                  <h6 style={{ marginTop: "1rem" }}>
                    {(totalAmount * KDVTax) / 100 + totalAmount}
                  </h6>
                </div>
              </div>
            </div>
            <div
              id="footer"
              style={{
                position: "relative",
                bottom: "0",
                paddingRight: "1.5rem",
                paddingLeft: "1.5rem",
                paddingBottom: "1.5rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "30%", textAlign: "left" }}>
                  <p>Müşteri</p>
                  <div
                    style={{
                      height: "100px",
                      border: "1px solid black",
                      boxSizing: "border-box",
                    }}
                  ></div>
                </div>
                <div style={{ width: "30%", textAlign: "left" }}>
                  <p>Firma</p>
                  <div
                    style={{
                      height: "100px",
                      border: "1px solid black",
                      boxSizing: "border-box",
                    }}
                  ></div>
                </div>
              </div>
              <div style={{ paddingLeft: "6%", marginTop: "2rem" }}>
                <Image src={logo} alt="Image" width="300" />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image src={imageBlue} alt="Image" width="500" />
                <Image src={blue} alt="Image" width="20" />
                <Image src={imageBlack} alt="Image" width="500" />
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    );
  };

  const toast = useRef(null);

  useEffect(() => {
    OrderService().then((data) => {
      setOffers(data.offers);
      setCustomers(data.customers);
      setProducts(data.products);
    });
    let total = 0;
    selectedProd.forEach((rowData) => {
      const totalPrice = Number(rowData.price) * Number(rowData.amount);
      total += totalPrice;
      setKDV(rowData.tax_percent);
    });
    setTotalAmount(total);
  }, [addModal, selectedData, selectedProd]);

  const getTurkishLabel = (url) => {
    switch (url) {
      case "/orders":
        return "Siparişler";
      // Diğer durumlar için gerektiğinde eşleme yapabilirsiniz
      default:
        return "Sayfa"; // Varsayılan olarak "Sayfa" kullanılabilir veya istediğiniz bir şeyi kullanabilirsiniz
    }
  };
  const url = "/orders";
  const turkishLabel = getTurkishLabel(url); // "Ürünler"

  const itemsBread = [{ label: turkishLabel, url: url }];
  const home = { icon: "pi pi-home", url: "/dashboard" };

  return (
    <Flex mt="5" justifyContent="center" alignItems="center">
      <div
        className="card"
        style={{
          overflow: "auto",
          boxSizing: "border-box",
          width: "100%",
          height: "100%",
          position: "relative",
          padding: "1rem",
        }}
      >
        <Card>
          <BreadCrumb
            model={itemsBread}
            home={home}
            style={{ padding: "1rem" }}
          />
          <CardHeader>
            <Flex justifyContent="space-between" alignItems="center">
              <Heading size="md">Siparişler</Heading>
            </Flex>
          </CardHeader>
          <Toast ref={toast} />

          <DataTable
            stripedRows
            value={offers}
            size="small"
            dataKey="id"
            tableStyle={{ minWidth: "50rem" }}
            rows={15}
            rowsPerPageOptions={[15, 30, 60, 100]}
            scrollable
            paginator
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate=" {totalRecords} siparişten {first} - {last} arası gösteriliyor"
          >
            <Column
              style={{ width: "8rem" }}
              field="accessor"
              body={accessors}
              header="İşlemler"
            ></Column>
            <Column field="offer_no" header="Sipariş No"></Column>
            <Column
              field="title"
              header="Müşteri"
              body={(rowData) => {
                const cust = customers.find(
                  (dept) => dept.id.toString() === rowData.customer_id
                );
                if (cust) {
                  // Başlığı boşluklara göre bölerek ilk iki kelimeyi al
                  const firstTwoWords =
                    cust.title && cust.title.split(" ").slice(0, 2).join(" ");
                  return firstTwoWords;
                } else {
                  return "Bilinmeyen";
                }
              }}
            ></Column>
            <Column field="subject" header="Konu"></Column>

            <Column
              field="date"
              body={(rowData) => formatDate(rowData.date)}
              header="Tarih"
            ></Column>
          </DataTable>
        </Card>
      </div>
    </Flex>
  );
}
