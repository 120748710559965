import React, { useState } from "react";
import { Splitter, SplitterPanel } from "primereact/splitter";
import AgeGraphTable from "./components/AgeGraphTable";
import AgeGraphAppearance from "./components/AgeGraphAppearance";
import AgeGraphPage from "./components/AgeGraphPage";

export default function AgeGraph({
  uniqueCustomerId,
  selectedMonth,
  selectedCustomer,
  months,
}) {



   const [tableKey, setTableKey] = useState(0);
   const [appearanceKey, setAppearanceKey] = useState(0);

   const updateTableData = () => {
     setTableKey((prevKey) => prevKey + 1);
     setAppearanceKey((prevKey) => prevKey + 1);
   };

  const DataTypes = [
    { name: "18-24 Yaş", value: "1" },
    { name: "25-34 Yaş", value: "2" },
    { name: "35-44 Yaş", value: "3" },
    { name: "45-54 Yaş", value: "4" },
    { name: "55+ Yaş", value: "5" },
    { name: "Diğerleri", value: "6" },
  ];
  return (
    <Splitter>
      <SplitterPanel>
        <AgeGraphPage
          updateTableData={updateTableData}
          selectedMonth={selectedMonth}
          selectedCustomer={selectedCustomer}
          uniqueCustomerId={uniqueCustomerId}
          DataTypes={DataTypes}
        />
      </SplitterPanel>
      <SplitterPanel size={75} style={{ display: "block", padding: "1rem" }}>
        <AgeGraphTable
          key={tableKey}
          updateTableData={updateTableData}
          months={months}
          uniqueCustomerId={uniqueCustomerId}
          DataTypes={DataTypes}
        />
        <br />
        <br />
        <AgeGraphAppearance
          appearanceKey={appearanceKey}
          months={months}
          uniqueCustomerId={uniqueCustomerId}
          DataTypes={DataTypes}
        />
      </SplitterPanel>
    </Splitter>
  );
}
