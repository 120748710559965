import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  CostService,
  AddCostService,
  DeleteCostService,
  EditCostService,
} from "../../../../core/Services";

import { Button } from "primereact/button";

import { Toast } from "primereact/toast";
import { Card, CardHeader, Flex, Heading } from "@chakra-ui/react";

import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { FileUpload } from "primereact/fileupload";
import { BsFillFilePdfFill } from "react-icons/bs";
import { BreadCrumb } from "primereact/breadcrumb";

export default function Cost() {
  const [costs, setCosts] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [department, setDepartment] = useState("");
  const [departments, setDepartments] = useState("");

  const [visible, setVisible] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [employees, setEmployees] = useState([]);

  const [formattedFileContent, setFormattedFileContent] = useState(null);

  const elements = document.getElementsByClassName(
    "p-button p-component p-button-icon-only p-button-text p-button-rounded p-button-danger"
  );
  for (const element of elements) {
    element.addEventListener("click", handleClick);
  }
  function handleClick() {
    setFormattedFileContent(null);
  }
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("tr-TR");
    return formattedDate;
  };
  const hideDialog = () => {
    setAddModal(false);
    setEditModal(false);
    setName("");
    setSelectedData("");
    setDesc("");
    setDepartment("");
    setFormattedFileContent("");
  };
  const accessors = (rowData) => {
    return (
      <div style={{ display: "flex" }}>
        <div style={{ color: "#f39f5a" }}>
          <Button
            severity="warning"
            onClick={() => {
              setEditModal(true);
              setName(rowData.title);
              setDesc(rowData.description);
              setSelectedData(rowData);
            }}
            icon="pi pi-pencil"
          />
        </div>
        <div style={{ color: "#ae445a" }}>
          <Button
            icon="pi pi-trash"
            onClick={() => {
              setVisible(true);
              setSelectedData(rowData);
            }}
            severity="danger"
          />
        </div>
        <Dialog
          header="Kullanıcıyı silmek istediğinize emin misiniz?"
          visible={visible}
          style={{ width: "50vw" }}
          onHide={() => setVisible(false)}
          footer={
            <div>
              <Button
                label="İptal"
                icon="pi pi-times"
                onClick={() => setVisible(false)}
                className="btn-excel-two"
              />
              <Button
                label="Masrafı Sil"
                icon="pi pi-check"
                onClick={() => {
                  DeleteCostService(selectedData.id);
                  setVisible(false);
                }}
                className="btn-excel-two"
                style={{ marginLeft: "15px" }}
              />
            </div>
          }
        ></Dialog>
        <Dialog
          visible={editModal}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          style={{ width: "60vw" }}
          header="Masraf Düzenle"
          onHide={hideDialog}
          modal
        >
          <div className="confirmation-content">
            <div className=" jc-space-around">
              {/* Sol Sütun */}
              <div className="col-6 " style={{ display: "inline-block" }}>
                <InputText
                  type="text"
                  className="p-inputtext-lg w-full"
                  placeholder="Konu"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </div>

              {/* Sağ Sütun */}
              <div className="col-6" style={{ display: "inline-block" }}>
                <Dropdown
                  options={
                    Array.isArray(departments) && departments.length > 0
                      ? departments.map((dep) => ({
                          label: dep.department,
                          value: dep.id,
                        }))
                      : []
                  }
                  value={department}
                  placeholder="Departman"
                  onChange={(e) => setDepartment(e.target.value)}
                  style={{
                    border: "1px solid gray",

                    width: "100%",
                  }}
                />
              </div>
            </div>
            <div
              className="col-12"
              style={{ display: "inline-block", marginTop: "0.5rem" }}
            >
              <span className="p-float-label">
                <InputTextarea
                  id="desc"
                  value={desc}
                  onChange={(e) => setDesc(e.target.value)}
                  rows={6}
                  className="w-full"
                  style={{ marginTop: "0.5rem" }}
                />
                <label htmlFor="desc">Açıklama</label>
              </span>
            </div>
            <div className="col-12" style={{ display: "inline-block" }}>
              <div>
                <p
                  style={{
                    marginBottom: "0.2rem",
                    marginTop: "2rem",
                    textAlign: "left",
                  }}
                >
                  Dosya Ekle(Pdf):
                </p>
              </div>

              <FileUpload
                chooseLabel="Dosya Seç"
                style={{ textAlign: "left" }}
                accept=".pdf"
                maxFileSize={2000000}
                auto
                pt={{
                  content: { className: "surface-ground" },
                  message: {
                    root: {
                      className: "w-1rem",
                    },
                  },
                }}
                uploadHandler={onUpload}
                customUpload
              />
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Button
                  label="Kaydet"
                  onClick={() => saveChanges(selectedData.id)}
                  className="btn-excel-two"
                  style={{
                    marginTop: "1rem",
                  }}
                />
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    );
  };

  const userData = JSON.parse(localStorage.getItem("userData"));

  const newCreate = async () => {
    let requestBody = {
      name,
      file: formattedFileContent,
      department,
      desc,
      user_id: userData.id,
    };

    await AddCostService(requestBody);
    setAddModal(false);
  };
  const onUpload = (event) => {
    const uploadedFile = event.files && event.files[0];

    if (uploadedFile) {
      const reader = new FileReader();

      reader.onload = async (e) => {
        const fileContent = e.target.result;

        // Convert file content to Base64
        const formattedContent = btoa(fileContent);

        // Update the state variable with the Base64 content
        await setFormattedFileContent(formattedContent);
      };

      reader.readAsBinaryString(uploadedFile);
    }
  };
  const saveChanges = async (id) => {
    let requestBody = {
      id,
      name,
      file: formattedFileContent,
      department,
      desc,
      user_id: userData.id,
    };
    await EditCostService(requestBody);
    setEditModal(false);
  };
  const toast = useRef(null);

  useEffect(() => {
    CostService().then((data) => {
      setCosts(data.costs);
      setDepartments(data.departments);
      setEmployees(data.users);
    });
  }, [addModal, visible, editModal, formattedFileContent]);
  const getTurkishLabel = (url) => {
    switch (url) {
      case "/costs":
        return "Masraflar";
      // Diğer durumlar için gerektiğinde eşleme yapabilirsiniz
      default:
        return "Sayfa"; // Varsayılan olarak "Sayfa" kullanılabilir veya istediğiniz bir şeyi kullanabilirsiniz
    }
  };
  const url = "/costs";
  const turkishLabel = getTurkishLabel(url); // "Ürünler"

  const itemsBread = [{ label: turkishLabel, url: url }];
  const home = { icon: "pi pi-home", url: "/dashboard" };
  return (
    <Flex mt="5" justifyContent="center" alignItems="center">
      <div
        className="card"
        style={{
          overflow: "auto",
          boxSizing: "border-box",
          width: "100%",
          height: "100%",
          position: "relative",
          padding: "1rem",
        }}
      >
        <Card>
          <BreadCrumb
            model={itemsBread}
            home={home}
            style={{ padding: "1rem" }}
          />
          <CardHeader>
            <Flex justifyContent="space-between" alignItems="center">
              <Heading size="md">Masraflar</Heading>
              <div>
                <Button
                  label="Yeni"
                  onClick={() => setAddModal(true)}
                  className="btn-excel-two"
                />
              </div>
            </Flex>
          </CardHeader>
          <Toast ref={toast} />

          <DataTable
            value={costs}
            size="small"
            dataKey="id"
            tableStyle={{ minWidth: "50rem" }}
            rows={15}
            rowsPerPageOptions={[15, 30, 60, 100]}
            scrollable
            paginator
            selectionMode="single"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate=" {totalRecords} masraftan {first} - {last} arası gösteriliyor"
          >
            <Column
              style={{ width: "8rem" }}
              field="accessor"
              body={(rowData) => accessors(rowData)}
              header="İşlemler"
            ></Column>

            <Column field="title" header="Başlık"></Column>
            <Column
              field="department"
              body={(rowData) => {
                const department = departments.find(
                  (dept) => dept.id.toString() === rowData.department
                );
                return department
                  ? department.department
                  : "Unknown Department";
              }}
              header="Departman"
            ></Column>
            <Column
              field="user"
              body={(rowData) => {
                const user = employees.find(
                  (usr) => usr.id.toString() === rowData.user
                );

                return user ? user.name : "Bilinmeyen"; // Kullanıcı adını bul veya "Unknown User" döndür
              }}
              header="Kullanıcı"
            ></Column>
            <Column field="description" header="Açıklama"></Column>
            <Column
              style={{ minWidth: "5rem" }}
              field="created_at"
              body={(rowData) => <span>{formatDate(rowData.created_at)}</span>}
              header="Tarih"
            ></Column>

            <Column
              field="file"
              body={(rowData) => (
                <a
                  href={`data:application/pdf;base64,${rowData.file}`}
                  download="document.pdf"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <BsFillFilePdfFill color="red" size={35} />
                </a>
              )}
              header="Belge"
            ></Column>
          </DataTable>
        </Card>
      </div>
      <Dialog
        visible={addModal}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        style={{ width: "60vw" }}
        header="Masraf Ekle"
        onHide={hideDialog}
        modal
      >
        <div className="confirmation-content">
          <div className=" jc-space-around">
            {/* Sol Sütun */}
            <div className="col-6 " style={{ display: "inline-block" }}>
              <InputText
                type="text"
                className="p-inputtext-lg w-full"
                placeholder="Konu"
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </div>

            {/* Sağ Sütun */}
            <div className="col-6" style={{ display: "inline-block" }}>
              <Dropdown
                filter
                options={
                  Array.isArray(departments) && departments.length > 0
                    ? departments.map((dep) => ({
                        label: dep.department,
                        value: dep.id,
                      }))
                    : []
                }
                value={department}
                placeholder="Departman"
                onChange={(e) => setDepartment(e.target.value)}
                style={{
                  border: "1px solid gray",

                  width: "100%",
                }}
              />
            </div>
          </div>
          <div
            className="col-12"
            style={{ display: "inline-block", marginTop: "0.5rem" }}
          >
            <span className="p-float-label">
              <InputTextarea
                id="desc"
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
                rows={6}
                className="w-full"
                style={{ marginTop: "0.5rem" }}
              />
              <label htmlFor="desc">Açıklama</label>
            </span>
          </div>
          <div className="col-12" style={{ display: "inline-block" }}>
            <div>
              <p
                style={{
                  marginBottom: "0.2rem",
                  marginTop: "2rem",
                  textAlign: "left",
                }}
              >
                Dosya Ekle(Pdf):
              </p>
            </div>

            <FileUpload
              chooseLabel="Dosya Seç"
              style={{ textAlign: "left" }}
              accept=".pdf"
              maxFileSize={2000000}
              auto
              pt={{
                content: { className: "surface-ground" },
                message: {
                  root: {
                    className: "w-1rem",
                  },
                },
              }}
              uploadHandler={onUpload}
              customUpload
            />
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button
                label="Ekle"
                onClick={newCreate}
                className="btn-excel-two"
                style={{
                  marginTop: "1rem",
                }}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </Flex>
  );
}
