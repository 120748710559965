import { Flex } from '@chakra-ui/react';
import { LeftPanel } from './components/left-panel';
import { RightPanel } from './components/right-panel';
import { useState } from 'react';

function Chat() {
  const [selectedUser, setSelectedUser] = useState(null);
  const handleUserSelect = (user) => {
    setSelectedUser(user);
  };
  return (
    <Flex h='100vh'>
      <LeftPanel onUserSelect={handleUserSelect} />
      <RightPanel selectedUser={selectedUser} />
    </Flex>
  );
}

export default Chat;
