import React, { useState } from "react";
import ViewVisitorChartPage from "./components/AddData";
import { Splitter, SplitterPanel } from "primereact/splitter";
import ViewVisitorChartTable from "./components/Editing";

export default function Page({ uniqueCustomerId, selectedMonth, selectedCustomer, months }) {
  const [tableKey, setTableKey] = useState(0);
  

  const updateTableData = () => {
    setTableKey((prevKey) => prevKey + 1);
    
  };

  return (
    <Splitter>
      <SplitterPanel>
        <ViewVisitorChartPage
          updateTableData={updateTableData}
          selectedMonth={selectedMonth}
          selectedCustomer={selectedCustomer}
          uniqueCustomerId={uniqueCustomerId}
        />
      </SplitterPanel>
      <SplitterPanel className="w-full">
        <div className="w-full">
          <ViewVisitorChartTable
            key={tableKey}
            months={months}
            uniqueCustomerId={uniqueCustomerId}
          />
        </div>
      </SplitterPanel>
    </Splitter>
  );
}
