import { createSlice } from "@reduxjs/toolkit";

export const BrandSlice = createSlice({
  name: "BrandSlice",
  initialState: {
    value: null,
  },
  reducers: {
    addBrandData: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { addBrandData } = BrandSlice.actions;
export default BrandSlice.reducer;
