import React from 'react';
import { Flex, Box, Heading, Text, ChakraProvider, Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../auth/core/AuthContext';

const Error404 = () => {

  const { userData } = useAuth();
  return (
    <ChakraProvider>
      <Flex
        height="100vh"
        align="center"
        justify="center"
        direction="column"
      >

        <Box textAlign="center">

          <Heading color="red.500" fontSize="4xl" mb={4}>
            404 - Sayfa Bulunamadı
          </Heading>
          <Text fontSize="xl" color="gray.600">
            Aradığınız sayfa maalesef bulunamadı.
          </Text>
        </Box>
        {userData?.authorization === "0" && (
          <Box>
            <Link to="/dashboard">
              <Button mt="25px" size="lg" bgColor="teal" color="white" >Ana Sayfa</Button>
            </Link>
          </Box>
        )}
        {userData?.authorization === "1" && (
          <Box>
            <Link to="/customer/detail">
              <Button mt="25px" size="lg" bgColor="teal" color="white" >Ana Sayfa</Button>
            </Link>
          </Box>
        )}
      </Flex>
    </ChakraProvider>
  );
}

export default Error404;
