import React from "react";
import Sidebar from "../../../../_ekdizayn/layout/Sidebar/Sidebar";
import NavBar from "../../../../_ekdizayn/layout/Navbar/components/Header";
import { Grid, GridItem } from "@chakra-ui/react";
import SettingsPage from "./components/SettingsPage";
import { BreadCrumb } from "primereact/breadcrumb";

export default function Settings() {
  const getTurkishLabel = (url) => {
    switch (url) {
      case "/settings":
        return "Ayarlar";
      // Diğer durumlar için gerektiğinde eşleme yapabilirsiniz
      default:
        return "Sayfa"; // Varsayılan olarak "Sayfa" kullanılabilir veya istediğiniz bir şeyi kullanabilirsiniz
    }
  };
  const url = "/settings";
  const turkishLabel = getTurkishLabel(url); // "Ürünler"

  const itemsBread = [{ label: turkishLabel, url: url }];

  const home = { icon: "pi pi-home", url: "/settings" };
  return (
    <Grid
      templateAreas={`"header header"
                  "nav main"
                 `}
      gridTemplateRows={"auto 1fr "}
      gridTemplateColumns={"auto 1fr"}
      h="auto"
      color="blackAlpha.700"
      fontWeight="bold"
    >
      <GridItem area={"header"}>
        <NavBar />
      </GridItem>
      <GridItem area={"nav"}>
        <Sidebar />
      </GridItem>
      <GridItem mt="5" area={"main"}>
        <BreadCrumb
          model={itemsBread}
          home={home}
          style={{ padding: "1rem" }}
        />
        <SettingsPage />
      </GridItem>
    </Grid>
  );
}
