import React, { useState } from "react";
import { Splitter, SplitterPanel } from "primereact/splitter";
import AddDataPage from "./components/AddDataPage";
import TablePage from "./components/TablePage";
import ChartPage from "./components/ChartPage";


export default function Map({ uniqueCustomerId, selectedMonth, selectedCustomer, months }) {
 
  const DataTypes = [
    { name: "Google", value: "1" },
    { name: "Yandex", value: "2" },
    { name: "Apple", value: "3" },
  ];
     const [tableKey, setTableKey] = useState(0);

     const updateTableData = () => {
       setTableKey((prevKey) => prevKey + 1);
     };
  return (
    <Splitter style={{ height: "auto" }}>
      <SplitterPanel size={25} style={{ display: "block", padding: "1rem" }}>
        <AddDataPage
          updateTableData={updateTableData}
          selectedMonth={selectedMonth}
          selectedCustomer={selectedCustomer}
          uniqueCustomerId={uniqueCustomerId}
          DataTypes={DataTypes}
        />
      </SplitterPanel>
      <SplitterPanel size={75} style={{ display: "block", padding: "1rem" }}>
        <TablePage
          key={tableKey}
          months={months}
          DataTypes={DataTypes}
          uniqueCustomerId={uniqueCustomerId}
        />
        <br></br>
        <ChartPage
          months={months}
          DataTypes={DataTypes}
          uniqueCustomerId={uniqueCustomerId}
        />
      </SplitterPanel>
    </Splitter>
  );
}
