import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
import { VisibileGuarantee } from "../../../../../core/Services";


export default function PassiveGuaranteeDialog({
  toast,
  form,
  setForm,
  setPassiveDialog,
  passiveDialog,
  selectedId,
  setSelectedId,
  serialNumber,
  timeRemaining,
  setTimeRemaining,
  setSerialNumber,
}) {
  const passiveGuarantee = () => {
    VisibileGuarantee(selectedId)
      .then((response) => {
        if (response.message === "Hata") {
          toast({
            title: "Hata",
            description: "Kullanıcya Ait Veri Mevcut",
            status: "error",
            duration: 3000,
            position: "top-right",
            isClosable: true,
          });
        } else {
          toast({
            title: "Başarılı",
            description: "Veri başarılı bir şekilde kaydedildi.",
            status: "success",
            duration: 3000,
            position: "top-right",
            isClosable: true,
          });
          setForm(null);
          setSelectedId("");
        }
      })
      .catch((error) => {
        console.error("Backend'e gönderme hatası:", error);
        toast({
          title: "Hata",
          description: "Veri gönderilirken bir hata oluştu.",
          status: "error",
          duration: 3000,
          position: "top-right",
          isClosable: true,
        });
      });
  };
  const deleteButton = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          marginTop: "1rem",
        }}
      >
        <Button
          label="Hayır"
          onClick={() => {
            setPassiveDialog(false);
            setForm(null);
            setTimeRemaining("");
            setSerialNumber("");
            setSelectedId("");
          }}
          className="btn-excel-two"
          style={{ marginRight: "1rem" }}
        />
        <Button
          onClick={() => {
            passiveGuarantee();
            setPassiveDialog(false);
            setForm(null);
            setTimeRemaining("");
            setSerialNumber("");
            setSelectedId("");
          }}
          label="Evet"
          className="btn-excel-two"
        />
      </div>
    );
  };
  return (
    <Dialog
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      style={{ width: "60vw" }}
      header="Garanti Pasife Al"
      visible={passiveDialog}
      onHide={() => {
        setPassiveDialog(false);
        setForm(null);
        setTimeRemaining("");
        setSerialNumber("");
        setSelectedId("");
      }}
      footer={deleteButton}
      modal
    >
      <div className="confirmation-content">
        <div
          className="font-bold mb-2"
          style={{ display: "flex", justifyContent: "center", color: "red" }}
        >
          <p>Garantiyi Pasife Almak İstediğinize Emin Misiniz?</p>
        </div>
        <div className="field">
          <label htmlFor="name" className="font-bold">
            Seri Numarası: {serialNumber}
          </label>
        </div>
        <div className="field">
          <label htmlFor="name" className="font-bold">
            Firma Adı: {form?.companyName}
          </label>
        </div>
        <div className="field">
          <label htmlFor="name" className="font-bold">
            İlk Kayıt Tarihi: {form?.firstRegistrationDate}
          </label>
        </div>
        <div className="field">
          <label htmlFor="name" className="font-bold">
            Bitiş Tarihi: {form?.endDate}
          </label>
        </div>
        <div className="field">
          <label htmlFor="name" className="font-bold">
            Kalan Süre: {timeRemaining}
          </label>
        </div>
      </div>
    </Dialog>
  );
}
