import { createSlice } from "@reduxjs/toolkit";

export const DashboardSlice = createSlice({
  name: "DashboardSlice",
  initialState: {
    value: null,
  },
  reducers: {
    addDashboardData: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { addDashboardData } = DashboardSlice.actions;
export default DashboardSlice.reducer;
