import { Box, Flex } from "@chakra-ui/react";
import { ChatList } from "./chat-list";
import { Header } from "./header";
import { SearchPanel } from "./search-panel";
import { useEffect, useState } from "react";
import { FetchChat, UserService } from "../../../../core/Services";
import { useAuth } from "../../../../auth/core/AuthContext";

export function LeftPanel({ onUserSelect, ...props }) {
  const [users, setUsers] = useState([]);
  const [messages, setMessages] = useState([]);
  const [searchText, setSearchText] = useState("");
  const { userData } = useAuth();
  const handleSearch = (searchText) => {
    setSearchText(searchText);
  };
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await UserService();
        setUsers(userData.users);
      } catch (error) {
        console.log("Error fetching users:", error);
      }
    };
  
    const fetchChatData = async () => {
      try {
        const chatData = await FetchChat(userData?.unique_id);
        if (Array.isArray(chatData)) {
          setMessages(chatData);
        } else {
          console.log(
            "FetchChat function returned unexpected data format:",
            chatData
          );
        }
      } catch (error) {
        console.log("Error fetching chat data:", error);
      }
    };
  
    fetchUserData();
    fetchChatData();
  
    const intervalId = setInterval(() => {
      fetchChatData();
      fetchUserData();
    }, 10000); // 10 saniyede bir çalışacak
  
    return () => clearInterval(intervalId); // useEffect temizleme fonksiyonu
  
  }, [userData]);
  
  return (
    <Flex bgColor="#42624d" direction="column" w="26%" style={{border:"2px solid gray"}} {...props}>
      <Box>
        <Header />
        <SearchPanel onSearch={handleSearch} />
      </Box>
      <ChatList
        searchText={searchText}
        onUserSelect={onUserSelect}
        users={users}
        userData={userData} 
        messages={messages}
        flex="1"
        overflow="auto"
      />
    </Flex>
  );
}
