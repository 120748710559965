import { createSlice } from "@reduxjs/toolkit";

export const LicenceSlice = createSlice({
  name: "LicenceSlice",
  initialState: {
    value: null,
  },
  reducers: {
    addLicenceData: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { addLicenceData } = LicenceSlice.actions;
export default LicenceSlice.reducer;
