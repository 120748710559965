import {
    HStack,
    Input,
    InputGroup,
    InputLeftElement,
    Tooltip,
  } from '@chakra-ui/react';
  import {SearchIcon } from '../assets/icons';
  export function SearchPanel({ onSearch, ...props }) {
    const handleInputChange = (event) => {
      const searchText = event.target.value;
      onSearch(searchText);
    };
    return (
      <HStack
        spacing={2}
        px='4'
        py='2'
        borderBottom='1px'
        borderColor='#183545'
        {...props}
      >
        <InputGroup>
          <InputLeftElement
            pointerEvents='none'
            children={<SearchIcon color='gray.300' />}
          />
          <Input
            _placeholder={{
              opacity: 0.6,
              color: '#3b4a54',
              paddingLeft: '24px',
              fontSize: '15px',
            }}
            h='36px'
            borderRadius="20px"
            onChange={handleInputChange}
            _hover={{ bg: '#f0f2f5' }}
            bg='#f0f2f5'
            variant="unstyled"
            placeholder='Arama yapın veya yeni sohbet başlatın'
          />
        </InputGroup>
        <Tooltip
          shouldWrapChildren
          label='Unread chats filter'
          bg='#eae6df'
          color='black'
          fontSize='xs'
        >
          {/* <IconButton>
            <FilterIcon />
          </IconButton> */}
        </Tooltip>
      </HStack>
    );
  }
  