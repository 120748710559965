import React from 'react'

export default function KvkkPage() {
  return (
    <div className="p-4">
      <div className="text-center p-3">
        <strong className="text-4xl w-full font-bold">
          KİŞİSEL VERİLERİN KORUNMASI
        </strong>
      </div>
      <h5 className="w-full">
        İşbu Aydınlatma ve Rıza Metni ile veri sorumlusu sıfatıyla hareket eden
        Emre Küçük EK Dizayn, (Bundan böyle kısaca “EK Dizayn” olarak
        anılacaktır.) 6698 sayılı Kişisel Verilerin Korunması Kanunu’na uygun
        olarak KVKK prosedüründe tanımlanmış ilgili kişilerin, kişisel
        verilerinin işlenmesine ilişkin açıklamalarda bulunmak ve bilgilendirmek
        amacıyla bu bilgilendirme metnini hazırlamıştır. Bu metin ile ilgili
        Kanun uyarınca EK Dizayn nezdindeki kişisel verileriniz aşağıda
        açıklanan işlenme amaçları, hukuki nedenleri, bilgilerin toplanma
        yöntemleri, kimlere ve hangi amaçlarla aktarılabileceğine ilişkin olarak
        sizleri bilgilendirmektedir. Kanun kapsamında kişisel veri, kimliği
        belirli veya belirlenebilir gerçek kişiye ilişkin her türlü veriyi
        kapsamaktadır. Kişisel verinin özel bir türü olan Özel Nitelikli Kişisel
        Veri, ırk, etnik köken, siyasi düşünce, felsefi inanç, din, mezhep,
        diğer inançlar, kılık ve kıyafet, dernek, vakıf veya sendika üyeliği,
        sağlık, cinsel hayat, ceza mahkumiyeti ve güvenlik tedbirleri,
        biyometrik ve genetik verileri ifade eder.
      </h5>
      <div className="mt-2">
        <strong>
          1. KİŞİSEL VERİLERİN TOPLANMASINA İLİŞKİN YÖNTEMLERİ VE HUKUKİ SEBEBİ
        </strong>
      </div>
      <h5>
        Toplanan veriler, EK Dizayn’ın iş ortaklarından, tedarikçilerinden,
        bayilerden (potansiyel iş ortakları, tedarikçiler ve bayilerde dahil
        olmak üzere) aşağıda sayılan kanallar vasıtasıyla otomatik ya da
        otomatik olmayan yöntemlerle, sözlü, yazılı veya elektronik yollar ile
        iletilen verilerdir. Kişisel verileriniz, tamamen veya kısmen otomatik
        yollarla ya da veri kayıt sisteminin parçası olmak kaydıyla otomatik
        olmayan yöntemlerle elde edilmektedir. EK Dizayn tarafından veri
        sorumlusu sıfatı ile, KVKK ve ilgili diğer mevzuattan kaynaklanan yasal
        yükümlülüklerimiz çerçevesinde; internet sitesi, mobil uygulamalar,
        çağrı merkezi, mağazalar, sosyal medya mecraları, doğrudan veya
        yetkilendirilmiş iletişim kanalları ve fiziki kanallar gibi farklı
        kanallarda icra edilen üretim ve düzenleme süreçlerindeki yöntemlerle;
        bu kanalları kullanarak sizlere kaliteli hizmetlerimizi sunmak ve bu
        çerçevede iş ve ticari faaliyetlerimizi yürütmek noktasında uygulama
        imkanı bulan ilgili mevzuat, sözleşme, talep, ticari teamül ve dürüstlük
        kurallarına dayalı olarak ortaya çıkan ve icra edilen hukuki sebepler
        çerçevesinde açık rızaya bağlı sözlü, yazılı ya da elektronik kişisel
        verileriniz, özel nitelikli kişisel veriler de dahil olmak üzere
        toplanmaktadır. Bu kapsamda kişisel verileriniz, EK Dizayn veya EK
        Dizayn adına veri işleyen gerçek ya da tüzel kişiler tarafından,
        sayılanlarla sınırlı olmamak üzere, yazılı veya elektronik olarak,
        aşağıda belirtilen yöntemlerle toplanabilir; Elektronik ya da fiziki
        olarak doldurduğunuz üyelik formu, İnternet sitelerimize üyelik veya
        giriş sırasında üye girişi yapmanızı sağlayan B2b ve sosyal ağlar,
        İnternet sitelerimiz veya üçüncü kişilere ait internet sitelerinde yer
        alan, bizimle iletişime geçmek, hizmet ve destek almak üzere
        doldurduğunuz iletişim formları, Çevirim içi alışveriş uygulamaları,
        sizi tanımak için kullanılan çerezler (cookies), mobil uygulamalarımız,
        Şirketimiz ile imzalamış olduğunuz muhtelif sözleşmeler ile Şirketimize
        göndermiş olduğunuz elektronik postalar, faks, kargo ve mektuplar,
        Şirketimiz adına veri işleyen yahut üyelik programı sürecinin herhangi
        bir safhasında şirketimize destek veren üçüncü kişi veya firmalar,
        Çalışanlarımız, dijital pazarlama ve çağrı merkezi dahil olmak üzere
        müşteri hizmetleri kanallarımız, Sosyal medya kanalları, Google vb.
        arama motorlarının kullanımı, Üyelik sözleşmeleri ve sair diğer
        sözleşmeler, kampanyalar, başvurular, formlar, teklifler, Bayilerimiz,
        ve diğer satış ağı. Toplanan kişisel veriler Kanun’un 5. ve 6.
        maddelerinde ve bu Aydınlatma ve Rıza Metni’nde belirtilen amaçlarla
        işlenebilmekte ve aktarılabilmektedir.
      </h5>
      <div className="mt-2">
        <strong>2. KİŞİSEL VERİLERİN İŞLENMESİ VE İŞLEME AMAÇLARI</strong>
      </div>
      <h5>
        EK Dizayn olarak, veri sorumlusu sıfatı ile, yazılı iletişim
        kanallarımız, sosyal medya sayfalarımız, mobil iletişim kanalları,
        ve/veya bunlarla sınırlı olmamak üzere her türlü kanallar aracılığı ile;
        açık rızanız ile elde ettiğimiz kişisel ve/veya özel nitelikli kişisel
        verileriniz tamamen veya kısmen elde edilebilir, kaydedilebilir,
        saklanabilir, depolanabilir, değiştirilebilir, güncellenebilir,
        periyodik olarak kontrol edilebilir, yeniden düzenlenebilir,
        sınıflandırılabilir, işlendikleri amaç için gerekli olan ya da ilgili
        kanunda öngörülen süre kadar muhafaza edilebilir, kanuni ya da hizmete
        bağlı fiili gereklilikler halinde EK Dizayn’ın birlikte çalıştığı
        özel-tüzel kişilerle, grup şirketleri veya işbirliği yaptığı şirketlerle
        ya da kanunen yükümlü olduğu kamu kurum ve kuruluşlarıyla ve/veya
        Türkiye'de veya yurtdışında mukim olan ilgili 3. kişi gerçek kişi/tüzel
        kişilerle paylaşılabilir/devredilebilir, kanuni ya da hizmete bağlı
        fiili gereklilikler halinde yurtdışına aktarılabilir. EK Dizayn
        müşterilerinin markalarımızın hizmetlerinden faydalanabilmesi, onayınız
        halinde ticari ve pazarlama stratejilerimizi belirlemek, geliştirmek
        amacı ile kampanyalarımız hakkında sizleri bilgilendirmek, öneri ve
        şikayetlerinizi kayıt altına alabilmek, sizleri daha iyi tanıyarak
        ihtiyaçlarınız doğrultusunda hizmet vermek, satış, pazarlama, reklam ve
        tanıtım faaliyetlerimizin yürütülmesinde öncülük etmeniz, satış ve
        pazarlama faaliyetleri için yapılacak analizler ve profilleme
        çalışmalarını yapabilmemiz, sizlere daha iyi hizmet standartları
        oluşturabilmek, EK Dizayn ticari ve iş stratejilerinin belirlenmesi ve
        uygulanması gibi amaçlarla ve her halükarda 6698 sayılı Kişisel
        Verilerin KorunmasıKanunu ve ilgili mevzuata uygun olarak kişisel
        verilerinizi işleyebileceğimizi bilginize sunarız.
      </h5>
      <div className="mt-2">
        <strong>3. KİŞİSEL VERİLERİN AKTARILMASI</strong>
      </div>
      <h5>
        EK Dizayn söz konusu kişisel verilerinizi sadece; açık rızanıza
        istinaden veya 6698 sayılı Kanun’da ve ilgili mevzuatta belirtilen
        güvenlik ve gizlilik esasları çerçevesinde yeterli önlemler alınmak
        kaydıyla yurt içinde ve gerekli güvenlik önlemlerinin alınması şartıyla
        yurtdışında, Şirket faaliyetlerinin yürütülmesi, veri sahipleri ile
        müşterilerimiz arasındaki iş ilişkisinin sağlanması ve/veya bu amaçla
        görüşmeler yapılması, hizmetler, fırsat ve olanaklar sunulması ve hizmet
        kalitesinin artırılması amacıyla; grup şirketlerimiz, iş ortaklarımız,
        iş bağlantılarımız, bayilerimiz, Kullandığımız bilişim teknolojileri
        gereği, yurt içinde ya da yurtdışında bulunan sunucular ve bu sunucu
        desteğini veren firmalar ile fiziksel server ve/veya bulut hizmeti
        aldığımız yurt içi/yurt dışı kişi ve kurumlar, Şirketimiz adına veri
        işleyen, müşteri memnuniyeti ölçümleme, profilleme desteği veren, satış
        ve pazarlama alanında kişisel verilerin işlenmesi gereken konularda
        destek veren firmalar, faaliyetlerimizin gereği anlaşmalı olduğumuz ve
        hizmet sunduğumuz müşteriler, tedarikçiler, şirket avukatlarımız ve mali
        müşavirler, denetim şirketleri veya yasal bir zorunluluk gereği bu
        verileri talep etmeye yetkili olan kamu kurum veya kuruluşları, bunlarla
        sınırlı olmamak üzere ilgili diğer otoriteler ile paylaşabilecektir.
      </h5>
      <div className="mt-2">
        <strong>
          4. KİŞİSEL VERİ SAHİBİNİN KVK KANUNU’NUN (“KANUN”) 11. MADDESİNDE
          SAYILAN HAKLARI
        </strong>
      </div>
      <h3>
        EK Dizayn ilgili kişilerin aşağıdaki taleplerine karşılık verecektir:
      </h3>
      <h5>
        a) EK Dizayn'ın kendilerine ilişkin kişisel verileri işleyip
        işlemediğini ve hangi kişisel verileri işlediğini öğrenme, <br />
        b)kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp
        kullanılmadığını öğrenme,
        <br /> c) EK Dizayn'ın yurt içinde veya yurt dışında kişisel verileri
        aktardığı üçüncü kişileri bilme, <br />
        d) kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların
        düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin
        aktarıldığı üçüncü kişilere bildirilmesini isteme,
        <br /> e) Kanuna uygun olarak kişisel verilerin silinmesini veya yok
        edilmesini isteme <br /> f) Kişisel verilerin düzeltilmesi, silinmesi ya
        da yok edilmesi talebi halinde; yapılan işlemlerin kişisel verilerin
        aktarıldığı üçüncü kişilere bildirilmesini isteme,
        <br /> g) kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle
        zarara uğraması hâlinde zararın giderilmesini talep etme <br /> h)
        Kişisel verilerinin birer kopyasını alma.
      </h5>
      <h3>Görüş ve sorularınızla ilgili bizimle iletişime geçebilirsiniz.</h3>
      <div className="mt-2">
        <strong>
          Şirket Ünvanı : Emre Küçük EK Dizayn <br /> E-mail adresi :
          info@ek.com.tr <br />
          Posta adresi : Kartaltepe Mah. 1.Malazgirt Cad. No:10/2 D:147
          Küçükçekmece - İstanbul
        </strong>
      </div>
    </div>
  );
}
