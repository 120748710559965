import { Card, CardBody, CardHeader } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { MapsView } from "../../../../../../core/Services";
import { useAuth } from "../../../../../../auth/core/AuthContext";

export default function Maps({selectedMonth}) {
  const { userData } = useAuth();
  const [view, setView] = useState([]);
  

  useEffect(() => {
    MapsView(userData?.unique_id, selectedMonth)
      .then((data) => {
        setView(data); // Gruplanmış verileri state'e atama
      })
      .catch((error) => {
        console.log("Müşteri Çekme Hatası", error);
      });
  }, [selectedMonth, userData?.unique_id]);
  const viewArray = Array.isArray(view) ? view : [];
  const foundWeb = (type) => {
    const foundItem = viewArray?.find((item) => item?.type === type);
    return foundItem ? foundItem.web : 0;
  };

  const foundRoad = (type) => {
    const foundItem = viewArray?.find((item) => item.type === type);
    return foundItem ? foundItem.road : 0;
  };

  const foundPhone = (type) => {
    const foundItem = viewArray?.find((item) => item.type === type);
    return foundItem ? foundItem.phone : 0;
  };

  return (
    <div className="w-full">
      <div className="mb-3 font-bold text-xl">
        Haritalar
      </div>
      <div className="row" style={{ display: "flex" }}>
        <div className="col-4">
          <Card
            style={{ height: "auto", background: "#662549", padding: "5px" }}
          >
            <CardHeader color={"white"} textAlign={"center"}>
              <i className="pi pi-google" style={{ fontSize: "1rem" }} /> GOOGLE
            </CardHeader>
            <CardBody
              background={"white"}
              textAlign={"center"}
              overflow={"hidden"}
            >
              <strong style={{ color: "orange" }}>WEB SİTESİ ZİYARET</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid orange",
                  padding: "4px",
                }}
              >
                <h3>{foundWeb("1")}</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "orange" }}>YOL TARİFİ</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid orange",
                  padding: "4px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <h3>{foundRoad("1")}</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "orange" }}>TELEFON ARAMA</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid orange",
                  padding: "4px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <h3>{foundPhone("1")}</h3>
              </div>{" "}
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "orange" }}>İŞLEM SAYISI</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid orange",
                  padding: "4px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <h3>
                  {Number(foundWeb("1")) +
                    Number(foundPhone("1")) +
                    Number(foundRoad("1"))}
                </h3>
              </div>
            </CardBody>
          </Card>
        </div>

        <div className="col-4">
          <Card
            style={{ height: "auto", background: "#662549", padding: "5px" }}
          >
            <CardHeader color={"white"} textAlign={"center"}>
              <i className="pi pi-map" style={{ fontSize: "1rem" }} /> YANDEX
            </CardHeader>
            <CardBody
              background={"white"}
              textAlign={"center"}
              overflow={"hidden"}
            >
              <strong style={{ color: "orange" }}>WEB SİTESİ ZİYARET</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid orange",
                  padding: "4px",
                }}
              >
                <h3>{foundWeb("2")}</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "orange" }}>YOL TARİFİ</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid orange",
                  padding: "4px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <h3>{foundRoad("2")}</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "orange" }}>TELEFON ARAMA</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid orange",
                  padding: "4px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <h3>{foundPhone("2")}</h3>
              </div>{" "}
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "orange" }}>İŞLEM SAYISI</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid orange",
                  padding: "4px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <h3>
                  {Number(foundWeb("2")) +
                    Number(foundRoad("2")) +
                    Number(foundPhone("2"))}
                </h3>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-4">
          <Card
            style={{ height: "auto", background: "#662549", padding: "5px" }}
          >
            <CardHeader color={"white"} textAlign={"center"}>
              <i className="pi pi-apple" style={{ fontSize: "1rem" }} /> APPLE
            </CardHeader>
            <CardBody
              background={"white"}
              textAlign={"center"}
              overflow={"hidden"}
            >
              <strong style={{ color: "orange" }}>WEB SİTESİ ZİYARET</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid orange",
                  padding: "4px",
                }}
              >
                <h3> {foundWeb("3")}</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "orange" }}>YOL TARİFİ</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid orange",
                  padding: "4px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <h3> {foundRoad("3")}</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "orange" }}>TELEFON ARAMA</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid orange",
                  padding: "4px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <h3> {foundPhone("3")}</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "orange" }}>İŞLEM SAYISI</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid orange",
                  padding: "4px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <h3>
                  {Number(foundWeb("3")) +
                    Number(foundRoad("3")) +
                    Number(foundPhone("3"))}
                </h3>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
}
