import React, { useEffect, useState } from "react";

import { Grid, GridItem } from "@chakra-ui/react";
import {
  FinishJobService,
  EditJobService,
  JobDetailService,
  AddStageService,
  DeleteStageService,
  EditStageService,
} from "../../../core/Services";
import Sidebar from "../../../../_ekdizayn/layout/Sidebar/Sidebar";
import Navbar from "../../../../_ekdizayn/layout/Navbar/Navbar";
import { Splitter, SplitterPanel } from "primereact/splitter";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Timeline } from "primereact/timeline";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

export default function JobDetailPage() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const id = params.id;
  const [subject, setSubject] = useState("");
  const [desc, setDesc] = useState("");
  const [stage, setStage] = useState("");
  const [newStage, setNewStage] = useState("");
  const [job, setJob] = useState({});
  const [selectedStage, setSelectedStage] = useState({});
  const [customers, setCustomers] = useState([]);
  const [users, setUsers] = useState([]);
  const [stages, setStages] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  useEffect(() => {
    JobDetailService(id).then((data) => {
      setJob(data?.job);
      setCustomers(data?.customers);
      setUsers(data?.users);
      setDesc(data.job?.desc);
      setSubject(data.job?.subject);
      setStages(data?.job_details);
    });
  }, [id, editModal, deleteModal]);

  let logData = JSON.parse(localStorage.getItem("userData"));
  const addStage = async () => {
    let requestBody = {
      id: job.jobs_no,
      stage,
      user_id: logData.id,
    };
    await AddStageService(requestBody);
    window.location.reload();
  };

  const saveChanges = async () => {
    let requestBody = {
      id: job.id,
      newSub: subject,
      newDesc: desc,
    };

    await EditJobService(requestBody);
  };

  const handleCompleteJob = async () => {
    try {
      await FinishJobService(job.id, job.designated_person);
      // setVisible(false); // Dialog'u kapat
    } catch (error) {
      console.error("Hata:", error.message);
    }
  };
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    return new Date(dateString).toLocaleString("tr-TR", options);
  };
  return (
    <Grid
      templateAreas={`"header header"
                  "nav main"
                 `}
      gridTemplateRows={"auto 1fr "}
      gridTemplateColumns={"auto 1fr"}
      h="auto"
      color="blackAlpha.700"
      fontWeight="bold"
    >
      <GridItem area={"header"}>
        <Navbar />
      </GridItem>
      <GridItem area={"nav"}>
        <Sidebar />
      </GridItem>
      <GridItem area={"main"}>
        <Splitter style={{ height: "100vh", marginTop: "1.4rem" }}>
          <SplitterPanel className=" p-5 block" size={45} minSize={10}>
            <div className="font-medium text-3xl text-900 mb-3 w-full">
              İş Detayları
            </div>
            <div className="w-full mt-5">
              <div className="surface-section">
                <ul className="list-none p-0 m-0">
                  <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                    <div className="text-500 w-6 md:w-2 font-medium">
                      İş Emri No
                    </div>
                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                      {job?.jobs_no}
                    </div>
                  </li>
                  <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                    <div className="text-500 w-6 md:w-2 font-medium">
                      Müşteri
                    </div>
                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                      {customers &&
                        Array.isArray(customers) &&
                        customers.find(
                          (cust) => Number(cust.id) === Number(job.customer_id)
                        )?.title}
                    </div>
                  </li>
                  <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                    <div className="text-500 w-6 md:w-2 font-medium">
                      Teslim Tarihi
                    </div>
                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                      {formatDate(job?.finish_date)}
                    </div>
                  </li>
                  <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                    <div className="text-500 w-6 md:w-2 font-medium">Konu</div>
                    <div className="w-full md:w-10 md:flex-order-0 flex-order-1">
                      <InputText
                        type="text"
                        className="p-inputtext-lg w-full"
                        placeholder="Konu"
                        onChange={(e) => setSubject(e.target.value)}
                        value={subject}
                      />
                    </div>
                  </li>

                  <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
                    <div className="text-500 w-6 md:w-2 font-medium">
                      Açıklama
                    </div>
                    <div className=" w-full md:w-10 md:flex-order-0 flex-order-1 line-height-3">
                      <InputTextarea
                        type="text"
                        rows={5}
                        cols={30}
                        className="p-inputtext-lg w-full"
                        placeholder="Açıklama"
                        onChange={(e) => setDesc(e.target.value)}
                        value={desc}
                        style={{ marginTop: "1rem", maxHeight: "10rem" }}
                      />
                    </div>
                  </li>
                </ul>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "2rem",
                }}
              >
                <Button
                  style={{ color: "white" }}
                  variant="solid"
                  className="detail-btn-one"
                  onClick={handleCompleteJob}
                >
                  İşi Tamamla
                </Button>
                <Button
                  variant="solid"
                  style={{
                    color: "white",
                    marginLeft: "1rem",
                  }}
                  className="detail-btn-two"
                  onClick={saveChanges}
                >
                  Değişiklikleri Kaydet
                </Button>
              </div>
            </div>
          </SplitterPanel>
          <SplitterPanel
            style={{ display: "flex", justifyContent: "end" }}
            className="p-5  block"
            size={55}
          >
            <div className="font-medium text-3xl text-900 mb-3 w-full">
              Zaman Çizelgesi
            </div>
            <div style={{ maxHeight: "70vh", overflowY: "auto" }}>
              <Timeline
                className="w-full"
                value={stages}
                content={(item) => (
                  <div className="flex">
                    <small className="text-color-secondary">
                      {formatDate(item.updated_at)}
                    </small>
                    <div style={{ color: "#f39f5a", padding: "0" }}>
                      <Button
                        severity="warning"
                        style={{ padding: "0" }}
                        onClick={() => {
                          setSelectedStage(item);
                          setNewStage(item.stage);
                          setEditModal(true);
                        }}
                        icon="pi pi-pencil"
                      />
                    </div>
                    <div style={{ color: "#ae445a", padding: "0" }}>
                      <Button
                        style={{ padding: "0" }}
                        icon="pi pi-trash"
                        onClick={() => {
                          setSelectedStage(item);
                          setDeleteModal(true);
                        }}
                        severity="danger"
                      />
                    </div>
                  </div>
                )}
                opposite={(item) => (
                  <span>
                    {item.stage} --{" "}
                    {users &&
                      Array.isArray(users) &&
                      users.find(
                        (cust) => Number(cust.id) === Number(item.user_id)
                      )?.name}
                  </span>
                )}
              />
            </div>

            <div
              style={{
                display: "flex",
                position: "fixed", // Sayfanın altında sabitlenmiş olmasını sağlar
                bottom: 0,
                alignItems: "center",
                padding: "1rem",
                width: "40%",
              }}
            >
              <InputText
                type="text"
                className="p-inputtext-lg w-full"
                placeholder="Aşama Giriniz"
                onChange={(e) => setStage(e.target.value)}
                value={stage}
              />

              <Button
                variant="solid"
                style={{
                  color: "white",
                  marginLeft: "1rem",
                }}
                className="detail-btn-three "
                onClick={addStage}
                icon="pi pi-caret-right"
              />
            </div>
          </SplitterPanel>
        </Splitter>
        <Dialog
          header="Aşamayı silmek istediğinize emin misiniz?"
          visible={deleteModal}
          style={{ width: "50vw" }}
          onHide={() => setDeleteModal(false)}
          footer={
            <div>
              <Button
                label="İptal"
                icon="pi pi-times"
                onClick={() => setDeleteModal(false)}
                className="btn-excel-two"
              />
              <Button
                label="Aşamayı Sil"
                icon="pi pi-check"
                onClick={() => {
                  DeleteStageService(selectedStage.id);
                  setDeleteModal(false);
                }}
                className="btn-excel-two"
                style={{ marginLeft: "15px" }}
              />
            </div>
          }
        ></Dialog>
        <Dialog
          header="Aşamayı Düzenle"
          visible={editModal}
          style={{ width: "50vw" }}
          onHide={() => setEditModal(false)}
          footer={
            <div>
              <Button
                label="İptal"
                icon="pi pi-times"
                onClick={() => setEditModal(false)}
                className="btn-excel-two"
              />
              <Button
                label="Kaydet"
                icon="pi pi-check"
                onClick={() => {
                  EditStageService(selectedStage.id, newStage);
                  setEditModal(false);
                }}
                className="btn-excel-two"
                style={{ marginLeft: "15px" }}
              />
            </div>
          }
        >
          <InputText
            type="text"
            className="p-inputtext-lg w-full"
            placeholder="Aşama Giriniz"
            onChange={(e) => setNewStage(e.target.value)}
            value={newStage}
          />
        </Dialog>
      </GridItem>
    </Grid>
  );
}
