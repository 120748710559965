import { Box, Grid, GridItem } from "@chakra-ui/react";
import React, { useEffect } from "react";
import DashboardBox from "./components/DashboardBox";
import DashboardTableAdminPage from "./admin/components/DashboardTableAdminPage";
import DashboardBussinessStatus from "./admin/components/DashboardBusinessStatus";
import DasboardJobTracking from "./admin/components/DashboardJobTracking";
import { DashboardService } from "../../core/Services";
import { useDispatch } from "react-redux";
import { addDashboardData } from "../../redux/DashboardSlice";
import { BreadCrumb } from "primereact/breadcrumb";
import { useAuth } from "../../auth/core/AuthContext";
import DashboardTableUserPage from "./user/components/DashboardTableUserPage";

function DashboardPage() {
  const getTurkishLabel = (url) => {
    switch (url) {
      case "/dashboard":
        return "";
      // Diğer durumlar için gerektiğinde eşleme yapabilirsiniz
      default:
        return "Sayfa"; // Varsayılan olarak "Sayfa" kullanılabilir veya istediğiniz bir şeyi kullanabilirsiniz
    }
  };

  const url = "/dashboard";
  const turkishLabel = getTurkishLabel(url); // "Ürünler"
  const { userData } = useAuth();
  const itemsBread = [{ label: turkishLabel, url: url }];
  const home = { icon: "pi pi-home", url: "/dashboard" };
  const dispatch = useDispatch();

  useEffect(() => {
    const id = userData.id;

    const fetchData = async () => {
      try {
        const data = await DashboardService(id);
        dispatch(addDashboardData(data));
      } catch (error) {
        console.error("API Hatası:", error);
      }
    };

    fetchData();
  }, [dispatch, userData.id]);

  return (
    <Box mt="5">
      <BreadCrumb model={itemsBread} home={home} style={{ padding: "1rem" }} />
      <Grid
        h="100vh"
        display="grid"
        w="auto"
        templateRows="repeat(8, 1fr)"
        templateColumns="repeat(8, 1fr)"
      >
        <GridItem
          marginBottom="1rem"
          direction="row"
          align="center"
          justify="center"
          w="auto"
          rowSpan={1}
          colSpan={8}
        >
          <DashboardBox />
        </GridItem>

        {userData?.type === "1" && (
          <GridItem w="auto" rowSpan={7} colSpan={8}>
            <DashboardTableAdminPage />
          </GridItem>
        )}

        {userData?.type === "0" && (
          <GridItem w="auto" rowSpan={7} colSpan={8}>
            <DashboardTableUserPage />
          </GridItem>
        )}

        {userData?.type === "1" && (
          <GridItem w="auto" rowSpan={4} colSpan={4}>
            <DasboardJobTracking />
          </GridItem>
        )}
        {userData?.type === "1" && (
          <GridItem w="auto" rowSpan={4} colSpan={4}>
            <DashboardBussinessStatus />
          </GridItem>
        )}
      </Grid>
    </Box>
  );
}
export default DashboardPage;
