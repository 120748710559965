import React, { useEffect, useState } from "react";
import { Chart } from "primereact/chart";

export default function TrafficChart({ trafficSource}) {
  const [trafficSourceData, setTrafficSourceData] = useState({});
  const [trafficSourceOptions, setTrafficSourceOptions] = useState({});
  const DataTypes = [
    { name: "Reklamlardan Gelen Trafik", value: "1" },
    { name: "Direkt", value: "2" },
    { name: "Doğal Arama", value: "3" },
    { name: "Referans", value: "4" },
    { name: "Sosyal", value: "5" },
    { name: "Diğer", value: "6" },
  ];


  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const data = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [
            documentStyle.getPropertyValue("--blue-500"),
            documentStyle.getPropertyValue("--yellow-500"),
            documentStyle.getPropertyValue("--green-500"),
          ],
          hoverBackgroundColor: [
            documentStyle.getPropertyValue("--blue-400"),
            documentStyle.getPropertyValue("--yellow-400"),
            documentStyle.getPropertyValue("--green-400"),
          ],
        },
      ],
    };

    // Gelen veriyi dönüştürme
    trafficSource?.forEach((item) => {
      const dataTypeName = DataTypes.find(
        (dt) => dt.value === item.traffic_source
      )?.name;
      data.labels.push(dataTypeName ? dataTypeName : item.trafficSource);
      data.datasets[0].data.push(parseInt(item.value));
    });

    const options = {
      plugins: {
        legend: {
          labels: {
            usePointStyle: true,
          },
        },
      },
    };

    setTrafficSourceData(data);
    setTrafficSourceOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trafficSource]);

  return (
    <div>
      <Chart
        style={{ width: "30rem", height: "20rem" }}
        type="pie"
        data={trafficSourceData}
        options={trafficSourceOptions}
      />
    </div>
  );
}
