import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  AllPermissions,
  UpdatePermissionStatus,
  UserService,
} from "../../../../core/Services";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Card, CardHeader, Flex, Heading } from "@chakra-ui/react";
import { Dialog } from "primereact/dialog";
import { BsFillFilePdfFill } from "react-icons/bs";
import { Dropdown } from "primereact/dropdown";
import { useAuth } from "../../../../auth/core/AuthContext";

export default function CancelPermissions() {
  const [addModal] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState(null);
  const { userData } = useAuth();
  const [dialogs, setDialogs] = useState({});
  const [selectedStatus, setSelectedStatus] = useState(null);

  const handleCompletePermissions = async () => {
    try {
      if (!selectedPermissions) {
        console.error("Tamamlanacak görev bulunamadı.");
        return;
      }

      await UpdatePermissionStatus(selectedPermissions.id, selectedStatus);
      setDialogs(false);
      window.location.reload();
    } catch (error) {
      console.error("Hata:", error.message);
    }
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("tr-TR");
    return formattedDate;
  };
  const handleStatusChange = (e) => {
    setSelectedStatus(e.value);
  };

  const actionApprovalBodyTemplate = (tasks) => {
    const isVisible = dialogs[tasks.id] || false;
    const calculateUsedDays = (renewalDate, startingDate) => {
      const renewalDateObj = new Date(renewalDate);
      const startingDateObj = new Date(startingDate);

      // Tarih farkını hesapla ve gün olarak al
      const timeDifference = renewalDateObj - startingDateObj;
      const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

      return daysDifference;
    };

    const statusType = [
      { label: "Reddet", value: "6" },
      { label: "Onayla", value: "5" },
    ];
    return (
      <React.Fragment>
        <Button
          icon="pi pi-check"
          rounded
          className="mr-2 radio-b-new"
          onClick={() => {
            setSelectedPermissions(tasks); 
            setDialogs((prevDialogs) => ({
              ...prevDialogs,
              [tasks.id]: true,
            }));
          }}
        />
        <Dialog
          header="İzin İçeriği"
          visible={isVisible}
          style={{ width: "50vw" }}
          onHide={() => {
            setDialogs((prevDialogs) => ({
              ...prevDialogs,
              [tasks.id]: false,
            }));
            setSelectedPermissions(null);
          }}
          footer={
            <div>
              <Button
                label="Kaydet"
                icon="pi pi-check"
                onClick={handleCompletePermissions}
                className="btn-excel-two"
                style={{ marginLeft: "15px" }}
              />
            </div>
          }
        >
          <div className="field">
            <label htmlFor="description" className="font-bold">
              Kullanılmak İstenen Gün Sayısı :{" "}
              {calculateUsedDays(
                selectedPermissions?.renewal_date,
                selectedPermissions?.starting_date
              )}
            </label>
          </div>
          <div className="field">
            <label className="mb-3 font-bold">
              Açıklama: {selectedPermissions?.note}
            </label>
          </div>
          <div className="field">
            <label htmlFor="description" className="font-bold">
              Başlangıç Tarihi :{" "}
              {formatDate(selectedPermissions?.starting_date)}
            </label>
          </div>
          <div className="field">
            <label htmlFor="description" className="font-bold">
              Bitiş Tarihi : {formatDate(selectedPermissions?.renewal_date)}
            </label>
          </div>
          <div className="field">
            <label htmlFor="description" className="font-bold">
              Toplam Kullanılan İzin : {selectedPermissions?.total_leave}
            </label>
          </div>
          <div className="field">
            <label htmlFor="status" className="font-bold">
              Durum:{" "}
              <Dropdown
                filter
                value={selectedStatus}
                options={statusType}
                onChange={handleStatusChange}
                optionLabel="label"
                placeholder="Durum Seçiniz"
              />
            </label>
          </div>
        </Dialog>
      </React.Fragment>
    );
  };

  const toast = useRef(null);

  useEffect(() => {
    AllPermissions()
      .then((data) => {
        setPermissions(data);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });

    UserService()
      .then((data) => {
        setEmployees(data.users);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  }, [addModal]);

  const getStatusInfo = (status) => {
    let statusLabel, statusColor;

    switch (status) {
      case "4":
        statusLabel = "İptal Talep Edildi";
        statusColor = "purple";
        break;
      case "5":
        statusLabel = "İptal Talebi Onaylandı";
        statusColor = "green";
        break;
      case "6":
        statusLabel = "İptal Talebi Reddedildi";
        statusColor = "blue";
        break;

      default:
        statusLabel = "Bilinmeyen Durum";
        statusColor = "black";
    }

    return { statusLabel, statusColor };
  };

  const filteredPermissions = permissions.filter(
    (permission) =>
      parseInt(permission.status) === 4 ||
      parseInt(permission.status) === 5 ||
      parseInt(permission.status) === 6
  );
  return (
    <Flex mt="5" justifyContent="center" alignItems="center">
      <div
        className="card"
        style={{
          overflow: "auto",
          boxSizing: "border-box",
          width: "100%",
          height: "100%",
          position: "relative",
          padding: "1rem",
        }}
      >
        <Card>
          <CardHeader>
            <Flex justifyContent="space-between" alignItems="center">
              <Heading size="md">İzin Durumu</Heading>
            </Flex>
          </CardHeader>
          <Toast ref={toast} />
          <DataTable
            stripedRows
            size="small"
            value={filteredPermissions}
            dataKey="id"
            tableStyle={{ minWidth: "50rem" }}
            rows={15}
            rowsPerPageOptions={[15, 30, 60, 100]}
            scrollHeight="65vh"
            scrollable
            paginator
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate=" {totalRecords} izinden {first} - {last} arası gösteriliyor"
          >
            <Column
              field="name"
              header="İzin Sahibi"
              body={(rowData) => {
                const employee = employees?.find(
                  (emp) => emp.id === Number(rowData.name)
                );
                return employee ? employee.name : "Bilinmeyen İzin Sahibi";
              }}
            ></Column>
            <Column field="note" header="Açıklama"></Column>
            <Column
              field="document"
              body={(rowData) => (
                <a
                  href={`data:application/pdf;base64,${rowData.document}`}
                  download="document.pdf"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <BsFillFilePdfFill color="red" size={35} />
                </a>
              )}
              header="Belge"
            ></Column>
            <Column
              style={{ minWidth: "5rem" }}
              field="created_at"
              body={(rowData) => <span>{formatDate(rowData.created_at)}</span>}
              header="Tarih"
            ></Column>
            <Column
              field="status"
              body={(rowData) => {
                const { statusLabel, statusColor } = getStatusInfo(
                  rowData.status
                );
                return (
                  <span
                    style={{
                      backgroundColor: statusColor,
                      color: "white",
                      padding: "0.5rem",
                      borderRadius: "4px",
                    }}
                  >
                    {statusLabel}
                  </span>
                );
              }}
              header="Durum"
            ></Column>
            {userData?.type === "1" && (
              <Column
                body={actionApprovalBodyTemplate}
                style={{ minWidth: "6rem" }}
              ></Column>
            )}
          </DataTable>
        </Card>
      </div>
    </Flex>
  );
}
