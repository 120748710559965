import React from "react";
import NewPermissions from "./components/NewPermissions";
import {
  Box,
  Card,
  CardBody,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import StatusPermissions from "./components/StatusPermissions";
import CancelPermissions from "./components/CancelPermissions";
import { BreadCrumb } from "primereact/breadcrumb";
import { useAuth } from "../../../auth/core/AuthContext";

function PermissionsPage() {
  const { userData } = useAuth();

  const getTurkishLabel = (url) => {
    switch (url) {
      case "/permissions":
        return "İzinler";
      default:
        return "Sayfa"; 
    }
  };
  const url = "/permissions";
  const turkishLabel = getTurkishLabel(url); 
  const itemsBread = [{ label: turkishLabel, url: url }];
  const home = { icon: "pi pi-home", url: "/dashboard" };
  return (
    <Box mt="5">
      <Card>
        <BreadCrumb
          model={itemsBread}
          home={home}
          style={{ padding: "1rem" }}
        />
        <CardBody>
          <Tabs align="end" variant="enclosed">
            <TabList>
              <Tab>Durum</Tab>
              <Tab>Yeni Talep</Tab>
              {Number(userData?.type) === 1 && <Tab>İzin İptal Talepleri</Tab>}
            </TabList>
            <TabPanels>
              <TabPanel>
                <StatusPermissions />
              </TabPanel>
              <TabPanel>
                <NewPermissions />
              </TabPanel>
              <TabPanel>
                <CancelPermissions />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </CardBody>
      </Card>
    </Box>
  );
}

export default PermissionsPage;
