import React, { useState } from "react";
import { Splitter, SplitterPanel } from "primereact/splitter";
import DevicesGraphPage from "./components/DevicesGraphPage";
import DevicesGraphTable from "./components/DevicesGraphTable";
import DevicesGraphAppearance from "./components/DevicesGraphAppearance";

export default function DevicesGraph({
  uniqueCustomerId,
  selectedMonth,
  selectedCustomer, 
  months,
}) {
   const [tableKey, setTableKey] = useState(0);
   const [appearanceKey, setAppearanceKey] = useState(0);

   const updateTableData = () => {
     setTableKey((prevKey) => prevKey + 1);
     setAppearanceKey((prevKey) => prevKey + 1);
   };
 
  const DataTypes = [
    { name: "Masaüstü", value: "1" },
    { name: "Mobil", value: "2" },
    { name: "Tablet", value: "3" },
  ];
  return (
    <Splitter>
      <SplitterPanel>
        <DevicesGraphPage
          updateTableData={updateTableData}
          selectedMonth={selectedMonth}
          selectedCustomer={selectedCustomer}
          uniqueCustomerId={uniqueCustomerId}
          DataTypes={DataTypes}
        />
      </SplitterPanel>
      <SplitterPanel size={75} style={{ display: "block", padding: "1rem" }}>
        <DevicesGraphTable
          key={tableKey}
          updateTableData={updateTableData}
          selectedMonth={selectedMonth}
          selectedCustomer={selectedCustomer}
          uniqueCustomerId={uniqueCustomerId}
          DataTypes={DataTypes}
          months={months}
        />
        <br></br>
        <DevicesGraphAppearance
          appearanceKey={appearanceKey}
          selectedMonth={selectedMonth}
          selectedCustomer={selectedCustomer}
          uniqueCustomerId={uniqueCustomerId}
          DataTypes={DataTypes}
          months={months}
        />
      </SplitterPanel>
    </Splitter>
  );
}
