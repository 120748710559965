import React, { useEffect, useState } from "react";
import CountryFlag from "react-country-flag";
import { GetCountryStatistics } from "../../../../../../../core/Services";
import { useAuth } from "../../../../../../../auth/core/AuthContext";

export default function CountryList() {
  const countriees = require("../../../../employees/employees-reports/user-web-site/country-visit-graph/components/countries.json");
  const {userData}= useAuth();
  const[countries , setCountries] = useState([]);
  useEffect(() => {
    GetCountryStatistics(userData?.unique_id)
      .then((data) => {
        setCountries(data.data);
      })
      .catch((error) => {
        console.log("Ülke Çekme Hatası", error);
      });
  }, [userData?.unique_id]);
   const months = [
     { name: "Ocak", value: "01" },
     { name: "Şubat", value: "02" },
     { name: "Mart", value: "03" },
     { name: "Nisan", value: "04" },
     { name: "Mayıs", value: "05" },
     { name: "Haziran", value: "06" },
     { name: "Temmuz", value: "07" },
     { name: "Ağustos", value: "08" },
     { name: "Eylül", value: "09" },
     { name: "Ekim", value: "10" },
     { name: "Kasım", value: "11" },
     { name: "Aralık", value: "12" },
   ];

  return (
    <div>
      <table
        style={{
          fontFamily: "arial, sans-serif",
          borderCollapse: "collapse",
          width: "80%",
        }}
      >
        <thead>
          <tr>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            ></th>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              Dönem
            </th>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              Ülkeler
            </th>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              Ziyaretçi Sayısı
            </th>
          </tr>
        </thead>
        <tbody>
          {countries?.map((country, index) => (
            <tr
              key={country.country}
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              <td
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                {index + 1}
              </td>
              <td
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                {months?.find((months) => months.value === country.month)
                  ?.name || ""}
              </td>
              <td
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                <div>
                  <CountryFlag
                    countryCode={country.country}
                    svg
                    style={{ marginRight: "8px" }}
                  />
                  {
                    countriees?.find(
                      (dataType) => dataType.code === country.country
                    )?.name
                  }
                </div>
              </td>
              <td
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                {country.visitor}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
