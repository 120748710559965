import { Card, CardBody, CardHeader } from "@chakra-ui/react";
import React from "react";

export default function ChartPage({ uniqueCustomerId, months, DataTypes }) {
  return (
    <div className="w-full">
      <div className=" font-bold w-full">Görünüm:</div>
      <div className="row" style={{ display: "flex" }}>
        <div className="col-4">
          <Card
            style={{ height: "auto", background: "#662549", padding: "5px" }}
          >
            <CardHeader color={"white"} textAlign={"center"}>
              <i className="pi pi-google" style={{ fontSize: "1rem" }} /> GOOGLE
            </CardHeader>
            <CardBody
              background={"white"}
              textAlign={"center"}
              overflow={"hidden"}
            >
              <strong style={{ color: "orange" }}>WEB SİTESİ ZİYARET</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid orange",
                  padding: "4px",
                }}
              >
                <h3>500</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "orange" }}>YOL TARİFİ</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid orange",
                  padding: "4px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <div style={{ alignItems: "center" }}>500</div>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "orange" }}>TELEFON ARAMA</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid orange",
                  padding: "4px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <div style={{ alignItems: "center" }}>500</div>
              </div>{" "}
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "orange" }}>İŞLEM SAYISI</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid orange",
                  padding: "4px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <div style={{ alignItems: "center" }}>1500</div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-4">
          <Card
            style={{ height: "auto", background: "#662549", padding: "5px" }}
          >
            <CardHeader color={"white"} textAlign={"center"}>
              <i className="pi pi-map" style={{ fontSize: "1rem" }} /> YANDEX
            </CardHeader>
            <CardBody
              background={"white"}
              textAlign={"center"}
              overflow={"hidden"}
            >
              <strong style={{ color: "orange" }}>WEB SİTESİ ZİYARET</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid orange",
                  padding: "4px",
                }}
              >
                <h3>500</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "orange" }}>YOL TARİFİ</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid orange",
                  padding: "4px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <div style={{ alignItems: "center" }}>500</div>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "orange" }}>TELEFON ARAMA</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid orange",
                  padding: "4px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <div style={{ alignItems: "center" }}>500</div>
              </div>{" "}
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "orange" }}>İŞLEM SAYISI</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid orange",
                  padding: "4px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <div style={{ alignItems: "center" }}>1500</div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-4">
          <Card
            style={{ height: "auto", background: "#662549", padding: "5px" }}
          >
            <CardHeader color={"white"} textAlign={"center"}>
              <i className="pi pi-apple" style={{ fontSize: "1rem" }} /> APPLE
            </CardHeader>
            <CardBody
              background={"white"}
              textAlign={"center"}
              overflow={"hidden"}
            >
              <strong style={{ color: "orange" }}>WEB SİTESİ ZİYARET</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid orange",
                  padding: "4px",
                }}
              >
                <h3>500</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "orange" }}>YOL TARİFİ</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid orange",
                  padding: "4px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <div style={{ alignItems: "center" }}>500</div>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "orange" }}>TELEFON ARAMA</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid orange",
                  padding: "4px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <div style={{ alignItems: "center" }}>500</div>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "orange" }}>İŞLEM SAYISI</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid orange",
                  padding: "4px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <div style={{ alignItems: "center" }}>1500</div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
}
