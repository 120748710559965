import { Input, InputGroup, InputLeftAddon } from "@chakra-ui/react";
import { Button } from "primereact/button";
import { Chips } from "primereact/chips";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React, { useRef, useState } from "react";
import { AddSupplier } from "../../../../core/Services";

export default function SupplierAddDialog({
  responsiblePerson,
  setResponsiblePerson,
  company,
  setCompany,
  address,
  setAdress,
  phone,
  setPhone,
  activityArea,
  setActivityArea,
  displayAddDialog,
  setDisplayAddDialog,
}) {
  const toast = useRef(null);
  const [inputErrors, setInputErrors] = useState({
    responsiblePerson: false,
    company: false,
    phone: false,
    address: false,
    activityArea: false,
  });
  const addButton = () => {
    return (
      <Button
        label="Ekle"
        style={{ width: "8rem" }}
        onClick={newCreate}
        className="btn-excel-two"
      />
    );
  };
  const hideAddDialog = () => {
    setDisplayAddDialog(false);
  };

  const newCreate = async () => {
    if (
      !responsiblePerson ||
      !company ||
      !phone ||
      !address ||
      !activityArea 
    ) {
      setInputErrors({
        responsiblePerson: !responsiblePerson,
        company: !company,
        phone: !phone,
        address: !address,
        activityArea: !activityArea,

      });
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Lütfen boş kısımları doldurun.",
      });
      return;
    }


    await AddSupplier(
      responsiblePerson,
      company,
      address,
      phone,
      activityArea
    );

    setDisplayAddDialog(false);
    setResponsiblePerson("");
    setCompany("");
    setAdress("");
    setPhone("");
    setActivityArea("");
  };
  return (
    <div>
      <Dialog
        visible={displayAddDialog}
        breakpoints={{ "960px": "70vw", "641px": "50vw" }}
        style={{ width: "50vw" }}
        header="Tedarikçi Ekle"
        onHide={hideAddDialog}
        modal
        footer={addButton}
      >
        <div className="confirmation-content" style={{ display: "flex" }}>
          <div className="col-6" style={{ display: "inline-block" }}>
            <div className="mb-3">
              <InputText
                type="text"
                value={company}
                onChange={(e) => {
                  setCompany(e.target.value);
                  setInputErrors({ ...inputErrors, company: false });
                }}
                className="p-inputtext-lg w-full"
                placeholder="Tedarikçi "
              />
              {inputErrors.company && <small  style={{color:"red"}} >Lütfen Tedarikçi Giriniz.</small>}
            </div>
            <div className="mb-3">
              <InputText
                type="text"
                value={address}
                onChange={(e) => {
                  setAdress(e.target.value);
                  setInputErrors({ ...inputErrors, address: false });
                }}
                className="p-inputtext-lg w-full"
                placeholder="Adres"
              />
              {inputErrors.address && <small  style={{color:"red"}} >Lütfen Adres Giriniz.</small>}
            </div>
            <div className="mb-3">
              <Chips
                value={activityArea}
                className="w-full p-inputtext"
                onChange={(e) => {
                  setActivityArea(e.value);
                  setInputErrors({ ...inputErrors, activityArea: false });
                }}
                placeholder="Sorumluluk Alanı"
                style={{
                  border: "1px solid ",
                }}
              />
              {inputErrors.activityArea && (
                <small  style={{color:"red"}} >Lütfen Sorumluluk Alanı Giriniz.</small>
              )}
            </div>
          </div>
          <div className="col-6" style={{ display: "inline-block" }}>
            <div className="mb-3">
              <InputText
                type="text"
                value={responsiblePerson}
                onChange={(e) => {
                  setResponsiblePerson(e.target.value);
                  setInputErrors({ ...inputErrors, responsiblePerson: false });
                }}
                className="p-inputtext-lg w-full"
                placeholder="Tedarikçi Sorumlusu"
              />
              {inputErrors.responsiblePerson && (
                <small  style={{color:"red"}}>Lütfen Tedarikçi Sorumlusu Giriniz.</small>
              )}
            </div>
            <div>
              <InputGroup>
                <InputLeftAddon>+90</InputLeftAddon>
                <Input
                  type="tel"
                  border="1px gray solid"
                  placeholder="Numara giriniz"
                  value={phone}
                  onChange={(e) => {
                    const inputNumber = e.target.value.replace(/\D/g, ""); // Sadece rakam karakterlerini al
                    const trimmedNumber = inputNumber.slice(0, 10); // İlk 10 rakamı al
                    setPhone(trimmedNumber);
                    setInputErrors({ ...inputErrors, phone: false });
                  }}
                />
              </InputGroup>
              {inputErrors.phone && (
                <small style={{color:"red"}}>Lütfen Telefon Numarası Giriniz.</small>
              )}
            </div>
          </div>
        </div>
      </Dialog>
      <Toast ref={toast} />
    </div>
  );
}
