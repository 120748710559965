import React from "react";
import UserMain from "./components/UserMain";

export default function EmployeesReportsPage() {
  return (
    <div className="card">
     <UserMain/>
    </div>
  );
}
