import { Card } from "primereact/card";
import React from "react";

export default function Services({ service, formatDate }) {
  return (
    <div>
      {service?.length ? (
        <Card
          style={{ minWidth: "15rem" }}
          className="d-flex h-auto w-auto text-center"
          title="Hizmetlerim"
        >
          {service &&
            service.map((service, index) => {
              const currentDate = new Date();
              const renewalDate = new Date(service?.renewal_date);
              const timeDifference =
                renewalDate.getTime() - currentDate.getTime();
              const daysRemaining = Math.ceil(
                timeDifference / (1000 * 60 * 60 * 24)
              );

              return (
                <div className="flex flex-column" key={index}>
                  <div className="flex text-left align-items-center text-gray h-auto justify-content-start  p-2 h-auto bg-cyan-100	 font-bold border-round m-2">
                    <div className="w-100">
                      <div className="d-flex">
                        <span>Alan Adı:</span>
                        <span>{service?.domain_name}</span>
                      </div>
                      <div className="d-flex">
                        <span>Platform:</span>
                        <span>{service?.platform}</span>
                      </div>
                      <div className="d-flex">
                        <span>Yöneten:</span>
                        <span>{service?.owner}</span>
                      </div>

                      <div className="d-flex">
                        <span>Kayıt:</span>
                        <span>{formatDate(service?.starting_date)}</span>
                      </div>
                      <div className="d-flex">
                        <span>Bitiş:</span>
                        <span>{formatDate(service?.renewal_date)}</span>
                      </div>
                      <div className="d-flex">
                        <span>Kalan Süre:</span>
                        <span>{daysRemaining} gün</span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </Card>
      ) : (
        null
      )}
    </div>
  );
}
