import { Card, CardHeader, Flex, Heading } from "@chakra-ui/react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";

export default function GuaranteeDatatable({
  setAddDialog,
  customer,
  guarantee,
  setEditDialog,
  setForm,
  setSelectedId,
  setDeleteDialog,
  setSerialNumber,
  setTimeRemaining,
  setPassiveDialog,
  setTimeAddDialog,
}) {
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    return new Date(dateString).toLocaleString("tr-TR", options);
  };

  const SearchCompanyName = (rowData) => {
    if (rowData.company_name) {
      const company = customer?.find((cus) => {
        return cus.id.toString() === rowData.company_name;
      });
      return company?.title;
    } else {
      return "Bilinmeyen Şirket";
    }
  };
  const FirstRegistrationDateTr = (rowData) => {
    return formatDate(rowData.first_registration_date);
  };
  const EndDateTr = (rowData) => {
    return formatDate(rowData.end_date);
  };
  const calculateRemainingDays = (rowData) => {
    const ONE_DAY = 24 * 60 * 60 * 1000;
    const renewalDateObject = new Date(rowData?.end_date);
    const startingDateObject = new Date();

    const timeDiff = renewalDateObject - startingDateObject;
    const daysDiff = Math.ceil(timeDiff / ONE_DAY);

    let backgroundColor;
    let textColor;
    if (daysDiff >= 30) {
      backgroundColor = "#4CAF50";
      textColor = "black";
    } else if (daysDiff >= 7) {
      backgroundColor = "#FFC107";
      textColor = "black";
    } else {
      backgroundColor = "#F44336";
      textColor = "black";
    }

    const style = {
      backgroundColor,
      borderRadius: "5px",
      color: textColor,
      padding: "5px",
      display: "inline-block",
    };

    return <span style={style}>{daysDiff} gün</span>;
  };
  const EditBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div style={{ display: "flex" }}>
          <div style={{ color: "#f39f5a" }}>
            <Button
              severity="warning"
              onClick={() => {
                setEditDialog((prevDialogs) => ({
                  ...prevDialogs,
                  [rowData.id]: true,
                }));

                setForm({
                  companyName: rowData.company_name,
                  region: rowData?.region,
                  productDescription: rowData?.product_description,
                  netPassageSize: rowData?.net_passage_size,
                  option: rowData?.option,
                  firstRegistrationDate: rowData?.first_registration_date
                    ? new Date(rowData.first_registration_date)
                    : null,
                  endDate: rowData?.end_date
                    ? new Date(rowData.end_date)
                    : null,
                });
                setSelectedId(rowData?.id);
              }}
              icon="pi pi-pencil"
            />
          </div>
          <div style={{ color: "#ae445a" }}>
            <Button
              icon="pi pi-trash"
              onClick={() => {
                setDeleteDialog((prevDialogs) => ({
                  ...prevDialogs,
                  [rowData.id]: true,
                }));
                setForm({
                  companyName: rowData.company_name
                    ? SearchCompanyName(rowData)
                    : "Bilinmeyen Şirket",
                  region: rowData?.region,
                  productDescription: rowData?.product_description,
                  netPassageSize: rowData?.net_passage_size,
                  option: rowData?.option,
                  firstRegistrationDate: formatDate(
                    rowData?.first_registration_date
                  ),
                  endDate: formatDate(rowData?.end_date),
                });
                setSelectedId(rowData?.id);
                setSerialNumber(rowData?.serial_number);
                setTimeRemaining(
                  rowData ? calculateRemainingDays(rowData) : "Bilinmeyen Süre"
                );
              }}
              severity="danger"
            />
          </div>
          <div style={{ color: "#00d9ff" }}>
            <Button
              icon="pi pi-clone"
              onClick={() => {
                setPassiveDialog((prevDialogs) => ({
                  ...prevDialogs,
                  [rowData.id]: true,
                }));
                setForm({
                  companyName: rowData.company_name
                    ? SearchCompanyName(rowData)
                    : "Bilinmeyen Şirket",
                  region: rowData?.region,
                  productDescription: rowData?.product_description,
                  netPassageSize: rowData?.net_passage_size,
                  option: rowData?.option,
                  firstRegistrationDate: formatDate(
                    rowData?.first_registration_date
                  ),
                  endDate: formatDate(rowData?.end_date),
                });
                setSelectedId(rowData?.id);
                setSerialNumber(rowData?.serial_number);
                setTimeRemaining(
                  rowData ? calculateRemainingDays(rowData) : "Bilinmeyen Süre"
                );
              }}
              severity="info"
            />
          </div>
        </div>
      </React.Fragment>
    );
  };

  const AddTimeTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div style={{ color: "green" }}>
          <Button
            icon="pi pi-calendar-plus"
            onClick={() => {
              setSelectedId(rowData?.id);
              setTimeAddDialog((prevDialogs) => ({
                ...prevDialogs,
                [rowData.id]: true,
              }));
            }}
            severity="success"
          />
        </div>
      </React.Fragment>
    );
  };
  const columns = [
    { field: "code", header: "İşlemler", body: EditBodyTemplate },
    { field: "serial_number", header: "Seri Numarası" },
    { field: "company_name", header: "Firma Adı", body: SearchCompanyName },
    { field: "region", header: "Bölge" },
    { field: "product_description", header: "Ürün Açıklama" },
    { field: "net_passage_size", header: "Net Geçiş Ölçüsü" },
    { field: "option", header: "Opsiyon" },
    {
      field: "first_registration_date",
      header: "İlk Kayıt Tarihi",
      body: FirstRegistrationDateTr,
    },
    { field: "end_date", header: "Bitiş Tarihi", body: EndDateTr },
    { field: "", header: "Kalan Süre", body: calculateRemainingDays },
    { field: "", header: "Ek Süre", body: AddTimeTemplate },
  ];

  return (
    <Card>
      <CardHeader>
        <Flex justifyContent="space-between" alignItems="center">
          <Heading size="md">Garanti</Heading>
          <div>
            {/* <span
              className="p-input-icon-right"
              style={{
                border: "solid 1px gray",
                borderRadius: "6px 6px 6px 6px",
                padding: "0.2rem",
              }}
            >
              <i className="pi pi-search" />
                    <InputText
            placeholder="Garanti Ara"
            
            />

            </span> */}
            <Button
              label="Yeni"
              style={{ marginLeft: "1rem" }}
              onClick={() => setAddDialog(true)}
              className="btn-excel-two"
            />
          </div>
        </Flex>
      </CardHeader>
      <DataTable
        stripedRows
        dataKey="id"
        size="small"
        value={
          guarantee &&
          Array.isArray(guarantee) &&
          guarantee.filter((guarante) => guarante?.visibility === "0")
        }
        tableStyle={{ minWidth: "50rem" }}
        rows={15}
        rowsPerPageOptions={[15, 30, 60, 100]}
        scrollHeight="65vh"
        scrollable
        paginator
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate=" {totalRecords} garantiden {first} - {last} arası gösteriliyor"
      >
        {columns.map((col, i) => (
          <Column
            key={col?.field}
            header={col?.header}
            field={col?.field}
            body={col?.body}
          />
        ))}
      </DataTable>
    </Card>
  );
}
