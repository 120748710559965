import React, { useState } from "react";
import { AddBestKeywords } from "../../../../../../../../core/Services";
import { Button, Input } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { Dropdown } from "primereact/dropdown";
import { Message } from "primereact/message";


export default function BestKeywordsGraphPage({
  uniqueCustomerId,
  selectedMonth,
  selectedCustomer,
  updateTableData,
  browser
}) {
  const [keywords, setKeywords] = useState("");
  const [clicks, setClicks] = useState("");
  const [screening, setScreening] = useState("");
  const [platform, setPlatform] = useState("");
  const [validationError, setValidationError] = useState(false);
  const toast = useToast();

  const validateInputs = () => {
    if (
      !selectedCustomer ||
      !keywords ||
      !clicks ||
      !platform ||
      !screening ||
      !selectedMonth
    ) {
      setValidationError(true);
      return false;
    }
    setValidationError(false);
    return true;
  };

  const AddKeywords = async () => {
    if (!validateInputs()) return;

    try {
      const response = await AddBestKeywords(
        uniqueCustomerId,
        keywords,
        clicks,
        screening,
        selectedMonth,
        platform
      );

      if (response.message === "Hata") {
        toast({
          title: "Hata",
          description: "Kullanıcya Ait Bu Ay Verisi Zaten Mevcut",
          status: "error",
          duration: 3000,
          position: "top-right",
          isClosable: true,
        });
      } else {
        setKeywords("");
        setClicks("");
        setScreening("")
        toast({
          title: "Başarılı",
          description: "Anahtar kelime başarıyla eklendi.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        updateTableData();
      }
    } catch (error) {
      console.error("Hata oluştu:", error);
    }
  };

  return (
    <div>
      <div>
        <div className="font-bold text-xl mt-4">Platform Seçiniz : </div>
        <div className="mt-2">
          <Dropdown
            value={platform}
            onChange={(e) => setPlatform(e.value)}
            options={browser}
            placeholder="Platform Seçiniz"
            style={{
              border:
                validationError && !platform
                  ? "1px solid red"
                  : "1px solid gray",
              width: "30rem",
            }}
          />
        </div>
      </div>
      <div>
        <div className="font-bold text-xl mt-4">
          Görüntüleme Sayısını Giriniz :
        </div>
        <div className="mt-2">
          <Input
            filter
            onChange={(e) => {
              setScreening(e.target.value);
            }}
            value={screening}
            placeholder=" Gösterimler Sayısı"
            style={{
              border:
                validationError && !screening
                  ? "1px solid red"
                  : "1px solid gray",
              width: "30rem",
            }}
          />
        </div>
      </div>
      <div>
        <div className="font-bold text-xl mt-4">Anahtar Kelime Giriniz : </div>
        <div className="mt-2">
          <Input
            filter
            onChange={(e) => {
              setKeywords(e.target.value);
            }}
            value={keywords}
            placeholder="Anahtar Kelime"
            style={{
              border:
                validationError && !keywords
                  ? "1px solid red"
                  : "1px solid gray",
              width: "30rem",
            }}
          />
        </div>
      </div>
      <div>
        <div className="font-bold text-xl mt-4">
          Kullanıcı Sayısını Giriniz :
        </div>
        <div className="mt-2">
          <Input
            filter
            onChange={(e) => {
              setClicks(e.target.value);
            }}
            value={clicks}
            placeholder=" Tıklamalar Sayısı"
            style={{
              border:
                validationError && !clicks ? "1px solid red" : "1px solid gray",
              width: "30rem",
            }}
          />
        </div>
      </div>

      {validationError && (
        <div className="text-red-500 mt-2 text-sm">
          Lütfen boş alanları doldurunuz.
        </div>
      )}
      <div className="mt-5 flex justify-content-end">
        <Button width="8rem" onClick={AddKeywords}>
          Kaydet
        </Button>
      </div>
      <Message
        className="mt-4 w-full"
        severity="info"
        text="Google Analytics > Raporlar > Search Console > Sorgular"
      />
    </div>
  );
}
