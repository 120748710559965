import React, { useEffect, useState } from "react";
import { addLocale } from "primereact/api";
import GuaranteeDatatable from "./GuaranteeDatatable";
import AddGuaranteeDialog from "./AddGuaranteeDialog";
import UpdateGuaranteeDialog from "./UpdateGuaranteeDialog";
import DeleteGuaranteeDialog from "./DeleteGuaranteeDialog";
import PassiveGuaranteeDialog from "./PassiveGuaranteeDialog";

import { useToast } from "@chakra-ui/react";
import AddTimeGuaranteeDialog from "./AddTimeGuaranteeDialog";
import { AllGuarantee } from "../../../../../core/Services";
export default function Guarantee() {
  const toast = useToast();
  const [guarantee, setGuarantee] = useState([]);
  const [customer , setCustomer] = useState([]);
  const [addDialog, setAddDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [passiveDialog, setPassiveDialog] = useState(false);
  const [timeAddDialog, setTimeAddDialog] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [time, setTime] = useState("");
  const [timeRemaining, setTimeRemaining] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const onChangeInput = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const [form, setForm] = useState({
    firstRegistrationDate: "",
    endDate:"",
    companyName: "",
    region: "",
    productDescription: "",
    netPassageSize: "",
    option: "",
  });
  addLocale("tr", {
    firstDayOfWeek: 1,
    showMonthAfterYear: true,
    dayNames: [
      "pazar",
      "pazartesi",
      "salı",
      "çarşamba",
      "perşembe",
      "cuma",
      "cumartesi",
    ],
    dayNamesShort: ["paz", "pzt", "sal", "çar", "per", "cum", "cmt"],
    dayNamesMin: ["P", "Pzt", "S", "Ç", "P", "C", "Cmt"],
    monthNames: [
      "ocak",
      "şubat",
      "mart",
      "nisan",
      "mayıs",
      "haziran",
      "temmuz",
      "ağustos",
      "eylül",
      "ekim",
      "kasım",
      "aralık",
    ],
    monthNamesShort: [
      "oca",
      "şub",
      "mar",
      "nis",
      "may",
      "haz",
      "tem",
      "ağu",
      "eyl",
      "eki",
      "kas",
      "ara",
    ],
    today: "Bugün",
    clear: "Temizle",
  });
  useEffect(()=> {
    AllGuarantee()
    .then((data)=> {
      setGuarantee(data?.guarantee);
      setCustomer(data?.customer)
    })
    .catch((error)=> {
      console.log("Garanti Çekme Hatası", error);
    })
  },[])
  return (
    <div>
      <GuaranteeDatatable
        customer={customer}
        guarantee={guarantee}
        setGuarantee={setGuarantee}
        setSerialNumber={setSerialNumber}
        setAddDialog={setAddDialog}
        setEditDialog={setEditDialog}
        setForm={setForm}
        setSelectedId={setSelectedId}
        setTimeRemaining={setTimeRemaining}
        setDeleteDialog={setDeleteDialog}
        setPassiveDialog={setPassiveDialog}
        setTimeAddDialog={setTimeAddDialog}
      />
      <AddGuaranteeDialog
        toast={toast}
        customer={customer}
        onChangeInput={onChangeInput}
        form={form}
        setForm={setForm}
        addDialog={addDialog}
        setAddDialog={setAddDialog}
      />
      <UpdateGuaranteeDialog
        toast={toast}
        selectedId={selectedId}
        customer={customer}
        onChangeInput={onChangeInput}
        form={form}
        setForm={setForm}
        editDialog={editDialog}
        setEditDialog={setEditDialog}
        setSelectedId={setSelectedId}
      />
      <DeleteGuaranteeDialog
        toast={toast}
        selectedId={selectedId}
        serialNumber={serialNumber}
        setSelectedId={setSelectedId}
        timeRemaining={timeRemaining}
        setTimeRemaining={setTimeRemaining}
        form={form}
        setSerialNumber={setSerialNumber}
        setForm={setForm}
        deleteDialog={deleteDialog}
        setDeleteDialog={setDeleteDialog}
      />
      <PassiveGuaranteeDialog
        toast={toast}
        selectedId={selectedId}
        serialNumber={serialNumber}
        setSelectedId={setSelectedId}
        timeRemaining={timeRemaining}
        setTimeRemaining={setTimeRemaining}
        form={form}
        setSerialNumber={setSerialNumber}
        setForm={setForm}
        passiveDialog={passiveDialog}
        setPassiveDialog={setPassiveDialog}
      />
      <AddTimeGuaranteeDialog
        toast={toast}
        time={time}
        setTime={setTime}
        selectedId={selectedId}
        setSelectedId={setSelectedId}
        timeAddDialog={timeAddDialog}
        setTimeAddDialog={setTimeAddDialog}
      />
    </div>
  );
}
