import React, { useEffect, useState } from "react";
import AgeChart from "./components/AgeChart";
import BestKeywordsList from "./components/BestKeywordsList";
import ViewVisitorChart from "./components/ViewVisitorChart";
import DevicesChart from "./components/DevicesChart";
import TrafficChart from "./components/TrafficChart";
import { useAuth } from "../../../../../../auth/core/AuthContext";
import { GetWebsiteAll } from "../../../../../../core/Services";
import LanguagesTable from "./components/LanguagesTable";
import PagesTable from "./components/PagesTable";
import CityList from "./components/CityList";
import CountryList from "./components/CountryList";

export default function Website({selectedMonth}) {
  const [viewVisitor, setViewVisitor] = useState([]);
  const [trafficSource, setTrafficSource] = useState([]);
  const [devices, setDevices] = useState([]);
  const [ageGraph, setAgeGraph] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [countries, setCountries] = useState([]);
  const { userData } = useAuth();
  useEffect(() => {
    GetWebsiteAll(userData?.unique_id, selectedMonth)
      .then((data) => {
        setViewVisitor(data?.view_visitor)
        setTrafficSource(data?.traffic_source)
        setDevices(data?.devices_graph)
        setAgeGraph(data?.age_graph)
        setKeywords(data?.best_keywords)
        setCountries(data?.country_statistics)
      })
      .catch((error) => {
        console.log("Ülke Veri Çekme Hatası", error);
      });
  
  }, [selectedMonth, userData?.unique_id]);


  return (
    <div className="content">
      <div className="card row" style={{ display: "flex" }}>
        <div className="col-6">
          <strong>Görüntüleme Ziyaretçi Trafiği</strong>
          <ViewVisitorChart viewVisitor={viewVisitor} />
        </div>
        <div className="col-6">
          <strong>Cihazlar</strong>
          <DevicesChart devices={devices} />
        </div>
      </div>
      <div className="card row" style={{ display: "flex" }}>
        <div className="col-6">
          <strong>Trafik Kaynağı</strong>
          <TrafficChart trafficSource={trafficSource} />
        </div>
        <div className="col-6">
          <strong>Yaş</strong>
          <AgeChart ageGraph={ageGraph} />
        </div>
      </div>
      <div className="card row" style={{ display: "flex" }}>
        <div className="col-6">
          <strong>Anahtar Kelime</strong>
          <BestKeywordsList keywords={keywords} />
        </div>
        <div className="col-6">
          <strong>Diller</strong>
          <LanguagesTable countries={countries} />
        </div>
      </div>
      <div className="card row" style={{ display: "flex" }}>
        <div className="col-6">
          <strong>Sayfalar</strong>
          <PagesTable />
        </div>
        <div className="col-6">
          <strong>Şehirler</strong>
          <CityList />
        </div>
      </div>
      <div className="card row" style={{ display: "flex" }}>
        <div className="col-6">
          <strong>Ülkeler</strong>
          <CountryList />
        </div>
        <div className="col-6">
          
        </div>
      </div>
    </div>
  );
}
