import React, { useEffect, useState } from "react";
import { GetMail } from "../../../../../../core/Services";
import { useAuth } from "../../../../../../auth/core/AuthContext";
import MailSetup from "./MailSetup";
import { Paginator } from "primereact/paginator";
import { Button } from "@chakra-ui/react";
import { IoEyeOffSharp, IoEye } from "react-icons/io5";

export default function MailAccounts({domainName}) {
  const [mail, setMail] = useState([]);
  const { userData } = useAuth();
    const [first, setFirst] = useState(0);
    const [showPassword, setShowPassword] = useState(false);
    const [rows, setRows] = useState(10);
  
  useEffect(() => {
    GetMail(userData?.unique_id)
      .then((data) => {
        setMail(data.data);
      })
      .catch((error) => {
        console.log("Ülke Veri Çekme Hatası", error);
      });
      
  }, [userData?.unique_id]);


const goToLink = () => {
  if (domainName?.length > 0) {
    const processedLink = domainName;
    window.open(`https://webmail.${processedLink}`, "_blank");
  } else {
    console.log("Domain bilgisi bulunamadı.");
  }
};

 
  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };
  return (
    <div>
      <div className="flex justify-content-end mb-2	">
       <Button
  bgColor="#F39F5A"
  color="white"
  width="8rem"
  onClick={() => goToLink()}
>
  Oturum Aç
</Button>
      </div>
      <div>
        <div>
          <table
            className="w-full"
            style={{
              fontFamily: "arial, sans-serif",
              borderCollapse: "collapse",
              maxHeight: "80%",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                ></th>
                <th
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  Ad-Soyad
                </th>
                <th
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  e-posta
                </th>
                <th
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  Boyut
                </th>
                <th
                  style={{
                    border: "1px solid #dddddd",
                    padding: "10px",
                    overflow: "hidden",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Parola
                  <button onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <IoEyeOffSharp size="20px" /> : <IoEye />}
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              {mail?.slice(first, first + rows)?.map((mail, index) => (
                <tr
                  key={mail.id}
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  <td
                    style={{
                      border: "1px solid #dddddd",
                      textAlign: "left",
                      padding: "8px",
                    }}
                  >
                    {index + 1 + first}
                  </td>
                  <td
                    style={{
                      border: "1px solid #dddddd",
                      textAlign: "left",
                      padding: "8px",
                    }}
                  >
                    {mail.name}
                  </td>
                  <td
                    style={{
                      border: "1px solid #dddddd",
                      textAlign: "left",
                      padding: "8px",
                    }}
                  >
                    {mail.mail}
                  </td>
                  <td
                    style={{
                      border: "1px solid #dddddd",
                      textAlign: "left",
                      padding: "8px",
                    }}
                  >
                    {mail.size}
                  </td>
                  <td
                    style={{
                      border: "1px solid #dddddd",
                      textAlign: "left",
                      padding: "8px",
                    }}
                  >
                    {showPassword ? mail.password : "********"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Paginator
            first={first}
            rows={rows}
            totalRecords={mail?.length}
            onPageChange={onPageChange}
          />
        </div>

      </div>
      <div style={{ bottom: "0", position: "fixed" }}>
        <MailSetup domainName={domainName} />
      </div>
    </div>
  );
}
