import { createSlice } from "@reduxjs/toolkit";

export const JobDetailSlice = createSlice({
  name: "JobDetailSlice",
  initialState: {
    value: null,
  },
  reducers: {
    addJobDetailData: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { addJobDetailData } = JobDetailSlice.actions;
export default JobDetailSlice.reducer;
