import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import { CardHeader, Flex, Heading } from "@chakra-ui/react";
import {
  FinishTask,
  AssignTaskService,
  DeleteTaskService,
  EditTaskService,
} from "../../../../core/Services";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Card } from "primereact/card";
import { useSelector } from "react-redux";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { Link } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { useAuth } from "../../../../auth/core/AuthContext";

export default function InProgress() {
  const [tasks, setTasks] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [users, setUsers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [visible, setVisible] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [assignedEmp, setAssignedEmp] = useState(false);
  const [newName, setNewName] = useState("");
  const [newStatus, setNewStatus] = useState("");
  const [newDesc, setNewDesc] = useState("");
  const [newDep, setNewDep] = useState("");
  const [search, setSearch] = useState("");
  const dt = useRef(null);
  const { userData } = useAuth();
  const [selectedData, setSelectedData] = useState("");
  const taskData = useSelector((state) => state.TaskReduce.value);
  const userDatas = useSelector((state) => state.UserReduce.value);
  const departmentData = useSelector((state) => state.DepartmentReduce.value);
  const customerData = useSelector((state) => state.CustomerReduce.value);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setTasks(taskData);
        setDepartments(departmentData);
        setCustomers(customerData);
        setUsers(userDatas);
      } catch (error) {
        console.error("API Hatası:", error);
      }
    };

    fetchData();
  }, [visible, taskData, userDatas, departmentData, customerData]);
  const assignTask = async (user_id, task_id) => {
    await AssignTaskService(user_id, task_id, userData?.id);
  };
  const handleAssignTask = async (id) => {
    try {
      if (!selectedTask) {
        console.error("Tamamlanacak görev bulunamadı.");
        return;
      }

      await assignTask(assignedEmp, id);

      setVisible(false); // Dialog'u kapat
      // window.location.reload();
    } catch (error) {
      console.error("Hata:", error.message);
    }
  };
  const saveChanges = async (id) => {
    await EditTaskService(id, newName, newDesc, newDep, newStatus);
  };

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false,
    };
    return new Date(dateString).toLocaleString("tr-TR", options);
  };
  const handleCompleteTask = async () => {
    try {
      if (!selectedTask) {
        console.error("Tamamlanacak görev bulunamadı.");
        return;
      }

      const designatedPerson = selectedTask.designated_person;

      await FinishTask(selectedTask.id, designatedPerson);

      setVisible(false); // Dialog'u kapat
      // window.location.reload();
    } catch (error) {
      console.error("Hata:", error.message);
    }
  };

  const handleCloseDialog = () => {
    // Kapat butonuna tıklandığında yapılacak işlemler buraya eklenir.
    setVisible(false);
  };

  const statusType = [
    { label: "Normal", value: "1" },
    { label: "Yüksek", value: "0" },
    { label: "Kritik", value: "2" },
  ];
  const workingBodyTemplate = (rowData) => {
    const getStatusLabel = (working_condition) => {
      switch (working_condition) {
        case "0":
          return "Yüksek";

        case "1":
          return "Normal";

        case "2":
          return "Kritik";

        default:
          return null;
      }
    };

    const statusLabel = getStatusLabel(rowData.working_condition);

    return <Tag value={statusLabel} severity={getSeverity(rowData)}></Tag>;
  };

  const getSeverity = (tasks) => {
    switch (tasks.working_condition) {
      case "0":
        return "warning";

      case "1":
        return "success";

      case "2":
        return "danger";

      default:
        return null;
    }
  };
  const searchEmpInTask = async (value) => {
    if (Array.isArray(taskData) && taskData.length > 0) {
      const matchingTasks = taskData.filter((task) => {
        // Task içindeki designated_person özelliğini kontrol et
        if (typeof task.designated_person === "string") {
          // Belirtilen kişileri diziye ayır
          const designatedPersons = task.designated_person
            .split(",")
            .map((person) => person.trim());
          // Aranan kişinin (value) bu göreve atanmış olup olmadığını kontrol et
          return designatedPersons.includes(value.toString());
        }
        return false; // designated_person özelliği tanımlı değilse veya bir string değilse, bu görevi eşleşen görevler listesine ekleme
      });

      // Eşleşen görevleri ana bileşene (örneğin, setTasks) atama
      setTasks(matchingTasks);
    }
  };

  const accessors = (rowData) => {
    const edit = editModal[rowData.id] || false;
    const deletes = deleteModal[rowData.id] || false;
    const assign = assignModal[rowData.id] || false;
    const setEmployees = visible[rowData.id] || false;
    return (
      <div style={{ display: "flex" }}>
        <div style={{ color: "#f39f5a" }}>
          {userData && (userData.type === "1" || userData.is_auth === "1") && (
            <Button
              severity="warning"
              onClick={() => {
                setEditModal((prevDialogs) => ({
                  ...prevDialogs,
                  [rowData.id]: true,
                }));
                setSelectedData(rowData);
                setNewName(Number(rowData.name));
                setNewDep(Number(rowData.department));
                setNewStatus(rowData.status);
              }}
              icon="pi pi-pencil"
            />
          )}
        </div>
        <div style={{ color: "#ae445a" }}>
          {userData?.type === "1" && (
            <Button
              icon="pi pi-trash"
              onClick={() => {
                setDeleteModal((prevDialogs) => ({
                  ...prevDialogs,
                  [rowData.id]: true,
                }));
                setNewDesc(rowData?.desc);

                if (rowData?.name) {
                  const cust = customers.find(
                    (dept) => dept.id.toString() === rowData.name
                  );
                  if (cust) {
                    const firstTwoWords =
                      cust.title && cust.title.split(" ").slice(0, 2).join(" ");
                    setNewName(firstTwoWords);
                  } else {
                    setNewName("Bilinmeyen");
                  }
                }

                if (rowData?.department) {
                  const department = departments.find(
                    (dept) => dept.id.toString() === rowData.department
                  );
                  if (department) {
                    setNewDep(department.department);
                  }
                }
                setSelectedData(rowData);
              }}
              severity="danger"
            />
          )}
        </div>
        <div style={{ color: "green" }}>
          {userData?.type === "1" && (
            <Button
              icon="pi pi-user-plus"
              severity="success"
              onClick={() => {
                setSelectedTask(tasks);
                setAssignModal((prevDialogs) => ({
                  ...prevDialogs,
                  [rowData.id]: true,
                }));
                setSelectedData(rowData);
              }}
            />
          )}
        </div>

        <div style={{ color: "#00d9ff" }}>
          <Button
            icon="pi pi-check"
            onClick={() => {
              setSelectedTask(rowData);
              setVisible((prevDialogs) => ({
                ...prevDialogs,
                [rowData.id]: true,
              }));
              setSelectedData(rowData);
            }}
            severity="info"
          />
        </div>
        <Dialog
          header="Görevi Tamamla"
          visible={setEmployees}
          style={{ width: "50vw" }}
          onHide={() => setVisible(false)}
          footer={
            <div>
              <Button
                label="Kapat"
                icon="pi pi-times"
                onClick={handleCloseDialog}
                className="btn-excel-two"
              />
              <Button
                label="Görevi Tamamla"
                icon="pi pi-check"
                onClick={handleCompleteTask}
                className="btn-excel-two"
                style={{ marginLeft: "15px" }}
              />
            </div>
          }
        ></Dialog>
        <Dialog
          header="Görev Ata"
          visible={assign}
          style={{ width: "50vw" }}
          onHide={() => setAssignModal(false)}
          footer={
            <div>
              <Button
                label="Kapat"
                icon="pi pi-times"
                onClick={() => setAssignModal(false)}
                className="btn-excel-two"
              />
              <Button
                label="Görevi Ata"
                icon="pi pi-check"
                onClick={() => handleAssignTask(selectedData.id)}
                className="btn-excel-two"
                style={{ marginLeft: "15px" }}
              />
            </div>
          }
        >
          <div className="field">
            <MultiSelect
              filter
              value={assignedEmp}
              options={
                Array.isArray(users) && users.length > 0
                  ? users.map((employ) => ({
                      label: employ.name,
                      value: employ.id,
                    }))
                  : []
              }
              placeholder="Görevliler"
              className="w-full "
              style={{
                border: "1px solid gray",
                width: "100%",
                marginBottom: "1rem",
              }}
              onChange={(e) => setAssignedEmp(e.target.value)}
            />
          </div>
        </Dialog>
        <Dialog
          header="Görevi silmek istediğinize emin misiniz?"
          visible={deletes}
          style={{ width: "50vw" }}
          onHide={() => {
            setDeleteModal(false);
            setNewDesc("");
            setNewDep("");
            setNewName("");
          }}
          footer={
            <div>
              <Button
                label="İptal"
                icon="pi pi-times"
                onClick={() => setDeleteModal(false)}
                className="btn-excel-two"
              />
              <Button
                label="Görevi Sil"
                icon="pi pi-check"
                onClick={() => {
                  DeleteTaskService(selectedData.id);
                  setDeleteModal(false);
                  setNewDep("");
                  setNewName("");
                  setNewDesc("")
                }}
                className="btn-excel-two"
                style={{ marginLeft: "15px" }}
              />
            </div>
          }
        >
          <div className="confirmation-content">
            <div className="field">
              <label htmlFor="name" className="font-bold">
                Müşteri Adı: {newName}
              </label>
            </div>
            <div className="field">
              <label htmlFor="description" className="font-bold">
                Açıklama : {newDesc}
              </label>
            </div>
            <div className="field">
              <label className="mb-3 font-bold">Departman: {newDep}</label>
            </div>
          </div>
        </Dialog>
        <Dialog
          visible={edit}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          style={{ width: "60vw" }}
          header="Görevi Düzenle"
          onHide={() => setEditModal(false)}
          modal
        >
          <div className="confirmation-content">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {/* Sol Sütun */}
              <div className="col-6">
                <div style={{ display: "flex" }}>
                  <Dropdown
                    filter
                    options={
                      customers && customers.length > 0
                        ? customers.map((dep) => ({
                            label: dep.title,
                            value: dep.id,
                          }))
                        : []
                    }
                    value={newName}
                    placeholder="Müşteri Adı / Ünvanı"
                    onChange={(e) => setNewName(e.target.value)}
                    style={{
                      border: "1px solid gray",
                      width: "80%",
                    }}
                  />
                  <div style={{ width: "0.4rem" }} />
                  <Button className="btn-plus">
                    <Link to="/customer-list">
                      <i
                        className="pi  pi-plus"
                        style={{ fontSize: "1rem" }}
                      ></i>
                    </Link>
                  </Button>
                </div>

                <Dropdown
                  filter
                  options={
                    Array.isArray(departments) && departments.length > 0
                      ? departments.map((dep) => ({
                          label: dep.department,
                          value: dep.id,
                        }))
                      : []
                  }
                  value={newDep}
                  placeholder="Departman"
                  onChange={(e) => setNewDep(e.target.value)}
                  style={{
                    border: "1px solid gray",
                    padding: "0rem",
                    width: "100%",
                    marginBottom: "1rem",
                    marginTop: "1rem",
                  }}
                />
              </div>

              {/* Sağ Sütun */}
              <div className="col-6">
                <Dropdown
                  filter
                  options={statusType}
                  placeholder="Durum"
                  value={newStatus}
                  onChange={(e) => setNewStatus(e.value)}
                  style={{
                    border: "1px solid gray",
                    width: "100%",
                    marginBottom: "1rem",
                  }}
                />
                <InputText
                  type="text"
                  className="p-inputtext-lg"
                  placeholder="Açıklama"
                  onChange={(e) => setNewDesc(e.target.value)}
                  defaultValue={selectedData.desc}
                  style={{
                    width: "100%",
                    marginBottom: "1rem",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    label="Kaydet"
                    onClick={() => saveChanges(selectedData.id)}
                    className="btn-excel-two"
                  />
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    );
  };

  return (
    <div>
      <Flex justifyContent="center" alignItems="center">
        <div
          className="card"
          style={{
            overflow: "auto",
            boxSizing: "border-box",
            width: "100%",
            height: "100%%",
            position: "relative",
          }}
        >
          <Card>
            <CardHeader>
              <Flex justifyContent="space-between" alignItems="center">
                <Heading size="md" style={{ color: "black" }}>
                  İşlemde Olan Görevler
                </Heading>
                <div style={{ display: "flex" }}>
                  <Dropdown
                    filter
                    value={search}
                    options={
                      Array.isArray(users) && users.length > 0
                        ? users.map((employ) => ({
                            label: employ.name,
                            value: employ.id,
                          }))
                        : []
                    }
                    placeholder="Çalışana Göre Filtrele"
                    className="w-full "
                    style={{
                      border: "1px solid gray",
                      width: "100%",
                      marginBottom: "1rem",
                    }}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      searchEmpInTask(e.target.value);
                    }}
                  />
                </div>
              </Flex>
            </CardHeader>
            <DataTable
              stripedRows
              size="small"
              ref={dt}
              value={tasks && tasks.filter((tasks) => tasks.tasks_id === "1")}
              dataKey="id"
              paginator
              tableStyle={{ maxWidth: "85vw" }}
              rows={15}
              rowsPerPageOptions={[15, 30, 60, 100]}
              scrollHeight="60vh"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{totalRecords} görevden {first} - {last} arası gösteriliyor"
            >
              <Column
                style={{ width: "8rem" }}
                body={accessors}
                exportable={false}
                header="İşlemler"
              ></Column>
              <Column
                field="title"
                header="Müşteri"
                body={(rowData) => {
                  const cust = customers.find(
                    (dept) => dept.id.toString() === rowData.name
                  );
                  if (cust) {
                    // Başlığı boşluklara göre bölerek ilk iki kelimeyi al
                    const firstTwoWords =
                      cust.title && cust.title.split(" ").slice(0, 2).join(" ");
                    return firstTwoWords;
                  } else {
                    return "Bilinmeyen";
                  }
                }}
              ></Column>

              <Column
                field="desc"
                header="Açıklama"
                style={{ maxWidth: "20rem" }}
              ></Column>
              <Column
                field="working_concition"
                header="Öncelik"
                body={workingBodyTemplate}
                style={{ minWidth: "4rem" }}
                filterMatchMode="equals"
                filterPlaceholder="Filter by Status"
              ></Column>
              <Column
                field="department"
                header="Departman"
                sortable
                body={(rowData) => {
                  const department = departments.find(
                    (dept) => dept.id.toString() === rowData.department
                  );
                  return department
                    ? department.department
                    : rowData.department;
                }}
                style={{ minWidth: "6rem" }}
              ></Column>
              <Column
                field="designated_person"
                sortable
                header="Atanan Kişi"
                style={{ maxWidth: "20rem" }}
                body={(rowData) => {
                  const designatedPersons =
                    rowData?.designated_person.split(","); // Virgüllerle ayrılmış kimlikleri diziye ayır

                  const assignedUsers = designatedPersons.map(
                    (designatedPerson) => {
                      const user = users.find(
                        (usr) => usr.id.toString() === designatedPerson.trim()
                      );

                      return user ? user.name : "Bilinmeyen"; // Kullanıcı adını bul veya "Unknown User" döndür
                    }
                  );

                  return assignedUsers.join(", "); // Virgülle ayrılmış kullanıcı adlarını birleştir ve sütuna yazdır
                }}
              ></Column>
              <Column
                field="created_at"
                header="Oluşturulma Tarihi"
                sortable
                body={(rowData) => formatDate(rowData.created_at)}
                style={{ minWidth: "6rem" }}
              ></Column>
            </DataTable>
          </Card>
        </div>
      </Flex>
    </div>
  );
}
