import React, { useState } from 'react'
import CountryVisitGraphPage from "./components/CountryVisitGraphPage";
import { Splitter, SplitterPanel } from "primereact/splitter";
import CountryVisitTable from './components/CountryVisitTable';

export default function CountryVisitGraph(
  {uniqueCustomerId,
  selectedMonth,
  selectedCustomer,
  months}
) {
  
  
  const countries = require("./components/countries.json");

    const [tableKey, setTableKey] = useState(0);
    

    const updateTableData = () => {
      setTableKey((prevKey) => prevKey + 1);
    
    };
 

  return (
    <Splitter style={{ height: "auto" }}>
      <SplitterPanel>
        <CountryVisitGraphPage
          updateTableData={updateTableData}
          selectedMonth={selectedMonth}
          selectedCustomer={selectedCustomer}
          uniqueCustomerId={uniqueCustomerId}
          countries={countries}
        />
      </SplitterPanel>
      <SplitterPanel size={75} style={{ display: "block", padding: "1rem" }}>
        <CountryVisitTable
          months={months}
          key={tableKey}
          uniqueCustomerId={uniqueCustomerId}
          countriees={countries}
        />
      </SplitterPanel>
    </Splitter>
  );
}
