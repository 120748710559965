import React from 'react'
import Security from '../customer-reports/security/Security'

export default function CustomerSecurityPage() {
  return (
    <div className='p-2'>
        <Security/>
    </div>
  )
}
