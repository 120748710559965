import React from "react";
import CustomerPanelView from "./customer/CustomerPanelView";
import EmployeesPanelView from "./employees/EmployeesPanelView";
import { useAuth } from "../../../auth/core/AuthContext";

export default function CustomerPanelPage() {
  const { userData } = useAuth();
  return (
    <div>
      {userData?.authorization === "1" && <CustomerPanelView />}
      {userData?.authorization === "0" && <EmployeesPanelView />}
    </div>
  );
}
