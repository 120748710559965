import { createSlice } from "@reduxjs/toolkit";

export const TallySlice = createSlice({
  name: "TallySlice",
  initialState: {
    value: null,
  },
  reducers: {
    addTallyData: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { addTallyData } = TallySlice.actions;
export default TallySlice.reducer;
