import React, { useEffect, useState } from "react";
import {
  GetOtherAccounts
} from "../../../../../../core/Services";
import { Paginator } from "primereact/paginator";
import { IoEyeOffSharp, IoEye } from "react-icons/io5";
import { useAuth } from "../../../../../../auth/core/AuthContext";

export default function OtherAccountsInformation({ uniqueCustomerId }) {
  const [otherAccount, setOtherAccount] = useState([]);
  const{userData} = useAuth();
  const [first, setFirst] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [rows, setRows] = useState(10);
  
  useEffect(() => {
    GetOtherAccounts(userData?.unique_id)
      .then((data) => {
        setOtherAccount(data.data);
      })
      .catch((error) => {
        console.log("Ülke Veri Çekme Hatası", error);
      });
  }, [userData?.unique_id]);


 
  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  return (
    <div>
      <table
        className="w-full"
        style={{
          fontFamily: "arial, sans-serif",
          borderCollapse: "collapse",
          maxHeight: "80%",
        }}
      >
        <thead>
          <tr>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            ></th>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              Platform
            </th>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              Kullanıcı Adı
            </th>
            <th
              style={{
                border: "1px solid #dddddd",
                padding: "10px",
                overflow: "hidden",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Parola
              <button onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <IoEyeOffSharp size="20px" /> : <IoEye />}
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          {otherAccount
            ?.slice(first, first + rows)
            ?.map((otherAccount, index) => (
              <tr
                key={otherAccount.id}
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                <td
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  {index + 1 + first}
                </td>
                <td
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  {otherAccount.platform}
                </td>
                <td
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  {otherAccount.user_name}
                </td>
                <td
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  {showPassword ? otherAccount.password : "********"}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <Paginator
        first={first}
        rows={rows}
        totalRecords={otherAccount?.length}
        onPageChange={onPageChange}
      />
    </div>
  );
}
