import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import { CardHeader, Flex, Heading } from "@chakra-ui/react";
import { Card } from "primereact/card";
import { useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
export default function Completed() {
  const [jobs, setJobs] = useState([]);

  const [employeeList, setEmployeeList] = useState([]);
  const [customers, setCustomers] = useState([]);
  const dt = useRef(null);
  const [departments, setDepartments] = useState([]);
  const [search, setSearch] = useState("");
  const jobData = useSelector((state) => state.JobReduce.value);
  const userData = useSelector((state) => state.UserReduce.value);
  const departmentData = useSelector((state) => state.DepartmentReduce.value);
  const customerData = useSelector((state) => state.CustomerReduce.value);
  useEffect(() => {
    setJobs(jobData);
    setEmployeeList(userData);
    setCustomers(customerData);
    setDepartments(departmentData);
  }, [jobData, userData, customerData, departmentData]);
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false,
    };
    return new Date(dateString).toLocaleString("tr-TR", options);
  };
  const statusBodyTemplate = (rowData) => {
    const getStatusLabel = (status) => {
      switch (status) {
        case "0":
          return "Boşta";
        case "1":
          return "İşlemde";
        case "2":
          return "Tamamlamdı";
        default:
          return null;
      }
    };

    const statusLabel = getStatusLabel(rowData.status);

    return <Tag value={statusLabel} severity={getStatus(rowData)}></Tag>;
  };

  const getStatus = (tasks) => {
    switch (tasks.status) {
      case "0":
        return "danger";
      case "1":
        return "warning";
      case "2":
        return "success";
      default:
        return null;
    }
  };
  const searchEmpInTask = async (value) => {
    if (Array.isArray(jobData) && jobData.length > 0) {
      const matchingTasks = jobData.filter((task) => {
        
        if (typeof task.designated_person === "string") {
          
          const designatedPersons = task.designated_person
            .split(",")
            .map((person) => person.trim());
      
          return designatedPersons.includes(value.toString());
        }
        return false; 
      });

      
      setJobs(matchingTasks);
    }
  };
  return (
    <div>
      <Flex justifyContent="center" alignItems="center">
        <div
          className="card"
          style={{
            overflow: "auto",
            boxSizing: "border-box",
            width: "100%",
            height: "100%%",
            position: "relative",
          }}
        >
          <Card style={{ textAlign: "left" }}>
            <CardHeader>
              <Flex justifyContent="space-between" alignItems="center">
                <Heading size="md" style={{ color: "black" }}>
                  Tamamlanan İşler
                </Heading>
                <div style={{ display: "flex" }}>
                  <Dropdown
                    filter
                    value={search}
                    options={
                      Array.isArray(employeeList) && employeeList.length > 0
                        ? employeeList.map((employ) => ({
                            label: employ.name,
                            value: employ.id,
                          }))
                        : []
                    }
                    placeholder="Çalışana Göre Filtrele"
                    className="w-full "
                    style={{
                      border: "1px solid gray",
                      width: "100%",
                      marginBottom: "1rem",
                    }}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      searchEmpInTask(e.target.value);
                    }}
                  />
                </div>
              </Flex>
            </CardHeader>
            <DataTable
              stripedRows
              ref={dt}
              size="small"
              value={jobs && jobs.filter((jobs) => jobs?.jobs_id === "2")}
              dataKey="id"
              paginator
              tableStyle={{ maxWidth: "85vw" }}
              rows={15}
              rowsPerPageOptions={[15, 30, 60, 100]}
              scrollHeight="60vh"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{totalRecords} işten {first} - {last} arası gösteriliyor"
            >
              <Column field="jobs_no" header="İş Emri No"></Column>
              <Column
                field="title"
                header="Müşteri"
                body={(rowData) => {
                  const cust = customers.find(
                    (dept) => dept.id.toString() === rowData.customer_id
                  );
                  if (cust) {
                    
                    const firstTwoWords =
                      cust.title && cust.title.split(" ").slice(0, 2).join(" ");
                    return firstTwoWords;
                  } else {
                    return "Bilinmeyen";
                  }
                }}
              ></Column>
              <Column
                field="subject"
                style={{ maxWidth: "10rem" }}
                header="Konu"
              ></Column>
              <Column
                field="desc"
                header="Açıklama"
                style={{ maxWidth: "20rem" }}
              ></Column>
              <Column
                header="Departman"
                sortable
                field="department"
                body={(rowData) => {
                  const department = departments.find(
                    (dept) => dept.id.toString() === rowData.department
                  );
                  return department
                    ? department.department
                    : "Unknown Department";
                }}
                style={{ minWidth: "6rem" }}
              ></Column>
              <Column
                field="status"
                header="Durum"
                body={statusBodyTemplate}
                style={{ minWidth: "6rem" }}
              ></Column>
              <Column
                header="Bitiren Kişi"
                field="designated_person"
                sortable
                body={(rowData) => {
                  const designatedPersons =
                    rowData?.designated_person?.split(","); 

                  const assignedUsers = designatedPersons?.map(
                    (designatedPerson) => {
                      const user = employeeList.find(
                        (usr) => usr.id.toString() === designatedPerson.trim()
                      );

                      return user ? user.name : "Bilinmeyen"; 
                    }
                  );

                  return assignedUsers?.join(", "); 
                }}
                style={{ maxWidth: "10rem" }}
              ></Column>
              <Column
                sortable
                field="created_at"
                header="Oluşturulma Tarihi"
                body={(rowData) => formatDate(rowData.created_at)}
                style={{ minWidth: "6rem" }}
              ></Column>
            </DataTable>
          </Card>
        </div>
      </Flex>
    </div>
  );
}
