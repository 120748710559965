import React, { useState } from "react";
import { Dialog } from 'primereact/dialog';
import { Button, useToast } from '@chakra-ui/react'
import "../css/Note.css";

export default function NoteSideBar({
  notes,
  onAddNote,
  onDeleteNote,
  activeNote,
  setActiveNote,
}) {
  const sortedNotes = notes
  ? [...notes].sort((a, b) => new Date(b.date) - new Date(a.date))
  : [];
  
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [noteToDelete, setNoteToDelete] = useState(null);
  const toast = useToast();
  const handleDeleteNote = async () => {
    if (noteToDelete) {
      try {
        await onDeleteNote(noteToDelete);
        toast({
          title: 'Not Silindi',
          description: 'Notunuz başarıyla silindi.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (error) {
        console.error('Not silinemedi:', error);
        toast({
          title: 'Not Silinemedi',
          description: 'Notunuz aksaklık nedeniyle silinememiştir.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
      } finally {
        setNoteToDelete(null);
        setShowDeleteConfirmation(false);
      }
    }
  };

  return (
    <div className="app-sidebar">
      <div className="app-sidebar-header">
        <h1>Notlar</h1>
        <button style={{color:"#08c"}} onClick={onAddNote}>Ekle</button>
      </div>
      <div className="app-sidebar-notes">
        {sortedNotes.map((note) => (
          <div
            className={`app-sidebar-note ${note.id === activeNote && "active"}`}
            onClick={() => setActiveNote(note.id)}
          >
            <div className="sidebar-note-title">
              <strong>{note.title}</strong>
              <button onClick={(e) => { e.stopPropagation(); setNoteToDelete(note.id); setShowDeleteConfirmation(true); }}>Sil</button>
            </div>
            <p>{note.description && note.description.substr(0, 100) + "..."}</p>
            <small className="note-meta">Son Güncelleme {note.date}</small>
          </div>
        ))}
      </div>
      <Dialog style={{width:"25%"}} visible={showDeleteConfirmation} onHide={() => setShowDeleteConfirmation(false)} header="Notu Sil">
        <div>Silmek istediğinize emin misiniz?</div>
        <div className="p-fluid">
          <div className="p-field" style={{marginTop:"1rem" , textAlign:"right" }}>
            <Button className="p-button-secondary" onClick={() => setShowDeleteConfirmation(false)} >Hayır</Button>
            <Button style={{marginLeft:"1rem"}} className="p-button-success" onClick={handleDeleteNote} >Evet</Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
