import React, { useEffect, useState } from "react";
import { Grid, GridItem } from "@chakra-ui/react";

import EmployeesSidebar from "../components/employees-sidebar/EmployeesSidebar";
import { CustomerService } from "../../../../../core/Services";
import EmployeesCustomersPage from "./components/EmployeesCustomersPage";

export default function EmployeesCustomers() {
  const [uniqueCustomerId, setUniqueCustomerId] = useState("");
  const [customers, setCustomers] = useState([]);
  

  useEffect(() => {
    CustomerService()
      .then((data) => {
        setCustomers(data);
      })
      .catch((error) => {
        console.log("Müşteri Çekme Hatası", error);
      });
  }, []);

  return (
    <Grid
      templateAreas={`"header header"
                  "nav main"
                 `}
      gridTemplateRows={"auto 1fr "}
      gridTemplateColumns={"auto 1fr"}
      h="auto"
      color="blackAlpha.700"
      fontWeight="bold"
    >
      <GridItem area={"header"}>

      </GridItem>
      <GridItem area={"nav"}>
        <EmployeesSidebar />
      </GridItem>
      <GridItem mt="5" area={"main"}>
        <EmployeesCustomersPage
          customers={customers}
          setUniqueCustomerId={setUniqueCustomerId}
          uniqueCustomerId={uniqueCustomerId}
        />
      </GridItem>
    </Grid>
  );
}
