import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useRef, useState } from "react";
import { AddTemplatesService } from "../../../core/Services";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
function AddTemplate() {
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [description, setDescription] = useState("");
  const toast = useRef(null);
  const [inputErrors, setInputErrors] = useState({
    name: false,
    description: false,
    type: false,
  });
  const newCreate = async () => {
    if (!name || !description || !type) {
      setInputErrors({
        name: !name,
        description: !description,
        type: !type,
      });
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Lütfen boş kısımları doldurun.",
      });
      return;
    } else {
      await AddTemplatesService(name, description, type).then(
        toast.current.show({
          severity: "success",
          summary: "İşlem Başarılı!",
          detail: "Başarıyla eklendi.",
          life: 3000,
        })
      );
      window.location.reload();
    }
  };
  return (
    <div>
      <Toast ref={toast} />
      <div style={{ display: "flex" }}>
        <div className="col-6">
          <Dropdown
            filter
            options={[
              { label: "Teklif", value: "0" },
              { label: "Sms", value: "1" },
              { label: "Mail", value: "2" },
            ]}
            value={type}
            placeholder="Şablon Türü"
            onChange={(e) => {
              setType(e.target.value);
              setInputErrors({ ...inputErrors, type: false });
            }}
            style={{
              border: "1px solid gray",
              height: "80%",
              width: "100%",
            }}
          />
          {inputErrors.type && (
            <small style={{ color: "red" }}> Lütfen doldurunuz.</small>
          )}
        </div>
        <div className="col-6">
          <InputText
            type="text"
            value={name}
            className="p-inputtext-lg w-full"
            placeholder="Şablon Adı"
            onChange={(e) => {
              setName(e.target.value);
              setInputErrors({ ...inputErrors, name: false });
            }}
          />
          {inputErrors.name && (
            <small style={{ color: "red" }}> Lütfen doldurunuz.</small>
          )}
        </div>
      </div>
      <div className="col-12">
        <InputTextarea
          type="text"
          rows={5}
          cols={30}
          className="p-inputtext-lg w-full"
          placeholder="İçerik"
          onChange={(e) => {
            setDescription(e.target.value);
            setInputErrors({ ...inputErrors, description: false });
          }}
          value={description}
        />
        {inputErrors.description && (
          <small style={{ color: "red" }}> Lütfen doldurunuz.</small>
        )}
      </div>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Button
          label="Ekle"
          onClick={newCreate}
          className="btn-excel-two mr-3"
        />{" "}
      </div>
    </div>
  );
}

export default AddTemplate;
