import React, { useState } from "react";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import { Card } from "primereact/card";
import { useToast } from "@chakra-ui/react";
import { Dialog } from "primereact/dialog";
import {
  AddTodoService,
  DeleteTodoService,
  SaveTodoService,
} from "../../../../core/Services";
import { Dropdown } from "primereact/dropdown";
export default function TodoItems({
  items,
  day,
  modal,
  setModal,
  userData,
  statusTodo,
  setStatusTodo,
}) {
  const [dateHour, setDateHour] = useState("");
  const [title, setTitle] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const toast = useToast();

  const handleDelete = (id, state) => {
    DeleteTodoService(id, state).then((data) => {
      if (data?.status === 200) {
        toast({
          title: "Başarılı!",
          description: "Başarıyla Gerçekleşti.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      } else {
        toast({
          title: "Hata!",
          description: "Bir Hata Oluştu.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      }
      setModal(false);
      setDateHour("");
      setTitle("");
      setStatusTodo("");
      setSelectedData({});
    });
  };
  const createTodo = () => {
    if (title === "" || dateHour === "" || statusTodo === "") {
      toast({
        title: "Hata!",
        description: "Lütfen Boşlukları Doldurunuz.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }

    const hourAndMinute = getHourAndMinute(dateHour);
    AddTodoService(userData?.id, day, title, hourAndMinute, statusTodo).then(
      (data) => {
        if (data?.status === 200) {
          toast({
            title: "Başarılı!",
            description: "Başarıyla Eklendi.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
          setTitle("");
          setDateHour("");

          setStatusTodo("");
        } else {
          toast({
            title: "Hata!",
            description: "Eklerken Bir Hata Oluştu.",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
        }
      }
    );
  };
  const saveTodo = () => {
    if (title === "" || dateHour === "" || statusTodo === "") {
      toast({
        title: "Hata!",
        description: "Lütfen Boşlukları Doldurunuz.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }

    const hourAndMinute = getHourAndMinute(dateHour);
    SaveTodoService(
      selectedData?.id,
      userData?.id,
      day,
      title,
      hourAndMinute,
      statusTodo
    ).then((data) => {
      if (data?.status === 200) {
        toast({
          title: "Başarılı!",
          description: "Başarıyla Kaydedildi.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        setTitle("");
        setDateHour("");
        setStatusTodo("");
      } else {
        toast({
          title: "Hata!",
          description: "Bir Hata Oluştu.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      }
    });
  };
  const statusType = [
    { label: "Mavi", value: "1" },
    { label: "Kırmızı", value: "2" },
    { label: "Sarı", value: "3" },
    { label: "Yeşil", value: "4" },
    { label: "Mor", value: "5" },
  ];

  const getHourAndMinute = (dateString) => {
    const date = new Date(dateString);
    const hour = date.getHours();
    const minute = date.getMinutes();
    const formattedHour = hour < 10 ? "0" + hour : hour.toString();
    const formattedMinute = minute < 10 ? "0" + minute : minute.toString();
    return formattedHour + ":" + formattedMinute;
  };

  const bgStatusColor = (item) => {
    switch (Number(item)) {
      case 1:
        return "#ADD8E6";
      case 2:
        return "#F08080";
      case 3:
        return "#FFD700";
      case 4:
        return "#90EE90";
      case 5:
        return "#D8BFD8";
      default:
        return "";
    }
  };

  return (
    <div
      style={{
        background: "#e8bcb9",
        borderRadius: "10px",
        padding: "1rem",
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      {items?.length > 0
        ? items?.map((item, index) => (
            <div className="col-3" key={index}>
              <Card
                style={{
                  padding: "1rem",
                  background: bgStatusColor(item?.status),
                  fontSize: `calc(10px + 0.3vw)`,
                }}
                footer={
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {item?.time_hour}
                    <div style={{ display: "flex" }}>
                      <Button
                        label="Düzenle"
                        severity="secondary"
                        icon="pi pi-pencil"
                        onClick={() => {
                          setEditMode(true);
                          setDateHour(item?.time_hour);
                          setTitle(item?.title);
                          setStatusTodo(item?.status.toString());
                          setSelectedData(item);
                        }}
                        style={{
                          marginLeft: "0.5em",
                          fontSize: `calc(10px + 0.3vw)`,
                        }}
                      />
                      <Button
                        label="Tamamla"
                        severity="secondary"
                        icon="pi pi-check"
                        onClick={() => {
                          setModal(true);
                          setSelectedData(item);
                        }}
                        style={{
                          marginLeft: "0.5em",
                          fontSize: `calc(10px + 0.3vw)`,
                        }}
                      />
                    </div>
                  </div>
                }
              >
                {item?.title}
              </Card>
            </div>
          ))
        : null}
      <div className="col-3">
        <Card style={{ padding: "1rem" }}>
          <span className="p-float-label">
            <InputTextarea
              style={{ height: "6rem" }}
              type="text"
              id="title"
              rows={3}
              className="p-inputtext-lg w-full "
              value={title}
              onChange={(e) => setTitle(e.target.value)} // Başlık girişi için değeri güncelle
            />
            <label htmlFor="title">Görev</label>
          </span>
          <div
            style={{
              display: "flex",
              marginBottom: "0.5rem",
              marginTop: "-0.7rem",
            }}
          >
            <Calendar
              value={dateHour}
              placeholder="Saat Seçiniz"
              onChange={(e) => setDateHour(e.value)}
              showIcon
              className="p-inputtext-lg w-full "
              timeOnly
              icon={() => <i className="pi pi-clock" />}
            />
          </div>
          <Dropdown
            filter
            value={statusTodo}
            options={statusType}
            onChange={(e) => {
              setStatusTodo(e.value);
            }}
            optionLabel="label"
            placeholder="Durum Seçiniz"
            className="w-full"
          />
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "0.5rem",
            }}
          >
            {editMode ? (
              <div style={{ display: "flex" }}>
                <Button
                  label="İptal"
                  onClick={() => {
                    setEditMode(false);
                    setDateHour("");
                    setTitle("");
                    setStatusTodo("");
                    setSelectedData({});
                  }}
                  className="btn-excel-two"
                  style={{ marginRight: "1rem" }}
                />{" "}
                <Button
                  label="Sil"
                  onClick={() => {
                    setModal(true);
                  }}
                  icon="pi pi-trash"
                  className="btn-excel-two"
                  style={{ marginRight: "1rem" }}
                />
                <Button
                  label="Kaydet"
                  icon="pi pi-check"
                  className="btn-excel-two"
                  onClick={saveTodo}
                />
              </div>
            ) : (
              <Button
                label="Oluştur"
                icon="pi pi-check"
                className="btn-excel-two"
                onClick={createTodo}
              />
            )}
          </div>
        </Card>
      </div>
      <Dialog
        visible={modal}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        style={{ width: "45vw" }}
        header={
          editMode
            ? "Görevi silmek istediğinize emin misiniz?"
            : "Görevi tamamlamak istediğinize emin misiniz?"
        }
        onHide={() => setModal(false)}
        modal
        footer={
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "1rem",
            }}
          >
            <Button
              label="İptal"
              onClick={() => setModal(false)}
              className="btn-excel-two"
              style={{ marginRight: "1rem" }}
            />
            <Button
              label={editMode ? "Sil" : "Tamamla"}
              onClick={() => {
                if (editMode) {
                  handleDelete(selectedData?.id, 1);
                } else {
                  handleDelete(selectedData?.id, 0);
                }
                setEditMode(false);
              }}
              className="btn-excel-two"
            />
          </div>
        }
      ></Dialog>
    </div>
  );
}
