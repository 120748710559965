import React, { useEffect, useRef, useState } from "react";
import {
  AddLicence,
  DeleteLicence,
  UpdateLicence,
  VisibileLicence,
} from "../../../../core/Services";
import { Card, CardHeader, Flex, Heading } from "@chakra-ui/react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import licencePackages from "../../../../../_ekdizayn/api/licence-packages.json";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { addLocale } from "primereact/api";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { useSelector } from "react-redux";

function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default function Licence() {
  const [tally, setTally] = useState(null);
  const [domain, setDomain] = useState(null);
  const [jobNo, setJobNo] = useState("");
  const [addModal, setAddModal] = useState(false);
  const [domainName, setDomainName] = useState("");
  const [startingDate, setStartingDate] = useState(null);
  const [renewalDate, setRenewalDate] = useState(null);
  const [dialogs, setDialogs] = useState({});
  const [visibilityDialog, setVisibilityDialog] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [packages, setPackages] = useState(null);
  const [displayDeleteDialog, setDisplayDeleteDialog] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const toast = useRef(null);
  const [inputErrors, setInputErrors] = useState({
    domainName: false,
    packages: false,
    startingDate: false,
    renewalDate: false,
  });
  const licenceData = useSelector((state) => state.LicenceReduce.value);
  const tallyData = useSelector((state) => state.TallyReduce.value);
  useInterval(() => {
    setTally((prevTally) => {
      if (!prevTally || !prevTally.length) {
        return prevTally;
      }
      const updatedTally = prevTally.map((item) => {
        const remainingDays = calculateRemainingDays(item.renewal_date);
        return {
          ...item,
          remainingDays,
        };
      });

      return updatedTally;
    });
  }, 24 * 60 * 60 * 1000);

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    return new Date(dateString).toLocaleString("tr-TR", options);
  };

  const filterHostName = (value) => {
    let matchingObjects = [];
    if (Array.isArray(tallyData.licences) && tallyData.licences.length > 0) {
      matchingObjects = tallyData.licences.filter((item) =>
        item.domain_name.includes(value)
      );
    }
    setTally(matchingObjects);
  };
  addLocale("tr", {
    firstDayOfWeek: 1,
    showMonthAfterYear: true,
    dayNames: [
      "pazar",
      "pazartesi",
      "salı",
      "çarşamba",
      "perşembe",
      "cuma",
      "cumartesi",
    ],
    dayNamesShort: ["paz", "pzt", "sal", "çar", "per", "cum", "cmt"],
    dayNamesMin: ["P", "Pzt", "S", "Ç", "P", "C", "Cmt"],
    monthNames: [
      "ocak",
      "şubat",
      "mart",
      "nisan",
      "mayıs",
      "haziran",
      "temmuz",
      "ağustos",
      "eylül",
      "ekim",
      "kasım",
      "aralık",
    ],
    monthNamesShort: [
      "oca",
      "şub",
      "mar",
      "nis",
      "may",
      "haz",
      "tem",
      "ağu",
      "eyl",
      "eki",
      "kas",
      "ara",
    ],
    today: "Bugün",
    clear: "Temizle",
  });

  const hideDialog = () => {
    setDomainName("");
    setPackages("");
    setStartingDate(null);
    setRenewalDate(null);
    setAddModal(false);
  };

  const handleCompleteTask = async () => {
    try {
      if (!selectedTask) {
        console.error("Düzenlenecek görev bulunamadı.");
        return;
      }

      const formattedStartingDate = formatDate(startingDate);
      const formattedRenewalDate = formatDate(renewalDate);

      await UpdateLicence(
        selectedTask.id,
        packages,
        domainName,
        formattedStartingDate,
        formattedRenewalDate
      );
      setDialogs(false);
    } catch (error) {
      console.error("Hata:", error.message);
    }
  };

  const hideDeleteDialog = () => {
    setDisplayDeleteDialog(false);
    setPackages("");
    setDomainName("");
    setJobNo("");
    setSelectedId(null);
  };
  const handleDeletedDomain = async () => {
    await DeleteLicence(selectedId);
    setDisplayDeleteDialog(false);
    setSelectedId(null);
    setPackages("");
    setDomainName("");
    setJobNo("");
  };

  const EditBodyTemplate = (tasks) => {
    const isVisible = dialogs[tasks.id] || false;

    useEffect(() => {
      if (isVisible) {
        setDomainName(tasks.domain_name || "");
        setPackages(tasks.package || "");

        const startingDateValue = tasks.starting_date
          ? new Date(tasks.starting_date)
          : new Date();
        const renewalDateValue = tasks.renewal_date
          ? new Date(tasks.renewal_date)
          : new Date();

        setStartingDate(startingDateValue);
        setRenewalDate(renewalDateValue);
      }
    }, [isVisible, tasks]);

    return (
      <React.Fragment>
        <div style={{ display: "flex" }}>
          <div style={{ color: "#f39f5a" }}>
            <Button
              severity="warning"
              onClick={() => {
                setSelectedTask(tasks);
                setDialogs((prevDialogs) => ({
                  ...prevDialogs,
                  [tasks.id]: true,
                }));
              }}
              icon="pi pi-pencil"
            />
          </div>
          <div style={{ color: "#ae445a" }}>
            <Button
              icon="pi pi-trash"
              onClick={() => {
                setSelectedId(tasks.id);
                setJobNo(tasks?.work_order_number);
                setDomainName(tasks?.domain_name);
                if (tasks?.package) {
                  const pack = licencePackages.find(
                    (pack) => pack.value.toString() === tasks.package
                  );
                  if (pack) {
                    setPackages(pack.label);
                  } else {
                    setPackages("Bilinmeyen");
                  }
                }
                setDisplayDeleteDialog(true);
              }}
              severity="danger"
            />
          </div>
          <div style={{ color: "#00d9ff" }}>
            <Button
              icon="pi pi-clone"
              onClick={() => {
                setSelectedId(tasks.id);
                setVisibilityDialog(true);
              }}
              severity="info"
            />
          </div>
        </div>
        <Dialog
          header="İçerik Düzenleme"
          visible={isVisible}
          style={{ width: "50vw" }}
          onHide={() => {
            setDialogs((prevDialogs) => ({
              ...prevDialogs,
              [tasks.id]: false,
            }));
            setSelectedTask(null);
          }}
          footer={
            <div>
              <Button
                label="Kaydet"
                icon="pi pi-check"
                onClick={handleCompleteTask}
                className="btn-excel-two"
                style={{ marginLeft: "15px" }}
              />
            </div>
          }
        >
          <div className="confirmation-content">
            <div className="p-grid p-fluid">
              <div className="p-col-12">
                <div style={{ display: "flex", marginBottom: "1rem" }}>
                  <InputText
                    type="text"
                    className="p-inputtext-lg w-full "
                    placeholder="Alan Adı"
                    defaultValue={domainName}
                    onChange={(e) => setDomainName(e.target.value)}
                  />
                </div>
              </div>
              <div style={{ marginBottom: "1rem" }}>
                <Dropdown
                  options={licencePackages}
                  value={packages}
                  filter
                  className="w-full"
                  placeholder="Paket"
                  onChange={(e) => setPackages(e.value)}
                  style={{
                    border: "1px solid gray",
                  }}
                />
              </div>

              <Calendar
                placeholder="İlk Kayıt Tarihi"
                locale="tr"
                className="w-full"
                style={{
                  border: "1px solid gray",
                  padding: "0.5rem",
                  marginBottom: "1rem",
                  borderRadius: "6px 6px 6px 6px",
                }}
                value={startingDate || null}
                onChange={(e) => setStartingDate(e.value)}
                dateFormat="dd/mm/yy"
                showTime={false}
                firstDayOfWeek={1}
              />

              <Calendar
                placeholder="Yenileme Tarihi"
                locale="tr"
                className="w-full"
                style={{
                  border: "1px solid gray",
                  padding: "0.5rem",
                  marginBottom: "1rem",
                  borderRadius: "6px 6px 6px 6px",
                }}
                value={renewalDate}
                onChange={(e) => setRenewalDate(e.value)}
                dateFormat="dd/mm/yy"
                showTime={false}
                firstDayOfWeek={1}
              />
            </div>
          </div>
        </Dialog>
      </React.Fragment>
    );
  };

  const newCreate = async () => {
    if (
      !domainName ||
      !packages ||
      !domainName ||
      !startingDate ||
      !renewalDate
    ) {
      setInputErrors({
        domainName: !domainName,
        packages: !packages,
        startingDate: !startingDate,
        renewalDate: !renewalDate,
      });
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Lütfen boş kısımları doldurun.",
      });
      return;
    }
    const formattedStartingDate = formatDate(startingDate);
    const formattedRenewalDate = formatDate(renewalDate);

    await AddLicence(
      packages,
      domainName,
      formattedStartingDate,
      formattedRenewalDate
    );
    setAddModal(false);

    setDomainName("");
    setStartingDate(null);
    setRenewalDate(null);
    setAddModal(false);

    window.location.reload();
  };

  const calculateRemainingDays = (renewalDate) => {
    const ONE_DAY = 24 * 60 * 60 * 1000;
    const renewalDateObject = new Date(renewalDate);
    const startingDateObject = new Date();

    const timeDiff = renewalDateObject - startingDateObject;
    const daysDiff = Math.ceil(timeDiff / ONE_DAY);

    return daysDiff;
  };

  useEffect(() => {
    if (licenceData) {
      const sortedData = [...licenceData].sort((a, b) => {
        const remainingDaysA = calculateRemainingDays(a.renewal_date);
        const remainingDaysB = calculateRemainingDays(b.renewal_date);
        return remainingDaysA - remainingDaysB;
      });
      setTally(sortedData);
      setDomain(tallyData?.tracks);
    }
  }, [licenceData, dialogs, tallyData?.tracks]);

  const handleCloseDialog = () => {
    setVisibilityDialog(false);
  };
  const handleCompleteVisibile = async () => {
    try {
      if (!selectedId) {
        console.error("Pasife alınacak domain bulunamadı.");
        return;
      }
      await VisibileLicence(selectedId);
      setVisibilityDialog(false);
    } catch (error) {
      console.error("Hata:", error.message);
    }
  };
  return (
    <Flex mt="5" justifyContent="center" alignItems="center">
      <div
        className="card"
        style={{
          overflow: "auto",
          boxSizing: "border-box",
          width: "100%",
          height: "100%",
          position: "relative",
          padding: "1rem",
        }}
      >
        <Card>
          <CardHeader>
            <Flex justifyContent="space-between" alignItems="center">
              <Heading size="md">Lisans</Heading>
              <div>
                <span
                  className="p-input-icon-right"
                  style={{
                    border: "solid 1px gray",
                    borderRadius: "6px 6px 6px 6px",
                    padding: "0.2rem",
                  }}
                >
                  <i className="pi pi-search" />
                  <InputText
                    placeholder="Alan Adı Ara"
                    onChange={(e) => filterHostName(e.target.value)}
                  />
                </span>
                <Button
                  label="Yeni"
                  style={{ marginLeft: "1rem" }}
                  onClick={() => setAddModal(true)}
                  className="btn-excel-two"
                />
              </div>
            </Flex>
          </CardHeader>
          <DataTable
            stripedRows
            dataKey="id"
            size="small"
            tableStyle={{ minWidth: "50rem" }}
            rows={15}
            value={
              tally &&
              Array.isArray(tally) &&
              tally.filter((item) => item?.licance_visibility === "0")
            }
            rowsPerPageOptions={[15, 30, 60, 100]}
            scrollHeight="65vh"
            scrollable
            paginator
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate=" {totalRecords} lisansdan {first} - {last} arası gösteriliyor"
          >
            <Column
              style={{ width: "8rem" }}
              body={EditBodyTemplate}
              header="İşlemler"
            ></Column>
            <Column field="work_order_number" header="İş Emri"></Column>
            <Column field="domain_name" header="Alan Adı"></Column>
            <Column
              field="package"
              body={(rowData) => {
                const pack = licencePackages.find(
                  (pack) => pack.value.toString() === rowData.package
                );
                if (pack) {
                  return pack.label;
                } else {
                  return "Bilinmeyen";
                }
              }}
              header="Paket"
            ></Column>
            <Column
              field="starting_date"
              header="Başlangıç  Tarihi"
              body={(rowData) => formatDate(rowData.starting_date)}
            ></Column>
            <Column
              field="renewal_date"
              header="Yenileme Tarihi"
              body={(rowData) => formatDate(rowData.renewal_date)}
            ></Column>
            <Column
              header="Kalan Süre"
              body={(rowData) => {
                const remainingDays = calculateRemainingDays(
                  rowData.renewal_date
                );

                // Kalan günler 0'dan küçükse ve rowData.id mevcutsa, updateTimeStopTrack fonksiyonunu çağır
                // if (remainingDays < 0 && rowData.id) {
                //   updateTimeStopTrack(rowData.id);
                // }
                let backgroundColor;
                let textColor;
                if (remainingDays >= 30) {
                  backgroundColor = "#4CAF50";
                  textColor = "black";
                } else if (remainingDays >= 7) {
                  backgroundColor = "#FFC107";
                  textColor = "black";
                } else {
                  backgroundColor = "#F44336";
                  textColor = "black";
                }
                const style = {
                  backgroundColor,
                  borderRadius: "5px",
                  color: textColor,
                  padding: "5px",
                  display: "inline-block",
                };

                return <span style={style}>{remainingDays} gün</span>;
              }}
            ></Column>
          </DataTable>
        </Card>
      </div>
      <Dialog
        visible={addModal}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        style={{ width: "50%" }}
        header="Lisans  Ekle"
        onHide={hideDialog}
        modal
      >
        <div className="confirmation-content">
          <div className="p-grid p-fluid">
            <div className="p-col-12">
              <div style={{ marginBottom: "1rem" }}>
                <Dropdown
                  filter
                  options={
                    tally &&
                    Array.isArray(tally) &&
                    domain
                      .filter(
                        (data) =>
                          data.service_type === "0" &&
                          data.licance_domain_status === "0"
                      )
                      .map((data) => ({
                        label: data.domain_name,
                        value: data.domain_name,
                      }))
                  }
                  placeholder="Alan Adı"
                  style={{
                    border: "1px solid gray",
                  }}
                  value={domainName}
                  className="w-full"
                  onChange={(e) => {
                    setDomainName(e.target.value);
                    setInputErrors({ ...inputErrors, domainName: false });
                  }}
                />
                {inputErrors.domainName && (
                  <small style={{ color: "red" }}>
                    Lütfen alan adı seçiniz.
                  </small>
                )}
              </div>

              <div style={{ marginBottom: "1rem" }}>
                <Dropdown
                  filter
                  options={licencePackages}
                  value={packages}
                  placeholder="Paket"
                  onChange={(e) => {
                    setPackages(e.value);
                    setInputErrors({ ...inputErrors, packages: false });
                  }}
                  className="w-full"
                  style={{
                    border: "1px solid gray",
                  }}
                />
                {inputErrors.packages && (
                  <small style={{ color: "red" }}>Lütfen Paket Seçiniz.</small>
                )}
              </div>

              <div style={{ marginBottom: "1rem" }}>
                <Calendar
                  placeholder="İlk Kayıt Tarihi"
                  locale="tr"
                  style={{
                    border: "1px solid gray",
                    padding: "0.5rem",

                    borderRadius: "6px 6px 6px 6px",
                  }}
                  value={startingDate}
                  onChange={(e) => {
                    setStartingDate(e.value);
                    setInputErrors({ ...inputErrors, startingDate: false });
                  }}
                  dateFormat="dd/mm/yy"
                  showTime={false}
                  className="w-full"
                  firstDayOfWeek={1}
                />
                {inputErrors.startingDate && (
                  <small style={{ color: "red" }}>
                    Lütfen ilk kayıt tarihi seçiniz.
                  </small>
                )}
              </div>
              <div style={{ marginBottom: "1rem" }}>
                <Calendar
                  placeholder="Yenileme Tarihi"
                  locale="tr"
                  className="w-full"
                  style={{
                    border: "1px solid gray",
                    padding: "0.5rem",
                    borderRadius: "6px 6px 6px 6px",
                  }}
                  value={renewalDate}
                  onChange={(e) => {
                    setRenewalDate(e.value);
                    setInputErrors({ ...inputErrors, renewalDate: false });
                  }}
                  dateFormat="dd/mm/yy"
                  showTime={false}
                  firstDayOfWeek={1}
                />
                {inputErrors.renewalDate && (
                  <small style={{ color: "red" }}>
                    Lütfen yenileme tarihi seçiniz.
                  </small>
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1rem",
          }}
        >
          <Button
            label="Ekle"
            onClick={newCreate}
            style={{ width: "7rem" }}
            className="btn-excel-two"
          />
        </div>
      </Dialog>
      <Dialog
        visible={displayDeleteDialog}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        style={{ width: "60vw" }}
        header="Lisans Sil"
        onHide={hideDeleteDialog}
        modal
      >
        <div className="confirmation-content">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <p>Lisans Silmek İstediğinize Emin Misiniz?</p>
          </div>

          <div className="confirmation-content">
            <div className="confirmation-content">
              <div className="confirmation-content">
                <div className="field">
                  <label htmlFor="name" className="font-bold">
                    İş Emri: {jobNo}
                  </label>
                </div>

                <div className="field">
                  <label htmlFor="name" className="font-bold">
                    Alan Adı: {domainName}
                  </label>
                </div>
                <div className="field">
                  <label htmlFor="name" className="font-bold">
                    Paket: {packages}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "1rem",
            }}
          >
            <Button
              label="Hayır"
              onClick={hideDeleteDialog}
              className="btn-excel-two"
              style={{ marginRight: "1rem" }}
            />
            <Button
              label="Evet"
              onClick={handleDeletedDomain}
              className="btn-excel-two"
            />
          </div>
        </div>
      </Dialog>
      <Dialog
        header="Pasife Al"
        visible={visibilityDialog}
        style={{ width: "50vw" }}
        onHide={() => setVisibilityDialog(false)}
        footer={
          <div>
            <Button
              label="Kapat"
              icon="pi pi-times"
              onClick={handleCloseDialog}
              className="btn-excel-two"
            />
            <Button
              label="Lisansı Pasife Al"
              icon="pi pi-check"
              onClick={handleCompleteVisibile}
              className="btn-excel-two"
              style={{ marginLeft: "15px" }}
            />
          </div>
        }
      ></Dialog>
      <Toast ref={toast} />
    </Flex>
  );
}
