import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import {
  AddJobsService,
  AssignJobService,
  EditJobService,
  DeleteJobService,
} from "../../../../core/Services";

import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { addLocale } from "primereact/api";
import { Toast } from "primereact/toast";
import { Card, CardHeader, Flex, Heading } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
import { Link } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";
import { useAuth } from "../../../../auth/core/AuthContext";

export default function Waiting() {
  const [jobs, setJobs] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomerUniqueId, setSelectedCustomerUniqueId] = useState("");
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const { userData } = useAuth();
  const [dep, setDep] = useState("");
  const [jobStatus, setStatus] = useState("");
  const [cus, setCus] = useState("");
  const [desc, setDesc] = useState("");
  const [newDep, setNewDep] = useState("");
  const [type] = useState("");
  const [employees, setEmployees] = useState([]);
  const [emp, setEmp] = useState("");
  const [jobsNo, setJobsNo] = useState("");
  const [newDate, setNewDate] = useState();
  const [departments, setDepartments] = useState([]);
  const [newSub, setNewSub] = useState("");
  const [date, setDate] = useState("");
  const [newCus, setNewCus] = useState("");
  const [newState, setNewState] = useState("");
  const [subject, setSubject] = useState("");
  const [newDesc, setNewDesc] = useState("");
  const isButtonDisable = false;
  const [selectedData, setSelectedData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const [assignedEmp, setAssignedEmp] = useState(false);
  const jobData = useSelector((state) => state.JobReduce.value);
  const userDatas = useSelector((state) => state.UserReduce.value);
  const departmentData = useSelector((state) => state.DepartmentReduce.value);
  const customerData = useSelector((state) => state.CustomerReduce.value);
  const toast = useRef(null);
  const [inputErrors, setInputErrors] = useState({
    subject: false,
    cus: false,
    dep: false,
    desc: false,
    jobStatus: false,
    date: false,
  });
  useEffect(() => {
    setJobs(jobData);
    setEmployees(userDatas);
    setCustomers(customerData);
    setDepartments(departmentData);
  }, [addModal, jobData, userDatas, customerData, departmentData, jobs]);

  const hideDialog = () => {
    setSubject("");
    setDesc("");
    setStatus(null);
    setDep("");
    setEmp(null);
    setDate(null);
    setSelectedCustomerUniqueId("");
    setCus("");
    setAddModal(false);
    setEditModal(false);
  };
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    return new Date(dateString).toLocaleString("tr-TR", options);
  };

  addLocale("tr", {
    firstDayOfWeek: 1,
    showMonthAfterYear: true,
    dayNames: [
      "pazar",
      "pazartesi",
      "salı",
      "çarşamba",
      "perşembe",
      "cuma",
      "cumartesi",
    ],
    dayNamesShort: ["paz", "pzt", "sal", "çar", "per", "cum", "cmt"],
    dayNamesMin: ["P", "Pzt", "S", "Ç", "P", "C", "Cmt"],
    monthNames: [
      "ocak",
      "şubat",
      "mart",
      "nisan",
      "mayıs",
      "haziran",
      "temmuz",
      "ağustos",
      "eylül",
      "ekim",
      "kasım",
      "aralık",
    ],
    monthNamesShort: [
      "oca",
      "şub",
      "mar",
      "nis",
      "may",
      "haz",
      "tem",
      "ağu",
      "eyl",
      "eki",
      "kas",
      "ara",
    ],
    today: "Bugün",
    clear: "Temizle",
  });

  const accessors = (rowData) => {

    const edit = editModal[rowData.id] || false;
    const visibles = visible[rowData.id] || false;
    const assign = assignModal[rowData.id] || false;
    return (
      <div style={{ display: "flex" }}>
        <div style={{ color: "#f39f5a" }}>
          <Button
            severity="warning"
            onClick={() => {
              setEditModal((prevDialogs) => ({
                ...prevDialogs,
                [rowData.id]: true,
              }));
              setSelectedData(rowData);
            }}
            icon="pi pi-pencil"
          />
        </div>
        <div style={{ color: "#ae445a" }}>
          <Button
            icon="pi pi-trash"
            onClick={() => {
              setVisible((prevDialogs) => ({
                ...prevDialogs,
                [rowData.id]: true,
              }));
              setDesc(rowData?.desc);
              setJobsNo(rowData?.jobs_no);
              if (rowData?.customer_id) {
                const cust = customers.find(
                  (dept) => dept.id.toString() === rowData.customer_id
                );
                if (cust) {
                  const firstTwoWords =
                    cust.title && cust.title.split(" ").slice(0, 2).join(" ");
                  setCus(firstTwoWords);
                } else {
                  setCus("Bilinmeyen");
                }
              }

              if (rowData?.department) {
                const department = departments.find(
                  (dept) => dept.id.toString() === rowData.department
                );
                if (department) {
                  setDep(department.department);
                }
              }
              setSelectedData(rowData);
            }}
            severity="danger"
          />
        </div>
        <div style={{ color: "green" }}>
          <Button
            icon="pi pi-user-plus"
            severity="help"
            onClick={() => {
              setAssignModal((prevDialogs) => ({
                ...prevDialogs,
                [rowData.id]: true,
              }));
              setSelectedData(rowData);
            }}
          />
        </div>
        <Dialog
          header="İşi Ata"
          visible={assign}
          style={{ width: "50vw" }}
          onHide={() => setAssignModal(false)}
          footer={
            <div>
              <Button
                label="Kapat"
                icon="pi pi-times"
                onClick={() => setAssignModal(false)}
                className="btn-excel-two"
              />
              <Button
                label="İşi Ata"
                icon="pi pi-check"
                onClick={() => handleAssignJob(selectedData.id)}
                className="btn-excel-two"
                style={{ marginLeft: "15px" }}
              />
            </div>
          }
        >
          <div className="field">
            <MultiSelect
              filter
              value={assignedEmp}
              options={
                Array.isArray(employees) && employees.length > 0
                  ? employees.map((employ) => ({
                      label: employ.name,
                      value: employ.id,
                    }))
                  : []
              }
              placeholder="Görevliler"
              className="w-full "
              style={{
                border: "1px solid gray",
                width: "100%",
                marginBottom: "1rem",
              }}
              onChange={(e) => setAssignedEmp(e.target.value)}
            />
          </div>
        </Dialog>
        <Dialog
          header="İşi silmek istediğinize emin misiniz?"
          visible={visibles}
          style={{ width: "50vw" }}
          onHide={() => {
            setVisible(false);
            setDesc("");
            setDep("");
            setJobsNo("")
            setCus("");
          }}
          footer={
            <div>
              <Button
                label="İptal"
                icon="pi pi-times"
                onClick={() => setVisible(false)}
                className="btn-excel-two"
              />
              <Button
                label="İşi Sil"
                icon="pi pi-check"
                onClick={() => {
                  DeleteJobService(selectedData.id);
                  setVisible(false);
                  setJobsNo("")
                  setCus("");
                  setDep("");
                  setDesc("");
                }}
                className="btn-excel-two"
                style={{ marginLeft: "15px" }}
              />
            </div>
          }
        >
          <div className="confirmation-content">
            <div className="field">
              <label htmlFor="name" className="font-bold">
                Müşteri Numarası: {jobsNo}
              </label>
            </div>
            <div className="field">
              <label htmlFor="name" className="font-bold">
                Müşteri Adı: {cus}
              </label>
            </div>
            <div className="field">
              <label htmlFor="description" className="font-bold">
                Açıklama : {desc}
              </label>
            </div>
            <div className="field">
              <label className="mb-3 font-bold">Departman: {dep}</label>
            </div>
          </div>
        </Dialog>
        <Dialog
          visible={edit}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          style={{ width: "60vw" }}
          header="İşi Düzenle"
          onHide={hideDialog}
          modal
        >
          <div className="confirmation-content">
            <div className=" jc-space-around">
              {/* Sol Sütun */}
              <div className="col-6 " style={{ display: "inline-block" }}>
                <Dropdown
                  filter
                  options={
                    customers && customers.length > 0
                      ? customers.map((dep) => ({
                          label: dep.title,
                          value: dep.id,
                        }))
                      : []
                  }
                  value={newCus}
                  placeholder="Müşteri Adı / Ünvanı"
                  onChange={(e) => setNewCus(e.target.value)}
                  style={{
                    marginBottom: "1rem",
                    border: "1px solid gray",
                    width: "100%",
                  }}
                />

                <InputText
                  type="text"
                  className="p-inputtext-lg w-full"
                  placeholder="Konu"
                  onChange={(e) => setNewSub(e.target.value)}
                  defaultValue={selectedData.subject}
                />

                <Dropdown
                  filter
                  value={newDep}
                  options={
                    Array.isArray(departments) && departments.length > 0
                      ? departments.map((dep) => ({
                          label: dep.department,
                          value: dep.id,
                        }))
                      : []
                  }
                  placeholder="Departman"
                  onChange={(e) => setNewDep(e.target.value)}
                  style={{
                    border: "1px solid gray",
                    marginTop: "1rem",
                    width: "100%",
                  }}
                />
              </div>
              {/* Sağ Sütun */}
              <div className="col-6" style={{ display: "inline-block" }}>
                <InputText
                  type="text"
                  className="p-inputtext-lg w-full"
                  placeholder="Açıklama"
                  onChange={(e) => setNewDesc(e.target.value)}
                  defaultValue={selectedData.desc}
                  style={{ marginBottom: "1rem" }}
                />
                <Dropdown
                  filter
                  options={statusType}
                  placeholder="Durum"
                  value={newState}
                  className="w-full"
                  onChange={(e) => setNewState(e.value)}
                  style={{
                    border: "1px solid gray",
                    marginBottom: "1rem",
                  }}
                />

                <Calendar
                  value={newDate}
                  dateFormat="dd/mm/yy"
                  onChange={(e) => setNewDate(e.value)}
                  placeholder="Teslim Tarihi"
                  locale="tr"
                  style={{
                    border: "1px solid gray",
                    width: "100%",
                    padding: "0.5rem",
                    marginBottom: "1rem",
                    borderRadius: "6px 6px 6px 6px",
                  }}
                />
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    label="Kaydet"
                    onClick={() => saveChanges(selectedData.id)}
                    className="btn-excel-two"
                  />
                </div>
              </div>{" "}
            </div>{" "}
          </div>
        </Dialog>
      </div>
    );
  };

  const saveChanges = async (id) => {
    let formattedDate = "";
    if (newDate !== "") {
      formattedDate = formatDate(newDate);
    }
    let requestBody = {
      id,
      newCus,
      newDate: formattedDate,
      newDep,
      newDesc,
      newState,
      newSub,
    };
    await EditJobService(requestBody);
    setEditModal(false);
  };

  const assignJob = async (user_id, job_id ) => {
    await AssignJobService(user_id, job_id , userData?.id);
  };

  const workingBodyTemplate = (rowData) => {
    const getStatusLabel = (working_condition) => {
      switch (working_condition) {
        case "1":
          return "Normal";

        case "0":
          return "Yüksek";

        case "2":
          return "Kritik";

        default:
          return null;
      }
    };

    const statusLabel = getStatusLabel(rowData.working_condition);

    return <Tag value={statusLabel} severity={getSeverity(rowData)}></Tag>;
  };
  const getSeverity = (jobs) => {
    switch (jobs.working_condition) {
      case "0":
        return "success";

      case "1":
        return "warning";

      case "2":
        return "danger";

      default:
        return null;
    }
  };

  const formattedDate = formatDate(date);
  const newCreate = async () => {
  if (!cus || !dep || !desc || !jobStatus || !subject  || !date) {
    setInputErrors({
      cus: !cus,
      dep: !dep,
      desc: !desc,
      jobStatus: !jobStatus,
      subject: !subject,
      date: !date,
    });
    toast.current.show({
      severity: "error",
      summary: "Hata",
      detail: "Lütfen boş kısımları doldurun.",
    });
    return;
  }

   await AddJobsService(
        cus,
        selectedCustomerUniqueId,
        subject,
        desc,
        dep,
        emp,
        formattedDate,
        jobStatus,
        userData?.id
      );
      setSubject("");
      setDesc("");
      setStatus(null);
      setDep("");
      setEmp(null);
      setDate(null);
      setSelectedCustomerUniqueId("");
      setCus("");
      setAddModal(false);
    
};


  const statusType = [
    { label: "Normal", value: "1" },
    { label: "Yüksek", value: "0" },
    { label: "Kritik", value: "2" },
  ];

  const handleAssignJob = async (id) => {
    try {
      await assignJob(assignedEmp, id , userData?.id);
      setVisible(false); 
    } catch (error) {
      console.error("Hata:", error.message);
    }
  };

  return (
    <Flex mt="5" justifyContent="center" alignItems="center">
      <div
        className="card"
        style={{
          overflow: "auto",
          boxSizing: "border-box",
          width: "100%",
          height: "100%",
          position: "relative",
          padding: "1rem",
        }}
      >
        <Card>
          <CardHeader>
            <Flex justifyContent="space-between" alignItems="center">
              <Heading size="md">Bekleyen İşler</Heading>
              <div>
                {userData &&
                  (userData.type === "1" || userData.is_auth === "1") && (
                    <Button
                      label="Yeni"
                      onClick={() => setAddModal(true)}
                      className="btn-excel-two"
                    />
                  )}
              </div>
            </Flex>
          </CardHeader>
          <Toast ref={toast} />

          <DataTable
            stripedRows
            size="small"
            value={jobs && jobs.filter((jobs) => jobs.jobs_id === "0")}
            dataKey="id"
            tableStyle={{ maxWidth: "85vw" }}
            rows={15}
            rowsPerPageOptions={[15, 30, 60, 100]}
            scrollHeight="65vh"
            scrollable
            paginator
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate=" {totalRecords} işten {first} - {last} arası gösteriliyor"
          >
            {userData &&
              (userData.type === "1" || userData.is_auth === "1") && (
                <Column
                  style={{ width: "8rem" }}
                  body={(rowData) => accessors(rowData)}
                  exportable={false}
                  header="İşlemler"
                ></Column>
              )}
            <Column field="jobs_no" sortable header="İş Emri No"></Column>
            <Column
              field="title"
              header="Müşteri"
              body={(rowData) => {
                const cust = customers.find(
                  (dept) => dept.id.toString() === rowData.customer_id
                );
                if (cust) {
                  const firstTwoWords =
                    cust.title && cust.title.split(" ").slice(0, 2).join(" ");
                  return firstTwoWords;
                } else {
                  return "Bilinmeyen";
                }
              }}
            ></Column>

            <Column
              field="subject"
              style={{ maxWidth: "10rem" }}
              header="Konu"
            ></Column>
            <Column
              field="desc"
              style={{ maxWidth: "20rem" }}
              header="Açıklama"
            ></Column>
            <Column
              field="priority"
              body={workingBodyTemplate}
              header="Öncelik"
            ></Column>
            <Column
              field="department_name"
              header="Departman"
              sortable
              body={(rowData) => {
                const department = departments.find(
                  (dept) => dept.id.toString() === rowData.department
                );
                return department
                  ? department.department
                  : "Unknown Department";
              }}
            ></Column>
            <Column
              sortable
              field="created_at"
              header="Oluşturma Tarihi"
              body={(rowData) => formatDate(rowData.created_at)}
              style={{ minWidth: "6rem" }}
            ></Column>
          </DataTable>
        </Card>
      </div>
      <Dialog
        visible={addModal}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        style={{ width: "60vw" }}
        header="İş Ekle"
        onHide={hideDialog}
        modal
      >
        <div className="confirmation-content">
          <div style={{ display: "flex" }}>
            <div className="col-6 col-sm-12">
              <div style={{ marginBottom: "1rem" }}>
                <div style={{ display: "flex" }}>
                  <Dropdown
                    filter
                    onChange={(e) => {
                      setCus(e.target.value);
                      const selectedCustomer = customers.find(
                        (customer) => customer.id === e.target.value
                      );
                      const uniqueId = selectedCustomer
                        ? selectedCustomer.unique_id
                        : null;

                      setSelectedCustomerUniqueId(uniqueId);
                      setInputErrors({ ...inputErrors, cus: false });
                    }}
                    options={
                      customers && customers.length > 0
                        ? customers.map((dep) => ({
                            label: dep.title,
                            value: dep.id,
                            unique_id: dep.unique_id,
                          }))
                        : []
                    }
                    value={cus}
                    placeholder="Müşteri Adı / Ünvanı"
                    style={{
                      border: "1px solid gray",
                      width: "100%",
                    }}
                  />

                  <div style={{ width: "0.4rem" }} />
                  <Button className="btn-plus">
                    <Link to="/customer-list">
                      <i
                        className="pi  pi-plus"
                        style={{ fontSize: "1rem" }}
                      ></i>
                    </Link>
                  </Button>
                </div>
                {inputErrors.cus && (
                  <small style={{ color: "red" }}>
                    Lütfen bir müşteri seçin.
                  </small>
                )}
              </div>
              <div
                style={{
                  marginBottom: "1rem",
                }}
              >
                <InputText
                  type="email"
                  className="p-inputtext-lg w-full"
                  placeholder="Konu"
                  onChange={(e) => {
                    setSubject(e.target.value);
                    setInputErrors({ ...inputErrors, subject: false });
                  }}
                  value={subject}
                  style={{
                    width: "100%",
                  }}
                />
                {inputErrors.subject && (
                  <small style={{ color: "red" }}>Lütfen bir konu girin.</small>
                )}
              </div>
              <div
                style={{
                  marginBottom: "1rem",
                }}
              >
                <InputText
                  type="text"
                  className="p-inputtext-lg w-full"
                  placeholder="Açıklama"
                  onChange={(e) => {
                    setDesc(e.target.value);
                    setInputErrors({ ...inputErrors, desc: false });
                  }}
                  value={desc}
                  style={{
                    width: "100%",
                  }}
                />
                {inputErrors.desc && (
                  <small style={{ color: "red" }}>
                    Lütfen bir açıklama girin.
                  </small>
                )}
              </div>
              <Dropdown
                filter
                options={statusType}
                placeholder="Durum"
                defaultValue={type}
                value={jobStatus}
                onChange={(e) => {
                  setStatus(e.value);
                  setInputErrors({ ...inputErrors, jobStatus: false });
                }}
                className="w-full"
                style={{
                  border: "1px solid gray",
                }}
              />
              {inputErrors.jobStatus && (
                <small style={{ color: "red" }}>Lütfen bir durum seçin.</small>
              )}
            </div>

            <div className="col-6 col-sm-12">
              <div
                style={{
                  marginBottom: "1rem",
                }}
              >
                <Dropdown
                  filter
                  options={
                    Array.isArray(departments) && departments.length > 0
                      ? departments.map((dep) => ({
                          label: dep.department,
                          value: dep.id,
                        }))
                      : []
                  }
                  value={dep}
                  style={{
                    border: "1px solid gray",
                    width: "100%",
                  }}
                  placeholder="Departman"
                  onChange={(e) => {
                    setDep(e.target.value);
                    setInputErrors({ ...inputErrors, dep: false });
                  }}
                />
                {inputErrors.dep && (
                  <small style={{ color: "red" }}>
                    Lütfen bir departman seçin.
                  </small>
                )}
              </div>
              <MultiSelect
                filter
                value={emp}
                options={
                  Array.isArray(employees) && employees.length > 0
                    ? employees.map((employ) => ({
                        label: employ.name,
                        value: employ.id,
                      }))
                    : []
                }
                placeholder="Görevliler"
                className="w-full "
                style={{
                  border: "1px solid gray",
                  width: "100%",
                  marginBottom: "1rem",
                }}
                onChange={(e) => {
                  setEmp(e.target.value);
                }}
              />
              <div
                style={{
                  marginBottom: "1rem",
                }}
              >
                <Calendar
                  value={date}
                  onChange={(e) => {
                    setDate(e.value);
                    setInputErrors({ ...inputErrors, date: false });
                  }}
                  dateFormat="dd/mm/yy"
                  placeholder="Teslim Tarihi"
                  locale="tr"
                  style={{
                    border: "1px solid gray",
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "6px 6px 6px 6px",
                  }}
                />
                {inputErrors.date && (
                  <small style={{ color: "red" }}>
                    Lütfen bir tarih seçin.
                  </small>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  label="Ekle"
                  disabled={isButtonDisable}
                  onClick={newCreate}
                  style={{width:"7rem"}}
                  className="btn-excel-two mt-4"
                />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </Flex>
  );
}
