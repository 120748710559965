import React, { useEffect, useState } from "react";
import { Chart } from "primereact/chart";


export default function ViewVisitorChart({viewVisitor}) {
  const [newViewVisitor, setNewViewVisitor] = useState({});
  const [viewVisitorOptions, setViewVisitorOptions] = useState({});
  const months = [
    { name: "Ocak", value: "01" },
    { name: "Şubat", value: "02" },
    { name: "Mart", value: "03" },
    { name: "Nisan", value: "04" },
    { name: "Mayıs", value: "05" },
    { name: "Haziran", value: "06" },
    { name: "Temmuz", value: "07" },
    { name: "Ağustos", value: "08" },
    { name: "Eylül", value: "09" },
    { name: "Ekim", value: "10" },
    { name: "Kasım", value: "11" },
    { name: "Aralık", value: "12" },
  ];

 const DataTypes = [
   { name: "Görüntüleme", value: "1" },
   { name: "Kullanıcılar", value: "2" },
   { name: "Etkinlik", value: "3" },
 ];

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
      },
    };
    setViewVisitorOptions(options);
  }, []);

  useEffect(() => {

        const documentStyle = getComputedStyle(document.documentElement);
        const orderedLabels = ["1", "2", "3"];
        const datasets = orderedLabels?.map((label) => {
          const name = DataTypes?.find((m) => m.value === label);
          const filteredData = viewVisitor?.filter(
            (item) => item.label === label
          );
          const values = months?.map((month) => {
            const dataItem = filteredData?.find(
              (item) => item?.month === month?.value
            );
            return dataItem ? parseInt(dataItem?.value) : null;
          });
          return {
            label: name ? name.name : "",
            data: values,
            fill: false,
            tension: 0.4,
            borderColor: documentStyle.getPropertyValue(
              name && name.value === "1"
                ? "--blue-500"
                : name && name.value === "2"
                ? "--teal-500"
                : "--orange-500"
            ),
          };
        });
        const newData = { labels: months.map((month) => month.name), datasets };
        setNewViewVisitor(newData);
 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewVisitor]);

  return (
    <div>
      <Chart
        style={{ width: "30rem", height: "20rem" }}
        type="line"
        data={newViewVisitor}
        options={viewVisitorOptions}
      />
    </div>
  );
}
