import {
  Box,
  Card,
  CardBody,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  TabPanel,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import Waiting from "./components/Waiting";
import Completed from "./components/Completed";
import { useDispatch } from "react-redux";
import { addTaskData } from "../../../redux/TaskSlice";
import { addCustomerData } from "../../../redux/CustomerSlice";
import { SupportService } from "../../../core/Services";
import { BreadCrumb } from "primereact/breadcrumb";

export default function SupportPage() {
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const taskData = await SupportService();
        dispatch(addTaskData(taskData.supports));
        dispatch(addCustomerData(taskData.customers));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [dispatch]);
  const getTurkishLabel = (url) => {
    switch (url) {
      case "/support":
        return "Destek";
      // Diğer durumlar için gerektiğinde eşleme yapabilirsiniz
      default:
        return "Sayfa"; // Varsayılan olarak "Sayfa" kullanılabilir veya istediğiniz bir şeyi kullanabilirsiniz
    }
  };

  const url = "/support";
  const turkishLabel = getTurkishLabel(url); // "Ürünler"

  const itemsBread = [{ label: turkishLabel, url: url }];
  const home = { icon: "pi pi-home", url: "/dashboard" };
  return (
    <Box mt="5">
      <Card>
        {" "}
        <BreadCrumb
          model={itemsBread}
          home={home}
          style={{ padding: "1rem" }}
        />
        <CardBody>
          <Tabs align="end" variant="enclosed">
            <TabList>
              <Tab>Bekleyen</Tab>
              <Tab>Tamamlanan</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Waiting />
              </TabPanel>
              <TabPanel>
                <Completed />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </CardBody>
      </Card>
    </Box>
  );
}
