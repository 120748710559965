import React from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import LoginPages from "./LoginPages";
import Image from "../../../_ekdizayn/img/14.png";

function LoginSplit() {
  const shouldShowImage = () => {
    return window.innerWidth >= 768;
  };

  return (
    <Grid
      templateColumns={{ base: "1fr", lg: "1fr 9fr" }}
      h={{ base: "100%", lg: "100vh" }}
    >
      {/* Sol Taraf - Giriş Sayfası */}
      <GridItem>
        <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
          <LoginPages />
        </div>
      </GridItem>

      {/* Sağ Taraf - Resim */}
      <GridItem overflow="hidden">
        {shouldShowImage() && (
          <img
            alt="Arkaplan Resmi"
            src={Image}
            className="w-100 h-100 object-cover"
            style={{ width: "100%", height: "100%", maxHeight: "100vh" }}
          />
        )}
      </GridItem>
    </Grid>
  );
}

export default LoginSplit;
