
import { InputTextarea } from 'primereact/inputtextarea'
import React, { useState } from 'react'
import { InputNumber } from "primereact/inputnumber";
        
export default function EmployeesPaymentMain() {
    const[header , setHeader] = useState("")
    const [body, setBody] = useState("");
    const [footer, setFooter] = useState("");
    const [totalAmount, setTotalAmount] = useState("");
    
  return (
    <div className="content">
      <div className="card row" style={{ display: "flex" }}>
        <div className="col-6">
          <strong className="font-bold text-xl mt-4">
            Başlık Kısmını Giriniz:
          </strong>
          <div>
            <InputTextarea
              style={{ width: "30rem", height: "10rem" }}
              value={header}
              onChange={(e) => setHeader(e.target.value)}
              autoResize
              rows={5}
              cols={30}
            />
          </div>
        </div>
        <div className="col-6">
          <strong className="font-bold text-xl mt-4">
            Gövde Kısmını Giriniz:
          </strong>
          <div>
            <InputTextarea
              style={{ width: "30rem", height: "10rem" }}
              value={body}
              onChange={(e) => setBody(e.target.value)}
              autoResize
              rows={5}
              cols={30}
            />
          </div>
        </div>
      </div>
      <div className="card row" style={{ display: "flex" }}>
        <div className="col-6">
          <strong className="font-bold text-xl mt-4">
            Alt Kısmını Giriniz:
          </strong>
          <div>
            <InputTextarea
              style={{ width: "30rem", height: "10rem" }}
              value={footer}
              onChange={(e) => setFooter(e.target.value)}
              autoResize
              rows={5}
              cols={30}
            />
          </div>
        </div>
        <div className="col-6">
          <div>
            <strong className="font-bold text-xl mt-4">Genel Bilgiler</strong>
            <div className="flex-auto">
              <label htmlFor="currency-turkey" className="font-bold block mb-2">
                Ödenecek Tutar (TL):
              </label>
              <InputNumber
                style={{ border: "2px solid teal", borderRadius: "0.2rem" }}
                inputId="currency-turkey"
                value={totalAmount}
                onValueChange={(e) => setTotalAmount(e.value)}
                mode="currency"
                currency="TRY"
                locale="tr-TR"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
