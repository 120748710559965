import React, { useEffect, useState } from "react";
import CustomerSupportForm from "./components/customer-support-form/CustomerSupportForm";
import CustomerSupportDescription from "./components/customer-support-description/CustomerSupportDescription";
import CustomerSupportFormPage from "./components/customer-support-form/CustomerSupportFormPage";
import { CustomerPanelByUniqueId } from "../../../../../core/Services";
import { useAuth } from "../../../../../auth/core/AuthContext";

export default function CustomerSupportPage() {
  const [customer, setCustomer] = useState([]);
  const { userData } = useAuth();

  useEffect(() => {
    if (userData?.unique_id) {
      CustomerPanelByUniqueId(userData?.unique_id)
        .then((data) => {
          setCustomer(data);
        })
        .catch((error) => {
          console.log("Customer Hatası", error);
        });
    }
  }, [userData?.unique_id]);

  return (
    <div
      style={{
        overflowY: "scroll",
        overflowX: "hidden",
        height: "100vh",
        width: "auto",
      }}
    >
      <div>
        <div className="flex align-items-center border-bottom-1 surface-border surface-overlay w-full">
          <p class="text-5xl w-10  p-1 mt-1 text-orange-500 ml-1 font-futura">
            Yeni Destek Talebi
          </p>
        </div>
      </div>
      <div
        style={{ marginTop: "6rem" }}
        className="flex justify-content-start ml-2 font-normal text-xl"
      >
        Sorunlarınızın çözümünü Bilgi Merkezi'nde bulamadıysanız aşağıda ilgili
        departmanı seçerek destek için bizlere bildirim gönderebilirsiniz
      </div>
      <div
        className="confirmation-content mt-8 "
        style={{ marginLeft: "1rem" }}
      >
        <div className=" jc-space-around flex align-item-center">
          <div
            className="col-6"
            style={{ display: "inline-block", marginRight: "1rem" }}
          >
            <CustomerSupportForm customer={customer} />
          </div>
          <div className="col-6" style={{ display: "inline-block" }}>
            <CustomerSupportDescription />
          </div>
        </div>
      </div>
      <div>
        <CustomerSupportFormPage customer={customer} />
      </div>
    </div>
  );
}
