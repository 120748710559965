import { createSlice } from "@reduxjs/toolkit";

export const CustomerSlice = createSlice({
  name: "CustomerSlice",
  initialState: {
    value: null,
  },
  reducers: {
    addCustomerData: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { addCustomerData } = CustomerSlice.actions;
export default CustomerSlice.reducer;
