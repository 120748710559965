import React, { useEffect, useState } from "react";
import { Card, CardHeader, Flex, Heading } from "@chakra-ui/react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SmsService } from "../../../../core/Services";

export default function Reports() {
  const [sms, setSms] = useState([]);
  const [guide, setGuide] = useState([]);
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    return new Date(dateString).toLocaleString("tr-TR", options);
  };

  useEffect(() => {
    SmsService()
      .then((data) => {
        setSms(data?.sms);
        setGuide(data?.guide);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  }, []);
  const getStatusInfo = (status) => {
    let statusLabel, statusColor;

    switch (status) {
      case "0":
        statusLabel = "Başarısız";
        statusColor = "red";
        break;
      case "1":
        statusLabel = "Başarılı";
        statusColor = "green";
        break;

      default:
        statusLabel = "Bilinmeyen Durum";
        statusColor = "black";
    }

    return { statusLabel, statusColor };
  };
  return (
    <Card p={2} className="w-full">
      <CardHeader>
        <Flex justifyContent="space-between" alignItems="center">
          <Heading size="md">SMS Raporu</Heading>
        </Flex>
      </CardHeader>
      <DataTable
        stripedRows
        size="small"
        value={sms}
        dataKey="id"
        tableStyle={{ minWidth: "50rem" }}
        rows={15}
        rowsPerPageOptions={[15, 30, 60, 100]}
        scrollable
        paginator
        selectionMode="single"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate=" {totalRecords} duyurudan {first} - {last} arası gösteriliyor"
      >
        <Column
          field="sent_to"
          body={(rowData) => {
            const guide_person = guide?.find(
              (emp) => emp.id === Number(rowData.sent_to)
            );
            return guide_person
              ? guide_person.name + " " + guide_person.surname
              : rowData?.sent_to;
          }}
          header="Gönderilen Kişi"
        ></Column>
        <Column field="message" header="Mesaj" style={{ minWidth: "8rem" }} />
        <Column
          field="status"
          body={(rowData) => {
            const { statusLabel, statusColor } = getStatusInfo(rowData?.status);
            return (
              <span
                style={{
                  backgroundColor: statusColor,
                  color: "white",
                  padding: "0.5rem",
                  borderRadius: "4px",
                }}
              >
                {statusLabel}
              </span>
            );
          }}
          header="Durum"
        ></Column>

        <Column
          field="created_at"
          body={(rowData) => formatDate(rowData.created_at)}
          header="Tarih"
        ></Column>
      </DataTable>
    </Card>
  );
}
