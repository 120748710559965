import React from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useToast } from "@chakra-ui/react";
import {  DeleteServices } from "../../../../core/Services";
export default function DeleteDialog({
  deleteServices,
  setDeleteServices,
  form,
  setForm,
  selectedId,
  setSelectedId,
}) {
  const hideDialog = () => {
    setDeleteServices(false);
    setForm(null);
  };
  const toast = useToast();

  const handleDelete = () => {
    DeleteServices(selectedId)
      .then((response) => {
        if (response.message === "Hata") {
          toast({
            title: "Hata",
            description: "Kullanıcya Ait Veri  Mevcut",
            status: "error",
            duration: 3000,
            position: "top-right",
            isClosable: true,
          });
        } else {
          toast({
            title: "Başarılı",
            description: "Veri başarılı bir şekilde kaydedildi.",
            status: "success",
            duration: 3000,
            position: "top-right",
            isClosable: true,
          });
          setForm(null);
          setSelectedId("");
          setDeleteServices(false)
        }
      })
      .catch((error) => {
        console.error("Backend'e gönderme hatası:", error);
        toast({
          title: "Hata",
          description: "Veri gönderilirken bir hata oluştu.",
          status: "error",
          duration: 3000,
          position: "top-right",
          isClosable: true,
        });
      });
  };
  const deleteButton = () => {
    return (
      <Button
        onClick={handleDelete}
        style={{ width: "9rem" }}
        label="Sil"
        className="btn-excel-two"
      />
    );
  };

  return (
    <Dialog
      visible={deleteServices}
      footer={deleteButton}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      style={{ width: "60vw" }}
      header="Hizmet Sil"
      onHide={hideDialog}
      modal
    >
      <div className="confirmation-content">
        <div
          className="font-bold mb-2"
          style={{ display: "flex", justifyContent: "center", color: "red" }}
        >
          <p>Hizmeti Silmek İstediğinize Emin Misiniz?</p>
        </div>

        <div className="field">
          <label htmlFor="name" className="font-bold">
            Hizmet Türü: {form?.servicesType}
          </label>
        </div>
        <div className="field">
          <label htmlFor="name" className="font-bold">
            Hizmet Adı: {form?.servicesName}
          </label>
        </div>
        <div className="field">
          <label htmlFor="name" className="font-bold">
            Stok: {form?.stock}
          </label>
        </div>
        <div className="field">
          <label htmlFor="name" className="font-bold">
            KDV Oranı: {form?.vatRate}
          </label>
        </div>
      </div>
    </Dialog>
  );
}
