import {
  Avatar,
  chakra,
  HStack,
  Stack,
  Flex,
  Box,
  Text,
} from "@chakra-ui/react";
import { DeliveredIcon } from "../assets/icons";
export function Chat({
  name,
  message,
  date,
  seen,
  onClick,
  src,
  style,
  ...rest
}) {
  return (
    <HStack
      _hover={{
        cursor: "pointer",
        backgroundColor: "#a1ac97",
      }}
      py="3"
      color="white"
      onClick={onClick}
      style={style}
      {...rest}
    >
      <Avatar mx="3" name={name} src={src} />
      <Box flex="1" pr="4">
        <Flex justify="space-between" align="baseline">
          <Box>
            <Text fontWeight="medium">{name}</Text>
            <HStack>
              <DeliveredIcon color={Number(seen) ? "#2afc11" : "#f50015"} />
              <Text color="#white" fontSize="sm">
                {message && message.length > 20
                  ? `${message.substring(0, 20)}...`
                  : message}
              </Text>
            </HStack>
          </Box>
          <chakra.time fontSize="xs" color="#white">
            {date}
          </chakra.time>
        </Flex>
      </Box>
    </HStack>
  );
}

export function ChatList({
  onUserSelect,
  users,
  searchText,
  messages,
  userData,
  ...props
}) {
  const filteredUsers = searchText
    ? users.filter((user) =>
        user.name.toLowerCase().includes(searchText.toLowerCase())
      )
    : users;

    const newMessageUserId = messages.length > 0 ? messages[messages.length - 1].outgoing_msg_id : null;

    // Yeni mesaj atan kullanıcının listedeki index'ini bulur
    const newUserIndex = newMessageUserId ? filteredUsers.findIndex(user => user.unique_id === newMessageUserId) : -1;
    
    // Eğer yeni mesaj atan bir kullanıcı bulunduysa ve listede görünüyorsa, onu listenin başına taşır
    if (newUserIndex !== -1) {
      const newUser = filteredUsers.splice(newUserIndex, 1)[0];
      filteredUsers.unshift(newUser);
    }
  return (
    <Stack
      spacing="0"
      pr="1"
      style={{ scrollbarColor: "white transparent" }}
      // divider={<StackDivider w="82%" alignSelf="flex-end" />}
      {...props}
    >
       {filteredUsers.map((item, index) => {
      // userData.unique_id ile item.unique_id eşitse döngüden çık
      if (userData?.unique_id === item.unique_id) {
        return null;
      }

      const filteredMessages = messages.filter(
        (msg) => (msg.incoming_msg_id === userData?.unique_id && msg.outgoing_msg_id === item?.unique_id) || (msg.incoming_msg_id === item?.unique_id && msg.outgoing_msg_id === userData?.unique_id)
      );

      const lastMessage =
        filteredMessages.length > 0
          ? filteredMessages[filteredMessages.length - 1].message
          : null;

      const shortenedMessage =
        lastMessage && lastMessage.length > 20
          ? `${lastMessage.substring(0, 20)}...`
          : lastMessage;

      return (
        <Chat
          key={index}
          src={`data:image/png;base64,${item.image}`}
          date={item.last_seen}
          message={shortenedMessage}
          name={item.name}
          seen={item.status}
          onClick={() => onUserSelect(item)}
        />
      );
      })}
    </Stack>
  );
}
