import React from 'react'
import Sidebar from '../../../../_ekdizayn/layout/Sidebar/Sidebar'
import NavBar from '../../../../_ekdizayn/layout/Navbar/components/Header'
import { Grid, GridItem } from '@chakra-ui/react'
import SupportPage from './SupportPage'




export default function Support() {
  return (
    <Grid
      templateAreas={`"header header"
                  "nav main"
                 `}
      gridTemplateRows={'auto 1fr '}
      gridTemplateColumns={'auto 1fr'}
      h='auto'
      color='blackAlpha.700'
      fontWeight='bold'
    >
      <GridItem area={'header'}>
        <NavBar />
      </GridItem>
      <GridItem area={'nav'}>
        <Sidebar />
      </GridItem>
      <GridItem area={'main'}>
        <SupportPage />
      </GridItem>
    </Grid>
  )
}
