import React, { useState } from "react";
import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
} from "@chakra-ui/react";
import { FaLock, FaEnvelope } from "react-icons/fa";
import { useAuth } from "../core/AuthContext";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import BackgroundImage from "../../../_ekdizayn/background/desen.png";

const LoginPages = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { login } = useAuth();
  const [showPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const handleLogin = async (e) => {
    e.preventDefault();

    const user = await login(email, password);
    if (user?.user?.authorization === "0") {
      navigate("/dashboard");
       toast({
         title: "Giriş Başarılı",
         description: "Sayfaya Yönlendiriliyorsunuz.",
         status: "success",
         duration: 3000,
         isClosable: true,
         position: "top-right",
       });
    } else if (user?.user?.authorization === "1") {
      navigate("/customer/detail");
       toast({
         title: "Giriş Başarılı",
         description: "Sayfaya Yönlendiriliyorsunuz.",
         status: "success",
         duration: 3000,
         isClosable: true,
         position: "top-right",
       });
    } else {
      toast({
        title: "Giriş Başarısız",
        description: "Mail  ya da Parola Hatalı",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <Flex
      backgroundImage={BackgroundImage}
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="white"
      justifyContent="center"
      alignItems="center"
    >
      <Heading
        fontSize="xl"
        fontStyle="normal"
        textAlign="center"
        color="black"
      >
        EK CLOUD <br />
        Yönetim Sistemi
      </Heading>

      <Stack
        flexDir="column"
        spacing={8}
        mb="4"
        justifyContent="center"
        alignItems="center"
        p={10}
        borderRadius="25px"
        boxShadow="10px"
      >
        <Box width="100%">
          <form onSubmit={handleLogin}>
            <Stack spacing={4} width="100%">
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<FaEnvelope color="gray.300" />}
                />
                <Input
                  type="email"
                  width="80"
                  placeholder="E-posta"
                  borderRadius="2px"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </InputGroup>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  color="gray.300"
                  children={<FaLock color="gray.300" />}
                />
                <Input
                  type={showPassword ? "text" : "password"}
                  placeholder="Şifre"
                  width="80"
                  borderRadius="2px"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </InputGroup>
              <chakra.h6
                style={{ textAlign: "center", textDecoration: "underline" }}
              >
                Şifremi Unuttum
              </chakra.h6>
              <br />
              <Button
                borderRadius="5px"
                color="white"
                type="button"
                onClick={handleLogin}
                variant="solid"
                bg="black"
                width="80"
                _hover="no"
              >
                Giriş Yap
              </Button>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
};

export default LoginPages;
