import React, { useEffect, useState, useMemo } from "react";
import { Flex } from "@chakra-ui/react";
import { FiCheck } from "react-icons/fi";
import NavItem from "../NavItem/NavItem";
import { BsActivity } from "react-icons/bs";
import { BsPencilSquare } from "react-icons/bs";
import { BsFillPeopleFill } from "react-icons/bs";
import { BsPersonWorkspace } from "react-icons/bs";
import { BsHouse } from "react-icons/bs";
import { IoIosSettings } from "react-icons/io";
import { BsFillTagsFill } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../../../app/auth/core/AuthContext";
import { IoRocket } from "react-icons/io5";
export default function SidebarPage() {
  const [navSize] = useState("large");
  const { userData } = useAuth();
  const [activeNavItem, setActiveNavItem] = useState(""); // Aktif bağla
  const location = useLocation();

  const subItemsCustomer = useMemo(
    () => {
      const items = [
        { to: "/marketingChase", title: "Seyyar" },
        { to: "/talepler", title: "Talepler" },
        { to: "/customer-list", title: "Müşteriler" },
      ];

      if (userData?.type === "1") {
        items.push(
          { to: "/offers", title: "Teklifler" },
          { to: "/orders", title: "Siparişler" }
        );
      }
      return items;
    },
    [userData?.type] // useMemo'nun bağımlılıkları buraya eklenmeli
  );

  const subItemsOperation = useMemo(
    () => [
      { to: "/support", title: "Destek" },
      { to: "/tasks", title: "Görev" },
      { to: "/jobs", title: "İşlerim" },
    ],
    []
  );

  const subItemsProduction = useMemo(() => {
    const items = [
      { to: "/tally", title: "Çetele" },
      { to: "/guarantee", title: "Garanti" },
    ];

    if (userData?.type === "1") {
      items.push(
        { to: "/products", title: "Ürünler" },
        { to: "/manufacturing", title: "Üretim" },
        { to: "/services", title: "Hizmetler" }
      );
    }

    return items;
  }, [userData?.type]);

  const subItemsEmployees = useMemo(() => {
    const items = [
      { to: "/permissions", title: "İzinler" },
      { to: "/my-files", title: "Evraklar" },
      { to: "/costs", title: "Masraflar" },
      { to: "/announcement", title: "Duyurular" },
    ];

    if (userData?.type === "1") {
      items.push(
        { to: "/employees-list", title: "Personel" },
        { to: "/supplier-add", title: "Tedarikçi" },
        { to: "/departments", title: "Departmanlar" }
      );
    }

    return items;
  }, [userData?.type]);

  const subItemsMarketing = useMemo(
    () => [
      { to: "/sms", title: "Sms Gönder" },
      { to: "/email", title: "Email Gönder" },
    ],
    []
  );
  const subItemsPanel = useMemo(
    () => [
      { to: "/customer/detail", icon: FiCheck, title: "Müşteri Paneli" },
      { to: "#", icon: FiCheck, title: "Faaliyet Raporları" },
      { to: "#", icon: FiCheck, title: "Ödeme İşlemleri" },
    ],
    []
  );
  const subItemsSettings = useMemo(
    () => [{ to: "/settings", icon: FiCheck, title: "Ayarlar" }],
    []
  );

  useEffect(() => {
    if (checkToValue(subItemsCustomer, location.pathname)) {
      handleNavItemClick("Satış");
    } else if (checkToValue(subItemsOperation, location.pathname)) {
      handleNavItemClick("Operasyon");
    } else if (checkToValue(subItemsEmployees, location.pathname)) {
      handleNavItemClick("İdari İşler");
    } else if (checkToValue(subItemsProduction, location.pathname)) {
      handleNavItemClick("Planlama");
    } else if (checkToValue(subItemsPanel, location.pathname)) {
      handleNavItemClick("Panel");
    } else if (checkToValue(subItemsSettings, location.pathname)) {
      handleNavItemClick("Ayarlar");
    } else if (checkToValue(subItemsMarketing, location.pathname)) {
      handleNavItemClick("Pazarlama");
    } else {
      handleNavItemClick("Ana Sayfa");
    }
  }, [
    location.pathname,
    subItemsCustomer,
    subItemsEmployees,
    subItemsOperation,
    subItemsPanel,
    subItemsProduction,
    subItemsMarketing,
    subItemsSettings,
  ]);

  const checkToValue = (items, targetTo) => {
    return items.some((item) => item.to === targetTo);
  };

  const handleNavItemClick = (title) => {
    setActiveNavItem(title);
  };

  return (
    <Flex
      pos="sticky"
      backgroundColor="#f5f5f5"
      h="100vh"
      mt="5"
      boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
      borderRadius={navSize === "small" ? "0" : "0"}
      w={navSize === "small" ? "120px" : "200px"}
      flexDir="column"
      justifyContent="space-between"
      overflowY="auto"
      maxHeight="100%"
    >
      <Flex
        p="5%"
        flexDir="column"
        w="100%"
        alignItems={navSize === "small" ? "center" : "flex-start"}
        as="nav"
      >
        <button
          className="w-full"
          onClick={() => handleNavItemClick("Ana Sayfa")}
        >
          <NavItem
            navSize={navSize}
            icon={BsHouse}
            title="Ana Sayfa"
            to="/dashboard"
            active={activeNavItem === "Ana Sayfa"}
          />
        </button>

        <button
          className="w-full"
          onClick={() => handleNavItemClick("Operasyon")}
        >
          <NavItem
            navSize={navSize}
            icon={BsActivity}
            title="Operasyon"
            subItems={subItemsOperation}
            active={activeNavItem === "Operasyon"}
          />
        </button>

        <button className="w-full" onClick={() => handleNavItemClick("Satış")}>
          <NavItem
            navSize={navSize}
            icon={BsFillTagsFill}
            className="w-full"
            title="Satış"
            subItems={subItemsCustomer}
            active={activeNavItem === "Satış"}
          />
        </button>

        <button
          className="w-full"
          onClick={() => handleNavItemClick("Planlama")}
        >
          <NavItem
            navSize={navSize}
            icon={BsPencilSquare}
            title="Planlama"
            active={activeNavItem === "Planlama"}
            subItems={subItemsProduction}
          />
        </button>

        <button
          className="w-full"
          onClick={() => handleNavItemClick("Pazarlama")}
        >
          <NavItem
            navSize={navSize}
            active={activeNavItem === "Pazarlama"}
            icon={IoRocket}
            title="Pazarlama"
            subItems={subItemsMarketing}
          />
        </button>

        <button
          className="w-full"
          onClick={() => handleNavItemClick("İdari İşler")}
        >
          <NavItem
            navSize={navSize}
            icon={BsFillPeopleFill}
            active={activeNavItem === "İdari İşler"}
            title="İdari İşler"
            subItems={subItemsEmployees}
          />
        </button>
        <button
          className="w-full"
          onClick={() => handleNavItemClick("Ayarlar")}
        >
          <NavItem
            navSize={navSize}
            active={activeNavItem === "Ayarlar"}
            icon={IoIosSettings}
            title="Ayarlar"
            to="/settings"
          />
        </button>
        <button className="w-full" onClick={() => handleNavItemClick("Panel")}>
          <NavItem
            navSize={navSize}
            active={activeNavItem === "Panel"}
            icon={BsPersonWorkspace}
            title="Panel"
            to="/panel/customers/add"
          />
        </button>
      </Flex>
    </Flex>
  );
}
