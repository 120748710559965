import React, { useState } from "react";
import { Splitter, SplitterPanel } from "primereact/splitter";
import AddDataPage from "./components/AddDataPage";
import TablePage from "./components/TablePage";
import ChartPage from "./components/ChartPage";


export default function SocialMedia({
  uniqueCustomerId,
  selectedMonth,
  selectedCustomer,
  months,
}) {
  const [tableKey, setTableKey] = useState(0);


  const updateTableData = () => {
    setTableKey((prevKey) => prevKey + 1);
    
  };
  const DataTypes = [
    { name: "Instagram", value: "1" },
    { name: "Facebook", value: "2" },
    { name: "Youtube", value: "3" },
    { name: "Linkedin", value: "4" },
  ];

  return (
    <Splitter style={{ height: "auto" }}>
      <SplitterPanel size={25} style={{ display: "block", padding: "1rem" }}>
        <AddDataPage
          updateTableData={updateTableData}
          selectedMonth={selectedMonth}
          selectedCustomer={selectedCustomer}
          uniqueCustomerId={uniqueCustomerId}
          DataTypes={DataTypes}
        />
      </SplitterPanel>
      <SplitterPanel size={75} style={{ display: "block", padding: "1rem" }}>
        <TablePage
          key={tableKey}
          updateTableData={updateTableData}
          months={months}
          DataTypes={DataTypes}
          uniqueCustomerId={uniqueCustomerId}
        />
        <br></br>
        <ChartPage
          
          months={months}
          DataTypes={DataTypes}
          uniqueCustomerId={uniqueCustomerId}
        />
      </SplitterPanel>
    </Splitter>
  );
}
