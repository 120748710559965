import React, { useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";

const PrivateRoutes = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuthentication = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        setIsLoggedIn(true);
      }
      setLoading(false);
    };

    checkAuthentication();
  }, []);


  if (isLoading) {
    return null;
  }

  return isLoggedIn ? <Outlet /> : <Navigate to="/" replace={true} />;
};

export default PrivateRoutes;
