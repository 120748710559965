import React from 'react'
import Website from '../../web-site/Website'
import SocialMedia from '../../social-media/SocialMedia'
import Maps from '../../maps/Maps'
import AdsReport from '../../../ads/AdsReport'
import Security from '../../security/Security'
import BenefitsReport from '../../benefits/BenefitsReport'

export default function OverviewPage() {
  return (
    <div className='grid'>
      <div className='col-12'>
        <Website />
      </div>
      <div className='col-12'>
        <SocialMedia />
      </div>
      <div className='col-12'>
        <Maps />
      </div>
      <div className='col-12'>
        <AdsReport />
      </div>
      <div className='col-12'>
        <Security />
      </div>
      <div className='col-12'>
        <BenefitsReport />
      </div>
    </div>
  );
}
