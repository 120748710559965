import React, {  useState } from "react";
import { Button, Input, useToast } from "@chakra-ui/react";
import { Dropdown } from "primereact/dropdown";
import { AddAgeGraph
} from "../../../../../../../../core/Services";
import { Message } from "primereact/message";

export default function AgeGraphPage({
  uniqueCustomerId,
  selectedMonth,
  selectedCustomer,
  DataTypes,
  updateTableData
}) {

  const [ageGraph, setAgeGraph] = useState("");
  const [google, setGoogle] = useState("");
  const [yandex, setYandex] = useState("");
  const [other, setOther] = useState("");
  const [error, setError] = useState(false);
  const toast = useToast();


  const handleSave = () => {
    if (
      !selectedCustomer ||
      !selectedMonth ||
      !ageGraph ||
      !google ||
      !yandex ||
      !other
    ) {
      setError(true);
      toast({
        title: "Hata",
        description: "Lütfen tüm alanları doldurunuz.",
        status: "error",
        duration: 3000,
        position: "top-right",
        isClosable: true,
      });
      return;
    }
    const value = Number(google) + Number(yandex) + Number(other);
    AddAgeGraph(uniqueCustomerId, ageGraph, value, selectedMonth)
      .then((response) => {
        if (response.message === "Hata") {
          toast({
            title: "Hata",
            description: "Kullanıcya Ait Bu Ay Verisi Zaten Mevcut",
            status: "error",
            duration: 3000,
            position: "top-right",
            isClosable: true,
          });
        } else {
          setAgeGraph("");
          setGoogle("");
          setYandex("");
          setOther("");
          setError(false);

          toast({
            title: "Başarılı",
            description: "Veri başarılı bir şekilde kaydedildi.",
            status: "success",
            duration: 3000,
            position: "top-right",
            isClosable: true,
          });
          updateTableData();
        }
      })
      .catch((error) => {
        console.error("Backend'e gönderme hatası:", error);
        toast({
          title: "Hata",
          description: "Veri gönderilirken bir hata oluştu.",
          status: "error",
          duration: 3000,
          position: "top-right",
          isClosable: true,
        });
      });
  };

  return (
    <div>
      <div className="font-bold text-xl mt-4">
        Not:(Sadece Google ya da Yandex verisi giriniz. Girmediğiniz veriye 0
        değerini girin.)
      </div>
      <div className="font-bold text-xl mt-5">Veri Adı : </div>
      <div className="mt-2">
        <Dropdown
          style={{
            border: `2px solid ${error && !ageGraph ? "red" : ""}`,
            borderRadius: "1rem",
            width: "30rem",
          }}
          value={ageGraph}
          options={DataTypes.map((type) => ({
            label: type.name,
            value: type.value,
          }))}
          onChange={(e) => setAgeGraph(e.value)}
          placeholder="Veri Adı Seçiniz"
        />
      </div>

      <div className="font-bold text-m mt-5">
        Google Platformu Değeri Giriniz :{" "}
      </div>
      <div>
        <Input
          placeholder="Google Değeri Giriniz"
          w="30rem"
          name="google"
          value={google}
          onChange={(e) => setGoogle(e.target.value)}
          borderColor={error && !google ? "red" : ""}
        />
      </div>

      <div className="font-bold text-m mt-5">
        Yandex Platformu Değeri Giriniz :{" "}
      </div>
      <div>
        <Input
          placeholder="Yandex Değeri Giriniz"
          w="30rem"
          name="yandex"
          value={yandex}
          onChange={(e) => setYandex(e.target.value)}
          borderColor={error && !yandex ? "red" : ""}
        />
      </div>

      <div className="font-bold text-m mt-5">
        Diğer Platform Değerini Giriniz :{" "}
      </div>
      <div>
        <Input
          placeholder="Diğer Değeri Giriniz"
          w="30rem"
          name="diger"
          value={other}
          onChange={(e) => setOther(e.target.value)}
          borderColor={error && !other ? "red" : ""}
        />
      </div>

      <div className="mt-5 flex justify-content-end">
        <Button onClick={handleSave}> Kaydet</Button>
      </div>
      <Message
        className="mt-4 w-full"
        severity="info"
        text="Google Analytics > Raporlar > Kullanıcı > Demografik Grup Ayrıntıları"
      />
    </div>
  );
}
