import React, { useState } from "react";
import Page from "./components/Page";
import { AddCustomerForm } from "../../core/Services";
import { useToast } from "@chakra-ui/react";
export default function CustomerQuotation() {
  const toast = useToast();
  const [packages, setPackages] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [selectedCookies, setSelectedCookies] = useState([]);
  const [selectedUrgency, setSelectedUrgency] = useState(null);
  const [selectedCounrtyPhone, setSelectedCounrtyPhone] = useState(null);
  const [form, setForm] = useState({
    name: "",
    surname: "",
    email: "",
    phone: "",
    description: "",
  });
  const [errors, setErrors] = useState({});
  const checkValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleSave = () => {
    AddCustomerForm(
      form,
      selectedUrgency,
      selectedCookies,
      selectedCounrtyPhone,
      selectedPackages
    )
      .then((response) => {
        if (response.message === "Hata") {
          toast({
            title: "Hata",
            description: "Kullanıcya Ait Veri  Mevcut",
            status: "error",
            duration: 3000,
            position: "top-right",
            isClosable: true,
          });
        } else {
          setForm({
            name: "",
            surname: "",
            email: "",
            phone: "",
            description: "",
          });
          setSelectedPackages(null);
          setSelectedUrgency("");
          setSelectedCookies("");
          setSelectedCounrtyPhone("");

          toast({
            title: "Başarılı",
            description: "Veri başarılı bir şekilde kaydedildi.",
            status: "success",
            duration: 3000,
            position: "top-right",
            isClosable: true,
          });
        }
      })
      .catch((error) => {
        console.error("Backend'e gönderme hatası:", error);
        toast({
          title: "Hata",
          description: "Veri gönderilirken bir hata oluştu.",
          status: "error",
          duration: 3000,
          position: "top-right",
          isClosable: true,
        });
      });
  };
  const onSubmitForm = () => {
    const inputErrors = {};
    if (!form.name) {
      inputErrors.name = "Ad alanı boş bırakılamaz";
    }
    if (!form.surname) {
      inputErrors.surname = "Soyad alanı boş bırakılamaz";
    }
    if (!form.email) {
      inputErrors.email = "E-posta alanı boş bırakılamaz";
    } else if (!checkValidEmail(form.email)) {
      inputErrors.email = "Geçersiz e-posta adresi";
    }
    if (!form.phone) {
      inputErrors.phone = "Telefon alanı boş bırakılamaz";
    } else if (!/^\d{10}$/.test(form.phone)) {
      inputErrors.phone = "Telefon numarası 10 haneli olmalıdır";
    }

    if (!selectedUrgency) {
      inputErrors.selectedUrgency = "Aciliyet durumu seçilmelidir";
    }
    const cookieArray = Array.isArray(selectedCookies) ? selectedCookies : [];
    const idCookieSelected = cookieArray?.find((cookie) => cookie === "0");
    if (!idCookieSelected) {
      inputErrors.selectedCookies = "Lütfen Çerez Politikasını  onaylayınız.";
    }

    if (Object.keys(inputErrors).length > 0) {
      setErrors(inputErrors);
    } else {
      setErrors({});
      handleSave();
    }
  };

  return (
    <div>
      <style>
        {`
          body {
            margin: 0;
            background: linear-gradient(169deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(22,106,123,1) 100%);
            font-family: "Euclid Circular A", "Poppins";
            color: #d6dfed;
          }
        `}
      </style>

      <div className="flex justify-content-center mt-8">
        <Page
          errors={errors}
          setErrors={setErrors}
          onSubmitForm={onSubmitForm}
          selectedCookies={selectedCookies}
          setSelectedCookies={setSelectedCookies}
          selectedCounrtyPhone={selectedCounrtyPhone}
          setSelectedCounrtyPhone={setSelectedCounrtyPhone}
          form={form}
          setForm={setForm}
          selectedUrgency={selectedUrgency}
          setSelectedUrgency={setSelectedUrgency}
          selectedPackages={selectedPackages}
          setSelectedPackages={setSelectedPackages}
          packages={packages}
          setPackages={setPackages}
        />
      </div>
    </div>
  );
}
