import React, { useEffect, useState } from "react";
import { Chart } from "primereact/chart";

export default function DeviceChart({ devices}) {
  const [devicesData, setDevicesData] = useState({});
  const [devicesOptions, setDevicesOptions] = useState({});

   const DataTypes = [
     { name: "Masaüstü", value: "1" },
     { name: "Mobil", value: "2" },
     { name: "Tablet", value: "3" },
   ];
  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const data = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [
            documentStyle.getPropertyValue("--blue-500"),
            documentStyle.getPropertyValue("--yellow-500"),
            documentStyle.getPropertyValue("--green-500"),
          ],
          hoverBackgroundColor: [
            documentStyle.getPropertyValue("--blue-400"),
            documentStyle.getPropertyValue("--yellow-400"),
            documentStyle.getPropertyValue("--green-400"),
          ],
        },
      ],
    };

    devices?.forEach((item) => {
      const dataTypeName = DataTypes.find(
        (dt) => dt.value === item.devices
      )?.name;
      data.labels.push(dataTypeName ? dataTypeName : item.devices);
      data.datasets[0].data.push(parseInt(item.value));
    });

    const options = {
      cutout: "60%",
    };

    setDevicesData(data);
    setDevicesOptions(options);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devices]);

  return (
    <div>
      <Chart
        style={{ width: "30rem", height: "20rem" }}
        type="doughnut"
        data={devicesData}
        options={devicesOptions}
      />
    </div>
  );
}
