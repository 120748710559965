import React from "react";
import { TabView, TabPanel } from "primereact/tabview";
import Profile from "./components/Profile";
import MailAccounts from "./components/MailAccounts";
import OtherAccountsInformation from "./components/OtherAccountsInformation";
import NoteEditor from "./components/NoteEditor";

export default function CustomerInformationPage({domainName , customer , user}) {
  return (
    <div className="card">
      <TabView>
        <TabPanel header="Şirketim">
          <Profile user={user} />
        </TabPanel>
        <TabPanel header="Mail Hesaplarım">
          <MailAccounts domainName={domainName} />
        </TabPanel>
        <TabPanel header="+ Şifreler">
          <OtherAccountsInformation domainName={domainName} />
        </TabPanel>
        <TabPanel header="Notlarım">
          <NoteEditor domainName={domainName} />
        </TabPanel>
      </TabView>
    </div>
  );
}
