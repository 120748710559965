import React, { useEffect, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import UserWebSite from "../user-web-site/UserWebSite";
import SocialMedia from "../social-media/SocialMedia";
import Maps from "../maps/Map";
import Ads from "../ads-report/AdsReport";
import Security from "../securit-report/Security";
import { CustomerService } from "../../../../../../core/Services";
import { Flex, Box } from "@chakra-ui/react";
import { Dropdown } from "primereact/dropdown";

export default function UserMain() {
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [error] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [uniqueCustomerId, setUniqueCustomerId] = useState("");
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    CustomerService()
      .then((data) => {
        setCustomers(data);
      })
      .catch((error) => {
        console.log("Müşteri Çekme Hatası", error);
      });
  }, []);

  const months = [
    { name: "Ocak", value: "01" },
    { name: "Şubat", value: "02" },
    { name: "Mart", value: "03" },
    { name: "Nisan", value: "04" },
    { name: "Mayıs", value: "05" },
    { name: "Haziran", value: "06" },
    { name: "Temmuz", value: "07" },
    { name: "Ağustos", value: "08" },
    { name: "Eylül", value: "09" },
    { name: "Ekim", value: "10" },
    { name: "Kasım", value: "11" },
    { name: "Aralık", value: "12" },
  ];
   const browser = [
     { value: "0", label: "Google" },
     { value: "1", label: "Yandex" },
     { value: "2", label: "Diğer" },
   ];

   
  const handleChange = (e) => {
    const selectedValue = e.value;
    setSelectedMonth(selectedValue);
  };
  const tabContents = [
    {
      title: "Web Sitesi",
      content: (
        <UserWebSite
          uniqueCustomerId={uniqueCustomerId}
          selectedCustomer={selectedCustomer}
          selectedMonth={selectedMonth}
          months={months}
          browser={browser}
        />
      ),
    },
    {
      title: "Sosyal Medya",
      content: (
        <SocialMedia
          uniqueCustomerId={uniqueCustomerId}
          selectedCustomer={selectedCustomer}
          selectedMonth={selectedMonth}
          months={months}
        />
      ),
    },
    {
      title: "Haritalar",
      content: (
        <Maps
          uniqueCustomerId={uniqueCustomerId}
          selectedCustomer={selectedCustomer}
          selectedMonth={selectedMonth}
          months={months}
        />
      ),
    },
    {
      title: "Reklamlar",
      content: (
        <Ads
          uniqueCustomerId={uniqueCustomerId}
          selectedCustomer={selectedCustomer}
          selectedMonth={selectedMonth}
          months={months}
        />
      ),
    },

    {
      title: "Güvenlik",
      content: (
        <Security
          uniqueCustomerId={uniqueCustomerId}
          selectedCustomer={selectedCustomer}
          selectedMonth={selectedMonth}
          months={months}
        />
      ),
    },
  ];

  return (
    <div>
      <Flex
        as="nav"
        align="center"
        justify="center"
        wrap="wrap"
        w="100%"
        mb={-5}
        p={8}
        bg="rgba(237, 237, 237, 0.8)"
        color="black"
      >
        <Box>
          <div className="justify-content-center flex ">
            <div style={{ marginRight: "1rem" }}>
              <Dropdown
                showClear
                filter
                onChange={(e) => {
                  const selectedCustomerId = e.value;
                  const selectedCustomer = customers.find(
                    (customer) => customer.id === selectedCustomerId
                  );
                  setSelectedCustomer(selectedCustomerId);
                  setUniqueCustomerId(selectedCustomer?.unique_id);
                }}
                options={
                  customers && customers?.length > 0
                    ? customers?.map((customer) => ({
                        label: customer.title,
                        value: customer.id,
                      }))
                    : []
                }
                value={selectedCustomer}
                placeholder="Müşteri Adı / Ünvanı"
                style={{
                  border: `2px solid ${
                    error && !selectedCustomer ? "red" : ""
                  }`,
                  borderRadius: "1rem",
                  width: "20rem",
                }}
              />
            </div>
            <div>
              <Dropdown
                style={{
                  border: `2px solid ${error && !selectedMonth ? "red" : ""}`,
                  borderRadius: "1rem",
                  width: "20rem",
                }}
                value={selectedMonth}
                showClear
                onChange={handleChange}
                options={months?.map((month) => ({
                  label: month.name,
                  value: month.value,
                }))}
                placeholder="Ay Seçiniz"
              />
            </div>
          </div>
        </Box>
      </Flex>

      <div className="card mt-3" style={{ width: "auto" }}>
        <TabView>
          {tabContents?.map((tab) => {
            return (
              <TabPanel key={tab.title} header={tab.title}>
                <p className="m-0">{tab.content}</p>
              </TabPanel>
            );
          })}
        </TabView>
      </div>
    </div>
  );
}
