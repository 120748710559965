import { Card, CardBody, CardHeader } from "@chakra-ui/react";
import React from "react";

export default function ChartPage({ uniqueCustomerId, months, DataTypes }) {
  return (
    <div className="w-full">
      <div className=" font-bold w-full">Görünüm:</div>
      <div className="row" style={{ display: "flex" }}>
        <div className="col-3">
          <Card
            style={{ height: "auto", background: "#662549", padding: "5px" }}
          >
            <CardHeader color={"white"} textAlign={"center"}>
              <i className="pi pi-instagram" style={{ fontSize: "1rem" }} />{" "}
              INSTAGRAM
            </CardHeader>
            <CardBody
              background={"white"}
              textAlign={"center"}
              overflow={"hidden"}
            >
              <strong style={{ color: "green" }}>Toplam Takipçi</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid green",
                  padding: "4px",
                }}
              >
                <h3>22121</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "blue" }}>Yeni Takipçi</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid blue",
                  padding: "4px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <div style={{ alignItems: "center" }}>+500</div>
              </div>{" "}
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "orange" }}>Erişim</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid orange",
                  padding: "4px",
                }}
              >
                <h3>22121</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "red" }}>Etkileşim</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid red",
                  padding: "4px",
                }}
              >
                <h3>22121</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "brown" }}>Profil Ziyaretleri</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid brown",
                  padding: "4px",
                }}
              >
                <h3>22121</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "purple" }}>TIklamalar</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid purple",
                  padding: "4px",
                }}
              >
                <h3>22121</h3>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-3">
          <Card
            style={{ height: "auto", background: "#662549", padding: "5px" }}
          >
            <CardHeader color={"white"} textAlign={"center"}>
              <i className="pi pi-facebook" style={{ fontSize: "1rem" }} />{" "}
              FACEBOOK
            </CardHeader>
            <CardBody
              background={"white"}
              textAlign={"center"}
              overflow={"hidden"}
            >
              <strong style={{ color: "green" }}>Toplam Takipçi</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid green",
                  padding: "4px",
                }}
              >
                <h3>22121</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "blue" }}>Yeni Takipçi</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid blue",
                  padding: "4px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <div style={{ alignItems: "center" }}>+500</div>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "orange" }}>Erişim</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid orange",
                  padding: "4px",
                }}
              >
                <h3>22121</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "red" }}>Etkileşim</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid red",
                  padding: "4px",
                }}
              >
                <h3>22121</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "brown" }}>Profil Ziyaretleri</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid brown",
                  padding: "4px",
                }}
              >
                <h3>22121</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "purple" }}>Tıklamalar</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid purple",
                  padding: "4px",
                }}
              >
                <h3>22121</h3>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-3">
          <Card
            style={{ height: "auto", background: "#662549", padding: "5px" }}
          >
            <CardHeader color={"white"} textAlign={"center"}>
              <i className="pi pi-youtube" style={{ fontSize: "1rem" }} />{" "}
              YOUTUBE
            </CardHeader>
            <CardBody
              background={"white"}
              textAlign={"center"}
              overflow={"hidden"}
            >
              <strong style={{ color: "green" }}>Toplam Takipçi</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid green",
                  padding: "4px",
                }}
              >
                <h3>22121</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "blue" }}>Yeni Takipçi</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid blue",
                  padding: "4px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <div style={{ alignItems: "center" }}>+500</div>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "orange" }}>Erişim</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid orange",
                  padding: "4px",
                }}
              >
                <h3>22121</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "red" }}>Etkileşim</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid red",
                  padding: "4px",
                }}
              >
                <h3>22121</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "brown" }}>Profil Ziyaretleri</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid brown",
                  padding: "4px",
                }}
              >
                <h3>22121</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "purple" }}>Tıklamalar</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid purple",
                  padding: "4px",
                }}
              >
                <h3>22121</h3>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-3">
          <Card
            style={{ height: "auto", background: "#662549", padding: "5px" }}
          >
            <CardHeader color={"white"} textAlign={"center"}>
              <i className="pi pi-linkedin" style={{ fontSize: "1rem" }} />{" "}
              LINKEDIN
            </CardHeader>
            <CardBody
              background={"white"}
              textAlign={"center"}
              overflow={"hidden"}
            >
              <strong style={{ color: "green" }}>Toplam Takipçi</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid green",
                  padding: "4px",
                }}
              >
                <h3>22121</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "blue" }}>Yeni Takipçi</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid blue",
                  padding: "4px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <div style={{ alignItems: "center" }}>+500</div>
              </div>{" "}
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "orange" }}>Erişim</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid orange",
                  padding: "4px",
                }}
              >
                <h3>22121</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "red" }}>Etkileşim</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid red",
                  padding: "4px",
                }}
              >
                <h3>22121</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "brown" }}>Profil Ziyaretleri</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid brown",
                  padding: "4px",
                }}
              >
                <h3>22121</h3>
              </div>
              <div style={{ marginTop: "2rem" }} />
              <strong style={{ color: "purple" }}>Tıklamalar</strong>
              <div
                style={{
                  background: "gainsboro",
                  color: "black",
                  borderTop: "3px solid purple",
                  padding: "4px",
                }}
              >
                <h3>22121</h3>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
}
