import React from "react";

import { Grid, GridItem } from "@chakra-ui/react";
import CustomerNavbar from "../components/Navbar/CustomerNavbar";
import CustomerSidebar from "../components/Sidebar/CustomerSidebar";
import CustomerReportsPage from "./CustomerReportsPage";

export default function CustomerReports() {
  return (
    <Grid
      templateAreas={`"header header"
                  "nav main"
                 `}
      gridTemplateRows={"auto 1fr "}
      gridTemplateColumns={"auto 1fr"}
      h="auto"
      color="blackAlpha.700"
      fontWeight="bold"
    >
      <GridItem area={"header"}>
        <CustomerNavbar />
      </GridItem>

      <GridItem area={"nav"}>
        <CustomerSidebar />
      </GridItem>

      <GridItem area={"main"} className="printCss">
        <CustomerReportsPage />
      </GridItem>
    </Grid>
  );
}
