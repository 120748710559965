import React from "react";
import { TabView, TabPanel } from "primereact/tabview";
import EmployeesPaymentMain from "./components/EmployeesPaymentMain";
import EmployeesPaymentAppearance from "./components/EmployeesPaymentAppearance";


export default function EmployeesPaymentPage() {


  return (
    <div className="card">
      <TabView>
        <TabPanel header="Ödeme Sayfası Oluşturma">
       <EmployeesPaymentMain/>
        </TabPanel>
        <TabPanel header="Görünüm">
      <EmployeesPaymentAppearance/>
        </TabPanel>
      </TabView>
    </div>
  );
}
