import React from 'react'
import {
  BsPersonSquare,
  BsFillEmojiFrownFill,
  BsBagPlusFill,
  BsBank2,
} from "react-icons/bs";

export default function CustomerSupportDescription() {
  return (
    <div>
      <div>
        <div className="flex align-items-center ">
          <BsPersonSquare size="1rem" />
          <div className="ml-2">Teknik Destek</div>
        </div>
        <div>
          Teknik destek almak için veya genel sorunlarınızı lütfen buradan
          belirtiniz..
        </div>
      </div>

      <div>
        <div className="flex align-items-center mt-7 ">
          <BsBank2 size="1rem" />
          <div className="ml-2">Muhasebe</div>
        </div>
        <div>
          Muhasebe sorunları ve ödeme bildirimleriniz için lütfen bu departmanı
          kullanınız..
        </div>
      </div>

      <div>
        <div className="flex align-items-center mt-7">
          <BsBagPlusFill size="1rem" />
          <div className="ml-2">Satış</div>
        </div>
        <div>
          Yeni bir hizmet almak istiyorsanız lütfen bu departmanı kullanınız..
        </div>
      </div>
      <div>
        <div className="flex align-items-center mt-7 ">
          <BsFillEmojiFrownFill size="1rem" />
          <div className="ml-2">Şikayet</div>
        </div>
        <div>
          Şikayetiniz mi var? Üzgünüz fakat bunun için elimizden geleni
          yaparız.. lütfen buradan bildiriniz..
        </div>
      </div>
    </div>
  );
}
