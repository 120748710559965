// store.js
import { configureStore } from "@reduxjs/toolkit";
import TaskReducer from "./TaskSlice";
import UserReducer from "./UserSlice";
import DepartmentReducer from "./DepartmentSlice";
import CustomerReducer from "./CustomerSlice";
import JobReducer from "./JobSlice";
import DashboardReducer from "./DashboardSlice";
import TallyReducer from "./TallySlice";
import LicenceReducer from "./LicenceSlice";
import ManufacturingReducer from "./ManufacturingSlice";
import JobDetailReducer from "./JobDetailSlice";
const store = configureStore({
  reducer: {
    TaskReduce: TaskReducer,
    UserReduce: UserReducer,
    DepartmentReduce: DepartmentReducer,
    CustomerReduce: CustomerReducer,
    JobReduce: JobReducer,
    DashboardReduce: DashboardReducer,
    TallyReduce: TallyReducer,
    LicenceReduce: LicenceReducer,
    ManufacturingReduce: ManufacturingReducer,
    JobDetailReduce: JobDetailReducer,
  },
});
export default store;
