import React, { useEffect, useState } from "react";
import Sidebar from "../../../../_ekdizayn/layout/Sidebar/Sidebar";
import NavBar from "../../../../_ekdizayn/layout/Navbar/components/Header";
import { Grid, GridItem } from "@chakra-ui/react";
import Sms from "./components/Sms";
import { BreadCrumb } from "primereact/breadcrumb";
import { Menu } from "primereact/menu";
import { useNavigate } from "react-router-dom";
import { SmsCreditService } from "../../../core/Services";

export default function SmsPage() {
  const history = useNavigate();
  const items = [
    {
      label: "SMS Gönder",
      icon: "pi pi-envelope",
      url: "/sms",
    },
    {
      label: "Periyodik SMS",
      icon: "pi pi-sliders-h",

      url: "/sms/periodic",
    },
    {
      label: "Gecikmeli SMS",
      icon: "pi pi-clock",
      url: "/sms/delayed",
    },
    {
      label: "Raporlar",
      icon: "pi pi-chart-line",
      url: "/sms/reports",
    },
    {
      label: "Rehber",
      icon: "pi pi-user",
      url: "/sms/persons",
    },
  ];
  const [credit, setCredit] = useState("");

  useEffect(() => {
    SmsCreditService()
      .then((data) => {
        setCredit(data?.balance[0]);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  }, []);

  const navigateTo = (url) => {
    history.push(url);
  };
  const getTurkishLabel = (url) => {
    switch (url) {
      case "/sms":
        return "SMS";
      default:
        return "Sayfa"; 
    }
  };
  const url = "/sms";
  const turkishLabel = getTurkishLabel(url); 

  const itemsBread = [{ label: turkishLabel, url: url }];
  const home = { icon: "pi pi-home", url: "/sms" };

  return (
    <Grid
      templateAreas={`"header header"
    "nav main"
   `}
      gridTemplateRows={"auto 1fr "}
      gridTemplateColumns={"auto 1fr"}
      h="auto"
      color="blackAlpha.700"
      fontWeight="bold"
    >
      <GridItem area={"header"}>
        <NavBar />
      </GridItem>
      <GridItem area={"nav"}>
        <Sidebar />
      </GridItem>
      <GridItem area={"main"} style={{ display: "flex", paddingTop: "2rem" }}>
        <div className="col-2">
          <BreadCrumb
            model={itemsBread}
            home={home}
            style={{ padding: "1rem" }}
          />
          <Menu
            model={items}
            style={{ background: "#e8bcb9", marginLeft: "1rem" }}
            onItemClick={(e) => navigateTo(e.item.url)}
          />
        </div>
        <div className="col-10">
          <Sms credit={credit} />
        </div>
      </GridItem>
    </Grid>
  );
}
