import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export default function BenefitsReportPage({ benefits }) {
  const [mergedData, setMergedData] = useState([]);

  useEffect(() => {
    if (benefits && benefits.jobs && benefits.tasks) {
      
      const mergedData = [...benefits.jobs, ...benefits.tasks];
      setMergedData(mergedData);
    }
  }, [benefits]);

const formatDate = (dateString) => {
  if (!dateString) return "-";
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
  };
  return new Date(dateString).toLocaleString("tr-TR", options);
};

  const columns = [
    {
      header: "Başlangıç Tarihi",
      body: (rowData) => {
        return formatDate(rowData.created_at || rowData.updated_at || "-");
      },
    },
    {
      header: "Konu",
      body: (rowData) => {
        return rowData.desc || rowData.desc || "-";
      },
    },
    {
      header: "Bitiş Tarihi",
      body: (rowData) => {
        return formatDate(rowData.updated_at || rowData.updated_at || "-");
      },
    },
  ];

  return (
    <div className="card">
      <DataTable
        value={mergedData}
        stripedRows
        paginator
        rows={15}
        rowsPerPageOptions={[15, 30, 60, 100]}
        scrollHeight="65vh"
        tableStyle={{ width:"100%" }}
        columnResizeMode="fit"
      >
        {columns?.map((col) => (
          <Column
            size="small"
            sortable
            key={col.header}
            header={col.header}
            body={col.body}
          />
        ))}
      </DataTable>
    </div>
  );
}
