import React from 'react'

export default function MailSetup({domainName}) {

  return (
    <div style={{ display: "flex" }}>
      <div
        style={{ maxWidth: "30rem", padding: "0.8rem", marginRight: "1rem" }}
      >
        <div className="mb-3 font-bold text-l">Notlar:</div>
        <ul>
          <li style={{ fontSize: "12px" }}>
            IMAP e-posta erişimi sunucu ile posta uygulamanız arasındaki
            koordinasyonu sağlar. Okunmuş/silinmiş/yanıtlanmış mesajlar hem
            sunucuda hem de posta uygulamasında bu şekilde gösterilecektir.
          </li>
          <li style={{ fontSize: "12px" }}>
            POP3, sunucu ile koordineli değil. Posta uygulamasında
            okunmuş/silinmiş/yanıtlanmış olarak işaretlenen mesajlar sunucudaki
            gibi gösterilmeyecek. Bu, POP3 ile gelecekteki posta indirmelerinin
            tüm mesajları okunmamış olarak göstereceği anlamına gelir.
          </li>
          <li style={{ fontSize: "12px" }}>
            Giden posta SMTP kullanılarak gönderildi.
          </li>
          <li style={{ fontSize: "12px" }}>
            SSL/TLS üzerinden POP3 ya da SSL/TLS üzerinden IMAP kullanılmasını
            öneririz zira uzak posta sunucusu ile etkileşimlerinizde daha fazla
            güvenlik sağlar.
          </li>
        </ul>
      </div>
      <div
        style={{ maxWidth: "34rem", padding: "0.8rem", marginRight: "1rem" }}
      >
        <table
          style={{
            fontFamily: "arial, sans-serif",
            borderCollapse: "collapse",
            width: "100%",
            fontSize: "12px",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                Secure SSL/TLS Settings (Önerilir)
              </th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ backgroundColor: "#dddddd" }}>
              <td
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                Kullanıcı adı: info@{domainName}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                Şifre: E-posta hesabının şifresini kullanın.
              </td>
            </tr>
            <tr style={{ backgroundColor: "#dddddd" }}>
              <td
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                Gelen Posta mail.{domainName} Sunucusu: IMAP Port: 993 POP3
                Port: 995
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                Giden Sunucu:mail.{domainName} Giden Sunucu: SMTP Port: 465
              </td>
            </tr>
            <tr style={{ backgroundColor: "#dddddd" }}>
              <td
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                IMAP, POP3 ve SMTP requires authentication.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        style={{ maxWidth: "34rem", padding: "0.8rem", marginRight: "1rem" }}
      >
        <table
          style={{
            fontFamily: "arial, sans-serif",
            borderCollapse: "collapse",
            width: "100%",
            fontSize: "12px",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                Non-SSL Settings (NOT Recommended)
              </th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ backgroundColor: "#dddddd" }}>
              <td
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                Kullanıcı adı: info@{domainName}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                Şifre: E-posta hesabının şifresini kullanın.
              </td>
            </tr>
            <tr style={{ backgroundColor: "#dddddd" }}>
              <td
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                Gelen Posta Sunucusu: mail.{domainName} IMAP Port: 143 POP3
                Port: 110
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                Giden Sunucu: mail.{domainName} SMTP Port: 587
              </td>
            </tr>
            <tr style={{ backgroundColor: "#dddddd" }}>
              <td
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                IMAP, POP3 ve SMTP requires authentication.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
