import React, { useState } from "react";
import { Splitter, SplitterPanel } from "primereact/splitter";
import MailAccountsTable from "./MailAccountsTable";
import MailAccount from "./MailAccount";

export default function MailAccountsApperance({
  uniqueCustomerId,
  selectedCustomer,
  
}) {
    const [tableKey, setTableKey] = useState(0);

    const updateTableData = () => {
      setTableKey((prevKey) => prevKey + 1);
    };
  return (
    <div>
      <Splitter>
        <SplitterPanel size={25}>
          <MailAccount updateTableData={updateTableData} selectedCustomer={selectedCustomer} uniqueCustomerId={uniqueCustomerId} />
        </SplitterPanel>
        <SplitterPanel size={75}>
          <div className="col-12">
            <MailAccountsTable key={tableKey}  uniqueCustomerId={uniqueCustomerId} />
          </div>
        </SplitterPanel>
      </Splitter>
    </div>
  );
}
