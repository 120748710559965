import { Code, Input, InputGroup, InputLeftAddon } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Stack } from "react-bootstrap";
import { Button } from "primereact/button";
import { SmsSettingsService } from "../../../../core/Services";
const SmsSettings = ({ data }) => {
  const [smsUserCode, setSmsUserCode] = useState("");
  const [smsPassword, setSmsPassword] = useState("");
  const [smsHeader, setSmsHeader] = useState("");
  const [smsLanguage, setSmsLanguage] = useState("");
  const [smsAppKey, setSmsAppKey] = useState("");

  const saveSmsSettings = async () => {
    await SmsSettingsService(
      smsUserCode,
      smsPassword,
      smsHeader,
      smsLanguage,
      smsAppKey
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setSmsUserCode(data?.sms_user_code);
        setSmsPassword(data?.sms_password);
        setSmsHeader(data?.sms_header);
        setSmsLanguage(data?.sms_language);
        setSmsAppKey(data?.sms_app_key);
      } catch (error) {
        console.error("Error fetching template data:", error);
      }
    };

    fetchData();
  }, [data]);
  return (
    <div style={{ display: "flex" }}>
      <div className="col-4">
        <Stack spacing={4} sty>
          <InputGroup size="sm">
            <InputLeftAddon>Sms Kullanıcı Kodu</InputLeftAddon>
            <Input
              value={smsUserCode}
              onChange={(e) => setSmsUserCode(e.target.value)}
            />
          </InputGroup>
          <InputGroup size="sm" marginTop="1rem">
            <InputLeftAddon>Sms Parolası</InputLeftAddon>
            <Input
              value={smsPassword}
              onChange={(e) => setSmsPassword(e.target.value)}
            />
          </InputGroup>
          <InputGroup size="sm" marginTop="1rem">
            <InputLeftAddon>Sms Başlığı</InputLeftAddon>
            <Input
              value={smsHeader}
              onChange={(e) => setSmsHeader(e.target.value)}
            />
          </InputGroup>
          <InputGroup size="sm" marginTop="1rem">
            <InputLeftAddon>Dil Seçeneği</InputLeftAddon>
            <Input
              value={smsLanguage}
              onChange={(e) => setSmsLanguage(e.target.value)}
            />
          </InputGroup>
          <InputGroup size="sm" marginTop="1rem">
            <InputLeftAddon>Uygulama Anahtarı</InputLeftAddon>
            <Input
              value={smsAppKey}
              onChange={(e) => setSmsAppKey(e.target.value)}
            />
          </InputGroup>
          <Button
            onClick={saveSmsSettings}
            style={{
              backgroundColor: "#662549",
              color: "white",
              padding: "1rem",
              height: "2rem",
              marginTop: "1rem",
            }}
          >
            Kaydet
          </Button>
        </Stack>
      </div>
      <div className="col-8">
        <Stack direction="column">
          <Code
            colorScheme="blue"
            children="Sistemimizde sms seçeneği olarak NetGSM entegrasyonu bulunmaktadır."
          />
          <Code
            colorScheme="blue"
            children="NetGSM hesabınıza girerek sağda doldurmanız gereken bilgilere ulaşabilirsiniz."
          />
          <Code
            colorScheme="blue"
            children="Bilgilerin doğru olduğundan emin olmalısınız."
          />
          <Code
            colorScheme="red"
            children="(Sms Kullanıcı Kodu : Hesaba girerken kullandığınız numara.)"
          />
          <Code
            colorScheme="red"
            children="(Sms Parolası : Hesaba girerken kullandığınız parola.)"
          />
          <Code
            colorScheme="red"
            children="(Sms Başlığı : Hesabınızdan belirlediğiniz başlıktır. Sms'lerde gönderen kişi olarak görünür.)"
          />
          <Code
            colorScheme="red"
            children="(Uygulama Anahtarı : Bu alan zorunlu değildir varsa giriniz.)"
          />
        </Stack>
      </div>{" "}
    </div>
  );
};

export default SmsSettings;
