import React, { useEffect, useState } from "react";
import {
  DeletePagesGraph,
  GetPagesGraph,
} from "../../../../../../../../core/Services";
import { BsFillTrashFill } from "react-icons/bs";
import { Dialog } from "primereact/dialog";
import { Button } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { Paginator } from "primereact/paginator";

export default function PagesGraphTable({
  uniqueCustomerId,
  tableKey,
  months,
  browser,
}) {
  const [pages, setPages] = useState([]);
  const [selectedPages, setSelectedPages] = useState(null);
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const toast = useToast();

  useEffect(() => {
    GetPagesGraph(uniqueCustomerId)
      .then((data) => {
        setPages(data.data);
      })
      .catch((error) => {
        console.log("Ülke Veri Çekme Hatası", error);
      });
  }, [uniqueCustomerId, tableKey, deleteSuccess]);

  useEffect(() => {
    if (deleteSuccess) {
      toast({
        title: "Başarılı",
        description: "Veri başarıyla silindi.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      setDeleteSuccess(false);
    }
  }, [deleteSuccess, toast]);

  const handleDelete = (countryId) => {
    setSelectedPages(countryId);
    setDisplayConfirmation(true);
  };

  const onDeleteConfirmation = () => {
    DeletePagesGraph(selectedPages);
    setDeleteSuccess(true);
    setSelectedPages("");
    setDisplayConfirmation(false);
  };

  const onHide = () => {
    setDisplayConfirmation(false);
  };
  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };
  return (
    <div>
      <div className="mb-3 font-bold text-xl">Tablo Görünümü :</div>
      <table
        style={{
          fontFamily: "arial, sans-serif",
          borderCollapse: "collapse",
          width: "80%",
        }}
      >
        <thead>
          <tr>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            ></th>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              Dönem
            </th>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              Platform
            </th>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              Sayfalar
            </th>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              Kullanıcılar
            </th>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              Görüntüleme Sayısı
            </th>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              İşlemler
            </th>
          </tr>
        </thead>
        <tbody>
          {pages &&
            pages?.slice(first, first + rows)?.map((page, index) => (
              <tr
                key={page.id}
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                <td
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  {index + 1}
                </td>
                <td
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  {months?.find((months) => months.value === page.month)
                    ?.name || ""}
                </td>
                <td
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  {browser?.find((bro) => bro.value === page.platform)?.label ||
                    ""}
                </td>
                <td
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  {page?.pages}
                </td>
                <td
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  {page?.visitor}
                </td>
                <td
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  {page?.screening}
                </td>
                <td
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  <button
                    className="ml-2"
                    onClick={() => handleDelete(page.id)}
                  >
                    <BsFillTrashFill />
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <Paginator
        first={first}
        rows={rows}
        totalRecords={pages?.length}
        onPageChange={onPageChange}
      />
      <Dialog
        visible={displayConfirmation}
        style={{ width: "450px" }}
        onHide={onHide}
        position="center"
        header="İçerik Düzenleme"
      >
        <p>Veriyi silmek istediğinize emin misiniz?</p>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "1rem",
            marginTop: "1rem",
          }}
        >
          <Button colorScheme="red" onClick={onHide}>
            Hayır
          </Button>
          <Button colorScheme="green" onClick={onDeleteConfirmation}>
            Evet
          </Button>
        </div>
      </Dialog>
    </div>
  );
}
