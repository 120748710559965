import React from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import moneyType from "../../../../../_ekdizayn/api/currency-type.json";
import { InputTextarea } from "primereact/inputtextarea";
export default function AddDialog({
  addServices,
  setAddServices,
  form,
  setForm,
  onChangeInput,
  onSubmitForm,
  errors,
}) {
  const hideDialog = () => {
    setAddServices(false);
    setForm(null);
  };
  const addButton = () => {
    return (
      <Button
        onClick={onSubmitForm}
        style={{ width: "9rem" }}
        label="Ekle"
        className="btn-excel-two"
      />
    );
  };
  return (
    <Dialog
      visible={addServices}
      footer={addButton}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      style={{ width: "60vw" }}
      header="Hizmet Ekle"
      onHide={hideDialog}
      modal
    >
      <div
        className="confirmation-content "
        style={{
          display: "flex",
        }}
      >
        <div className="col-6" style={{ display: "inline-block" }}>
          <InputText
            name="servicesName"
            type="text"
            onChange={onChangeInput}
            value={form?.servicesName}
            className="p-inputtext-lg w-full"
            placeholder="Hizmet Adı"
          />
          <div className="flex justify-content-start">
            {errors.servicesName && (
              <span className="text-red-500">{errors.servicesName}</span>
            )}
          </div>

          <InputText
            name="servicesType"
            type="text"
            onChange={onChangeInput}
            value={form?.servicesType}
            className="p-inputtext-lg w-full"
            style={{
              marginTop: "1rem",
            }}
            placeholder="Hizmet Türü"
          />
          <div className="flex justify-content-start">
            {errors.servicesType && (
              <span className="text-red-500">{errors.servicesType}</span>
            )}
          </div>

          <InputText
            name="vatRate"
            type="text"
            onChange={onChangeInput}
            value={form?.vatRate}
            className="p-inputtext-lg w-full"
            style={{
              marginTop: "1rem",
            }}
            placeholder="KDV Oranı"
          />
          <div className="flex justify-content-start">
            {errors.vatRate && (
              <span className="text-red-500">{errors.vatRate}</span>
            )}
          </div>
          <InputText
            type="text"
            name="buying"
            value={form?.buying}
            onChange={onChangeInput}
            className="p-inputtext-lg w-full"
            placeholder="Alış Fiyatı"
            style={{
              marginTop: "1rem",
            }}
          />
          <div className="flex justify-content-start">
            {errors.buying && (
              <span className="text-red-500">{errors.buying}</span>
            )}
          </div>
          <InputTextarea
            value={form?.description}
            className="mt-2"
            name="description"
            placeholder="Açıklama"
            onChange={onChangeInput}
            rows={5}
            cols={30}
          />
          <div className="flex justify-content-start">
            {errors.description && (
              <span className="text-red-500">{errors.description}</span>
            )}
          </div>
        </div>
        <div className="col-6" style={{ display: "inline-block" }}>
          <InputText
            type="text"
            name="stock"
            value={form?.stock}
            className="p-inputtext-lg w-full"
            placeholder="Stok"
            onChange={onChangeInput}
          />
          <div className="flex justify-content-start">
            {errors.stock && (
              <span className="text-red-500">{errors.stock}</span>
            )}
          </div>
          <Dropdown
            filter
            value={form?.currency}
            name="currency"
            onChange={onChangeInput}
            options={moneyType}
            placeholder="Para Birimi"
            className="w-full "
            style={{
              border: "1px solid gray",
              marginTop: "1rem",
            }}
          />
          <div className="flex justify-content-start">
            {errors.currency && (
              <span className="text-red-500">{errors.currency}</span>
            )}
          </div>
          <InputText
            type="text"
            name="sales"
            value={form?.sales}
            onChange={onChangeInput}
            className="p-inputtext-lg w-full"
            placeholder="Satış Fiyatı"
            style={{
              marginTop: "0.3rem",
            }}
          />
          <div className="flex justify-content-start">
            {errors.sales && (
              <span className="text-red-500">{errors.sales}</span>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
}
