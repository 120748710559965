import React from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";

export default function AboutCustomer({customer}) {


  return (
    <div className="p-3 d-flex flex-wrap justify-content-center align-items-center h-100">
      <Card style={{ width: "100%", maxWidth: "30rem" }} title="Bilgileriniz">
        <p
          className="m-0 ml-2"
          style={{ fontSize: "15px", fontWeight: "bold", marginLeft: "10px" }}
        >
          {customer?.name} <br />
          {customer?.email} <br />
          {customer?.phone} <br />
          {customer?.website}
        </p>
        <div style={{ textAlign: "center" }}>
          <Button
            style={{
              marginTop: "0.4rem",
              height: "1.5rem",
              width: "80%",
              fontSize: "15px",
              display: "inline-block",
              verticalAlign: "middle",
              lineHeight: "0rem",
            }}
            className="btn-excel-two"
          >
            Güncelle
          </Button>
        </div>
      </Card>
    </div>
  );
}
