import React from "react";
import { Box } from "@chakra-ui/react";
import DashboardLogo from "../../../../../../../../_ekdizayn/logo/logo.png";
import { Link } from "react-router-dom";

export default function Logo(props) {
  return (
    <Box {...props}>
      <Link to="/customer/detail">
        <img
          alt="Logo"
          src={DashboardLogo}
          className="w-150 h-150 object-cover"
          style={{ width: "100%", height: "100%", maxHeight: "100vh" }}
        />
      </Link>
    </Box>
  );
}
