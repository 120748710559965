import React, { useEffect, useState } from "react";
import { Chart } from "primereact/chart";
import { useAuth } from "../../../../../../../auth/core/AuthContext";

export default function ChartJ({ months, selectedMonth, sourceAds }) {
  const { userData } = useAuth();
  const uniqueCustomerId = userData?.unique_id;
  const [viewVisitorData, setViewVisitorData] = useState([]);
  const [chartOptions, setChartOptions] = useState();

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

    const data = sourceAds?.data;
    const type1Data = data?.find((entry) => entry.type === "1");
    const type2Data = data?.find((entry) => entry.type === "2");
    const type3Data = data?.find((entry) => entry.type === "3");

    const datalist = {
      labels: ["Tıklamalar", "Maliyet"],
      datasets: [
        {
          label: "Google Ads",
          backgroundColor: documentStyle.getPropertyValue("--blue-500"),
          borderColor: documentStyle.getPropertyValue("--blue-500"),
          data: [type1Data?.clicks, type1Data?.cost],
        },
        {
          label: "Arama Ortakları",
          backgroundColor: documentStyle.getPropertyValue("--pink-500"),
          borderColor: documentStyle.getPropertyValue("--pink-500"),
          data: [type2Data?.clicks, type2Data?.cost],
        },
        {
          label: "Görüntülü Reklam Ağı",
          backgroundColor: documentStyle.getPropertyValue("--orange-500"),
          borderColor: documentStyle.getPropertyValue("--orange-500"),
          data: [type3Data?.clicks, type3Data?.cost],
        },
      ],
    };
    const options = {
      indexAxis: "y",
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          labels: {
            fontColor: textColor,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
            font: {
              weight: 500,
            },
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false,
          },
        },
      },
    };

    setViewVisitorData(datalist);
    setChartOptions(options);
  }, [uniqueCustomerId, months, selectedMonth, sourceAds?.data]);

  return (
    <div className="print-width-50">
      {viewVisitorData && (
        <Chart
          width="100%"
          type="bar"
          data={viewVisitorData}
          options={chartOptions}
        />
      )}
    </div>
  );
}
