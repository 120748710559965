import React from "react";
import { TabView, TabPanel } from "primereact/tabview";
import CountryVisitGraph from "../country-visit-graph/CountryVisitGraph";
import BestKeywordsGraph from "../best-keywords-graph/BestKeywordsGraph";
import ViewVisitorChart from "../view-visitor-chart/ViewVisitorChart";
import DevicesGraph from "../devices-graph/DevicesGraph";
import TrafficSourceGraph from "../traffic-source-graph/TrafficSourceGraph";
import AgeGraph from "../age-graph/AgeGraph";
import Languages from "../languages-graph/Languages";
import PagesGraph from "../pages-graph/PagesGraph";
import CityGraph from "../city-graph/CityGraph";

export default function WebSiteMain({
  uniqueCustomerId,
  selectedMonth,
  selectedCustomer,
  months,
  browser,
}) {
  const tabContents = [
    {
      title: "Performans",
      content: (
        <ViewVisitorChart
          uniqueCustomerId={uniqueCustomerId}
          selectedCustomer={selectedCustomer}
          selectedMonth={selectedMonth}
          months={months}
        />
      ),
    },
    {
      title: "Cihazlar",
      content: (
        <DevicesGraph
          uniqueCustomerId={uniqueCustomerId}
          selectedCustomer={selectedCustomer}
          selectedMonth={selectedMonth}
          months={months}
        />
      ),
    },
    {
      title: "Kaynak",
      content: (
        <TrafficSourceGraph
          uniqueCustomerId={uniqueCustomerId}
          selectedCustomer={selectedCustomer}
          selectedMonth={selectedMonth}
          months={months}
        />
      ),
    },
    {
      title: "Yaş",
      content: (
        <AgeGraph
          uniqueCustomerId={uniqueCustomerId}
          selectedCustomer={selectedCustomer}
          selectedMonth={selectedMonth}
          months={months}
        />
      ),
    },
    {
      title: "Ülkeler",
      content: (
        <CountryVisitGraph
          uniqueCustomerId={uniqueCustomerId}
          selectedCustomer={selectedCustomer}
          selectedMonth={selectedMonth}
          months={months}
        />
      ),
    },
    {
      title: "Şehirler",
      content: (
        <CityGraph
          uniqueCustomerId={uniqueCustomerId}
          selectedCustomer={selectedCustomer}
          selectedMonth={selectedMonth}
          months={months}
        />
      ),
    },
    {
      title: "Anahtar Kelime",
      content: (
        <BestKeywordsGraph
          uniqueCustomerId={uniqueCustomerId}
          selectedCustomer={selectedCustomer}
          selectedMonth={selectedMonth}
          months={months}
          browser={browser}
        />
      ),
    },
    {
      title: "Diller",
      content: (
        <Languages
          uniqueCustomerId={uniqueCustomerId}
          selectedCustomer={selectedCustomer}
          selectedMonth={selectedMonth}
          months={months}
          browser={browser}
        />
      ),
    },
    {
      title: "Sayfalar",
      content: (
        <PagesGraph
          uniqueCustomerId={uniqueCustomerId}
          selectedCustomer={selectedCustomer}
          selectedMonth={selectedMonth}
          months={months}
          browser={browser}
        />
      ),
    },
  ];

  return (
    <div className="card" style={{ width: "80vw" }}>
      <TabView>
        {tabContents?.map((tab) => {
          return (
            <TabPanel key={tab.title} header={tab.title}>
              <p className="m-0">{tab.content}</p>
            </TabPanel>
          );
        })}
      </TabView>
    </div>
  );
}
