import {
  AbsoluteCenter,
  Center,
  Flex,
  Box,
  Heading,
  HStack,
  Text,
  Avatar,
  Grid,
  GridItem,

  Textarea,
} from "@chakra-ui/react";
import { EncryptedIcon } from "../assets/icons";
import background from "../assets/image/chat-back-ground.png";
import { useEffect, useState } from "react";
import { useAuth } from "../../../../auth/core/AuthContext";
import { FetchChat, StoreChat } from "../../../../core/Services";
// import Sound from "../../../../../_ekdizayn/sound/chat.mp3";
function Message({ text, sender }) {
  return (
    <Flex justifyContent={sender === "user" ? "flex-end" : "flex-start"} mb="1">
      <Box
        maxW="70%" // Örnek olarak 300 piksel sabit genişlik belirleyin
        p="3"
        borderRadius="10px"
        bg={sender === "user" ? "#015c4b" : "gray.200"}
        color={sender === "user" ? "white" : "black"}
        wordBreak="break-word" // Uzun kelimeleri satır sonunda kır
      >
        <Text>{text}</Text>
      </Box>
    </Flex>
  );
}
// const newMessageSound = new Audio(Sound);

export function RightPanel({ selectedUser, ...props }) {
  const { userData } = useAuth();
  const handleSendMessage = () => {
    if (message.trim() !== "") {
      const latin1Text = unescape(encodeURIComponent(message));
      const encryptedMessage = btoa(latin1Text);
      setMessages([...messages, { text: message, sender: "user" }]);
      setMessage("");
      StoreChat(userData?.unique_id, selectedUser?.unique_id, encryptedMessage)
        .then(() => {
          triggerFetchChat();
        })
        .catch((error) => {
          console.error("Error storing chat:", error);
        });
    }
  };
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [historyMessage, setHistoryMessage] = useState([]);
  const triggerFetchChat = () => {
    FetchChat(userData?.unique_id)
      .then((data) => {
        if (Array.isArray(data) && data.length > 0) {
          setHistoryMessage(data);
        } else {
          setHistoryMessage([]);
        }
      })
      .catch((error) => {
        console.log("FetchChat error:", error);
        setHistoryMessage([]);
      });
  };

  useEffect(() => {
    triggerFetchChat();
    const intervalId = setInterval(triggerFetchChat, 5000); // Her 5 saniyede bir mesajları yenile
    return () => clearInterval(intervalId); // İlk mesajları yükle
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.unique_id, selectedUser]);

  const filterMessagesByUser = (messages, userId) => {
    return messages?.filter(
      (message) =>
        message.incoming_msg_id === userId || message.outgoing_msg_id === userId
    );
  };

  const userMessages = filterMessagesByUser(
    historyMessage,
    selectedUser?.unique_id
  );

  const getFirstTwoWords = (str) => {
    const words = str.split(" ");
    if (words.length >= 2) {
      return (
        words[0].charAt(0).toUpperCase() + words[1].charAt(0).toUpperCase()
      );
    } else if (words.length === 1) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    } else {
      return "";
    }
  };
  const [textareaHeight, setTextareaHeight] = useState("1.5px");
  useEffect(() => {
    const textarea = document.getElementById("messageTextarea");
    if (textarea) {
      const scrollHeight = textarea.scrollHeight;
      const maxHeight = 6 * 16; // 6 rem değerini piksel cinsine çevir
      const newHeight = Math.min(scrollHeight, maxHeight); // Yeni yüksekliği en fazla 6 rem olarak sınırla
      setTextareaHeight(`${newHeight}px`);
    }
  }, [message]);

  useEffect(() => {
    const messageBox = document.getElementById("messageBox");
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollHeight;
    }
  }, [userMessages]);

  return (
    <>
      {selectedUser ? (
        <Grid
          templateRows="repeat(16, 1fr)"
          w="74%"
          height="100%"
          overflow="hidden"
          color="blackAlpha.700"
          fontWeight="bold"
        >   
          <GridItem bg="#12372A" p="0.4rem" rowSpan="1">
            <Box display="flex" alignItems="center">
              {/* Avatar */}
              <Avatar boxSize="3%" src={`data:image/png;base64,${selectedUser?.image}`} />

              {/* Kullanıcı Adı */}
              <Box
                color="white"
                fontSize="15px"
                ml="2"
                textAlign="center"
                fontWeight="bold"
              >
                {getFirstTwoWords(selectedUser?.name)}
              </Box>
            </Box>
          </GridItem>

          <GridItem bgImage={background} rowSpan="15">
            <Box
              style={{
                scrollbarColor: "white transparent",
                behavior: "smooth",
                block: "end",
                padding: "1rem",
              }}
              overflowY="auto"
              w="100%"
              h="100%"
              id="messageBox"
                         
            >
              {userMessages?.map((msg, index) => (
                <Message
                  key={index}
                  text={msg.message}
                  sender={
                    userData?.unique_id === msg.incoming_msg_id
                      ? "user"
                      : "other"
                  }
                />
              ))}
            </Box>
          </GridItem>

          <GridItem
            textAlign="center"
            p="2"
            border="2px solid #436850"
            bg="#12372A"
            display="flex"
            alignItems="center"
            rowSpan="1"
          >
            {/* <Icon as={""} color="white" marginLeft="1rem" /> */}
            <Box flex="1">
              {" "}
              {/* input alanını esnek yapmak için */}
              <Textarea
                id="messageTextarea"
                variant="unstyled"
                minHeight="3rem"
                h={textareaHeight}
                width="65%"
                border="2px solid gray"
                margin="auto"
                borderRadius="20px"
                color="white"
                placeholder="Mesajınızı buraya yazın..."
                backgroundColor="transparent"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    handleSendMessage();
                  }
                }}
                textAlign="left"
                paddingLeft="8px"
                resize="none"
                overflowY="auto"
                sx={{
                  "&::-webkit-scrollbar": {
                    width: "8px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "transparent",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "rgba(255, 255, 255, 0.3)",
                    borderRadius: "20px",
                  },
                }}
              />
            </Box>
            {/* <BsFillSendFill style={{marginRight:"1rem"}} /> */}
          </GridItem>
        </Grid>
      ) : (
        <Center
          bg="#f0f2f5"
          borderBottom="6px solid #43c960"
          position="relative"
          {...props}
          w="74%"
        >
          <Flex
            direction="column"
            textAlign="center"
            color="#41525d"
            align="center"
          >
            <Box pt="8">
              <Heading fontWeight="light">Ek Web</Heading>
              <Text fontSize="sm" mt="4">
                Mesaj gönderin ve alın. <br />{" "}
              </Text>
            </Box>
            <AbsoluteCenter axis="horizontal" bottom="10" flex="1" mt="10">
              <HStack justifyItems="baseline" color="#8696a0">
                <EncryptedIcon />
                <Text fontSize="sm" fontWeight="medium">
                  Uçtan uca şifrelenmiş
                </Text>
              </HStack>
            </AbsoluteCenter>
          </Flex>
        </Center>
      )}
    </>
  );
}
