import React, { useEffect, useState } from "react";
import { Editor } from "primereact/editor";
import { GetNoteEditor } from "../../../../../../core/Services";
import { useAuth } from "../../../../../../auth/core/AuthContext";

export default function NoteEditor() {
  const [text, setText] = useState("");
  const {userData} = useAuth();
 const emptyHeader = <div style={{ height: "0", visibility: "hidden" }}></div>;

 useEffect(()=> {
    GetNoteEditor(userData?.unique_id)
    .then((data)=> {
        setText(data.data)
    })
    .catch((error)=> {
    console.log("EditorNoteHatası", error);
    }
    )
 },[userData?.unique_id])
  return (
    <div className="card">
      <Editor
        value={text}
        style={{ height: "700px" }}
        headerTemplate={emptyHeader}
        readOnly
      />
    </div>
  );
}
