import React from 'react'
import CustomerPanelPage from './CustomerPanelPage'




export default function CustomerPanel() {
  return (
    <>
      <CustomerPanelPage />
    </>
  );
}
