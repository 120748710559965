import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Flex } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { Tag } from "primereact/tag";
import { useAuth } from "../../../../auth/core/AuthContext";

export default function DahsboardTableUserJobs() {
  const [jobs, setJobs] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const dt = useRef(null);
  const { userData } = useAuth();
  const dashboardData = useSelector((state) => state.DashboardReduce.value);
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    return new Date(dateString).toLocaleString("tr-TR", options);
  };

  const workingBodyTemplate = (rowData) => {
    const getStatusLabel = (working_condition) => {
      switch (working_condition) {
        case "0":
          return "Normal";

        case "1":
          return "Öncelikli";

        case "2":
          return "Kritik";

        default:
          return null;
      }
    };

    const statusLabel = getStatusLabel(rowData.working_condition);

    return <Tag value={statusLabel} severity={getSeverity(rowData)}></Tag>;
  };
  const getSeverity = (jobs) => {
    switch (jobs.working_condition) {
      case "0":
        return "success";

      case "1":
        return "warning";

      case "2":
        return "danger";

      default:
        return null;
    }
  };
  const filteredJobs =
    jobs &&
    jobs?.filter(
      (job) =>
        job?.jobs_id === 1 &&
        (job?.designated_person.includes(",")
          ? job?.designated_person.split(",").includes(userData.id.toString())
          : job?.designated_person === userData.id.toString())
    );
  useEffect(() => {
    const fetchData = async () => {
      try {
        setJobs(dashboardData.jobs);
        setEmployeeList(dashboardData.users);
        setDepartments(dashboardData.departments);
        setCustomers(dashboardData.customers);
      } catch (error) {
        console.error("API Hatası:", error);
      }
    };
    fetchData();
  }, [filteredJobs, dashboardData]);

  return (
    <Flex justifyContent="center" alignItems="center">
      <div
        className="card"
        style={{
          overflow: "auto",
          boxSizing: "border-box",
          width: "100%",
          height: "100%",
          position: "relative",
          padding: "1rem",
        }}
      >
        <DataTable
          stripedRows
          ref={dt}
          size="small"
          value={filteredJobs}
          dataKey="id"
          paginator
          rows={15}
          rowsPerPageOptions={[15, 30, 60, 100]}
          scrollHeight="60vh"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="{totalRecords} işten {first} - {last} arası gösteriliyor"
        >
          <Column field="jobs_no" header="İş Emri No"></Column>
          <Column
            field="title"
            header="Müşteri"
            body={(rowData) => {
              const cust = customers?.find(
                (dept) => dept.id.toString() === rowData.customer_id
              );
              if (cust) {
                // Başlığı boşluklara göre bölerek ilk iki kelimeyi al
                const firstTwoWords =
                  cust.title && cust.title.split(" ").slice(0, 2).join(" ");
                return firstTwoWords;
              } else {
                return "Bilinmeyen";
              }
            }}
          ></Column>
          <Column field="subject" header="Konu"></Column>
          <Column
            field="desc"
            header="Açıklama"
            style={{ minWidth: "6rem" }}
          ></Column>
          <Column
            header="Departman"
            body={(rowData) => {
              const department = departments?.find(
                (dept) => dept.id.toString() === rowData.department
              );
              return department ? department.department : "Unknown Department";
            }}
            style={{ minWidth: "6rem" }}
          ></Column>
          <Column
            header="İş Durumu"
            body={workingBodyTemplate}
            style={{ minWidth: "4rem" }}
            filterMatchMode="equals"
            filterPlaceholder="Filter by Status"
          ></Column>
          <Column
            header="Atanan Kişi"
            body={(rowData) => {
              const designatedPersons = rowData?.designated_person.split(","); // Virgüllerle ayrılmış kimlikleri diziye ayır

              const assignedUsers = designatedPersons.map(
                (designatedPerson) => {
                  const user = employeeList?.find(
                    (usr) => usr.id.toString() === designatedPerson.trim()
                  );

                  return user ? user.name : "Bilinmeyen"; // Kullanıcı adını bul veya "Unknown User" döndür
                }
              );

              return assignedUsers.join(", "); // Virgülle ayrılmış kullanıcı adlarını birleştir ve sütuna yazdır
            }}
            style={{ minWidth: "6rem" }}
          ></Column>

          <Column
            field="created_at"
            header="Oluşturulma Tarihi"
            body={(rowData) => formatDate(rowData.created_at)}
            style={{ minWidth: "6rem" }}
          ></Column>
        </DataTable>
      </div>
    </Flex>
  );
}
