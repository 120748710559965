import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  AddTasksService,
  AssignTaskService,
  DeleteTaskService,
  EditTaskService,
} from "../../../../core/Services";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Card, CardHeader, Flex, Heading } from "@chakra-ui/react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { MultiSelect } from "primereact/multiselect";
import { useAuth } from "../../../../auth/core/AuthContext";

export default function Waiting() {
  const [tasks, setTasks] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState(false);
  const [visible, setVisible] = useState(false);
  const [dep, setDep] = useState("");
  const [selectedCustomerUniqueId, setSelectedCustomerUniqueId] = useState("");
  const [taskStatus, setTaskStatus] = useState(""); 
  const [cus, setCus] = useState("");
  const [desc, setDesc] = useState("");
  const [newDep, setNewDep] = useState("");
  const [selectedData, setSelectedData] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [departments, setDepartments] = useState([]);
  const { userData } = useAuth();
  const [customers, setCustomers] = useState([]);
  const [newName, setNewName] = useState("");
  const [newStatus, setNewStatus] = useState("");
  const [newDesc, setNewDesc] = useState("");
  const toast = useRef(null);
  const [inputErrors, setInputErrors] = useState({
      cus: false,
      dep: false,
      desc: false,
      taskStatus: false,
    });
  const [assignedEmp, setAssignedEmp] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const isButtonDisable = false;
  const taskData = useSelector((state) => state.TaskReduce.value);
  const userDatas = useSelector((state) => state.UserReduce.value);
  const departmentData = useSelector((state) => state.DepartmentReduce.value);
  const customerData = useSelector((state) => state.CustomerReduce.value);
  useEffect(() => {
    setTasks(taskData);
    setEmployee(userDatas);
    setCustomers(customerData);
    setDepartments(departmentData);
  }, [addModal, taskData, userDatas, departmentData, customerData]);

  const hideDialog = () => {
    setAddModal(false);
    setEditModal(false)
    setSelectedCustomerUniqueId("");
    setCus("");
  };

  const assignTask = async (user_id, task_id) => {
    await AssignTaskService(user_id, task_id , userData?.id);
  };

  const workingBodyTemplate = (rowData) => {
    const getStatusLabel = (working_condition) => {
      switch (working_condition) {
        case "0":
          return "Yüksek";

        case "1":
          return "Normal";

        case "2":
          return "Kritik";

        default:
          return null;
      }
    };

    const statusLabel = getStatusLabel(rowData.working_condition);

    return <Tag value={statusLabel} severity={getSeverity(rowData)}></Tag>;
  };
  const getSeverity = (tasks) => {
    switch (tasks.working_condition) {
      case "0":
        return "warning";

      case "1":
        return "success";

      case "2":
        return "danger";

      default:
        return null;
    }
  };
  const accessors = (rowData) => {
    const edit = editModal[rowData.id] || false;
    const deletes = deleteModal[rowData.id] || false;
    const setEmployees = visible[rowData.id] || false;
    return (
      <div style={{ display: "flex" }}>
        <div style={{ color: "#f39f5a" }}>
          <Button
            severity="warning"
            onClick={() => {
              setEditModal((prevDialogs) => ({
                ...prevDialogs,
                [rowData.id]: true,
              }));
              setSelectedData(rowData);
              setNewName(Number(rowData.name));
              setNewDep(Number(rowData.department));
              setNewStatus(rowData.status);
            }}
            icon="pi pi-pencil"
          />
        </div>
        <div style={{ color: "#ae445a" }}>
          <Button
            icon="pi pi-trash"
            onClick={() => {
              setDeleteModal((prevDialogs) => ({
                ...prevDialogs,
                [rowData.id]: true,
              }));
              setDesc(rowData?.desc);

              if (rowData?.name) {
                const cust = customers.find(
                  (dept) => dept.id.toString() === rowData.name
                );
                if (cust) {
                  const firstTwoWords =
                    cust.title && cust.title.split(" ").slice(0, 2).join(" ");
                  setCus(firstTwoWords);
                } else {
                  setCus("Bilinmeyen");
                }
              }

              if (rowData?.department) {
                const department = departments.find(
                  (dept) => dept.id.toString() === rowData.department
                );
                if (department) {
                  setDep(department.department);
                }
              }
            }}
            severity="danger"
          />
        </div>
        <div style={{ color: "green" }}>
          <Button
            icon="pi pi-user-plus"
            severity="success"
            onClick={() => {
              setSelectedTask(tasks);
              setSelectedData(rowData);
              setVisible((prevDialogs) => ({
                ...prevDialogs,
                [rowData.id]: true,
              }));
            }}
          />
        </div>
        <Dialog
          header="Görev Ata"
          visible={setEmployees}
          style={{ width: "50vw" }}
          onHide={() => setVisible(false)}
          footer={
            <div>
              <Button
                label="Kapat"
                icon="pi pi-times"
                onClick={() => setVisible(false)}
                className="btn-excel-two"
              />
              <Button
                label="Görevi Ata"
                icon="pi pi-check"
                onClick={() => handleAssignTask(selectedData.id)}
                className="btn-excel-two"
                style={{ marginLeft: "15px" }}
              />
            </div>
          }
        >
          <div className="field">
            <MultiSelect
              value={assignedEmp}
              filter
              options={
                Array.isArray(employee) && employee.length > 0
                  ? employee.map((employ) => ({
                      label: employ.name,
                      value: employ.id,
                    }))
                  : []
              }
              placeholder="Görevliler"
              className="w-full "
              style={{
                border: "1px solid gray",
                width: "100%",
                marginBottom: "1rem",
              }}
              onChange={(e) => setAssignedEmp(e.target.value)}
            />
          </div>
        </Dialog>
        <Dialog
          header="Görevi silmek istediğinize emin misiniz?"
          visible={deletes}
          style={{ width: "50vw" }}
          onHide={() => {
            setDeleteModal(false);
            setDesc("")
            setDep("")
            setCus("");
          } }
          footer={
            <div>
              <Button
                label="İptal"
                icon="pi pi-times"
                onClick={() => setDeleteModal(false)}
                className="btn-excel-two"
              />
              <Button
                label="Görevi Sil"
                icon="pi pi-check"
                onClick={() => {
                  DeleteTaskService(selectedData?.id);
                  setDeleteModal(false);
                  setDesc("");
                  setCus("");
                  setDep("");
                }}
                className="btn-excel-two"
                style={{ marginLeft: "15px" }}
              />
            </div>
          }
        >
          <div className="confirmation-content">
           
            <div className="field">
              <label htmlFor="name" className="font-bold">
                Müşteri Adı: {cus}
              </label>
            </div>
            <div className="field">
              <label htmlFor="description" className="font-bold">
                Açıklama : {desc}
              </label>
            </div>
            <div className="field">
              <label className="mb-3 font-bold">
                Departman: {dep}
              </label>
            </div>
          
          </div>
        </Dialog>
        <Dialog
          visible={edit}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          style={{ width: "60vw" }}
          header="Görevi Düzenle"
          onHide={() => {
            setEditModal(false);
          }}
          modal
        >
          <div className="confirmation-content">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {/* Sol Sütun */}
              <div className="col-6">
                <div style={{ display: "flex" }}>
                  <Dropdown
                    filter
                    options={
                      customers && customers.length > 0
                        ? customers.map((dep) => ({
                            label: dep.title,
                            value: dep.id,
                          }))
                        : []
                    }
                    value={newName}
                    placeholder="Müşteri Adı / Ünvanı"
                    onChange={(e) => setNewName(e.target.value)}
                    style={{
                      border: "1px solid gray",
                      width: "100%",
                    }}
                  />
                  <div style={{ width: "0.4rem" }} />
                  <Button className="btn-plus">
                    <Link to="/customer-list">
                      <i
                        className="pi  pi-plus"
                        style={{ fontSize: "1rem" }}
                      ></i>
                    </Link>
                  </Button>
                </div>

                <Dropdown
                  filter
                  options={
                    Array.isArray(departments) && departments.length > 0
                      ? departments.map((dep) => ({
                          label: dep.department,
                          value: dep.id,
                        }))
                      : []
                  }
                  value={newDep}
                  placeholder="Departman"
                  onChange={(e) => setNewDep(e.target.value)}
                  style={{
                    border: "1px solid gray",
                    padding: "0rem",
                    width: "100%",
                    marginBottom: "1rem",
                    marginTop: "1rem",
                  }}
                />
              </div>

              {/* Sağ Sütun */}
              <div className="col-6">
                <Dropdown
                  filter
                  options={statusType}
                  placeholder="Durum"
                  value={newStatus}
                  onChange={(e) => setNewStatus(e.value)}
                  style={{
                    border: "1px solid gray",
                    width: "100%",
                    marginBottom: "1rem",
                  }}
                />
                <InputText
                  type="text"
                  className="p-inputtext-lg"
                  placeholder="Açıklama"
                  onChange={(e) => setNewDesc(e.target.value)}
                  defaultValue={selectedData.desc}
                  style={{
                    width: "100%",
                    marginBottom: "1rem",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    label="Kaydet"
                    disabled={isButtonDisable}
                    onClick={() => saveChanges(selectedData.id)}
                    className="btn-excel-two"
                  />
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    );
  };

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false,
    };
    return new Date(dateString).toLocaleString("tr-TR", options);
  };
  const saveChanges = async (id) => {
    await EditTaskService(id, newName, newDesc, newDep, newStatus);
  };

const newCreate = async () => {
  if (!cus || !dep || !desc || !taskStatus) {
    setInputErrors({
      cus: !cus,
      dep: !dep,
      desc: !desc,
      taskStatus: !taskStatus,
    });
    toast.current.show({
      severity: "error",
      summary: "Hata",
      detail: "Lütfen boş kısımları doldurun.",
    });
    return;
  }

 await AddTasksService(cus, selectedCustomerUniqueId, desc, dep, taskStatus);
 setSelectedCustomerUniqueId("");
 setCus("");
 setAddModal(false);
};



   
  const statusType = [
    { label: "Normal", value: "1" },
    { label: "Yüksek", value: "0" },
    { label: "Kritik", value: "2" },
  ];
  const handleAssignTask = async (id) => {
    try {
      if (!selectedTask) {
        console.error("Tamamlanacak görev bulunamadı.");
        return;
      }
      await assignTask(assignedEmp, id);
      setVisible(false);
    } catch (error) {
      console.error("Hata:", error.message);
    }
  };

  return (
    <Flex mt="5" justifyContent="center" alignItems="center">
      <div
        className="card"
        style={{
          overflow: "auto",
          boxSizing: "border-box",
          width: "100%",
          height: "100%",
          position: "relative",
          padding: "1rem",
        }}
      >
        <Card>
          <CardHeader>
            <Flex justifyContent="space-between" alignItems="center">
              <Heading size="md">Bekleyen Görevler</Heading>
              <div>
                <Button
                  label="Yeni"
                  onClick={() => setAddModal(true)}
                  className="btn-excel-two"
                />
              </div>
            </Flex>
          </CardHeader>
          <Toast ref={toast} />

          <DataTable
            stripedRows
            size="small"
            value={tasks && tasks?.filter((tasks) => tasks?.tasks_id === "0")}
            dataKey="id"
            tableStyle={{ maxWidth: "85vw" }}
            rows={15}
            rowsPerPageOptions={[15, 30, 60, 100]}
            scrollHeight="65vh"
            scrollable
            paginator
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate=" {totalRecords} görevden {first} - {last} arası gösteriliyor"
          >
            {userData &&
              (userData.type === "1" || userData.is_auth === "1") && (
                <Column
                  style={{ width: "8rem" }}
                  body={accessors}
                  exportable={false}
                  header="İşlemler"
                />
              )}

            <Column
              field="title"
              header="Müşteri"
              body={(rowData) => {
                const cust = customers.find(
                  (dept) => dept.id.toString() === rowData.name
                );
                if (cust) {
                  // Başlığı boşluklara göre bölerek ilk iki kelimeyi al
                  const firstTwoWords =
                    cust.title && cust.title.split(" ").slice(0, 2).join(" ");
                  return firstTwoWords;
                } else {
                  return "Bilinmeyen";
                }
              }}
            ></Column>

            <Column
              field="desc"
              style={{ maxWidth: "20rem" }}
              header="Açıklama"
            ></Column>
            <Column
              field="priority"
              body={workingBodyTemplate}
              header="Öncelik"
            ></Column>
            <Column
              field="department_name"
              header="Departman"
              sortable
              body={(rowData) => {
                const department = departments.find(
                  (dept) => dept.id.toString() === rowData.department
                );
                return department ? department.department : rowData.department;
              }}
            ></Column>
            <Column
              field="created_at"
              header="Oluşturulma Tarihi"
              body={(rowData) => formatDate(rowData.created_at)}
              style={{ minWidth: "6rem" }}
              sortable
            ></Column>
          </DataTable>
        </Card>
      </div>
      <Dialog
        visible={addModal}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        style={{ width: "60vw" }}
        header="Görev Ekle"
        onHide={hideDialog}
        modal
      >
        <div className="confirmation-content">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            
            <div className="col-6">
              <div style={{ display: "flex" }}>
                <Dropdown
                  filter
                  onChange={(e) => {
                    setCus(e.target.value);
                    const selectedCustomer = customers.find(
                      (customer) => customer.id === e.target.value
                    );
                    const uniqueId = selectedCustomer
                      ? selectedCustomer.unique_id
                      : null;

                    setSelectedCustomerUniqueId(uniqueId);
                    setInputErrors({ ...inputErrors, cus: false });
                  }}
                  options={
                    customers && customers.length > 0
                      ? customers.map((dep) => ({
                          label: dep.title,
                          value: dep.id,
                          unique_id: dep.unique_id,
                        }))
                      : []
                  }
                  value={cus}
                  placeholder="Müşteri Adı / Ünvanı"
                  style={{
                    border: "1px solid gray",
                    width: "100%",
                  }}
                />
                <div style={{ width: "0.4rem" }} />
                <Button className="btn-plus">
                  <Link to="/customer-list">
                    <i className="pi  pi-plus" style={{ fontSize: "1rem" }}></i>
                  </Link>
                </Button>
              </div>
              {inputErrors.cus && (
                <small style={{ color: "red" }}>
                  Lütfen bir müşteri seçin.
                </small>
              )}
              <div style={{ marginTop: "1rem" }}>
                <Dropdown
                  filter
                  options={
                    Array.isArray(departments) && departments.length > 0
                      ? departments.map((dep) => ({
                          label: dep.department,
                          value: dep.id,
                        }))
                      : []
                  }
                  value={dep}
                  placeholder="Departman"
                  onChange={(e) => {
                    setDep(e.target.value);
                    setInputErrors({ ...inputErrors, dep: false });
                  }}
                  style={{
                    border: "1px solid gray",
                    padding: "0rem",
                    width: "100%",
                  }}
                />
                {inputErrors.dep && (
                  <small style={{ color: "red" }}>
                    Lütfen bir departman seçin.
                  </small>
                )}
              </div>
            </div>

            <div className="col-6">
              <div
                style={{
                  marginBottom: "1rem",
                }}
              >
                <Dropdown
                  filter
                  options={statusType}
                  placeholder="Durum"
                  value={taskStatus}
                  onChange={(e) => {
                    setTaskStatus(e.value);
                    setInputErrors({ ...inputErrors, taskStatus: false });
                  }}
                  style={{
                    border: "1px solid gray",
                    width: "100%",
                  }}
                />
                {inputErrors.taskStatus && (
                  <small style={{ color: "red" }}>
                    Lütfen bir durum seçin.
                  </small>
                )}
              </div>
              <InputText
                type="text"
                className="p-inputtext-lg"
                placeholder="Açıklama"
                onChange={(e) => {
                  const inputValue = e.target.value;
                  setDesc(inputValue);
                  setInputErrors({
                    ...inputErrors,
                    desc: inputValue.trim() === "",
                  });
                }}
                value={desc}
                style={{
                  width: "100%",
                 
                }}
              />
              {inputErrors.desc && (
                <small style={{ color: "red" }}>
                  Lütfen bir açıklama girin.
                </small>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  label="Ekle"
                  disabled={isButtonDisable}
                  onClick={newCreate}
                  style={{width:"9rem"}}
                  className="btn-excel-two mt-2"
                />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </Flex>
  );
}
