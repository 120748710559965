import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { Flex } from "@chakra-ui/react";
import { useSelector } from "react-redux";

export default function DashboardJobTracking() {
  const [tally, setTally] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const data = useSelector((state) => state.DashboardReduce.value);
  useEffect(() => {
    if (data) {
      const tallyData = data?.tracks;

      const updatedTally = tallyData?.map((item) => {
        const remainingDays = calculateRemainingDays(
          item.renewal_date,
          item.starting_date
        );
        return {
          ...item,
          remainingDays,
        };
      });

      const filteredTally = updatedTally?.filter(
        (item) => item.remainingDays <= 30
      );

      // Kalan süreye göre sıralama
      filteredTally?.sort((a, b) => a.remainingDays - b.remainingDays);

      setCustomerList(data.customers);
      setTally(filteredTally);
    }
  }, [data]);

  const calculateRemainingDays = (renewalDate) => {
    const ONE_DAY = 24 * 60 * 60 * 1000;
    const renewalDateObject = new Date(renewalDate);
    const startingDateObject = new Date();
    const timeDiff = renewalDateObject - startingDateObject;
    const daysDiff = Math.ceil(timeDiff / ONE_DAY);
    return daysDiff;
  };

  return (
    <Flex
      border="2px solid teal"
      h="100%"
      justifyContent="center"
      alignItems="center"
    >
      <div
        className="card"
        style={{
          overflow: "auto",
          boxSizing: "border-box",
          width: "100%",
          height: "100%",
          position: "relative",
        }}
      >
        <DataTable
          value={tally}
          size="small"
          stripedRows
          scrollable
          scrollHeight="40vh"
          dataKey="id"
        >
          <Column
            field="title"
            header="Müşteri"
            body={(rowData) => {
              const cust = customerList?.find(
                (dept) => dept.id.toString() === rowData.customer
              );
              if (cust) {
                // Başlığı boşluklara göre bölerek ilk iki kelimeyi al
                const firstTwoWords =
                  cust.title && cust.title.split(" ").slice(0, 2).join(" ");
                return firstTwoWords;
              } else {
                return "Bilinmeyen";
              }
            }}
          ></Column>

          <Column
            field="service_type"
            header="Hizmet"
            body={(rowData) => {
              const serviceType = rowData.service_type;
              let serviceTypeName;

              switch (serviceType) {
                case "0":
                  serviceTypeName = "Alan Adı";
                  break;
                case "1":
                  serviceTypeName = "Hosting";
                  break;
                case "2":
                  serviceTypeName = "Hizmet";
                  break;
                default:
                  serviceTypeName = "Bilinmeyen Hizmet";
              }

              return <span>{serviceTypeName}</span>;
            }}
          ></Column>
          <Column
            header="Kalan Süre"
            body={(rowData) => {
              const remainingDays = calculateRemainingDays(
                rowData.renewal_date,
                rowData.starting_date
              );
              let backgroundColor;
              let textColor;

              if (remainingDays >= 30) {
                backgroundColor = "#4CAF50"; // Yeşil tonu
                textColor = "black";
              } else if (remainingDays >= 7) {
                backgroundColor = "#FFC107"; // Sarı tonu
                textColor = "black";
              } else {
                backgroundColor = "#F44336"; // Kırmızı tonu
                textColor = "black";
              }

              const style = {
                backgroundColor,
                borderRadius: "5px",
                color: textColor,
                padding: "5px",
                display: "inline-block",
              };

              return <span style={style}>{remainingDays} gün</span>;
            }}
          ></Column>
        </DataTable>
      </div>
    </Flex>
  );
}
