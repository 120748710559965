import { createSlice } from "@reduxjs/toolkit";

export const UserSlice = createSlice({
  name: "UserSlice",
  initialState: {
    value: null,
  },
  reducers: {
    addUserData: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { addUserData } = UserSlice.actions;
export default UserSlice.reducer;
