import React, { useEffect, useState } from "react";
import { Chart } from "primereact/chart";
import { GetDevices } from "../../../../../../../../core/Services";

export default function DevicesGraphAppearance({
  uniqueCustomerId,
  DataTypes,
  appearanceKey,
}) {
  const [devicesData, setDevicesData] = useState({});
  const [devicesOptions, setDevicesOptions] = useState({});
  const [devices, setDevices] = useState([]);

  useEffect(() => {
    GetDevices(uniqueCustomerId)
      .then((data) => {
        setDevices(data.data);
      })
      .catch((error) => {
        console.log("Ülke Veri Çekme Hatası", error);
      });
  }, [uniqueCustomerId, appearanceKey]);

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const data = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [
            documentStyle.getPropertyValue("--blue-500"),
            documentStyle.getPropertyValue("--yellow-500"),
            documentStyle.getPropertyValue("--green-500"),
          ],
          hoverBackgroundColor: [
            documentStyle.getPropertyValue("--blue-400"),
            documentStyle.getPropertyValue("--yellow-400"),
            documentStyle.getPropertyValue("--green-400"),
          ],
        },
      ],
    };

    // Gelen veriyi dönüştürme
    devices?.forEach((item) => {
      const dataTypeName = DataTypes.find(
        (dt) => dt.value === item.devices
      )?.name;
      data.labels.push(dataTypeName ? dataTypeName : item.devices);
      data.datasets[0].data.push(parseInt(item.value));
    });

    const options = {
      cutout: "60%",
    };

    setDevicesData(data);
    setDevicesOptions(options);
  }, [DataTypes, devices, uniqueCustomerId]);

  return (
    <div>
      <div className="mb-3 font-bold text-xl ml-5">Görünüm:</div>
      <Chart
        style={{ width: "30rem", height: "20rem" }}
        type="doughnut"
        data={devicesData}
        options={devicesOptions}
      />
    </div>
  );
}
