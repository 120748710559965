import React from "react";

export default function BestKeywordsList({keywords}) {
 
 const browser = [
   { value: "0", label: "Google" },
   { value: "1", label: "Yandex" },
   { value: "2", label: "Diğer" },
 ];

   const months = [
     { name: "Ocak", value: "01" },
     { name: "Şubat", value: "02" },
     { name: "Mart", value: "03" },
     { name: "Nisan", value: "04" },
     { name: "Mayıs", value: "05" },
     { name: "Haziran", value: "06" },
     { name: "Temmuz", value: "07" },
     { name: "Ağustos", value: "08" },
     { name: "Eylül", value: "09" },
     { name: "Ekim", value: "10" },
     { name: "Kasım", value: "11" },
     { name: "Aralık", value: "12" },
   ];

  return (
    <div className="w-full">
      <table
        style={{
          className: "w-full",
          fontFamily: "arial, sans-serif",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              Sıralama
            </th>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              Dönem
            </th>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              Platform
            </th>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              Anahtar Kelimeler
            </th>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              Tıklamalar
            </th>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              Gösterimler
            </th>
          </tr>
        </thead>
        <tbody>
          {keywords?.map((keyword, index) => (
            <tr
              key={keyword.id}
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
            >
              <td
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                {index + 1}
              </td>
              <td
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                {months?.find((months) => months.value === keyword.month)
                  ?.name || ""}
              </td>
              <td
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                {browser.find(
                  (browser) =>
                    Number(browser.value) === Number(keyword.platform)
                )?.label || ""}
              </td>
              <td
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                {keyword.keywords}
              </td>
              <td
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                {keyword.total}
              </td>
              <td
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
              >
                {keyword.screening}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
