import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { DataTable } from "primereact/datatable";
import React, { useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { DeleteTemplateService } from "../../../core/Services";
const Datatables = ({ templates }) => {
  const [id, setId] = useState();
  const toast = useRef(null);

  const confirmDelete = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Silmek istediğinize emin misiniz?",
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
      rejectClassName: "confirm-popup-reject",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      accept,
    });
  };
  const accept = () => {
    DeleteTemplateService(id);

    toast.current.show({
      severity: "info",
      summary: "İşlem Başarılı!",
      detail: "Başarıyla silindi.",
      life: 3000,
    });
  };
  return (
    <div>
      <Toast ref={toast} />
      <DataTable
        value={templates}
        stripedRows
        scrollable
        scrollHeight="400px"
        rows={5}
      >
        <Column field="name" header="Şablon Adı" />
        <Column field="description" header="İçerik"></Column>
        <Column
          body={(rowData) => {
            return (
              <div>
                <ConfirmPopup />
                <Button
                  icon="pi pi-trash"
                  onClick={(e) => {
                    setId(rowData?.id);
                    confirmDelete(e);
                  }}
                  className="btn-excel-two"
                  style={{ marginRight: "10px" }}
                />
              </div>
            );
          }}
          header="Sil"
        ></Column>
      </DataTable>
    </div>
  );
};

export default Datatables;
