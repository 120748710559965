import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import { Flex } from "@chakra-ui/react";

import { useSelector } from "react-redux";

export default function DashboardBusinessStatus() {
  const [tasksData, setTasks] = useState(null);
  const [users, setUsers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const data = useSelector((state) => state.DashboardReduce.value);
  useEffect(() => {
    const fetchData = async () => {
      if (data) {
        const tasksData = data?.tasks;
        const sortedTasks = [...tasksData]?.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        setTasks(sortedTasks);
        setCustomers(data.customers);
        setUsers(data.users);
      }
    };

    fetchData();
  }, [data]); // Sadece data değiştiğinde useEffect çalışacak şekilde bağımlılığı güncelledik

  const statusBodyTemplate = (rowData) => {
    const getStatusLabel = (status) => {
      switch (status) {
        case "0":
          return "Boşta";
        case "1":
          return "İşlemde";
        case "2":
          return "Tamamlamdı";
        default:
          return null;
      }
    };

    const statusLabel = getStatusLabel(rowData.status);

    return <Tag value={statusLabel} severity={getStatus(rowData)}></Tag>;
  };

  const getStatus = (tsk) => {
    switch (tsk.status) {
      case 0:
        return "danger";
      case 1:
        return "warning";
      case 2:
        return "success";
      default:
        return null;
    }
  };

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    return new Date(dateString).toLocaleString("tr-TR", options);
  };

  return (
    <Flex
      border="2px solid teal"
      h="100%"
      justifyContent="center"
      alignItems="center"
    >
      <div
        className="card"
        style={{
          overflow: "auto",
          boxSizing: "border-box",
          width: "100%",
          height: "100%",
          position: "relative",
        }}
      >
        <DataTable
          stripedRows
          size="small"
          value={tasksData}
          scrollable
          scrollHeight="40vh"
          dataKey="id"
        >
          <Column
            field="title"
            header="Müşteri"
            body={(rowData) => {
              const cust = customers?.find(
                (dept) => dept.id.toString() === rowData.name
              );
              if (cust) {
                // Başlığı boşluklara göre bölerek ilk iki kelimeyi al
                const firstTwoWords =
                  cust.title && cust.title.split(" ").slice(0, 2).join(" ");
                return firstTwoWords;
              } else {
                return "Bilinmeyen";
              }
            }}
          ></Column>
          <Column
            field="created_at"
            header="Oluşturulma Tarihi"
            body={(rowData) => formatDate(rowData.created_at)}
            style={{ minWidth: "6rem" }}
          ></Column>
          <Column
            field="designated_person"
            header="Atanan Kişi"
            body={(rowData) => {
              const designatedPersons = rowData?.designated_person
                ? rowData.designated_person.split(",")
                : [];

              const assignedUsers = designatedPersons?.map(
                (designatedPerson) => {
                  const user = users?.find(
                    (usr) => usr?.id.toString() === designatedPerson?.trim()
                  );

                  return user ? user.name : "Bilinmeyen"; // Kullanıcı adını bul veya "Unknown User" döndür
                }
              );

              return assignedUsers.join(", "); // Virgülle ayrılmış kullanıcı adlarını birleştir ve sütuna yazdır
            }}
            style={{ minWidth: "6rem" }}
          ></Column>
          <Column
            field="status"
            header="Durum"
            body={statusBodyTemplate}
            style={{ minWidth: "6rem" }}
          ></Column>
        </DataTable>
      </div>
    </Flex>
  );
}
