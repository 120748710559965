import React from "react";
import Sidebar from "../../../../_ekdizayn/layout/Sidebar/Sidebar";
import NavBar from "../../../../_ekdizayn/layout/Navbar/components/Header";
import { Grid, GridItem } from "@chakra-ui/react";
import { BreadCrumb } from "primereact/breadcrumb";
import { Menu } from "primereact/menu";
import { useNavigate } from "react-router-dom";
import Reports from "./components/Reports";

export default function MailReportsPage() {
  const history = useNavigate();
  const items = [
    {
      label: "Mail Gönder",
      icon: "pi pi-envelope",
      url: "/email",
    },
    {
      label: "Raporlar",
      icon: "pi pi-chart-line",
      url: "/email/reports",
    },
    {
      label: "Rehber",
      icon: "pi pi-user",
      url: "/email/persons",
    },
  ];
  const navigateTo = (url) => {
    history.push(url);
  };
  const getTurkishLabel = (url) => {
    switch (url) {
      case "/email/reports":
        return "Email -> Raporlar";
      // Diğer durumlar için gerektiğinde eşleme yapabilirsiniz
      default:
        return "Sayfa"; // Varsayılan olarak "Sayfa" kullanılabilir veya istediğiniz bir şeyi kullanabilirsiniz
    }
  };
  const url = "/email/reports";
  const turkishLabel = getTurkishLabel(url); // "Ürünler"

  const itemsBread = [{ label: turkishLabel, url: url }];
  const home = { icon: "pi pi-home", url: "/email/reports" };

  return (
    <Grid
      templateAreas={`"header header"
    "nav main"
   `}
      gridTemplateRows={"auto 1fr "}
      gridTemplateColumns={"auto 1fr"}
      h="auto"
      color="blackAlpha.700"
      fontWeight="bold"
    >
      <GridItem area={"header"}>
        <NavBar />
      </GridItem>
      <GridItem area={"nav"}>
        <Sidebar />
      </GridItem>
      <GridItem area={"main"} style={{ display: "flex", paddingTop: "2rem" }}>
        <div className="col-2">
          <BreadCrumb
            model={itemsBread}
            home={home}
            style={{ padding: "1rem" }}
          />
          <Menu
            model={items}
            style={{ background: "#e8bcb9", marginLeft: "1rem" }}
            onItemClick={(e) => navigateTo(e.item.url)}
          />
        </div>
        <div className="col-10">
          <Reports />
        </div>
      </GridItem>
    </Grid>
  );
}
