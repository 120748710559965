import React from 'react'
import NotesPages from './NotesPages'

export default function Notes({ uniqueCustomerId, selectedCustomer }) {
  return (
    <div>
      <NotesPages uniqueCustomerId={uniqueCustomerId} selectedCustomer={selectedCustomer} />
    </div>
  );
}
