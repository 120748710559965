import React from "react";
import { Card } from "primereact/card";

export default function Overview({ items, days }) {
  const bgStatusColor = (item) => {
    switch (Number(item)) {
      case 1:
        return "#ADD8E6";
      case 2:
        return "#F08080";
      case 3:
        return "#FFD700";
      case 4:
        return "#90EE90";
      case 5:
        return "#D8BFD8";
      default:
        return "";
    }
  };
  const groupedItems = items.reduce((acc, item) => {
    const day = item.day.split(" ")[0];
    if (!acc[day]) {
      acc[day] = [];
    }
    acc[day].push(item);
    return acc;
  }, {});
  return (
    <div className="w-full" style={{ display: "flex" }}>
      {days.map((day) => (
        <div key={day.value} style={{ width: "14.28%", padding: "0.1rem" }}>
          <strong>{day.label}</strong>

          {groupedItems[day.value]?.map((item, index) => (
            <div className="w-full" key={index}>
              <Card
                style={{
                  paddingLeft: "0.5rem",
                  marginBottom: "0.2rem",
                  background: bgStatusColor(item?.status),
                  fontSize: `calc(10px + 0.3vw)`,
                }}
              >
                ({item?.time_hour}) {item?.title}
              </Card>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
