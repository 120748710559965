import React, { useEffect, useState } from "react";
import { Chart } from "primereact/chart";
import { GetSourceAds } from "../../../../../../../../core/Services";

export default function ChartJ({ uniqueCustomerId, months, appearanceKey }) {
  const [viewVisitorData, setViewVisitorData] = useState([]);
  const [chartOptions, setChartOptions] = useState();

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    GetSourceAds(uniqueCustomerId)
      .then((response) => {
        const data = response.data;
        const type1Data = data.find((entry) => entry.type === "1");
        const type2Data = data.find((entry) => entry.type === "2");
        const type3Data = data.find((entry) => entry.type === "3");

        const datalist = {
          labels: ["Tıklamalar", "Maliyet"],
          datasets: [
            {
              label: "Google Ads",
              backgroundColor: documentStyle.getPropertyValue("--blue-500"),
              borderColor: documentStyle.getPropertyValue("--blue-500"),
              data: [type1Data?.clicks, type1Data?.cost],
            },
            {
              label: "Arama Ortakları",
              backgroundColor: documentStyle.getPropertyValue("--pink-500"),
              borderColor: documentStyle.getPropertyValue("--pink-500"),
              data: [type2Data?.clicks, type2Data?.cost],
            },
            {
              label: "Görüntülü Reklam Ağı",
              backgroundColor: documentStyle.getPropertyValue("--orange-500"),
              borderColor: documentStyle.getPropertyValue("--orange-500"),
              data: [type3Data?.clicks, type3Data?.cost],
            },
          ],
        };
        const options = {
          indexAxis: "y",
          maintainAspectRatio: false,
          aspectRatio: 0.8,
          plugins: {
            legend: {
              labels: {
                fontColor: textColor,
              },
            },
          },
          scales: {
            x: {
              ticks: {
                color: textColorSecondary,
                font: {
                  weight: 500,
                },
              },
              grid: {
                display: false,
                drawBorder: false,
              },
            },
            y: {
              ticks: {
                color: textColorSecondary,
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false,
              },
            },
          },
        };

        setViewVisitorData(datalist);
        setChartOptions(options);
      })
      .catch((error) => {
        console.log("ViewVisitor Hatası", error);
      });
  }, [uniqueCustomerId, months, appearanceKey]);

  return (
    <div>
      <div className="mb-3 font-bold ml-5 ">Görünüm:</div>
      {viewVisitorData && (
        <Chart type="bar" data={viewVisitorData} options={chartOptions} />
      )}
    </div>
  );
}
