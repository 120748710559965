import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import React from "react";
import yearTime from "../../../../../../_ekdizayn/../_ekdizayn/api/guarantee-year.json"
import { AddTimeGuarantee } from "../../../../../core/Services";
export default function AddTimeGuaranteeDialog({
  toast,
  selectedId,
  time,
  setTime,
  setSelectedId,
  timeAddDialog,
  setTimeAddDialog,
}) {
  const handleAddTime = () => {
    AddTimeGuarantee(selectedId , time)
      .then((response) => {
        if (response.message === "Hata") {
          toast({
            title: "Hata",
            description: "Kullanıcya Ait Veri Mevcut",
            status: "error",
            duration: 3000,
            position: "top-right",
            isClosable: true,
          });
        } else {
          toast({
            title: "Başarılı",
            description: "Veri başarılı bir şekilde kaydedildi.",
            status: "success",
            duration: 3000,
            position: "top-right",
            isClosable: true,
          });

          setSelectedId("");
          setTime("");
        }
      })
      .catch((error) => {
        console.error("Backend'e gönderme hatası:", error);
        toast({
          title: "Hata",
          description: "Veri gönderilirken bir hata oluştu.",
          status: "error",
          duration: 3000,
          position: "top-right",
          isClosable: true,
        });
      });
  };
  return (
    <Dialog
      header="Ek Süre"
      visible={timeAddDialog}
      style={{ width: "50vw" }}
      onHide={() => {
        setTimeAddDialog(false);
        setSelectedId("");
        setTime("")
      }}
      footer={
        <div>
          <Button
            label="Onayla"
            icon="pi pi-check"
            onClick={handleAddTime}
            className="btn-excel-two"
            style={{ width:"9rem" }}
          />
        </div>
      }
    >
      <div>
        <Dropdown
          options={yearTime}
          value={time}
          className="w-full"
          placeholder="Ek Süre"
          onChange={(e) => setTime(e.target.value)}
          style={{
            border: "1px solid gray",
          }}
        />
      </div>
    </Dialog>
  );
}
