import React, { useState } from "react";
import { Button, Input, useToast } from "@chakra-ui/react";
import { AddRivalAds } from "../../../../../../../../core/Services";
import { Message } from "primereact/message";

export default function AddData({
  uniqueCustomerId,
  selectedMonth,
  selectedCustomer,
  updateTableData
}) {
  
  const [value, setValue] = useState("");
  const [cost, setCost] = useState("");
  const [error, setError] = useState(false);
  const toast = useToast();



  const handleSave = () => {
    if (!selectedCustomer || !selectedMonth || !value) {
      setError(true);
      toast({
        title: "Hata",
        description: "Lütfen tüm alanları doldurunuz.",
        status: "error",
        duration: 3000,
        position: "top-right",
        isClosable: true,
      });
      return;
    }

    AddRivalAds(uniqueCustomerId, value, cost, selectedMonth)
      .then((response) => {
        if (response.message === "Hata") {
          toast({
            title: "Hata",
            description: "Kullanıcya Ait Bu Ay Verisi Zaten Mevcut",
            status: "error",
            duration: 3000,
            position: "top-right",
            isClosable: true,
          });
        } else {
          setValue("");
          setCost("");
          setError(false);

          toast({
            title: "Başarılı",
            description: "Veri başarılı bir şekilde kaydedildi.",
            status: "success",
            duration: 3000,
            position: "top-right",
            isClosable: true,
          });
          updateTableData();
        }
      })
      .catch((error) => {
        console.error("Backend'e gönderme hatası:", error);
        toast({
          title: "Hata",
          description: "Veri gönderilirken bir hata oluştu.",
          status: "error",
          duration: 3000,
          position: "top-right",
          isClosable: true,
        });
      });
  };

  return (
    <div>
      <div className="mt-2">Görünen Url Alanı :</div>
      <div>
        <Input
          placeholder="Görünen Url Alanı"
          w="30rem"
          name="value"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          borderColor={error && !value ? "red" : ""}
        />
      </div>

      <div className="mt-2">Gösterim Payı (%) : </div>
      <div>
        <Input
          placeholder="Gösterim Payı (%)"
          w="30rem"
          value={cost}
          onChange={(e) => setCost(e.target.value)}
          borderColor={error && !value ? "red" : ""}
        />
      </div>

      <div className="mt-5 flex justify-content-end">
        <Button onClick={handleSave}> Kaydet</Button>
      </div>
      <Message className="mt-4 w-full" severity="info" text="Google Ads" />
    </div>
  );
}
