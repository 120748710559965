import React from 'react'
import LoginSplit from './components/LoginSplit';

function Login() {
  return (
  <>
   <LoginSplit />
  </>

  )
}
 export default Login;