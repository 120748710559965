import {
  Box,
  Card,
  CardBody,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  TabPanel,
} from "@chakra-ui/react";
import React, { useEffect } from "react";

import { useDispatch } from "react-redux";
import { addTaskData } from "../../../../../../../redux/TaskSlice";
import { addCustomerData } from "../../../../../../../redux/CustomerSlice";
import {  SupportsByUniqueId } from "../../../../../../../core/Services";
import CustomerSupportRequest from "./CustomerSupportRequest";
import CustomerSupportRequestStatus from "./CustomerSupportRequestStatus";
import { useAuth } from "../../../../../../../auth/core/AuthContext";

export default function SupportPage({customer}) {
  const dispatch = useDispatch();
  const {userData} = useAuth();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const taskData = await SupportsByUniqueId( userData?.unique_id);

        dispatch(addTaskData(taskData.supports));
        dispatch(addCustomerData(taskData.customers));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
 
  return (
    <Box mt="5">
      <Card>
        <CardBody>
          <Tabs align="end" variant="enclosed">
            <TabList>
              <Tab>Bekleyen</Tab>
              <Tab>Tamamlanan</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <CustomerSupportRequest customer={customer} />
              </TabPanel>
              <TabPanel>
                <CustomerSupportRequestStatus customer={customer} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </CardBody>
      </Card>
    </Box>
  );
}
