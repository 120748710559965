import React, { useEffect, useState } from "react";
import ViewVisitorChart from "../view-visitor-chart/Page";
import SourceAds from "../source-ads/Page";
import DeviceAds from "../device-ads/Page";
import AdsCosts from "../ads-costs/Page";
import AdsRival from "../ads-rival/Page";
import { useAuth } from "../../../../../../auth/core/AuthContext";
import { GetAdsAll } from "../../../../../../core/Services";

export default function AdsMain({ selectedMonth }) {
  const [googleAds, setGoogleAds] = useState([]);
  const [sourceAds, setSourceAds] = useState([]);
  const { userData } = useAuth();
  useEffect(() => {
    GetAdsAll(userData?.unique_id, selectedMonth)
      .then((data) => {
        setGoogleAds(data?.ads);
        setSourceAds(data?.source_ads);
      })
      .catch((error) => {
        console.log("Veri Çekme Hatası", error);
      });
  }, [selectedMonth, userData?.unique_id]);
  return (
    <div className="content printCss3">
      <div className=" row " style={{ display: "flex" }}>
        <div className="col-6">
          <strong>Google Reklamları</strong>
          <ViewVisitorChart googleAds={googleAds} />
        </div>
        <div className="col-6">
          <strong>Kaynak</strong>
          <SourceAds selectedMonth={selectedMonth} sourceAds={sourceAds} />
        </div>
      </div>
      <div className=" row" style={{ display: "flex" }}>
        <div className="col-6">
          <strong>Reklam Harcamaları</strong>
          <AdsCosts selectedMonth={selectedMonth} />
        </div>
        <div className="col-6">
          <strong>Cihazlar</strong>
          <DeviceAds selectedMonth={selectedMonth} />
        </div>
      </div>
      <div className=" row" style={{ display: "flex" }}>
        <div className="col-12">
          <strong>Rakip Analizi</strong>
          <AdsRival selectedMonth={selectedMonth} />
        </div>
      </div>
    </div>
  );
}
