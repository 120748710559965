import React, { useEffect, useState } from "react";

import { Grid, GridItem } from "@chakra-ui/react";

import { TabView, TabPanel } from "primereact/tabview";
import NavBar from "../../../../_ekdizayn/layout/Navbar/Navbar";
import Sidebar from "../../../../_ekdizayn/layout/Sidebar/Sidebar";
import PaymentSettings from "./components/PaymentSettings";
import { EntegrationService } from "../../../core/Services";
import SmsSettings from "./components/SmsSettings";

export default function Entegration() {
  const [smsEntegrations, setSmsEntegrations] = useState([]);
  const [payEntegrations, setPayEntegrations] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await EntegrationService();

        setPayEntegrations(data?.payment);
        setSmsEntegrations(data?.sms);
      } catch (error) {
        console.error("Error fetching template data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Grid
      templateAreas={`"header header"
    "nav main"
   `}
      gridTemplateRows={"auto 1fr "}
      gridTemplateColumns={"auto 1fr"}
      h="auto"
      color="blackAlpha.700"
      fontWeight="bold"
    >
      <GridItem area={"header"}>
        <NavBar />
      </GridItem>
      <GridItem area={"nav"}>
        <Sidebar />
      </GridItem>
      <GridItem area={"main"} p={5}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <strong>Entegrasyon Ayarları</strong>
        </div>
        <TabView>
          <TabPanel
            header="Ödeme Entegrasyonu"
            leftIcon="pi pi-credit-card mr-2"
          >
            <PaymentSettings data={payEntegrations} />
          </TabPanel>
          <TabPanel header="Sms Entegrasyonu" leftIcon="pi pi-envelope mr-2">
            {" "}
            <SmsSettings data={smsEntegrations} />
          </TabPanel>
          <TabPanel
            header="Mail Entegrasyonu"
            leftIcon="pi pi-inbox mr-2"
          ></TabPanel>
        </TabView>
      </GridItem>
    </Grid>
  );
}
