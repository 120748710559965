import React, { useEffect, useState } from "react";
import { Card, CardHeader, Flex, Heading } from "@chakra-ui/react";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  AddMailPersonService,
  MailPersonService,
  DeleteMailPersonService,
} from "../../../../core/Services";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useToast } from "@chakra-ui/react";
export default function Persons() {
  const [persons, setPersons] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [dialogsDelete, setDialogsDelete] = useState({});
  const [selectedData, setSelectedData] = useState([]);
  const [title, setTitle] = useState("");
  const [mail, setMail] = useState("");
  const [desc, setDesc] = useState("");
  const toast = useToast();
  const hideDialog = () => {
    setAddModal(false);
    setDialogsDelete(false);
    setName("");
    setSurname("");
    setMail("");
    setTitle("");
    setDesc("");
  };
  useEffect(() => {
    MailPersonService()
      .then((data) => {
        setPersons(data?.mail_persons);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  }, [addModal, dialogsDelete]);

  const createNewPerson = () => {
    if (name.length === 0 || surname.length === 0 || mail.length <= 0) {
      toast({
        title: "Hata!",
        description: "Lütfen alanları doldurunuz",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }

    AddMailPersonService(name, surname, desc, title, mail).then((res) => {
      if (res?.status === 200) {
        toast({
          title: "Başarılı!",
          description: "Kişi başarıyla eklendi.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        hideDialog();
      } else {
        toast({
          title: "Hata!",
          description: "Ekleme işlemi başarısız.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        hideDialog();
      }
    });
  };
  const accessors = (rowData) => {
    const visible = dialogsDelete[rowData.id] || false;
    return (
      <div style={{ display: "flex" }}>
        <div style={{ color: "#ae445a" }}>
          <Button
            icon="pi pi-trash"
            onClick={() => {
              setSelectedData(rowData);
              setDialogsDelete((prevDialogs) => ({
                ...prevDialogs,
                [rowData.id]: true,
              }));
            }}
            severity="danger"
          />
        </div>
        <Dialog
          header="Kişiyi silmek istediğinize emin misiniz?"
          visible={visible}
          style={{ width: "50vw" }}
          onHide={hideDialog}
          footer={
            <div>
              <Button
                label="İptal"
                icon="pi pi-times"
                onClick={hideDialog}
                className="btn-excel-two"
              />
              <Button
                label="Kişiyi Sil"
                icon="pi pi-check"
                onClick={() => {
                  DeleteMailPersonService(selectedData.id);
                  hideDialog();
                }}
                className="btn-excel-two"
                style={{ marginLeft: "15px" }}
              />
            </div>
          }
        ></Dialog>
      </div>
    );
  };
  return (
    <Card p={2} className="w-full">
      <CardHeader>
        <Flex justifyContent="space-between" alignItems="center">
          <Heading size="md">Rehber</Heading>
          <Button
            label="Kişi Ekle"
            onClick={() => setAddModal(true)}
            className="btn-excel-two"
            style={{
              marginTop: "1rem",
            }}
          />
        </Flex>
      </CardHeader>
      <DataTable
        stripedRows
        size="small"
        value={persons}
        dataKey="id"
        tableStyle={{ minWidth: "50rem" }}
        rows={15}
        rowsPerPageOptions={[15, 30, 60, 100]}
        scrollable
        paginator
        selectionMode="single"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate=" {totalRecords} duyurudan {first} - {last} arası gösteriliyor"
      >
        <Column field="accessor" body={accessors} header="İşlemler"></Column>
        <Column field="name" header="İsim"></Column>
        <Column field="surname" header="Soy İsim" />
        <Column field="mail" header="Numara"></Column>
        <Column field="title" header="Ünvan" />
        <Column field="desc" header="Açıklama" />
      </DataTable>
      <Dialog
        visible={addModal}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        style={{ width: "60vw" }}
        header="Kişi Ekle"
        onHide={hideDialog}
        modal
      >
        <div className="confirmation-content row">
          <div className="col-4" style={{ display: "inline-block" }}>
            <label className="pl-2">İsim</label>
            <InputText
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              className="p-inputtext-lg w-full"
              placeholder="İsim"
            />
          </div>
          <div className="col-4" style={{ display: "inline-block" }}>
            <label className="pl-2">Soy İsim</label>
            <InputText
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
              type="text"
              className="p-inputtext-lg w-full"
              placeholder="Soy İsim"
            />
          </div>
          <div className="col-4" style={{ display: "inline-block" }}>
            <label className="pl-2">Email Adresi</label>
            <InputText
              value={mail}
              onChange={(e) => setMail(e.target.value)}
              type="text"
              className="p-inputtext-lg w-full"
              placeholder="Email Adresi"
            />
          </div>
        </div>
        <div className="confirmation-content row mt-1">
          <div className="col-4" style={{ display: "inline-block" }}>
            <label className="pl-2">Ünvan</label>
            <InputText
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              type="text"
              className="p-inputtext-lg w-full"
              placeholder="Ünvan"
            />
          </div>

          <div className="col-4" style={{ display: "inline-block" }}>
            <label className="pl-2">Açıklama</label>
            <InputText
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
              type="text"
              className="p-inputtext-lg w-full"
              placeholder="Açıklama"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button
            label="Ekle"
            onClick={createNewPerson}
            className="btn-excel-two"
          />
        </div>
      </Dialog>
    </Card>
  );
}
